var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import router from "@/router/router";
import globalCSS from "~/styles/global.css";
import changePasswordCSS from "./change-password.css";
import "@/components/start-container/start-container";
import "@/components/loading-spinner/loading-spinner";
import "@/components/uf-modal/uf-modal";
import low6Api from "@/services/api/low6/low6.api";
let ChangePasswordView = class ChangePasswordView extends LitElement {
    constructor() {
        super(...arguments);
        this.oldPassword = "";
        this.oldPasswordTouched = false;
        this.newPassword = "";
        this.newPasswordTouched = false;
        this.loading = false;
        this.errorModalVisible = false;
        this.errorModalText = "";
        this.successModalVisible = false;
    }
    get changeDisabled() {
        return !this.oldPassword || !this.newPassword;
    }
    render() {
        return html `
      <start-container heading="Change Password" ?historicBack=${true} ?noHelp=${true}>
      
        <form @submit=${this.attemptChangePassword} novalidate>

          <div class="input">
            <input
              id="old"
              type="password"
              placeholder="current password"
              @input=${this.onInput}
              @blur=${this.onBlur}
              class=${this.oldPasswordTouched && !this.oldPassword ? "error" : ""}
            >
            <span>Field is required</span>
          </div>

          <div class="input">
            <input
              id="new"
              type="password"
              placeholder="new password"
              @input=${this.onInput}
              @blur=${this.onBlur}
              class=${this.newPasswordTouched && !this.newPassword ? "error" : ""}
            >
            <span>Field is required</span>
          </div>

          <button ?disabled=${this.changeDisabled}>Save</button>

          ${when(this.loading, () => html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            `)}
          
        </form>

        ${when(this.errorModalVisible, () => html `
            <uf-modal>
              <h3>Error</h3>
              <p>${this.errorModalText}</p>
              <div>
                <button @click=${this.hideErrorModal}>OK</button>
              </div>
            </uf-modal>
          `)}

        ${when(this.successModalVisible, () => html `
            <uf-modal>
              <h3>Success</h3>
              <p>Password changed.</p>
              <div>
                <button @click=${this.hideSuccessModal}>OK</button>
              </div>
            </uf-modal>
          `)}

      </start-container>
    `;
    }
    onInput(e) {
        const input = e.composedPath()[0];
        switch (input.id) {
            case "old":
                this.oldPassword = input.value;
                break;
            case "new":
                this.newPassword = input.value;
        }
    }
    onBlur(e) {
        const input = e.composedPath()[0];
        switch (input.title) {
            case "old password":
                if (!this.oldPasswordTouched) {
                    this.oldPasswordTouched = true;
                }
                break;
            case "new password":
                if (!this.newPasswordTouched) {
                    this.newPasswordTouched = true;
                }
                break;
        }
    }
    /**
     * Attempts to change password with Low6
     * @param e Form SubmitEvent
     */
    async attemptChangePassword(e) {
        e.preventDefault();
        this.loading = true;
        try {
            const passwords = {
                old: this.oldPassword,
                new: this.newPassword
            };
            await low6Api.changePassword(passwords);
            this.loading = false;
            this.successModalVisible = true;
        }
        catch (error) {
            this.loading = false;
            this.showErrorModal(error.message);
        }
    }
    /**
     * Displays an error modal and and sets an appropriate message based on the error type.
     * @param errorType Type of error.
     */
    showErrorModal(errorType) {
        switch (errorType) {
            case "client":
                this.errorModalText = "The user credentials were incorrect, please try again. The new password must contain at least 1 lowercase, 1 uppercase, and 1 numeric character.";
                break;
            case "server":
                this.errorModalText = "There was something wrong on our end, please try again.";
                break;
            case "other":
            default:
                this.errorModalText = "There was an issue, please try again.";
                break;
        }
        this.errorModalVisible = true;
    }
    hideErrorModal() {
        this.errorModalVisible = false;
        this.errorModalText = "";
    }
    hideSuccessModal() {
        this.successModalVisible = false;
        this.oldPasswordTouched = false;
        this.newPasswordTouched = false;
        if (this.form) {
            this.form.reset();
        }
        router.navigate('lobby');
    }
};
ChangePasswordView.styles = [globalCSS, changePasswordCSS];
__decorate([
    state()
], ChangePasswordView.prototype, "oldPassword", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "oldPasswordTouched", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "newPassword", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "newPasswordTouched", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "changeDisabled", null);
__decorate([
    state()
], ChangePasswordView.prototype, "loading", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "errorModalVisible", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "errorModalText", void 0);
__decorate([
    state()
], ChangePasswordView.prototype, "successModalVisible", void 0);
__decorate([
    query("form")
], ChangePasswordView.prototype, "form", void 0);
ChangePasswordView = __decorate([
    customElement("change-password-view")
], ChangePasswordView);
export { ChangePasswordView };
