var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let Icon21 = class Icon21 extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = "default";
    }
    render() {
        return html `
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.88284 30H18.8788V26.766H13.9438L16.3798 24.309C17.7238 22.965 18.6688 21.453 18.6688 19.689C18.6688 16.728 16.3168 15.006 13.6918 15.006C11.6548 15.006 9.68084 16.035 8.73584 18.219L11.5708 19.878C11.9698 18.954 12.7048 18.282 13.7338 18.282C14.7208 18.282 15.3088 18.912 15.3088 19.836C15.3088 20.697 14.6788 21.558 13.8178 22.44L8.88284 27.564V30Z" fill="#F6F3EE"/>
        <path d="M23.2511 15.3L19.4081 16.686L20.2271 19.563L22.8311 18.849V30H26.1911V15.3H23.2511Z" fill="#F6F3EE"/>
        <path d="M37.5378 21.18H34.1778V17.82H31.2378V21.18H27.8778V24.12H31.2378V27.48H34.1778V24.12H37.5378V21.18Z" fill="#F6F3EE"/>
        <circle class="${this.theme}" cx="23" cy="23" r="21.5" stroke="#FF284F" stroke-width="3"/>
      </svg> 
    `;
    }
};
Icon21.styles = [iconCSS, css `
    circle.default {
      stroke: "#FF284F"; 
    }
    circle.white {
      stroke: "#F6F3EE"; 
    }
    circle.black {
      stroke: "#000000"; 
    }
  `];
__decorate([
    property()
], Icon21.prototype, "theme", void 0);
Icon21 = __decorate([
    customElement("icon-21")
], Icon21);
export { Icon21 };
