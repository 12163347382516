var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
let PointsSVG = class PointsSVG extends ScopedElement {
    render() {
        return html `
      <svg viewBox="0 0 343 493" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M343 99.1514C343 87.9503 343 82.3498 340.82 78.0716C338.903 74.3083 335.843 71.2487 332.08 69.3312C327.802 67.1514 322.201 67.1514 311 67.1514H32C20.7989 67.1514 15.1984 67.1514 10.9202 69.3312C7.15695 71.2487 4.09734 74.3083 2.17987 78.0716C0 82.3498 0 87.9503 0 99.1514V99.1514C0 110.352 0 115.953 2.17987 120.231C4.09734 123.994 7.15695 127.054 10.9202 128.971C15.1984 131.151 20.7989 131.151 32 131.151H311C322.201 131.151 327.802 131.151 332.08 128.971C335.843 127.054 338.903 123.994 340.82 120.231C343 115.953 343 110.352 343 99.1514V99.1514Z"
          fill="url(#paint0_linear_2894_30433)" />
        <rect x="256" y="76.1514" width="78" height="46" rx="10" fill="#0F0F0F" />
        <path d="M274.329 85.1516H269.229V86.2516H271.209V92.1516H272.359V86.2516H274.329V85.1516Z" fill="#858585" />
        <path
          d="M276.571 92.2816C278.041 92.2816 279.211 91.1316 279.211 89.6516C279.211 88.1616 278.041 87.0216 276.571 87.0216C275.111 87.0216 273.931 88.1616 273.931 89.6516C273.931 91.1316 275.111 92.2816 276.571 92.2816ZM276.571 91.2316C275.691 91.2316 275.011 90.5616 275.011 89.6516C275.011 88.7416 275.691 88.0716 276.571 88.0716C277.451 88.0716 278.131 88.7416 278.131 89.6516C278.131 90.5616 277.451 91.2316 276.571 91.2316Z"
          fill="#858585" />
        <path
          d="M282.88 88.1916V87.1516H281.64V85.7516L280.56 86.0716V87.1516H279.64V88.1916H280.56V90.5916C280.56 91.8916 281.22 92.3516 282.88 92.1516V91.1816C282.06 91.2216 281.64 91.2316 281.64 90.5916V88.1916H282.88Z"
          fill="#858585" />
        <path
          d="M287.615 87.1516V87.8616C287.235 87.3416 286.645 87.0216 285.865 87.0216C284.505 87.0216 283.375 88.1616 283.375 89.6516C283.375 91.1316 284.505 92.2816 285.865 92.2816C286.645 92.2816 287.235 91.9616 287.615 91.4316V92.1516H288.695V87.1516H287.615ZM286.035 91.2516C285.135 91.2516 284.455 90.5816 284.455 89.6516C284.455 88.7216 285.135 88.0516 286.035 88.0516C286.935 88.0516 287.615 88.7216 287.615 89.6516C287.615 90.5816 286.935 91.2516 286.035 91.2516Z"
          fill="#858585" />
        <path d="M289.954 92.1516H291.034V84.8516H289.954V92.1516Z" fill="#858585" />
        <path
          d="M297.438 85.1516H294.858V92.1516H296.008V89.7516H297.438C298.758 89.7516 299.758 88.7416 299.758 87.4516C299.758 86.1516 298.758 85.1516 297.438 85.1516ZM297.438 88.6716H296.008V86.2316H297.438C298.118 86.2316 298.608 86.7416 298.608 87.4516C298.608 88.1516 298.118 88.6716 297.438 88.6716Z"
          fill="#858585" />
        <path
          d="M302.694 92.2816C304.164 92.2816 305.334 91.1316 305.334 89.6516C305.334 88.1616 304.164 87.0216 302.694 87.0216C301.234 87.0216 300.054 88.1616 300.054 89.6516C300.054 91.1316 301.234 92.2816 302.694 92.2816ZM302.694 91.2316C301.814 91.2316 301.134 90.5616 301.134 89.6516C301.134 88.7416 301.814 88.0716 302.694 88.0716C303.574 88.0716 304.254 88.7416 304.254 89.6516C304.254 90.5616 303.574 91.2316 302.694 91.2316Z"
          fill="#858585" />
        <path
          d="M306.851 86.4016C307.231 86.4016 307.541 86.0816 307.541 85.7116C307.541 85.3316 307.231 85.0216 306.851 85.0216C306.471 85.0216 306.161 85.3316 306.161 85.7116C306.161 86.0816 306.471 86.4016 306.851 86.4016ZM306.311 92.1516H307.391V87.1516H306.311V92.1516Z"
          fill="#858585" />
        <path
          d="M311.275 87.0216C310.595 87.0216 310.055 87.2716 309.725 87.7916V87.1516H308.645V92.1516H309.725V89.4716C309.725 88.4516 310.285 88.0316 310.995 88.0316C311.675 88.0316 312.115 88.4316 312.115 89.1916V92.1516H313.195V89.0816C313.195 87.7816 312.395 87.0216 311.275 87.0216Z"
          fill="#858585" />
        <path
          d="M317.148 88.1916V87.1516H315.908V85.7516L314.828 86.0716V87.1516H313.908V88.1916H314.828V90.5916C314.828 91.8916 315.488 92.3516 317.148 92.1516V91.1816C316.328 91.2216 315.908 91.2316 315.908 90.5916V88.1916H317.148Z"
          fill="#858585" />
        <path
          d="M318.912 88.5316C318.912 88.1716 319.252 88.0016 319.642 88.0016C320.052 88.0016 320.402 88.1816 320.582 88.5716L321.492 88.0616C321.142 87.4016 320.462 87.0216 319.642 87.0216C318.622 87.0216 317.812 87.6116 317.812 88.5516C317.812 90.3416 320.502 89.8416 320.502 90.7316C320.502 91.1316 320.132 91.2916 319.652 91.2916C319.102 91.2916 318.702 91.0216 318.542 90.5716L317.612 91.1116C317.932 91.8316 318.652 92.2816 319.652 92.2816C320.732 92.2816 321.602 91.7416 321.602 90.7416C321.602 88.8916 318.912 89.4416 318.912 88.5316Z"
          fill="#858585" />
        <path d="M281.935 99L278.255 100.3L278.935 102.66L281.575 101.92V113H284.335V99H281.935Z" fill="#F6F3EE" />
        <path
          d="M292.069 113.28C295.649 113.28 297.669 110.32 297.669 106C297.669 101.68 295.649 98.72 292.069 98.72C288.509 98.72 286.469 101.68 286.469 106C286.469 110.32 288.509 113.28 292.069 113.28ZM292.069 110.58C290.189 110.58 289.229 108.96 289.229 106C289.229 103.04 290.189 101.4 292.069 101.4C293.949 101.4 294.929 103.04 294.929 106C294.929 108.96 293.949 110.58 292.069 110.58Z"
          fill="#F6F3EE" />
        <path
          d="M304.667 113.28C308.247 113.28 310.267 110.32 310.267 106C310.267 101.68 308.247 98.72 304.667 98.72C301.107 98.72 299.067 101.68 299.067 106C299.067 110.32 301.107 113.28 304.667 113.28ZM304.667 110.58C302.787 110.58 301.827 108.96 301.827 106C301.827 103.04 302.787 101.4 304.667 101.4C306.547 101.4 307.527 103.04 307.527 106C307.527 108.96 306.547 110.58 304.667 110.58Z"
          fill="#F6F3EE" />
        <rect x="230" y="106.151" width="17" height="11" rx="3" fill="#926F34" />
        <circle cx="238.5" cy="89.6514" r="8.5" fill="#926F34" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M26 117.641V131.5H103V117.524C103 117.524 86.5189 107.662 77.9985 103.665C77.9985 103.665 77.3975 102.267 77.529 99.1229C77.6604 95.9783 77.2942 95.396 77.2942 95.396C77.2942 95.396 77.5747 91.9498 78.7027 88.9904C78.7027 88.9904 78.9668 89.9221 79.9939 89.9221C81.021 89.9221 81.5198 89.4039 81.5198 88.1752C81.5198 88.1752 83.5152 85.613 83.5152 83.1672C83.5152 80.7215 83.3568 80.5922 83.5152 79.5568C83.6737 78.5214 83.75 77.7062 82.9284 77.6934C82.1067 77.6806 81.2851 78.9745 81.2851 78.9745C81.2851 78.9745 81.6372 75.4724 81.6372 72.6854C81.6372 69.8984 81.4024 68.9585 81.4024 68.9585C81.4024 68.9585 81.2675 68.0874 81.5198 67.328C81.7722 66.5687 82.2135 59.8324 72.0122 57.3121C61.8109 54.7918 54.875 58.9426 54.875 58.9426C54.875 58.9426 48.3089 61.6399 49.3582 67.6774C49.4512 68.3732 49.4512 69.078 49.3582 69.7738C49.1998 70.8499 49.0859 75.7065 49.593 78.858C49.593 78.858 48.8453 77.6934 47.9497 77.6934C47.0541 77.6934 47.1281 78.561 47.1281 78.858C47.1281 79.155 47.4626 79.8247 47.3628 80.8379C47.263 81.8512 46.9485 83.7996 47.8323 85.613C48.7162 87.4263 49.2409 87.8258 49.2409 87.8258C49.2409 87.8258 49.0495 89.9221 50.6494 89.9221C51.6401 89.9944 52.1753 88.7575 52.1753 88.7575C52.1753 88.7575 53.3855 95.2399 53.8186 96.2112L53.5838 99.2393C53.5838 99.2393 53.7435 101.997 53.2317 103.432L41.6113 109.372C41.6113 109.372 30.7679 114.436 26 117.641Z"
          fill="#926F34" />
        <path
          d="M343 190.151C343 178.95 343 173.35 340.82 169.072C338.903 165.308 335.843 162.249 332.08 160.331C327.802 158.151 322.201 158.151 311 158.151H32C20.7989 158.151 15.1984 158.151 10.9202 160.331C7.15695 162.249 4.09734 165.308 2.17987 169.072C0 173.35 0 178.95 0 190.151V190.151C0 201.352 0 206.953 2.17987 211.231C4.09734 214.994 7.15695 218.054 10.9202 219.971C15.1984 222.151 20.7989 222.151 32 222.151H311C322.201 222.151 327.802 222.151 332.08 219.971C335.843 218.054 338.903 214.994 340.82 211.231C343 206.953 343 201.352 343 190.151V190.151Z"
          fill="url(#paint1_linear_2894_30433)" />
        <rect x="256" y="167.151" width="78" height="46" rx="10" fill="#0F0F0F" />
        <path d="M274.329 176.152H269.229V177.252H271.209V183.152H272.359V177.252H274.329V176.152Z" fill="#858585" />
        <path
          d="M276.571 183.282C278.041 183.282 279.211 182.132 279.211 180.652C279.211 179.162 278.041 178.022 276.571 178.022C275.111 178.022 273.931 179.162 273.931 180.652C273.931 182.132 275.111 183.282 276.571 183.282ZM276.571 182.232C275.691 182.232 275.011 181.562 275.011 180.652C275.011 179.742 275.691 179.072 276.571 179.072C277.451 179.072 278.131 179.742 278.131 180.652C278.131 181.562 277.451 182.232 276.571 182.232Z"
          fill="#858585" />
        <path
          d="M282.88 179.192V178.152H281.64V176.752L280.56 177.072V178.152H279.64V179.192H280.56V181.592C280.56 182.892 281.22 183.352 282.88 183.152V182.182C282.06 182.222 281.64 182.232 281.64 181.592V179.192H282.88Z"
          fill="#858585" />
        <path
          d="M287.615 178.152V178.862C287.235 178.342 286.645 178.022 285.865 178.022C284.505 178.022 283.375 179.162 283.375 180.652C283.375 182.132 284.505 183.282 285.865 183.282C286.645 183.282 287.235 182.962 287.615 182.432V183.152H288.695V178.152H287.615ZM286.035 182.252C285.135 182.252 284.455 181.582 284.455 180.652C284.455 179.722 285.135 179.052 286.035 179.052C286.935 179.052 287.615 179.722 287.615 180.652C287.615 181.582 286.935 182.252 286.035 182.252Z"
          fill="#858585" />
        <path d="M289.954 183.152H291.034V175.852H289.954V183.152Z" fill="#858585" />
        <path
          d="M297.438 176.152H294.858V183.152H296.008V180.752H297.438C298.758 180.752 299.758 179.742 299.758 178.452C299.758 177.152 298.758 176.152 297.438 176.152ZM297.438 179.672H296.008V177.232H297.438C298.118 177.232 298.608 177.742 298.608 178.452C298.608 179.152 298.118 179.672 297.438 179.672Z"
          fill="#858585" />
        <path
          d="M302.694 183.282C304.164 183.282 305.334 182.132 305.334 180.652C305.334 179.162 304.164 178.022 302.694 178.022C301.234 178.022 300.054 179.162 300.054 180.652C300.054 182.132 301.234 183.282 302.694 183.282ZM302.694 182.232C301.814 182.232 301.134 181.562 301.134 180.652C301.134 179.742 301.814 179.072 302.694 179.072C303.574 179.072 304.254 179.742 304.254 180.652C304.254 181.562 303.574 182.232 302.694 182.232Z"
          fill="#858585" />
        <path
          d="M306.851 177.402C307.231 177.402 307.541 177.082 307.541 176.712C307.541 176.332 307.231 176.022 306.851 176.022C306.471 176.022 306.161 176.332 306.161 176.712C306.161 177.082 306.471 177.402 306.851 177.402ZM306.311 183.152H307.391V178.152H306.311V183.152Z"
          fill="#858585" />
        <path
          d="M311.275 178.022C310.595 178.022 310.055 178.272 309.725 178.792V178.152H308.645V183.152H309.725V180.472C309.725 179.452 310.285 179.032 310.995 179.032C311.675 179.032 312.115 179.432 312.115 180.192V183.152H313.195V180.082C313.195 178.782 312.395 178.022 311.275 178.022Z"
          fill="#858585" />
        <path
          d="M317.148 179.192V178.152H315.908V176.752L314.828 177.072V178.152H313.908V179.192H314.828V181.592C314.828 182.892 315.488 183.352 317.148 183.152V182.182C316.328 182.222 315.908 182.232 315.908 181.592V179.192H317.148Z"
          fill="#858585" />
        <path
          d="M318.912 179.532C318.912 179.172 319.252 179.002 319.642 179.002C320.052 179.002 320.402 179.182 320.582 179.572L321.492 179.062C321.142 178.402 320.462 178.022 319.642 178.022C318.622 178.022 317.812 178.612 317.812 179.552C317.812 181.342 320.502 180.842 320.502 181.732C320.502 182.132 320.132 182.292 319.652 182.292C319.102 182.292 318.702 182.022 318.542 181.572L317.612 182.112C317.932 182.832 318.652 183.282 319.652 183.282C320.732 183.282 321.602 182.742 321.602 181.742C321.602 179.892 318.912 180.442 318.912 179.532Z"
          fill="#858585" />
        <path
          d="M292.774 194.58C292.774 191.72 290.714 189.72 287.694 189.72C284.714 189.72 282.634 191.72 282.634 194.58C282.634 197.4 284.654 199.38 287.554 199.44L284.434 204H287.594L291.954 197.42H291.934C292.474 196.64 292.774 195.66 292.774 194.58ZM285.374 194.58C285.374 193.18 286.414 192.32 287.694 192.32C289.014 192.32 290.034 193.16 290.034 194.58C290.034 196 289.014 196.86 287.694 196.86C286.414 196.86 285.374 196 285.374 194.58Z"
          fill="#F6F3EE" />
        <path
          d="M299.528 204.28C303.108 204.28 305.128 201.32 305.128 197C305.128 192.68 303.108 189.72 299.528 189.72C295.968 189.72 293.928 192.68 293.928 197C293.928 201.32 295.968 204.28 299.528 204.28ZM299.528 201.58C297.648 201.58 296.688 199.96 296.688 197C296.688 194.04 297.648 192.4 299.528 192.4C301.408 192.4 302.388 194.04 302.388 197C302.388 199.96 301.408 201.58 299.528 201.58Z"
          fill="#F6F3EE" />
        <rect x="230" y="197.151" width="17" height="11" rx="3" fill="#8B8B8B" />
        <circle cx="238.5" cy="180.651" r="8.5" fill="#8B8B8B" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M26 208.641V222.5H103V208.524C103 208.524 86.5189 198.662 77.9985 194.665C77.9985 194.665 77.3975 193.267 77.529 190.123C77.6604 186.978 77.2942 186.396 77.2942 186.396C77.2942 186.396 77.5747 182.95 78.7027 179.99C78.7027 179.99 78.9668 180.922 79.9939 180.922C81.021 180.922 81.5198 180.404 81.5198 179.175C81.5198 179.175 83.5152 176.613 83.5152 174.167C83.5152 171.721 83.3568 171.592 83.5152 170.557C83.6737 169.521 83.75 168.706 82.9284 168.693C82.1067 168.681 81.2851 169.974 81.2851 169.974C81.2851 169.974 81.6372 166.472 81.6372 163.685C81.6372 160.898 81.4024 159.959 81.4024 159.959C81.4024 159.959 81.2675 159.087 81.5198 158.328C81.7722 157.569 82.2135 150.832 72.0122 148.312C61.8109 145.792 54.875 149.943 54.875 149.943C54.875 149.943 48.3089 152.64 49.3582 158.677C49.4512 159.373 49.4512 160.078 49.3582 160.774C49.1998 161.85 49.0859 166.706 49.593 169.858C49.593 169.858 48.8453 168.693 47.9497 168.693C47.0541 168.693 47.1281 169.561 47.1281 169.858C47.1281 170.155 47.4626 170.825 47.3628 171.838C47.263 172.851 46.9485 174.8 47.8323 176.613C48.7162 178.426 49.2409 178.826 49.2409 178.826C49.2409 178.826 49.0495 180.922 50.6494 180.922C51.6401 180.994 52.1753 179.758 52.1753 179.758C52.1753 179.758 53.3855 186.24 53.8186 187.211L53.5838 190.239C53.5838 190.239 53.7435 192.997 53.2317 194.432L41.6113 200.372C41.6113 200.372 30.7679 205.436 26 208.641Z"
          fill="#292929" />
        <g opacity="0.4">
          <path
            d="M343 280.151C343 268.95 343 263.35 340.82 259.072C338.903 255.308 335.843 252.249 332.08 250.331C327.802 248.151 322.201 248.151 311 248.151H32C20.7989 248.151 15.1984 248.151 10.9202 250.331C7.15695 252.249 4.09734 255.308 2.17987 259.072C0 263.35 0 268.95 0 280.151V280.151C0 291.352 0 296.953 2.17987 301.231C4.09734 304.994 7.15695 308.054 10.9202 309.971C15.1984 312.151 20.7989 312.151 32 312.151H311C322.201 312.151 327.802 312.151 332.08 309.971C335.843 308.054 338.903 304.994 340.82 301.231C343 296.953 343 291.352 343 280.151V280.151Z"
            fill="url(#paint2_linear_2894_30433)" />
          <rect x="256" y="257.151" width="78" height="46" rx="10" fill="#0F0F0F" />
          <path d="M274.329 266.152H269.229V267.252H271.209V273.152H272.359V267.252H274.329V266.152Z" fill="#858585" />
          <path
            d="M276.571 273.282C278.041 273.282 279.211 272.132 279.211 270.652C279.211 269.162 278.041 268.022 276.571 268.022C275.111 268.022 273.931 269.162 273.931 270.652C273.931 272.132 275.111 273.282 276.571 273.282ZM276.571 272.232C275.691 272.232 275.011 271.562 275.011 270.652C275.011 269.742 275.691 269.072 276.571 269.072C277.451 269.072 278.131 269.742 278.131 270.652C278.131 271.562 277.451 272.232 276.571 272.232Z"
            fill="#858585" />
          <path
            d="M282.88 269.192V268.152H281.64V266.752L280.56 267.072V268.152H279.64V269.192H280.56V271.592C280.56 272.892 281.22 273.352 282.88 273.152V272.182C282.06 272.222 281.64 272.232 281.64 271.592V269.192H282.88Z"
            fill="#858585" />
          <path
            d="M287.615 268.152V268.862C287.235 268.342 286.645 268.022 285.865 268.022C284.505 268.022 283.375 269.162 283.375 270.652C283.375 272.132 284.505 273.282 285.865 273.282C286.645 273.282 287.235 272.962 287.615 272.432V273.152H288.695V268.152H287.615ZM286.035 272.252C285.135 272.252 284.455 271.582 284.455 270.652C284.455 269.722 285.135 269.052 286.035 269.052C286.935 269.052 287.615 269.722 287.615 270.652C287.615 271.582 286.935 272.252 286.035 272.252Z"
            fill="#858585" />
          <path d="M289.954 273.152H291.034V265.852H289.954V273.152Z" fill="#858585" />
          <path
            d="M297.438 266.152H294.858V273.152H296.008V270.752H297.438C298.758 270.752 299.758 269.742 299.758 268.452C299.758 267.152 298.758 266.152 297.438 266.152ZM297.438 269.672H296.008V267.232H297.438C298.118 267.232 298.608 267.742 298.608 268.452C298.608 269.152 298.118 269.672 297.438 269.672Z"
            fill="#858585" />
          <path
            d="M302.694 273.282C304.164 273.282 305.334 272.132 305.334 270.652C305.334 269.162 304.164 268.022 302.694 268.022C301.234 268.022 300.054 269.162 300.054 270.652C300.054 272.132 301.234 273.282 302.694 273.282ZM302.694 272.232C301.814 272.232 301.134 271.562 301.134 270.652C301.134 269.742 301.814 269.072 302.694 269.072C303.574 269.072 304.254 269.742 304.254 270.652C304.254 271.562 303.574 272.232 302.694 272.232Z"
            fill="#858585" />
          <path
            d="M306.851 267.402C307.231 267.402 307.541 267.082 307.541 266.712C307.541 266.332 307.231 266.022 306.851 266.022C306.471 266.022 306.161 266.332 306.161 266.712C306.161 267.082 306.471 267.402 306.851 267.402ZM306.311 273.152H307.391V268.152H306.311V273.152Z"
            fill="#858585" />
          <path
            d="M311.275 268.022C310.595 268.022 310.055 268.272 309.725 268.792V268.152H308.645V273.152H309.725V270.472C309.725 269.452 310.285 269.032 310.995 269.032C311.675 269.032 312.115 269.432 312.115 270.192V273.152H313.195V270.082C313.195 268.782 312.395 268.022 311.275 268.022Z"
            fill="#858585" />
          <path
            d="M317.148 269.192V268.152H315.908V266.752L314.828 267.072V268.152H313.908V269.192H314.828V271.592C314.828 272.892 315.488 273.352 317.148 273.152V272.182C316.328 272.222 315.908 272.232 315.908 271.592V269.192H317.148Z"
            fill="#858585" />
          <path
            d="M318.912 269.532C318.912 269.172 319.252 269.002 319.642 269.002C320.052 269.002 320.402 269.182 320.582 269.572L321.492 269.062C321.142 268.402 320.462 268.022 319.642 268.022C318.622 268.022 317.812 268.612 317.812 269.552C317.812 271.342 320.502 270.842 320.502 271.732C320.502 272.132 320.132 272.292 319.652 272.292C319.102 272.292 318.702 272.022 318.542 271.572L317.612 272.112C317.932 272.832 318.652 273.282 319.652 273.282C320.732 273.282 321.602 272.742 321.602 271.742C321.602 269.892 318.912 270.442 318.912 269.532Z"
            fill="#858585" />
          <path
            d="M290.778 286.72C291.678 286.08 292.238 285.12 292.238 283.82C292.238 281.12 290.158 279.72 287.698 279.72C285.238 279.72 283.138 281.12 283.138 283.82C283.138 285.12 283.718 286.08 284.598 286.72C283.378 287.46 282.678 288.68 282.678 290.1C282.678 292.68 284.578 294.28 287.698 294.28C290.798 294.28 292.718 292.68 292.718 290.1C292.718 288.68 292.018 287.46 290.778 286.72ZM287.698 282.32C288.738 282.32 289.498 282.92 289.498 283.96C289.498 284.98 288.738 285.62 287.698 285.62C286.658 285.62 285.898 284.98 285.898 283.96C285.898 282.92 286.658 282.32 287.698 282.32ZM287.698 291.68C286.378 291.68 285.418 291 285.418 289.74C285.418 288.5 286.378 287.82 287.698 287.82C289.018 287.82 289.978 288.5 289.978 289.74C289.978 291 289.018 291.68 287.698 291.68Z"
            fill="#F6F3EE" />
          <path
            d="M299.685 294.28C303.265 294.28 305.285 291.32 305.285 287C305.285 282.68 303.265 279.72 299.685 279.72C296.125 279.72 294.085 282.68 294.085 287C294.085 291.32 296.125 294.28 299.685 294.28ZM299.685 291.58C297.805 291.58 296.845 289.96 296.845 287C296.845 284.04 297.805 282.4 299.685 282.4C301.565 282.4 302.545 284.04 302.545 287C302.545 289.96 301.565 291.58 299.685 291.58Z"
            fill="#F6F3EE" />
          <rect x="230" y="287.151" width="17" height="11" rx="3" fill="#292929" />
          <circle cx="238.5" cy="270.651" r="8.5" fill="#292929" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26 298.641V312.5H103V298.524C103 298.524 86.5189 288.662 77.9985 284.665C77.9985 284.665 77.3975 283.267 77.529 280.123C77.6604 276.978 77.2942 276.396 77.2942 276.396C77.2942 276.396 77.5747 272.95 78.7027 269.99C78.7027 269.99 78.9668 270.922 79.9939 270.922C81.021 270.922 81.5198 270.404 81.5198 269.175C81.5198 269.175 83.5152 266.613 83.5152 264.167C83.5152 261.721 83.3568 261.592 83.5152 260.557C83.6737 259.521 83.75 258.706 82.9284 258.693C82.1067 258.681 81.2851 259.974 81.2851 259.974C81.2851 259.974 81.6372 256.472 81.6372 253.685C81.6372 250.898 81.4024 249.959 81.4024 249.959C81.4024 249.959 81.2675 249.087 81.5198 248.328C81.7722 247.569 82.2135 240.832 72.0122 238.312C61.8109 235.792 54.875 239.943 54.875 239.943C54.875 239.943 48.3089 242.64 49.3582 248.677C49.4512 249.373 49.4512 250.078 49.3582 250.774C49.1998 251.85 49.0859 256.706 49.593 259.858C49.593 259.858 48.8453 258.693 47.9497 258.693C47.0541 258.693 47.1281 259.561 47.1281 259.858C47.1281 260.155 47.4626 260.825 47.3628 261.838C47.263 262.851 46.9485 264.8 47.8323 266.613C48.7162 268.426 49.2409 268.826 49.2409 268.826C49.2409 268.826 49.0495 270.922 50.6494 270.922C51.6401 270.994 52.1753 269.758 52.1753 269.758C52.1753 269.758 53.3855 276.24 53.8186 277.211L53.5838 280.239C53.5838 280.239 53.7435 282.997 53.2317 284.432L41.6113 290.372C41.6113 290.372 30.7679 295.436 26 298.641Z"
            fill="#292929" />
        </g>
        <g opacity="0.3">
          <path
            d="M343 370.151C343 358.95 343 353.35 340.82 349.072C338.903 345.308 335.843 342.249 332.08 340.331C327.802 338.151 322.201 338.151 311 338.151H32C20.7989 338.151 15.1984 338.151 10.9202 340.331C7.15695 342.249 4.09734 345.308 2.17987 349.072C0 353.35 0 358.95 0 370.151V370.151C0 381.352 0 386.953 2.17987 391.231C4.09734 394.994 7.15695 398.054 10.9202 399.971C15.1984 402.151 20.7989 402.151 32 402.151H311C322.201 402.151 327.802 402.151 332.08 399.971C335.843 398.054 338.903 394.994 340.82 391.231C343 386.953 343 381.352 343 370.151V370.151Z"
            fill="url(#paint3_linear_2894_30433)" />
          <rect x="256" y="347.151" width="78" height="46" rx="10" fill="#0F0F0F" />
          <path d="M274.329 356.152H269.229V357.252H271.209V363.152H272.359V357.252H274.329V356.152Z" fill="#858585" />
          <path
            d="M276.571 363.282C278.041 363.282 279.211 362.132 279.211 360.652C279.211 359.162 278.041 358.022 276.571 358.022C275.111 358.022 273.931 359.162 273.931 360.652C273.931 362.132 275.111 363.282 276.571 363.282ZM276.571 362.232C275.691 362.232 275.011 361.562 275.011 360.652C275.011 359.742 275.691 359.072 276.571 359.072C277.451 359.072 278.131 359.742 278.131 360.652C278.131 361.562 277.451 362.232 276.571 362.232Z"
            fill="#858585" />
          <path
            d="M282.88 359.192V358.152H281.64V356.752L280.56 357.072V358.152H279.64V359.192H280.56V361.592C280.56 362.892 281.22 363.352 282.88 363.152V362.182C282.06 362.222 281.64 362.232 281.64 361.592V359.192H282.88Z"
            fill="#858585" />
          <path
            d="M287.615 358.152V358.862C287.235 358.342 286.645 358.022 285.865 358.022C284.505 358.022 283.375 359.162 283.375 360.652C283.375 362.132 284.505 363.282 285.865 363.282C286.645 363.282 287.235 362.962 287.615 362.432V363.152H288.695V358.152H287.615ZM286.035 362.252C285.135 362.252 284.455 361.582 284.455 360.652C284.455 359.722 285.135 359.052 286.035 359.052C286.935 359.052 287.615 359.722 287.615 360.652C287.615 361.582 286.935 362.252 286.035 362.252Z"
            fill="#858585" />
          <path d="M289.954 363.152H291.034V355.852H289.954V363.152Z" fill="#858585" />
          <path
            d="M297.438 356.152H294.858V363.152H296.008V360.752H297.438C298.758 360.752 299.758 359.742 299.758 358.452C299.758 357.152 298.758 356.152 297.438 356.152ZM297.438 359.672H296.008V357.232H297.438C298.118 357.232 298.608 357.742 298.608 358.452C298.608 359.152 298.118 359.672 297.438 359.672Z"
            fill="#858585" />
          <path
            d="M302.694 363.282C304.164 363.282 305.334 362.132 305.334 360.652C305.334 359.162 304.164 358.022 302.694 358.022C301.234 358.022 300.054 359.162 300.054 360.652C300.054 362.132 301.234 363.282 302.694 363.282ZM302.694 362.232C301.814 362.232 301.134 361.562 301.134 360.652C301.134 359.742 301.814 359.072 302.694 359.072C303.574 359.072 304.254 359.742 304.254 360.652C304.254 361.562 303.574 362.232 302.694 362.232Z"
            fill="#858585" />
          <path
            d="M306.851 357.402C307.231 357.402 307.541 357.082 307.541 356.712C307.541 356.332 307.231 356.022 306.851 356.022C306.471 356.022 306.161 356.332 306.161 356.712C306.161 357.082 306.471 357.402 306.851 357.402ZM306.311 363.152H307.391V358.152H306.311V363.152Z"
            fill="#858585" />
          <path
            d="M311.275 358.022C310.595 358.022 310.055 358.272 309.725 358.792V358.152H308.645V363.152H309.725V360.472C309.725 359.452 310.285 359.032 310.995 359.032C311.675 359.032 312.115 359.432 312.115 360.192V363.152H313.195V360.082C313.195 358.782 312.395 358.022 311.275 358.022Z"
            fill="#858585" />
          <path
            d="M317.148 359.192V358.152H315.908V356.752L314.828 357.072V358.152H313.908V359.192H314.828V361.592C314.828 362.892 315.488 363.352 317.148 363.152V362.182C316.328 362.222 315.908 362.232 315.908 361.592V359.192H317.148Z"
            fill="#858585" />
          <path
            d="M318.912 359.532C318.912 359.172 319.252 359.002 319.642 359.002C320.052 359.002 320.402 359.182 320.582 359.572L321.492 359.062C321.142 358.402 320.462 358.022 319.642 358.022C318.622 358.022 317.812 358.612 317.812 359.552C317.812 361.342 320.502 360.842 320.502 361.732C320.502 362.132 320.132 362.292 319.652 362.292C319.102 362.292 318.702 362.022 318.542 361.572L317.612 362.112C317.932 362.832 318.652 363.282 319.652 363.282C320.732 363.282 321.602 362.742 321.602 361.742C321.602 359.892 318.912 360.442 318.912 359.532Z"
            fill="#858585" />
          <path d="M283.754 370V372.64H289.734L285.134 384H288.054L292.794 372.34V370H283.754Z" fill="#F6F3EE" />
          <path
            d="M299.208 384.28C302.788 384.28 304.808 381.32 304.808 377C304.808 372.68 302.788 369.72 299.208 369.72C295.648 369.72 293.608 372.68 293.608 377C293.608 381.32 295.648 384.28 299.208 384.28ZM299.208 381.58C297.328 381.58 296.368 379.96 296.368 377C296.368 374.04 297.328 372.4 299.208 372.4C301.088 372.4 302.068 374.04 302.068 377C302.068 379.96 301.088 381.58 299.208 381.58Z"
            fill="#F6F3EE" />
          <rect x="230" y="377.151" width="17" height="11" rx="3" fill="#292929" />
          <circle cx="238.5" cy="360.651" r="8.5" fill="#292929" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26 388.641V402.5H103V388.524C103 388.524 86.5189 378.662 77.9985 374.665C77.9985 374.665 77.3975 373.267 77.529 370.123C77.6604 366.978 77.2942 366.396 77.2942 366.396C77.2942 366.396 77.5747 362.95 78.7027 359.99C78.7027 359.99 78.9668 360.922 79.9939 360.922C81.021 360.922 81.5198 360.404 81.5198 359.175C81.5198 359.175 83.5152 356.613 83.5152 354.167C83.5152 351.721 83.3568 351.592 83.5152 350.557C83.6737 349.521 83.75 348.706 82.9284 348.693C82.1067 348.681 81.2851 349.974 81.2851 349.974C81.2851 349.974 81.6372 346.472 81.6372 343.685C81.6372 340.898 81.4024 339.959 81.4024 339.959C81.4024 339.959 81.2675 339.087 81.5198 338.328C81.7722 337.569 82.2135 330.832 72.0122 328.312C61.8109 325.792 54.875 329.943 54.875 329.943C54.875 329.943 48.3089 332.64 49.3582 338.677C49.4512 339.373 49.4512 340.078 49.3582 340.774C49.1998 341.85 49.0859 346.706 49.593 349.858C49.593 349.858 48.8453 348.693 47.9497 348.693C47.0541 348.693 47.1281 349.561 47.1281 349.858C47.1281 350.155 47.4626 350.825 47.3628 351.838C47.263 352.851 46.9485 354.8 47.8323 356.613C48.7162 358.426 49.2409 358.826 49.2409 358.826C49.2409 358.826 49.0495 360.922 50.6494 360.922C51.6401 360.994 52.1753 359.758 52.1753 359.758C52.1753 359.758 53.3855 366.24 53.8186 367.211L53.5838 370.239C53.5838 370.239 53.7435 372.997 53.2317 374.432L41.6113 380.372C41.6113 380.372 30.7679 385.436 26 388.641Z"
            fill="#292929" />
        </g>
        <g opacity="0.1">
          <path
            d="M343 460.151C343 448.95 343 443.35 340.82 439.072C338.903 435.308 335.843 432.249 332.08 430.331C327.802 428.151 322.201 428.151 311 428.151H32C20.7989 428.151 15.1984 428.151 10.9202 430.331C7.15695 432.249 4.09734 435.308 2.17987 439.072C0 443.35 0 448.95 0 460.151V460.151C0 471.352 0 476.953 2.17987 481.231C4.09734 484.994 7.15695 488.054 10.9202 489.971C15.1984 492.151 20.7989 492.151 32 492.151H311C322.201 492.151 327.802 492.151 332.08 489.971C335.843 488.054 338.903 484.994 340.82 481.231C343 476.953 343 471.352 343 460.151V460.151Z"
            fill="url(#paint4_linear_2894_30433)" />
          <rect x="256" y="437.151" width="78" height="46" rx="10" fill="#0F0F0F" />
          <path d="M274.329 446.152H269.229V447.252H271.209V453.152H272.359V447.252H274.329V446.152Z" fill="#858585" />
          <path
            d="M276.571 453.282C278.041 453.282 279.211 452.132 279.211 450.652C279.211 449.162 278.041 448.022 276.571 448.022C275.111 448.022 273.931 449.162 273.931 450.652C273.931 452.132 275.111 453.282 276.571 453.282ZM276.571 452.232C275.691 452.232 275.011 451.562 275.011 450.652C275.011 449.742 275.691 449.072 276.571 449.072C277.451 449.072 278.131 449.742 278.131 450.652C278.131 451.562 277.451 452.232 276.571 452.232Z"
            fill="#858585" />
          <path
            d="M282.88 449.192V448.152H281.64V446.752L280.56 447.072V448.152H279.64V449.192H280.56V451.592C280.56 452.892 281.22 453.352 282.88 453.152V452.182C282.06 452.222 281.64 452.232 281.64 451.592V449.192H282.88Z"
            fill="#858585" />
          <path
            d="M287.615 448.152V448.862C287.235 448.342 286.645 448.022 285.865 448.022C284.505 448.022 283.375 449.162 283.375 450.652C283.375 452.132 284.505 453.282 285.865 453.282C286.645 453.282 287.235 452.962 287.615 452.432V453.152H288.695V448.152H287.615ZM286.035 452.252C285.135 452.252 284.455 451.582 284.455 450.652C284.455 449.722 285.135 449.052 286.035 449.052C286.935 449.052 287.615 449.722 287.615 450.652C287.615 451.582 286.935 452.252 286.035 452.252Z"
            fill="#858585" />
          <path d="M289.954 453.152H291.034V445.852H289.954V453.152Z" fill="#858585" />
          <path
            d="M297.438 446.152H294.858V453.152H296.008V450.752H297.438C298.758 450.752 299.758 449.742 299.758 448.452C299.758 447.152 298.758 446.152 297.438 446.152ZM297.438 449.672H296.008V447.232H297.438C298.118 447.232 298.608 447.742 298.608 448.452C298.608 449.152 298.118 449.672 297.438 449.672Z"
            fill="#858585" />
          <path
            d="M302.694 453.282C304.164 453.282 305.334 452.132 305.334 450.652C305.334 449.162 304.164 448.022 302.694 448.022C301.234 448.022 300.054 449.162 300.054 450.652C300.054 452.132 301.234 453.282 302.694 453.282ZM302.694 452.232C301.814 452.232 301.134 451.562 301.134 450.652C301.134 449.742 301.814 449.072 302.694 449.072C303.574 449.072 304.254 449.742 304.254 450.652C304.254 451.562 303.574 452.232 302.694 452.232Z"
            fill="#858585" />
          <path
            d="M306.851 447.402C307.231 447.402 307.541 447.082 307.541 446.712C307.541 446.332 307.231 446.022 306.851 446.022C306.471 446.022 306.161 446.332 306.161 446.712C306.161 447.082 306.471 447.402 306.851 447.402ZM306.311 453.152H307.391V448.152H306.311V453.152Z"
            fill="#858585" />
          <path
            d="M311.275 448.022C310.595 448.022 310.055 448.272 309.725 448.792V448.152H308.645V453.152H309.725V450.472C309.725 449.452 310.285 449.032 310.995 449.032C311.675 449.032 312.115 449.432 312.115 450.192V453.152H313.195V450.082C313.195 448.782 312.395 448.022 311.275 448.022Z"
            fill="#858585" />
          <path
            d="M317.148 449.192V448.152H315.908V446.752L314.828 447.072V448.152H313.908V449.192H314.828V451.592C314.828 452.892 315.488 453.352 317.148 453.152V452.182C316.328 452.222 315.908 452.232 315.908 451.592V449.192H317.148Z"
            fill="#858585" />
          <path
            d="M318.912 449.532C318.912 449.172 319.252 449.002 319.642 449.002C320.052 449.002 320.402 449.182 320.582 449.572L321.492 449.062C321.142 448.402 320.462 448.022 319.642 448.022C318.622 448.022 317.812 448.612 317.812 449.552C317.812 451.342 320.502 450.842 320.502 451.732C320.502 452.132 320.132 452.292 319.652 452.292C319.102 452.292 318.702 452.022 318.542 451.572L317.612 452.112C317.932 452.832 318.652 453.282 319.652 453.282C320.732 453.282 321.602 452.742 321.602 451.742C321.602 449.892 318.912 450.442 318.912 449.532Z"
            fill="#858585" />
          <path
            d="M287.874 464.54L290.974 460H287.814L283.454 466.58C282.934 467.36 282.634 468.32 282.634 469.4C282.634 472.26 284.694 474.28 287.694 474.28C290.694 474.28 292.774 472.26 292.774 469.4C292.774 466.6 290.774 464.62 287.874 464.54ZM287.694 471.68C286.394 471.68 285.374 470.82 285.374 469.4C285.374 467.98 286.394 467.14 287.694 467.14C288.994 467.14 290.034 468 290.034 469.4C290.034 470.82 288.994 471.68 287.694 471.68Z"
            fill="#F6F3EE" />
          <path
            d="M299.528 474.28C303.108 474.28 305.128 471.32 305.128 467C305.128 462.68 303.108 459.72 299.528 459.72C295.968 459.72 293.928 462.68 293.928 467C293.928 471.32 295.968 474.28 299.528 474.28ZM299.528 471.58C297.648 471.58 296.688 469.96 296.688 467C296.688 464.04 297.648 462.4 299.528 462.4C301.408 462.4 302.388 464.04 302.388 467C302.388 469.96 301.408 471.58 299.528 471.58Z"
            fill="#F6F3EE" />
          <rect x="230" y="467.151" width="17" height="11" rx="3" fill="#292929" />
          <circle cx="238.5" cy="450.651" r="8.5" fill="#292929" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M26 478.641V492.5H103V478.524C103 478.524 86.5189 468.662 77.9985 464.665C77.9985 464.665 77.3975 463.267 77.529 460.123C77.6604 456.978 77.2942 456.396 77.2942 456.396C77.2942 456.396 77.5747 452.95 78.7027 449.99C78.7027 449.99 78.9668 450.922 79.9939 450.922C81.021 450.922 81.5198 450.404 81.5198 449.175C81.5198 449.175 83.5152 446.613 83.5152 444.167C83.5152 441.721 83.3568 441.592 83.5152 440.557C83.6737 439.521 83.75 438.706 82.9284 438.693C82.1067 438.681 81.2851 439.974 81.2851 439.974C81.2851 439.974 81.6372 436.472 81.6372 433.685C81.6372 430.898 81.4024 429.959 81.4024 429.959C81.4024 429.959 81.2675 429.087 81.5198 428.328C81.7722 427.569 82.2135 420.832 72.0122 418.312C61.8109 415.792 54.875 419.943 54.875 419.943C54.875 419.943 48.3089 422.64 49.3582 428.677C49.4512 429.373 49.4512 430.078 49.3582 430.774C49.1998 431.85 49.0859 436.706 49.593 439.858C49.593 439.858 48.8453 438.693 47.9497 438.693C47.0541 438.693 47.1281 439.561 47.1281 439.858C47.1281 440.155 47.4626 440.825 47.3628 441.838C47.263 442.851 46.9485 444.8 47.8323 446.613C48.7162 448.426 49.2409 448.826 49.2409 448.826C49.2409 448.826 49.0495 450.922 50.6494 450.922C51.6401 450.994 52.1753 449.758 52.1753 449.758C52.1753 449.758 53.3855 456.24 53.8186 457.211L53.5838 460.239C53.5838 460.239 53.7435 462.997 53.2317 464.432L41.6113 470.372C41.6113 470.372 30.7679 475.436 26 478.641Z"
            fill="#292929" />
        </g>
        <rect width="343" height="42" rx="10" fill="#292929" />
        <path
          d="M153.274 23.272H151.994V20.36H150.138V23.272H146.634L150.49 15.8H148.49L144.634 23.272V24.984H150.138V27H151.994V24.984H153.274V23.272Z"
          fill="#F6F3EE" />
        <path
          d="M158.587 27.208C161.403 27.208 162.987 24.856 162.987 21.4C162.987 17.944 161.403 15.592 158.587 15.592C155.771 15.592 154.187 17.944 154.187 21.4C154.187 24.856 155.771 27.208 158.587 27.208ZM158.587 25.416C156.939 25.416 156.027 23.976 156.027 21.4C156.027 18.824 156.939 17.384 158.587 17.384C160.251 17.384 161.147 18.824 161.147 21.4C161.147 23.976 160.251 25.416 158.587 25.416Z"
          fill="#F6F3EE" />
        <path
          d="M168.665 27.208C171.481 27.208 173.065 24.856 173.065 21.4C173.065 17.944 171.481 15.592 168.665 15.592C165.849 15.592 164.265 17.944 164.265 21.4C164.265 24.856 165.849 27.208 168.665 27.208ZM168.665 25.416C167.017 25.416 166.105 23.976 166.105 21.4C166.105 18.824 167.017 17.384 168.665 17.384C170.329 17.384 171.225 18.824 171.225 21.4C171.225 23.976 170.329 25.416 168.665 25.416Z"
          fill="#F6F3EE" />
        <path
          d="M183.239 18.792C181.991 18.792 181.047 19.304 180.439 20.152V19H178.711V30.2H180.439V25.864C181.047 26.696 181.991 27.208 183.239 27.208C185.415 27.208 187.223 25.384 187.223 23C187.223 20.632 185.415 18.792 183.239 18.792ZM182.967 25.56C181.527 25.56 180.439 24.488 180.439 23C180.439 21.512 181.527 20.44 182.967 20.44C184.407 20.44 185.495 21.512 185.495 23C185.495 24.488 184.407 25.56 182.967 25.56Z"
          fill="#F6F3EE" />
        <path
          d="M193.097 20.664V19H191.113V16.76L189.385 17.272V19H187.913V20.664H189.385V24.504C189.385 26.584 190.441 27.32 193.097 27V25.448C191.785 25.512 191.113 25.528 191.113 24.504V20.664H193.097Z"
          fill="#F6F3EE" />
        <path
          d="M195.92 21.208C195.92 20.632 196.464 20.36 197.088 20.36C197.744 20.36 198.304 20.648 198.592 21.272L200.048 20.456C199.488 19.4 198.4 18.792 197.088 18.792C195.456 18.792 194.16 19.736 194.16 21.24C194.16 24.104 198.464 23.304 198.464 24.728C198.464 25.368 197.872 25.624 197.104 25.624C196.224 25.624 195.584 25.192 195.328 24.472L193.84 25.336C194.352 26.488 195.504 27.208 197.104 27.208C198.832 27.208 200.224 26.344 200.224 24.744C200.224 21.784 195.92 22.664 195.92 21.208Z"
          fill="#F6F3EE" />
        <path
          d="M295.373 15.8H291.245V27H293.085V23.16H295.373C297.485 23.16 299.085 21.544 299.085 19.48C299.085 17.4 297.485 15.8 295.373 15.8ZM295.373 21.432H293.085V17.528H295.373C296.461 17.528 297.245 18.344 297.245 19.48C297.245 20.6 296.461 21.432 295.373 21.432Z"
          fill="#36FA73" />
        <path
          d="M302.142 20.344V19H300.414V27H302.142V22.968C302.142 21.176 303.47 20.648 304.606 20.728V18.856C303.598 18.856 302.622 19.256 302.142 20.344Z"
          fill="#36FA73" />
        <path
          d="M306.747 17.8C307.355 17.8 307.851 17.288 307.851 16.696C307.851 16.088 307.355 15.592 306.747 15.592C306.139 15.592 305.643 16.088 305.643 16.696C305.643 17.288 306.139 17.8 306.747 17.8ZM305.883 27H307.611V19H305.883V27Z"
          fill="#36FA73" />
        <path d="M311.681 25.368L315.409 20.152V19H309.489V20.632H313.073L309.329 25.848V27H315.569V25.368H311.681Z"
          fill="#36FA73" />
        <path
          d="M318.056 23.736H324.424C324.456 23.512 324.488 23.256 324.488 23.016C324.488 20.712 322.856 18.792 320.472 18.792C317.96 18.792 316.248 20.632 316.248 23C316.248 25.4 317.96 27.208 320.6 27.208C322.168 27.208 323.368 26.552 324.104 25.464L322.68 24.632C322.296 25.192 321.576 25.624 320.616 25.624C319.336 25.624 318.328 24.984 318.056 23.736ZM318.04 22.328C318.28 21.128 319.16 20.36 320.456 20.36C321.496 20.36 322.504 20.952 322.744 22.328H318.04Z"
          fill="#36FA73" />
        <path d="M21.152 15.8L18.208 16.84L18.656 18.408L20.912 17.768V27H22.752V15.8H21.152Z" fill="#F6F3EE" />
        <path
          d="M28.9931 27.208C31.8091 27.208 33.3931 24.856 33.3931 21.4C33.3931 17.944 31.8091 15.592 28.9931 15.592C26.1771 15.592 24.5931 17.944 24.5931 21.4C24.5931 24.856 26.1771 27.208 28.9931 27.208ZM28.9931 25.416C27.3451 25.416 26.4331 23.976 26.4331 21.4C26.4331 18.824 27.3451 17.384 28.9931 17.384C30.6571 17.384 31.5531 18.824 31.5531 21.4C31.5531 23.976 30.6571 25.416 28.9931 25.416Z"
          fill="#F6F3EE" />
        <path
          d="M39.5033 20.664V19H37.5193V16.76L35.7913 17.272V19H34.3193V20.664H35.7913V24.504C35.7913 26.584 36.8473 27.32 39.5033 27V25.448C38.1913 25.512 37.5193 25.528 37.5193 24.504V20.664H39.5033Z"
          fill="#F6F3EE" />
        <path
          d="M45.2785 18.792C44.1905 18.792 43.3265 19.192 42.7985 20.024V15.8H41.0705V27H42.7985V22.712C42.7985 21.08 43.6945 20.408 44.8305 20.408C45.9185 20.408 46.6225 21.048 46.6225 22.264V27H48.3505V22.088C48.3505 20.008 47.0705 18.792 45.2785 18.792Z"
          fill="#F6F3EE" />
        <defs>
          <linearGradient id="paint0_linear_2894_30433" x1="244" y1="19.1514" x2="130" y2="182.151"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#B88C41" stop-opacity="0.95" />
            <stop offset="1" stop-color="#FFD46B" />
          </linearGradient>
          <linearGradient id="paint1_linear_2894_30433" x1="244" y1="110.151" x2="130" y2="273.151"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#292929" />
            <stop offset="1" stop-color="#454545" />
          </linearGradient>
          <linearGradient id="paint2_linear_2894_30433" x1="244" y1="200.151" x2="130" y2="363.151"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#292929" />
            <stop offset="1" stop-color="#454545" />
          </linearGradient>
          <linearGradient id="paint3_linear_2894_30433" x1="244" y1="290.151" x2="130" y2="453.151"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#292929" />
            <stop offset="1" stop-color="#454545" />
          </linearGradient>
          <linearGradient id="paint4_linear_2894_30433" x1="244" y1="380.151" x2="130" y2="543.151"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#292929" />
            <stop offset="1" stop-color="#454545" />
          </linearGradient>
        </defs>
      </svg>
    `;
    }
};
PointsSVG.styles = [ScopedElement.styles, css `
    :host {
      display: contents;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  `];
PointsSVG = __decorate([
    customElement("points-svg")
], PointsSVG);
