var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import "./onboarding.css";
import "./parts/intro-pack/intro-pack";
import "./parts/lineup/lineup";
import "./parts/info/info";
import "./parts/complete/complete";
let Onboarding = class Onboarding extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.stageIndex = 0;
        this.stages = [
            html `<onboarding-intro-pack @next=${this.nextStage}></onboarding-intro-pack>`,
            html `<onboarding-lineup @next=${this.nextStage}></onboarding-lineup>`,
            html `<onboarding-info @next=${this.nextStage}></onboarding-info>`,
            html `<onboarding-complete @continue=${this.complete}></onboarding-complete>`
        ];
    }
    get currentStage() {
        return this.stages[this.stageIndex];
    }
    render() {
        return this.currentStage;
    }
    nextStage() {
        this.stageIndex++;
    }
    complete() {
        this.dispatchEvent(new Event("complete"));
    }
};
__decorate([
    state()
], Onboarding.prototype, "stageIndex", void 0);
Onboarding = __decorate([
    customElement("app-onboarding")
], Onboarding);
