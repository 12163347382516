var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconEdit = class IconEdit extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11.4905V14.4068H2.91626L11.5173 5.80574L8.60103 2.88948L0 11.4905ZM13.7725 3.5505C14.0758 3.24721 14.0758 2.75728 13.7725 2.45398L11.9528 0.634237C11.6495 0.330946 11.1596 0.330946 10.8563 0.634237L9.43314 2.05737L12.3494 4.97363L13.7725 3.5505V3.5505Z" />
      </svg>
    `;
    }
};
IconEdit.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }
  `];
IconEdit = __decorate([
    customElement("icon-edit")
], IconEdit);
export { IconEdit };
