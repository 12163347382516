var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var OnboardingIntroPack_1;
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./intro-pack.css";
import "@/components/loading-spinner/loading-spinner";
import "@/components/open-pack-overlay/open-pack-overlay";
import ufApi from "~/app/services/api/uf/uf.api";
let OnboardingIntroPack = OnboardingIntroPack_1 = class OnboardingIntroPack extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.skipButtonVisible = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.loading = true;
        this.packsSubscription = ufApi.packs.subscribe(packs => {
            const signupPack = packs.find(pack => pack.code === "0");
            if (signupPack) {
                if (!signupPack.isNew) {
                    this.loading = false;
                    this.pack = signupPack;
                }
                else
                    this.next();
            }
            else
                this.getPacks();
        });
        this.getPacks();
    }
    getPacks() {
        if (OnboardingIntroPack_1.packsRequestAttempts) {
            OnboardingIntroPack_1.packsRequestAttempts--;
            try {
                ufApi.getPacks();
            }
            catch (e) {
                this.handleError(e);
            }
        }
        else {
            console.log("Ran out of attempts");
            this.loading = false;
            this.next();
        }
    }
    handleError(e) {
        console.log(e);
        this.getPacks();
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.packsSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.packsSubscription = undefined;
    }
    render() {
        return html `
      ${this.loading ? html `

          <div class="center-content">
            <loading-spinner></loading-spinner>
          </div>

        ` : this.pack ? html `

          <open-pack-overlay
            .packs=${[this.pack]}
            @packopen=${() => this.skipButtonVisible = true}
            @next=${this.next}
          ></open-pack-overlay>

          ${when(this.skipButtonVisible, () => html `
              <button class="no-style" id="skip-button" @click=${this.next}>
                <span>Skip</span>
              </button>
            `)}

        ` : html `
          <div class="center-content">
            <div id="error">
              <p>We are currently processing your first pack.</p>
              <button>Continue</button>
            </div>
          </div>
        `}
    `;
    }
    next() {
        this.dispatchEvent(new Event("next"));
    }
};
OnboardingIntroPack.packsRequestAttempts = 4;
__decorate([
    state()
], OnboardingIntroPack.prototype, "loading", void 0);
__decorate([
    state()
], OnboardingIntroPack.prototype, "pack", void 0);
__decorate([
    state()
], OnboardingIntroPack.prototype, "skipButtonVisible", void 0);
OnboardingIntroPack = OnboardingIntroPack_1 = __decorate([
    customElement("onboarding-intro-pack")
], OnboardingIntroPack);
