var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import introCSS from "./intro.css";
import "@/components/start-container/start-container";
import "@/components/asset/uf-stamp/uf-stamp";
import "@/components/low6-user-message/low6-user-message";
import "@/components/intro-footer-text/intro-footer-text";
import "@/components/uf-swiper/uf-swiper";
import "@/components/asset/bengals-stamp/bengals-stamp";
import "@/components/asset/chargers-stamp/chargers-stamp";
import ConfigurationService from "@/services/config/config";
import { when } from "lit/directives/when.js";
import { appContainer } from "@/services/config/constants";
let IntroView = class IntroView extends LitElement {
    connectedCallback() {
        super.connectedCallback();
        if (ConfigurationService.isUF)
            appContainer.id = "intro";
        else
            appContainer.id = "intro-nfl";
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        appContainer.id = "";
    }
    render() {
        return html `
      <start-container ?hideheader=${true}>
        ${this.renderAgeRatingIcon()}
        <main>
          <header>
            ${when(ConfigurationService.isUF, () => html `<uf-stamp></uf-stamp>`)}
            ${when(ConfigurationService.isBengals, () => html `<bengals-stamp></bengals-stamp>`)}
            ${when(ConfigurationService.isChargers, () => html `<chargers-stamp></chargers-stamp>`)}
          </header>

          <uf-swiper .images=${[
            "/intro_free_to_play.png",
            "/intro_transfers.png",
            "/intro_leaderboard.png",
            "/intro_free_pack.png"
        ]} autoplay="3" loop></uf-swiper>
          
          <div>
            <div id="buttons">
              <router-link href="/signup">
                <button class="dark"><span>Sign Up</span></button>
              </router-link>
              <router-link href="/login">
                <button><span>Log in</span></button>
              </router-link>
            </div>
            <footer>
              <low6-user-message></low6-user-message>
              ${when(!ConfigurationService.isUF, () => html `<intro-footer-text></intro-footer-text>`)}
            </footer>
          </div>

        </main>
      </start-container>
    `;
    }
    renderAgeRatingIcon() {
        return html `
      <div id="age-rating">
        <uf-icon icon=${ConfigurationService.ageRatingIcon()}></uf-icon>
      </div>
    `;
    }
};
IntroView.styles = [globalCSS, introCSS];
IntroView = __decorate([
    customElement("intro-view")
], IntroView);
export { IntroView };
