import SkinnableElement from "./skinnable-element";
import { css } from "lit";
/**
 * Makes use of the Shadow DOM and provides reset styles which subclasses should inherit.
 */
export default class ScopedElement extends SkinnableElement {
    constructor(styles) {
        super(styles);
    }
}
ScopedElement.styles = css `
    :host {
      box-sizing: border-box;
    }
    
    :host *, :host *::before, :host *::after {
      box-sizing: inherit;
    }
    
    [hidden] {
      display: none !important;
    }
  `;
