var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
let UFOverlayDimmed = class UFOverlayDimmed extends LitElement {
    render() {
        return html `
      <slot></slot>
    `;
    }
};
UFOverlayDimmed.styles = [globalCSS, css `
    :host {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: var(--real100vh, 100vh);
      background-color: #000000DD;
      z-index: 2;
      overflow-y: auto;
    }
  `];
UFOverlayDimmed = __decorate([
    customElement("uf-overlay-dimmed")
], UFOverlayDimmed);
export { UFOverlayDimmed };
