var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import signupCSS from "../signup.view.css";
import "@/components/forms/form-textbox";
import "@/components/forms/form-datepicker";
import "@/components/forms/form-grid";
import "@/components/forms/form-row";
import { initialValues, initialErrors } from "../signup.config";
import ConfigurationService from "@/services/config/config";
import { validateEmail } from "@/services/utils/validation";
import low6Api from "@/services/api/low6/low6.api";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
import router from "~/app/router/router";
let SignupStep1 = class SignupStep1 extends LitElement {
    constructor() {
        super(...arguments);
        this.confirmEmailAddress = "";
        this.backRoute = "/";
        this.setErrors = () => null;
        this.setStepNumber = () => null;
        this.isStepValid = false;
        this.handleChange = () => null;
        this.values = initialValues;
        this.errors = initialErrors;
        this.emailAvailable = "";
        this.onChange = (e) => {
            this.handleChange(e);
            this.performUpdate();
        };
        this.validateEmail = async (e) => {
            const { value } = e.target;
            if (!validateEmail(value))
                return;
            const available = await low6Api.validateEmail(value);
            this.errors.email = available ? '' : 'Email already in use';
            this.requestUpdate();
        };
        this.validateUsername = async (e) => {
            const { value, validity } = e.target;
            if (!validity.valid)
                return;
            const available = await low6Api.validateUsername(value);
            this.errors.username = available ? '' : 'Username already in use';
            this.requestUpdate();
        };
    }
    connectedCallback() {
        super.connectedCallback();
        analytics.recordTag(AnalyticsKeys.REGISTRATION_CONTACT_DETAILS_PAGE);
    }
    handleNextClicked() {
        //if (!this.isStepValid) return;
        //TODO: need to revalidate before leaving the form
        this.performUpdate();
        this.setStepNumber(2);
    }
    minAgeValidation() {
        return ConfigurationService.minAgeValidation();
    }
    birthDatePlaceholder() {
        return `date of birth (${ConfigurationService.datePlaceHolder()})`;
    }
    render() {
        const { values, errors } = this;
        return html `

      <header>
        <h1>Step 1</h1>
        <span>Personal Details</span>
      </header>

      <form-grid>
        <form-textbox
          name="email"
          .minlength=${2}
          .maxlength=${100}
          .handleChange=${this.onChange}
          .onBlur=${this.validateEmail}
          .value=${values.email}
          .errorMessage=${errors.email}
          placeholder="email"
        >
        </form-textbox>
        <form-textbox
          name="firstName"
          .minlength=${2}
          .maxlength=${20}
          .handleChange=${this.onChange}
          .value=${values.firstName}
          .errorMessage=${errors.firstName}
          placeholder="first name"
        >
        </form-textbox>
        <form-textbox
          name="lastName"
          .minlength=${2}
          .maxlength=${20}
          .handleChange=${this.onChange}
          .value=${values.lastName}
          .errorMessage=${errors.lastName}
          placeholder="last name"
        >
        </form-textbox>
        <form-textbox
          name="username"
          .minlength=${5}
          .maxlength=${40}
          .handleChange=${this.onChange}
          .onBlur=${this.validateUsername}
          .value=${values.username}
          .errorMessage=${errors.username}
          placeholder="username"
          pattern='.*'
        >
        </form-textbox>
        <form-datepicker
          name="birthDate"
          type="text"
          pattern="[0-9\/]{1,10}"
          .minlength=${5}
          .maxlength=${40}
          .handleChange=${this.onChange}
          .value=${values.birthDate}
          .errorMessage=${errors.birthDate}
          placeholder=${this.birthDatePlaceholder()}
          minAgeValidation=${this.minAgeValidation()}>
        </form-datepicker>

        <div>
          <uf-icon icon=${ConfigurationService.ageRatingIcon()}></uf-icon>
        </div>
      </form-grid>
      <form-row>
        <button 
          class="grey no-border" 
          @click=${router.back}>
          Cancel
        </button>
        <button
          @click=${this.handleNextClicked}
          .disabled=${!this.isStepValid}
          .isPrimary=${true}
          id="nxt-btn">
          Next Step
        </button>
      </form-row>
    `;
    }
};
SignupStep1.styles = [globalCSS, signupCSS, css `
    header {
      margin-bottom: 2.5em;
    }
    form-row button {
      width: 50%;
    }
  `];
__decorate([
    property()
], SignupStep1.prototype, "confirmEmailAddress", void 0);
__decorate([
    property()
], SignupStep1.prototype, "backRoute", void 0);
__decorate([
    state()
], SignupStep1.prototype, "setErrors", void 0);
__decorate([
    state()
], SignupStep1.prototype, "setStepNumber", void 0);
__decorate([
    state()
], SignupStep1.prototype, "isStepValid", void 0);
__decorate([
    state()
], SignupStep1.prototype, "handleChange", void 0);
__decorate([
    state()
], SignupStep1.prototype, "values", void 0);
__decorate([
    state()
], SignupStep1.prototype, "errors", void 0);
__decorate([
    state()
], SignupStep1.prototype, "emailAvailable", void 0);
SignupStep1 = __decorate([
    customElement("signup-step1")
], SignupStep1);
export { SignupStep1 };
