var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Cleave from 'cleave.js';
import { html, LitElement, css } from 'lit';
import { customElement, property, state, query } from 'lit/decorators.js';
import ConfigurationService from "@/services/config/config";
const componentStyles = [css `
  .wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }

  .input {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 3rem;
    color: #f9f9f9;
    background: transparent;
    border: 0;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
  }

  .wrapper.form-error {
    border-color: var(--color-primary);
  }

  .input:focus:not(.form-error) {
    border-color: #f9f9f9;
  }

  .error-message {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
`];
let FormDatePicker = class FormDatePicker extends LitElement {
    constructor() {
        super(...arguments);
        this.value = "";
        this.placeholder = "";
        this.name = "";
        this.id = "";
        this.type = "text";
        this.pattern = "";
        this.error = "";
        this.minAgeValidation = 0;
        this.handleChange = () => null;
        this.isTouched = false;
        this.errorMessage = "";
        this.onChange = (event) => {
            this.handleChange(event);
        };
        this.getWrapperClass = (originalClass) => {
            return `
      ${originalClass}
      ${this.errorMessage && " form-error"}
    `;
        };
        this.getClass = (originalClass) => {
            return `
      ${originalClass}
      ${this.errorMessage && " form-error"}
    `;
        };
    }
    firstUpdated() {
        new Cleave(this.inputEl, {
            date: true,
            datePattern: ConfigurationService.datePattern(),
        });
    }
    render() {
        return html `
    <div class="wrapper" class=${this.getWrapperClass("wrapper")}>
      <input 
        name=${this.name} 
        id=${this.id} 
        .type=${this.type} 
        .pattern=${this.pattern}
        placeholder=${this.placeholder} 
        value=${this.value}
        @input=${this.onChange}
        class=${this.getClass("input")} 
        data-minAge=${this.minAgeValidation}
        data-format=${ConfigurationService.datePattern().join(",")}
      />
    </div>
    ${this.errorMessage ? html `<div class="error-message">${this.errorMessage}</div>` : null}
    `;
    }
};
FormDatePicker.styles = [componentStyles];
__decorate([
    state()
], FormDatePicker.prototype, "value", void 0);
__decorate([
    property()
], FormDatePicker.prototype, "placeholder", void 0);
__decorate([
    property()
], FormDatePicker.prototype, "name", void 0);
__decorate([
    property()
], FormDatePicker.prototype, "id", void 0);
__decorate([
    property()
], FormDatePicker.prototype, "type", void 0);
__decorate([
    property()
], FormDatePicker.prototype, "pattern", void 0);
__decorate([
    property()
], FormDatePicker.prototype, "error", void 0);
__decorate([
    property({ type: Number })
], FormDatePicker.prototype, "minAgeValidation", void 0);
__decorate([
    state()
], FormDatePicker.prototype, "handleChange", void 0);
__decorate([
    state()
], FormDatePicker.prototype, "isTouched", void 0);
__decorate([
    state()
], FormDatePicker.prototype, "errorMessage", void 0);
__decorate([
    query('input') // Define the query
], FormDatePicker.prototype, "inputEl", void 0);
FormDatePicker = __decorate([
    customElement("form-datepicker")
], FormDatePicker);
export { FormDatePicker };
