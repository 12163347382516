var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import "@/components/forms/form-textbox";
import "@/components/forms/form-grid";
import "@/components/forms/form-row";
import { initialValues, initialErrors } from "../signup.config";
import ConfigurationService from "@/services/config/config";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
let SignupStep3 = class SignupStep3 extends LitElement {
    constructor() {
        super(...arguments);
        this.confirmEmailAddress = "";
        this.backRoute = "/";
        this.handleChange = () => null;
        this.setErrors = () => null;
        this.setStepNumber = () => null;
        this.isStepValid = false;
        this.values = initialValues;
        this.errors = initialErrors;
        this.onChange = (e) => {
            const { name } = e.target;
            const valueThatHasToMatch = name === "pinConfirm"
                ? this.values.pin
                : name === "pin"
                    ? this.values.pinConfirm
                    : name === "passwordConfirm"
                        ? this.values.password
                        : name === "password"
                            ? this.values.passwordConfirm
                            : null;
            this.handleChange(e, valueThatHasToMatch);
        };
    }
    connectedCallback() {
        super.connectedCallback();
        analytics.recordTag(AnalyticsKeys.REGISTRATION_PERSONAL_DETAILS_PAGE);
    }
    handleBackClicked() {
        this.setStepNumber(2);
    }
    handleNextClicked() {
        this.setStepNumber(4);
    }
    render() {
        return html `
      <header>
        <h1>Step 3</h1>
        <span>Security Details</span>
      </header>

      <form-grid>

      ${ConfigurationService.useMinRequirementsAPI() ? html `
        <form-textbox
          name="password"
          type="password"
          .minlength=${8}
          .maxlength=${40}
          .handleChange=${this.onChange}
          .value=${this.values.password}
          .errorMessage=${this.errors.password}
          placeholder="password"
        >
        </form-textbox>
        <form-textbox
          name="passwordConfirm"
          type="password"
          .minlength=${8}
          .maxlength=${40}
          .handleChange=${this.onChange}
          .value=${this.values.passwordConfirm}
          .errorMessage=${this.errors.passwordConfirm}
          placeholder="confirm password"
        >
        </form-textbox>
        ` : null}

        ${!ConfigurationService.useMinRequirementsAPI() ? html `
        <form-textbox
          name="password"
          type="password"
          .minlength=${8}
          .maxlength=${40}
          .handleChange=${this.onChange}
          .value=${this.values.password}
          .errorMessage=${this.errors.password}
          placeholder="password"
        >
        </form-textbox>
        <form-textbox
          name="passwordConfirm"
          type="password"
          .minlength=${8}
          .maxlength=${40}
          .handleChange=${this.onChange}
          .value=${this.values.passwordConfirm}
          .errorMessage=${this.errors.passwordConfirm}
          placeholder="confirm password"
        >
        </form-textbox>
        <form-textbox
          name="pin"
          type="password"
          inputmode="numeric"
          pattern="[0-9]*"
          .minlength=${4}
          .maxlength=${6}
          .handleChange=${this.onChange}
          .value=${this.values.pin}
          .errorMessage=${this.errors.pin}
          placeholder="passcode"
        >
        </form-textbox>
        <form-textbox
          name="pinConfirm"
          type="password"
          inputmode="numeric"
          pattern="[0-9]*"
          .minlength=${4}
          .maxlength=${6}
          .handleChange=${this.onChange}
          .value=${this.values.pinConfirm}
          .errorMessage=${this.errors.pinConfirm}
          placeholder="confirm passcode">
        </form-textbox>
        ` : null}

        
      </form-grid>

      <form-row>
        <button 
          class="grey no-border" 
          @click=${this.handleBackClicked}>
          Previous Step
        </button>
        <button
          @click=${this.handleNextClicked}
          .disabled=${!this.isStepValid}
          .isPrimary=${true}
          id="nxt-btn">
          Next Step
        </button>
      </form-row>
    `;
    }
};
SignupStep3.styles = [globalCSS, css `
    header {
      margin-bottom: 2.5em;
    }
    form-row button {
      width: 50%;
    }
  `];
__decorate([
    property()
], SignupStep3.prototype, "confirmEmailAddress", void 0);
__decorate([
    property()
], SignupStep3.prototype, "backRoute", void 0);
__decorate([
    state()
], SignupStep3.prototype, "handleChange", void 0);
__decorate([
    state()
], SignupStep3.prototype, "setErrors", void 0);
__decorate([
    state()
], SignupStep3.prototype, "setStepNumber", void 0);
__decorate([
    state()
], SignupStep3.prototype, "isStepValid", void 0);
__decorate([
    state()
], SignupStep3.prototype, "values", void 0);
__decorate([
    state()
], SignupStep3.prototype, "errors", void 0);
SignupStep3 = __decorate([
    customElement("signup-step3")
], SignupStep3);
export { SignupStep3 };
