var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconCurrencyUS = class IconCurrencyUS extends LitElement {
    render() {
        return html `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11.125" stroke-width="1.75"/>
        <path d="M15.7438 14.06C15.7438 11.96 14.0788 11.42 12.7138 10.88V8.375C13.2388 8.51 13.6138 8.87 13.8538 9.47L15.5938 8.45C15.0238 7.175 13.9588 6.485 12.7138 6.335V5H11.7088V6.305C10.1338 6.455 8.72379 7.475 8.72379 9.335C8.72379 11.33 10.2688 12.005 11.6638 12.545C11.6788 12.545 11.6938 12.56 11.7088 12.56V15.155C10.8838 15.035 10.3588 14.57 10.0438 13.79L8.25879 14.825C8.78379 16.22 10.0288 17.075 11.7088 17.195V18.5H12.7138V17.18C14.4538 16.97 15.7438 15.875 15.7438 14.06ZM10.7788 9.35C10.7788 8.84 11.1088 8.45 11.7088 8.33V10.43C11.0188 10.085 10.7788 9.785 10.7788 9.35ZM12.7138 15.125V12.965C13.3288 13.25 13.6738 13.55 13.6738 14.075C13.6738 14.54 13.3888 14.975 12.7138 15.125Z"/>
      </svg>
    `;
    }
};
IconCurrencyUS.styles = [iconCSS, css `
    circle {
      stroke: var(--color);
    }
    path {
      fill: var(--color);
    }
  `];
IconCurrencyUS = __decorate([
    customElement("icon-currency-us")
], IconCurrencyUS);
export { IconCurrencyUS };
