var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { map } from "lit/directives/map.js";
import { choose } from "lit/directives/choose.js";
import "./info-contest.css";
import config from "@/services/config/config";
import router from "@/router/router";
import { groupBy } from "@/services/utils/utils";
import { formatAsCurrency, formatAsNth, formatAsStageDate } from "@/views/game/directives/game.directives";
import ufApi from "@/services/api/uf/uf.api";
let InfoContestView = class InfoContestView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.type = "overall";
        this.isLoading = true;
        this.fixturesGroupedByDate = {};
    }
    async connectedCallback() {
        super.connectedCallback();
        this.routerSubscription = router.routeChange.subscribe({
            next: route => {
                if ((route.path === "leaderboard/info" || route.path === "leaderboard/info/contest") && route.data) {
                    const routeData = route.data;
                    this.stage = routeData.stage;
                    this.type = routeData.type;
                    this.getFixtures().catch(() => {
                        this.isLoading = false;
                    });
                }
            }
        });
    }
    async getFixtures() {
        const fixtures = (await ufApi.getFixtures(this.stage.id)).fixtures;
        const fixturesGroupedByDateTime = groupBy(fixtures, "gameDate");
        const dateTimes = Object.keys(fixturesGroupedByDateTime).sort();
        const fixturesGroupedByDate = {};
        // Map keys that are in the format '2022-08-27T16:30:00+00:00' to the format '2022-08-27' and combine all values that fall on the same day (regardless of what time of day they are)
        for (let dateTime of dateTimes) {
            const dateString = dateTime.split("T")[0];
            if (!fixturesGroupedByDate[dateString]) {
                fixturesGroupedByDate[dateString] = [];
            }
            fixturesGroupedByDate[dateString] = fixturesGroupedByDate[dateString].concat(fixturesGroupedByDateTime[dateTime]);
        }
        this.fixturesGroupedByDate = fixturesGroupedByDate;
        this.isLoading = false;
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.routerSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.routerSubscription = undefined;
    }
    render() {
        return html `
      ${this.renderHeader()}
      <section>
        ${when(this.isLoading, () => this.renderLoading(), () => this.renderFixtures())}
        ${when(this.stage, () => this.renderPrizeBreakdown())}
      </section>
      ${this.renderFooter()}
    `;
    }
    renderHeader() {
        var _a;
        return html `
      <header>
        ${when(this.type === "clash", () => html `<div class="clash-image"></div>`)}

        <h3>
          ${choose(this.type, [
            ["overall", () => "Overall"],
            ["clash", () => `${config.appName()} ${config.clashName()}`],
            ["userLeague", () => ""]
        ])}
        </h3>
        <h1>${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.name}</h1>
        <h3>${formatAsStageDate(this.stage.start, this.stage.end)}</h3>
        ${when(this.type === "clash", () => html `<p>Take on other ${config.appName()} members in a 5-player clash to reign supreme ${config.appName()} player!</p>`)}
      </header>
    `;
    }
    renderLoading() {
        return html `
      <div id="loader">
        <loading-spinner></loading-spinner>
      </div>
    `;
    }
    renderFixtures() {
        const dates = Object.keys(this.fixturesGroupedByDate).sort();
        return html `
      <div class="fixtures">
        <div class="heading">
          <p>Fixtures</p>
        </div>
        <hr/>
        ${map(dates, (dateString) => {
            const date = new Date(dateString);
            // convert date to the form 'Sat 27 Aug 2022'
            const dateStringPretty = date.toLocaleDateString(undefined, { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' });
            return html `
            <p>${dateStringPretty}</p>
            <div class="rows">
              ${map(this.fixturesGroupedByDate[dateString], (fixture) => {
                const gameDate = new Date(fixture.gameDate);
                const gameDateStringPretty = gameDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                return html `
                  <div class="row">
                    <p>${fixture.homeTeam.teamName} v ${fixture.awayTeam.teamName}</p>
                    <p>${gameDateStringPretty}</p>
                  </div>
                `;
            })}
            </div>
          `;
        })}
      </div>
    `;
    }
    renderPrizeBreakdown() {
        switch (this.type) {
            case "clash":
                return this.renderClashPrizeBreakdown();
            case "userLeague":
                return html ``; // There are no prizes for user leagues at the moment
            case "overall":
                return this.renderOverallPrizeBreakdown();
        }
    }
    renderClashPrizeBreakdown() {
        return html `
      <div class="prize-breakdown">
        <div class="heading">
          <p>Position</p>
          <p>Prize</p>
        </div>
        <hr/>
        <div class="rows">
         <div class="row">
            <p>1st</p>
            <p>FREE CARD</p>
          </div>
        </div>
      </div>
    `;
    }
    renderOverallPrizeBreakdown() {
        const prizes = this.stage.breakdown.split(",");
        return html `
      <div class="prize-breakdown">
        <div class="heading">
          <p>Position</p>
          <p>Prize</p>
        </div>
        <hr/>
        <div class="rows">
          ${map(prizes, (prize) => {
            // Prize breakdown is in the form '1=100,2=50,3=25...' or '1='Season Tickets',2='Fan Merch'...
            const prizeBreakdown = prize.split("=");
            const positions = prizeBreakdown[0].split('-');
            const position = positions.length == 1
                ?
                    formatAsNth(+positions[0])
                :
                    `${formatAsNth(+positions[0])}-${formatAsNth(+positions[1])}`;
            const prizeAmount = (+prizeBreakdown[1])
                ?
                    formatAsCurrency(+prizeBreakdown[1] * 100)
                :
                    prizeBreakdown[1].replace(/^\'+|\'+$/g, ''); // Trim off the ' at the start and end of the prize
            return html `
                <div class="row">
                  <p>${position}</p>
                  <p>${prizeAmount}</p>
                </div>
              `;
        })}
        </div>
      </div>
    `;
    }
    renderFooter() {
        return html `
      <footer>
        ${when(this.type === "clash", () => html `<p>Users are automatically entered into a random contest with 4 other users. Winner receives 1x free ${config.appName()} Clash Pack. If there is a tie, both users will receive 1x free ${config.appName()} Clash Pack. Terms and Game Rules Apply. ${config.minAgeValidation()}+</p>`)} 
      </footer>
    `;
    }
};
__decorate([
    state()
], InfoContestView.prototype, "stage", void 0);
__decorate([
    state()
], InfoContestView.prototype, "type", void 0);
__decorate([
    state()
], InfoContestView.prototype, "isLoading", void 0);
__decorate([
    state()
], InfoContestView.prototype, "fixturesGroupedByDate", void 0);
InfoContestView = __decorate([
    customElement("info-contest-view")
], InfoContestView);
export { InfoContestView };
