import { SquadItemLinupPosition } from "@/services/api/uf/types/uf.api.types.enums";
import ConfigurationService from "@/services/config/config";
const lineupPositions = () => {
    if (ConfigurationService.isUF) {
        const positions = [
            {
                id: "top",
                type: "team",
                name: "Team",
                position: SquadItemLinupPosition.Team
            },
            {
                id: "top-left",
                type: "player",
                name: "Defender",
                position: SquadItemLinupPosition.DefenderLeft
            },
            {
                id: "top-right",
                type: "player",
                name: "Defender",
                position: SquadItemLinupPosition.DefenderRight
            },
            {
                id: "bottom-left",
                type: "player",
                name: "Midfielder",
                position: SquadItemLinupPosition.MidfieldLeft
            },
            {
                id: "bottom-right",
                type: "player",
                name: "Midfielder",
                position: SquadItemLinupPosition.MidfieldRight
            },
            {
                id: "bottom",
                type: "player",
                name: "Forward",
                position: SquadItemLinupPosition.Forward
            },
            {
                id: "middle",
                type: "player",
                name: "D,M,F",
                position: SquadItemLinupPosition.Wild
            }
        ];
        return positions;
    }
    else {
        const positions = [
            {
                id: "top",
                type: "team",
                name: "Team",
                position: SquadItemLinupPosition.Team
            },
            {
                id: "top-left",
                type: "player",
                name: "Running Back",
                position: SquadItemLinupPosition.DefenderLeft
            },
            {
                id: "top-right",
                type: "player",
                name: "Tight End",
                position: SquadItemLinupPosition.DefenderRight
            },
            {
                id: "bottom-left",
                type: "player",
                name: "Wide Receiver",
                position: SquadItemLinupPosition.MidfieldLeft
            },
            {
                id: "bottom-right",
                type: "player",
                name: "Wide Receiver",
                position: SquadItemLinupPosition.MidfieldRight
            },
            {
                id: "bottom",
                type: "player",
                name: "Quarter-Back",
                position: SquadItemLinupPosition.Forward
            },
            {
                id: "middle",
                type: "player",
                name: "RB,TE,WR",
                position: SquadItemLinupPosition.Wild
            }
        ];
        return positions;
    }
};
export default lineupPositions;
