var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let LogoGambleAware = class LogoGambleAware extends LitElement {
    constructor() {
        super(...arguments);
        this.fill = "#f9f9f9";
    }
    render() {
        return html `
      <svg viewBox="0 0 175 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0372431 0.574829H5.12113C7.38995 0.574829 8.24037 0.647826 8.88284 0.92458C9.49974 1.19737 10.0218 1.64099 10.3846 2.2008C10.7475 2.76062 10.9354 3.41219 10.9251 4.07531C10.9251 5.38912 10.4906 6.03691 9.20563 6.72119C10.7171 7.32944 11.4155 8.26006 11.4155 9.98141C11.4155 12.1681 9.69601 13.6309 7.12614 13.6309H0V11.502H1.03975V2.7037H0.0372431V0.574829ZM3.7617 2.7037V5.86964H6.38745C7.52341 5.86964 8.29934 5.18536 8.29934 4.20304C8.29934 3.33325 7.42719 2.7037 6.20123 2.7037H3.7617ZM3.7617 7.92552V11.502H6.55505C7.74688 11.502 8.67179 10.7417 8.67179 9.74115C8.67179 8.64934 7.74067 7.9164 6.29124 7.9164L3.7617 7.92552Z" fill="white"/>
      <path d="M22.5733 10.3706C22.1202 12.5208 20.2859 13.8528 17.8308 13.8528C14.994 13.8528 12.9146 11.6875 12.9146 8.64927C12.9146 5.61106 15.0127 3.42441 17.8495 3.42441C18.6188 3.41519 19.3782 3.59521 20.0582 3.94801C20.7381 4.3008 21.3169 4.81509 21.7415 5.44379C22.6726 6.83364 22.7068 8.14746 22.744 9.14803H15.4472C15.6955 11.724 17.4149 11.8517 17.8867 11.8517C18.8892 11.8517 19.5503 11.3894 19.9476 10.3706H22.5733ZM19.9848 7.44494C19.8141 6.18586 19.0537 5.50158 17.8495 5.50158C16.7507 5.50158 15.9034 6.22236 15.5806 7.44494H19.9848Z" fill="white"/>
      <path d="M30.9285 7.15H35.5407V13.6309H34.0292V11.6115C33.7819 12.0738 33.4482 12.4865 33.0453 12.828C32.2011 13.5598 31.1121 13.9624 29.985 13.9593C26.8813 13.9593 24.4635 10.9941 24.4635 7.1804C24.4635 3.36668 26.8658 0.325439 29.9478 0.325439C31.2141 0.325439 32.707 0.678222 33.9143 2.58508V0.574815H35.3141V4.72308H33.8585C33.7348 4.14176 33.4756 3.59634 33.1012 3.12947C32.7674 2.69958 32.3397 2.34835 31.8493 2.10154C31.359 1.85472 30.8185 1.71859 30.2675 1.70312C28.6225 1.70312 26.2606 3.07473 26.2606 7.11046C26.2606 10.2429 27.98 12.5847 30.2954 12.5847C31.9776 12.5847 33.5667 11.2313 33.9423 9.49175C34.008 9.14372 34.0403 8.79039 34.0385 8.43644H30.9348V7.15912L30.9285 7.15Z" fill="white"/>
      <path d="M46.9003 13.6309H44.0262V11.873C43.2162 13.1138 42.1175 13.8164 40.6121 13.8164C38.722 13.8164 37.3439 12.539 37.3439 10.8177C37.3439 8.92604 39.0448 7.98021 43.0517 7.61222C43.1851 7.59398 43.601 7.55748 43.9828 7.51795V6.49913C43.9828 5.37082 43.0951 4.67438 41.6953 4.67438C40.2211 4.67438 39.56 5.15794 39.2589 6.43526H37.7288C37.9554 5.43469 38.2564 4.91464 38.8617 4.39762C39.6391 3.81004 40.6025 3.50866 41.5836 3.54608C43.6631 3.54608 45.4384 4.4919 45.4384 6.99181V12.4661H46.8755V13.6339L46.9003 13.6309ZM44.0076 8.53676L43.0765 8.64929C39.9728 9.02032 38.9361 9.56166 38.9361 10.8329C38.9469 11.0922 39.0098 11.3468 39.1211 11.5823C39.2324 11.8177 39.39 12.0293 39.5849 12.205C39.7798 12.3807 40.0081 12.5171 40.2568 12.6062C40.5055 12.6953 40.7697 12.7356 41.0342 12.7246C42.8313 12.7246 44.0014 11.058 44.0014 10.1486V8.53676H44.0076Z" fill="white"/>
      <path d="M51.1058 5.53806C51.559 4.85074 52.4684 3.48218 54.3182 3.48218C55.6621 3.48218 56.4349 4.16646 56.6832 5.55631C56.9195 5.09297 57.2464 4.67964 57.6454 4.33981C58.2332 3.79634 59.0098 3.49196 59.818 3.48827C61.3698 3.48827 62.273 4.46754 62.273 6.19193V12.4691H63.6728V13.6369H60.8329V6.70287C60.8329 5.31606 60.3611 4.68652 59.3772 4.68652C57.6205 4.68652 56.6956 6.76065 56.6956 7.42364V12.4599H58.1513V13.6278H55.24V6.48085C55.24 5.24002 54.7682 4.68652 53.7843 4.68652C52.2138 4.68652 51.0996 6.59338 51.0996 7.49967V12.463H52.5366V13.6308H48.1573V12.463H49.6316V4.83249H48.1573V3.64641H51.1058V5.53806Z" fill="white"/>
      <path d="M65.1781 0H68.0149V5.76012C68.3935 5.05759 69.5667 3.55522 71.3421 3.55522C73.6264 3.55522 75.3769 5.83311 75.3769 8.75879C75.3769 11.4959 73.5147 13.7616 71.311 13.7616C69.5171 13.7616 68.648 12.5938 68.0242 11.7605V13.6309H65.1315V12.463H66.5686V1.16784H65.1781V0ZM68.0149 9.42786C68.0149 11.0762 69.3743 12.5391 70.9262 12.5391C72.6643 12.5391 73.7785 11.0002 73.7785 8.61281C73.7785 6.31667 72.6643 4.7413 70.9851 4.7413C69.5109 4.7413 68.018 6.35317 68.018 7.75822V9.42786H68.0149Z" fill="white"/>
      <path d="M76.5284 0H79.418V12.4661H80.7805V13.6309H76.5098V12.4661H77.9623V1.16784H76.5284V0Z" fill="white"/>
      <path d="M90.6037 10.8907C90.4596 11.4529 90.1821 11.9739 89.7937 12.4113C89.4006 12.8653 88.9098 13.2283 88.3565 13.4744C87.8033 13.7205 87.2013 13.8435 86.5937 13.8346C83.8345 13.8346 81.9071 11.724 81.9071 8.68575C81.9071 5.76007 83.8345 3.51562 86.3485 3.51562C88.8625 3.51562 90.8272 5.44073 90.8272 8.92296H83.49C83.6235 10.3858 83.9649 11.2009 84.6632 11.8699C84.9146 12.1121 85.2132 12.3023 85.5411 12.4293C85.869 12.5562 86.2195 12.6173 86.572 12.609C87.7452 12.609 88.4622 12.0889 88.9743 10.8876L90.6037 10.8907ZM89.1512 7.8342C89.0363 6.59337 88.7539 5.90909 88.1673 5.35254C87.7074 4.94519 87.1048 4.72733 86.4851 4.74429C85.2436 4.74429 84.2349 5.55935 83.7818 6.87317C83.683 7.18774 83.6134 7.5104 83.5738 7.83724L89.1512 7.8342Z" fill="white"/>
      <path d="M97.0129 0.574829H100.207L103.872 11.502H104.875V13.6309H101.802L100.933 10.7965H96.2556L95.3866 13.6309H92.2828V11.502H93.304L97.0098 0.574829H97.0129ZM98.6175 3.00783L96.8422 8.78619H100.359L98.6175 3.00783Z" fill="white"/>
      <path d="M104.8 3.72253H108.844V5.65069H107.823L109.223 11.4838L110.887 3.72253H113.494L115.176 11.3895L116.52 5.65069H115.536V3.72253H119.602V5.65069H118.73L116.557 13.6309H113.872L112.19 6.74249L110.604 13.6309H107.882L105.651 5.65069H104.8V3.72253Z" fill="white"/>
      <path d="M129.164 11.7057H130.046V13.6308H126.895V12.463C125.856 13.4818 125.083 13.8346 123.835 13.8346C121.812 13.8346 120.263 12.4995 120.263 10.7416C120.263 7.83421 123.609 7.51792 126.84 7.22292C126.821 5.70229 126.442 5.31606 124.977 5.31606C123.938 5.31606 123.221 5.88781 123.162 6.81539L120.707 6.74239C120.837 4.7595 122.463 3.48218 124.866 3.48218C127.659 3.48218 129.174 4.72301 129.174 6.98266V11.7057H129.164ZM126.802 8.94426C124.419 9.16628 122.87 9.37004 122.87 10.7416C122.87 11.5567 123.531 12.092 124.534 12.092C124.83 12.1002 125.126 12.0496 125.402 11.9432C125.679 11.8369 125.93 11.677 126.142 11.4731C126.354 11.2693 126.522 11.0256 126.635 10.7567C126.748 10.4878 126.805 10.1993 126.802 9.90833V8.94426Z" fill="white"/>
      <path d="M135.251 5.25827C135.912 3.9992 136.651 3.48218 137.783 3.48218C138.165 3.48765 138.546 3.53045 138.919 3.60991V5.92734C138.512 5.83843 138.095 5.79457 137.678 5.79657C136.353 5.79657 135.238 6.70895 135.238 7.85246V11.7057H136.449V13.6308H131.793V11.7057H132.774V5.65363H131.812V3.72853H135.251V5.26435V5.25827Z" fill="white"/>
      <path d="M149.087 10.3707C148.634 12.5208 146.806 13.8651 144.341 13.8651C141.508 13.8651 139.428 11.6997 139.428 8.6615C139.428 5.6233 141.526 3.43663 144.36 3.43663C145.13 3.42718 145.89 3.60708 146.57 3.95988C147.251 4.31267 147.83 4.82708 148.255 5.45603C149.186 6.84588 149.22 8.15969 149.258 9.16026H141.961C142.206 11.7362 143.926 11.8639 144.397 11.8639C145.4 11.8639 146.061 11.4017 146.458 10.3828L149.087 10.3707ZM146.495 7.44501C146.325 6.18593 145.564 5.50164 144.357 5.50164C143.261 5.50164 142.411 6.22242 142.091 7.44501H146.495Z" fill="white"/>
      <path d="M150.474 12.3718H151.79V13.6309H150.474V12.3718Z" fill="white"/>
      <path d="M153.339 9.89018C153.339 7.61836 154.767 6.05212 156.831 6.05212C158.895 6.05212 160.322 7.67311 160.322 9.89018C160.322 12.2046 158.879 13.7982 156.803 13.7982C154.726 13.7982 153.339 12.2167 153.339 9.89018ZM156.787 12.8706C158.231 12.8706 159.093 11.7332 159.093 9.86281C159.093 8.10192 158.187 6.9797 156.787 6.9797C155.388 6.9797 154.565 8.10193 154.565 9.91147C154.565 11.721 155.4 12.8645 156.784 12.8645L156.787 12.8706Z" fill="white"/>
      <path d="M163.476 7.88291C163.672 7.37864 163.981 6.92359 164.379 6.55085C164.764 6.22508 165.255 6.04398 165.763 6.03992C166.039 6.04923 166.314 6.07667 166.586 6.12204V7.21689C166.32 7.16019 166.05 7.12761 165.779 7.11957C163.715 7.11957 163.476 9.21194 163.476 9.32447V12.758H164.776V13.6309H161.297V12.758H162.387V7.04961H161.254V6.16461H163.476V7.88291Z" fill="white"/>
      <path d="M172.852 11.8031C172.16 12.7581 171.397 13.0774 170.562 13.0774C168.737 13.0774 167.48 11.6085 167.48 9.48873C167.48 7.48151 168.721 6.05212 170.434 6.05212C171.834 6.05212 172.455 6.92496 172.852 7.49367V6.16465H175V7.04965H173.942V13.1747C173.942 14.9751 172.768 16 170.701 16C169.46 16 168.371 15.4587 167.862 14.5889C167.735 14.3535 167.631 14.1072 167.551 13.8529H168.793C169.246 14.7653 169.783 15.0846 170.801 15.0846C172.086 15.0846 172.865 14.4338 172.865 13.3542V11.8031H172.852ZM172.852 8.92002C172.852 8.10192 171.834 6.94017 170.68 6.94017C169.438 6.94017 168.675 7.9377 168.675 9.55868C168.675 11.1797 169.451 12.2046 170.751 12.2046C171.868 12.2046 172.858 11.1371 172.858 10.2643V8.92002H172.852Z" fill="white"/>
      </svg>

    `;
    }
};
LogoGambleAware.styles = [css `
    :host {
      display: flex;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  `];
__decorate([
    property()
], LogoGambleAware.prototype, "fill", void 0);
LogoGambleAware = __decorate([
    customElement("logo-gamble-aware")
], LogoGambleAware);
export { LogoGambleAware };
