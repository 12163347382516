var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import globalCSS from "~/styles/global.css";
import loginCSS from "./login.css";
import "@/components/start-container/start-container";
import "@/components/asset/uf-stamp/uf-stamp";
import "@/components/asset/logo-uf/logo-uf";
import "@/components/low6-user-message/low6-user-message";
import "@/components/loading-spinner/loading-spinner";
import "@/components/uf-modal/uf-modal";
import low6Api from "@/services/api/low6/low6.api";
import config from "@/services/config/config";
import { appContainer } from "@/services/config/constants";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
let LoginView = class LoginView extends LitElement {
    constructor() {
        super(...arguments);
        this.emailAddress = "";
        this.emailInputTouched = false;
        this.password = "";
        this.passwordInputTouched = false;
        this.loading = false;
        this.errorModalVisible = false;
        this.errorModalText = "";
    }
    get loginDisabled() {
        return !this.emailAddress || !this.password;
    }
    connectedCallback() {
        super.connectedCallback();
        analytics.recordTag(AnalyticsKeys.LOGIN_PAGE);
        if (!config.isUF)
            appContainer.id = "intro-nfl";
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.emailInputTouched = false;
        this.passwordInputTouched = false;
    }
    render() {
        return html `
      <start-container backRoute="/">
      
        <form @submit=${this.attemptLogin} novalidate>
          
          ${when(config.isUF, () => html `<uf-stamp></uf-stamp>`)}
          ${when(config.isBengals, () => html `<bengals-stamp isHorizontal="true"></bengals-stamp>`)}
          ${when(config.isChargers, () => html `<chargers-stamp></chargers-stamp>`)}

          <main>
            <div>

              <div class="input">
                <input
                  type="email"
                  placeholder="email"
                  title="email or username"
                  @input=${this.onInput}
                  @blur=${this.onBlur}
                  class=${this.emailInputTouched && !this.emailAddress ? "error" : ""}
                >
                <span>Field is required</span>
              </div>

              <div class="input">
                <input
                  type="password"
                  placeholder="password"
                  @input=${this.onInput}
                  @blur=${this.onBlur}
                  class=${this.passwordInputTouched && !this.password ? "error" : ""}
                >
                <span>Field is required</span>
                <router-link href="/reset-password">
                  <span class="small-text">Forgotten Password?</span>
                </router-link>
              </div>

            </div>

            <footer>
              <button ?disabled=${this.loginDisabled}>Login</button>
              <low6-user-message></low6-user-message>
            </footer>
          </main>

          ${when(this.loading, () => html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            `)}
          
        </form>

        ${when(this.errorModalVisible, () => html `
            <uf-modal>
              <h3>Error</h3>
              <p>${this.errorModalText}</p>
              <div>
                <button @click=${this.hideErrorModal}>OK</button>
              </div>
            </uf-modal>
          `)}

      </start-container>
    `;
    }
    onInput(e) {
        const input = e.composedPath()[0];
        switch (input.type) {
            case "email":
                this.emailAddress = input.value;
                break;
            case "password":
                this.password = input.value;
        }
    }
    onBlur(e) {
        const input = e.composedPath()[0];
        switch (input.type) {
            case "email":
                if (!this.emailInputTouched) {
                    this.emailInputTouched = true;
                }
                break;
            case "password":
                if (!this.passwordInputTouched) {
                    this.passwordInputTouched = true;
                }
        }
    }
    /**
     * Attempts to log in to both Low6 and UF. If successful, it dispatches an event for the parent login view to handle the necessary routing. If unsuccessful, an error modal is displayed.
     * @param e Form SubmitEvent
     */
    async attemptLogin(e) {
        e.preventDefault();
        this.loading = true;
        try {
            await low6Api.login(this.emailAddress, this.password);
            location.reload();
        }
        catch (error) {
            this.loading = false;
            this.showErrorModal(error.message);
        }
    }
    /**
     * Displays an error modal and and sets an appropriate message based on the error type.
     * @param errorType Type of error.
     */
    showErrorModal(errorType) {
        switch (errorType) {
            case "client":
                this.errorModalText = "The user credentials were incorrect, please try again.";
                break;
            case "server":
                this.errorModalText = "There was something wrong on our end, please try again.";
                break;
            case "other":
            default:
                this.errorModalText = "There was an issue, please try again.";
                break;
        }
        this.errorModalVisible = true;
    }
    hideErrorModal() {
        this.errorModalVisible = false;
        this.errorModalText = "";
    }
};
LoginView.styles = [globalCSS, loginCSS];
__decorate([
    state()
], LoginView.prototype, "emailAddress", void 0);
__decorate([
    state()
], LoginView.prototype, "emailInputTouched", void 0);
__decorate([
    state()
], LoginView.prototype, "password", void 0);
__decorate([
    state()
], LoginView.prototype, "passwordInputTouched", void 0);
__decorate([
    state()
], LoginView.prototype, "loginDisabled", null);
__decorate([
    state()
], LoginView.prototype, "loading", void 0);
__decorate([
    state()
], LoginView.prototype, "errorModalVisible", void 0);
__decorate([
    state()
], LoginView.prototype, "errorModalText", void 0);
LoginView = __decorate([
    customElement("login-view")
], LoginView);
export { LoginView };
