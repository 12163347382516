var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import session from "@/services/session/session";
import globalCSS from "~/styles/global.css";
import profileCSS from "./profile.css";
import "./parts/profile-image";
import "./parts/profile-form";
import "./parts/profile-actions";
import "./parts/profile-checkboxes";
import "./parts/profile-help";
import "./parts/profile-footer";
import low6Api from "@/services/api/low6/low6.api";
import ConfigurationService from "@/services/config/config";
let ProfileView = class ProfileView extends LitElement {
    constructor() {
        super(...arguments);
        this.isOpen = false;
    }
    updated() {
        if (!this.profile) {
            this.loadData();
        }
    }
    render() {
        return html `
      <div
        @click=${this.toggleProfile}
        class=${classMap({ overlay: true, "is-open": this.isOpen })}
      ></div>

      <div 
        class=${classMap({ wrapper: true, "is-open": this.isOpen })}
      >
        <header class="profile-header">
          <uf-icon
            icon='cancel'
            @click=${this.toggleProfile}
          ></uf-icon>
          Profile
        </header>

        <profile-image 
          .profile=${this.profile}>
        </profile-image>

        ${ConfigurationService.isUF ? html `
          <router-link href="/wallet" @click=${this.toggleProfile}>
          <button>
            <uf-icon icon='wallet'></uf-icon> View Wallet
            </button>
          </router-link>
        ` : html `<div></div>`}

        <profile-form
          .profile=${this.profile}
          .fullProfile=${this.fullProfile}
        ></profile-form>

        <profile-actions></profile-actions>

        ${this.profile && this.profile.username
            ? html `<profile-checkboxes
              .checkboxes=${this.profile.contactPermissions}
              .fullProfile=${this.fullProfile}
            ></profile-checkboxes>`
            : ""}

        <profile-help></profile-help>

        <profile-footer
          @logout=${this.removeUser}
        ></profile-footer>

      </div>
    `;
    }
    async loadData() {
        try {
            const res = await low6Api.getProfile();
            this.profile = this.setShortProfile(res);
            this.fullProfile = res;
        }
        catch (error) {
            console.log("error", error);
        }
    }
    ;
    setShortProfile(profile) {
        return {
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            mobileNumber: profile.mobileNumber,
            address: profile.address,
            image: profile.image,
            username: profile.username,
            termsConditionsAccepted: profile.termsConditionsAccepted,
            contactPermissions: profile.contactPermissions
        };
    }
    async toggleProfile() {
        await this.updateComplete;
        this.dispatchEvent(new CustomEvent('toggleprofile', { bubbles: true, composed: true }));
    }
    ;
    removeUser() {
        this.fullProfile = undefined;
        this.profile = undefined;
        session.logout();
    }
};
ProfileView.styles = [globalCSS, profileCSS];
__decorate([
    state()
], ProfileView.prototype, "profile", void 0);
__decorate([
    state()
], ProfileView.prototype, "fullProfile", void 0);
__decorate([
    state()
], ProfileView.prototype, "isOpen", void 0);
ProfileView = __decorate([
    customElement("profile-view")
], ProfileView);
export { ProfileView };
