var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./mini-league.css";
import "@/components/loading-spinner/loading-spinner";
import "@/views/game/views/leaderboards/components/leaderboard-mini-league/leaderboard-mini-league.view";
import "@/components/header-bar/header-bar";
import router from "@/router/router";
import ufApi from "@/services/api/uf/uf.api";
let MiniLeagueView = class MiniLeagueView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.loading = false;
    }
    async connectedCallback() {
        super.connectedCallback();
        this.loading = true;
        await this.updateComplete;
        this.routeSubscription = router.routeChange.subscribe({
            next: route => {
                if (route.data) {
                    const routeData = route.data;
                    this.stageId = routeData.stageId;
                    this.userLeagueDetailId = routeData.userLeagueDetailId;
                    if (this.stageId && this.userLeagueDetailId) {
                        this.getKnownStage(this.stageId);
                    }
                }
                else {
                    this.getCurrentStage();
                    ufApi.getStages().catch(error => () => this.handleErrors(error));
                }
            }
        });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.currentStageSubscription) {
            this.currentStageSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }
    render() {
        return html `
      <article>
        <header>
          ${when(this.leagueDetails, () => this.renderHeaderBar())}
        </header>
        <main>

          <!-- Leaderboard component goes here -->
          ${when(this.leagueDetails, () => html `
            <leaderboard-mini-league
              .leagueDetails=${this.leagueDetails}
            ></leaderboard-mini-league>
            `)}

          ${when(this.loading, () => html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            `)}

        </main>
      </article>
    `;
    }
    renderBack() {
        return html `
      <div class="left-header">
        <router-link href="/leaderboard">
          <uf-icon icon='chevron'></uf-icon>
        </router-link>
        <span>${when(this.stage, () => this.stage.name)}</span>
      </div>
    `;
    }
    renderHeaderBar() {
        var _a;
        return html `
      <header-bar class="clickable">
        <div slot="center"><h2>${(_a = this.leagueDetails) === null || _a === void 0 ? void 0 : _a.name}</h2></div>
        <div slot="left">${this.renderBack()}</div>
      </header-bar>
    `;
    }
    getCurrentStage() {
        this.currentStageSubscription = ufApi.currentStage.subscribe({
            next: stage => {
                if (stage) {
                    this.stage = stage.stage;
                    this.getMiniLeagues(stage.stage.id);
                }
            }
        });
    }
    getKnownStage(stageId) {
        this.currentStageSubscription = ufApi.stages.subscribe({
            next: stages => {
                if (stages) {
                    const stage = stages.find(stage => stage.id == stageId);
                    if (stage) {
                        this.stage = stage;
                        this.getMiniLeagues(stage.id);
                    }
                }
            }
        });
    }
    async getMiniLeagues(stageId) {
        try {
            const miniLeagues = await ufApi.getMiniLeaguesForStage(stageId);
            const league = miniLeagues.leaderboards.find((league) => league.userLeagueDetailId === this.userLeagueDetailId);
            if (league) {
                this.leagueDetails = league;
                this.loading = false;
            }
            else {
                this.loading = false;
                //redirect
                // router.navigate('leaderboard');
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }
    handleErrors(error) {
        console.log(error);
    }
};
__decorate([
    state()
], MiniLeagueView.prototype, "userLeagueDetailId", void 0);
__decorate([
    state()
], MiniLeagueView.prototype, "stageId", void 0);
__decorate([
    state()
], MiniLeagueView.prototype, "leagueDetails", void 0);
__decorate([
    state()
], MiniLeagueView.prototype, "stage", void 0);
__decorate([
    state()
], MiniLeagueView.prototype, "loading", void 0);
MiniLeagueView = __decorate([
    customElement("mini-league-view")
], MiniLeagueView);
export { MiniLeagueView };
