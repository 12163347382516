var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
const componentStyles = [
    css `
    :host {
      display: flex;
    }

    label {
      display: flex;
      gap: 1rem;
    }

    .checkbox-background {
      display: grid;
      place-items: center;
      width: 1.5rem;
      min-width: 1.5rem;
      max-width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: #3e3b3b;
      cursor: pointer;
    }

    .checkbox-input {
      position: absolute;
      transform: scale(0);
    }

    .checkbox-check {
      position: relative;
      display: block;
      width: 3px;
      height: 12px;
      background: #f9f9f9;
      transform: rotate(45deg) translate(1px, -1px) scale(0);
    }

    .checkbox-check::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 7px;
      height: 3px;
      background: inherit;
    }

    .checkbox-text {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.57);
    }

    .checkbox-input:checked ~ label .checkbox-background {
      background: var(--color-primary);
    }

    .checkbox-input:checked ~ label .checkbox-check {
      transform: rotate(45deg) translate(1px, -1px) scale(1);
    }

    .checkbox-href {
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.3s;
    }

    .checkbox-href:link,
    .checkbox-href:visited,
    .checkbox-href:active {
      color: rgba(255, 255, 255, 0.57);
    }

    .checkbox-href:hover {
      color: #f9f9f9;
    }
  `,
];
let FormCheckbox = class FormCheckbox extends LitElement {
    constructor() {
        super(...arguments);
        this.value = false;
        this.text = "";
        this.placeholder = "";
        this.name = "";
        this.href = "";
        this.hrefText = "";
        this.id = "";
        this.handleChange = () => null;
        this.onChange = (event) => {
            this.handleChange(event);
        };
    }
    render() {
        return html `
      <input
        class="checkbox-input"
        name=${this.name}
        id=${this.id}
        type="checkbox"
        .checked=${this.value}
        @input=${this.onChange}
      />
      <label for=${this.id}>
        <span class="checkbox-background">
          <span class="checkbox-check"></span>
        </span>
        <span class="checkbox-text">
          ${this.text}
          ${this.href
            ? html `<a class="checkbox-href" href=${this.href} target="_blank"
                >${this.hrefText}</a
              >`
            : null}
        </span>
      </label>
    `;
    }
};
FormCheckbox.styles = [componentStyles];
__decorate([
    state()
], FormCheckbox.prototype, "value", void 0);
__decorate([
    property()
], FormCheckbox.prototype, "text", void 0);
__decorate([
    property()
], FormCheckbox.prototype, "placeholder", void 0);
__decorate([
    property()
], FormCheckbox.prototype, "name", void 0);
__decorate([
    property()
], FormCheckbox.prototype, "href", void 0);
__decorate([
    property()
], FormCheckbox.prototype, "hrefText", void 0);
__decorate([
    property()
], FormCheckbox.prototype, "id", void 0);
__decorate([
    state()
], FormCheckbox.prototype, "handleChange", void 0);
FormCheckbox = __decorate([
    customElement("form-checkbox")
], FormCheckbox);
export { FormCheckbox };
