var _a, _b;
import { AsyncSubject } from "rxjs";
import configService from "@/services/config/config";
const { features } = configService.skin;
export const marketplaceViewsReady = new AsyncSubject();
export const walletViewsReady = new AsyncSubject();
// START //
import "@/views/start/views/intro/intro.view";
export const intro = document.createElement("intro-view");
import "@/views/start/views/login/login.view";
export const login = document.createElement("login-view");
import "@/views/start/views/signup/signup.view";
export const signup = document.createElement("signup-view");
import "@/views/start/views/reset-password/reset-password.view";
export const resetPassword = document.createElement("reset-password-view");
// GAME //
import "@/views/game/views/change-password/change-password.view";
export const changePassword = document.createElement("change-password-view");
import "@/views/game/views/lobby/lobby.view";
export const lobby = document.createElement("lobby-view");
import "@/views/game/views/squad/squad.view";
export const squad = document.createElement("squad-view");
import "@/views/game/views/squad/views/lineup/squad-lineup.view";
export const squadLineup = document.createElement("squad-lineup-view");
import "@/views/game/views/squad/views/browse/squad-browse.view";
export const squadBrowse = document.createElement("squad-browse-view");
import "@/views/game/views/squad/views/transfer/transfer.view";
export const transfer = document.createElement("transfer-view");
import "@/views/game/views/leaderboards/views/stage-list/stage-list.view";
export const stageListView = document.createElement("stage-list-view");
import "@/views/game/views/leaderboards/views/stage/stage.view";
export const stageView = document.createElement("stage-view");
import "@/views/game/views/leaderboards/leaderboard.view";
export const leaderboard = document.createElement("leaderboard-view");
import "@/views/game/views/leaderboards/views/leaderboards/leaderboard-position-list.view";
export const leaderboardPositionListView = document.createElement("leaderboard-position-list-view");
import "@/views/game/views/leaderboards/views/mini-leagues/create-league.view";
export const createLeague = document.createElement("create-league-view");
import "@/views/game/views/leaderboards/views/mini-leagues/join-league.view";
export const joinLeague = document.createElement("join-league-view");
import "@/views/game/views/refer-a-friend/refer-a-friend.view";
export const referAFriend = document.createElement("refer-a-friend-view");
import "@/views/game/views/refer-a-friend/views/refer-a-friend-home.view";
export const referAFriendHome = document.createElement("refer-a-friend-home-view");
import "@/views/game/views/refer-a-friend/views/refer-a-friend-onboarding.view";
export const referAFriendOnboarding = document.createElement("refer-a-friend-onboarding-view");
import "@/views/game/views/leaderboards/views/mini-leagues/mini-league.view";
export const miniLeagueMain = document.createElement("mini-league-view");
import "@/views/game/views/leaderboards/views/info/info.view";
export const info = document.createElement("info-view");
import "@/views/game/views/leaderboards/views/info/views/contest/info-contest.view";
export const infoContest = document.createElement("info-contest-view");
import "@/views/game/views/leaderboards/views/info/views/scoring/info-scoring.view";
export const infoScoring = document.createElement("info-scoring-view");
import "@/views/game/views/leaderboards/views/lineup/lineup.view";
export const userLineup = document.createElement("lineup-view");
import "@/views/game/views/leaderboards/views/points-breakdown/points-breakdown.view";
export const pointsBreakdownView = document.createElement("points-breakdown-view");
export const marketplaceViews = {
    main: null, packs: null, coins: null, cards: null, sell: null
};
if (!((_a = features === null || features === void 0 ? void 0 : features.marketplace) === null || _a === void 0 ? void 0 : _a.exclude)) {
    import("@/views/game/views/marketplace/marketplace.view").then(() => {
        marketplaceViews.main = document.createElement("marketplace-view");
        checkMarketplaceViews();
    });
    import("@/views/game/views/marketplace/views/packs/packs.view").then(() => {
        marketplaceViews.packs = document.createElement("marketplace-packs-view");
        checkMarketplaceViews();
    });
    import("@/views/game/views/marketplace/views/coins/coins.view").then(() => {
        marketplaceViews.coins = document.createElement("marketplace-coins-view");
        checkMarketplaceViews();
    });
    import("@/views/game/views/marketplace/views/cards/cards.view").then(() => {
        marketplaceViews.cards = document.createElement("marketplace-cards-view");
        checkMarketplaceViews();
    });
    import("@/views/game/views/marketplace/views/sell/sell.view").then(() => {
        marketplaceViews.sell = document.createElement("marketplace-sell-view");
        checkMarketplaceViews();
    });
    function checkMarketplaceViews() {
        if (Object.keys(marketplaceViews).map(key => marketplaceViews[key]).every(val => val !== null)) {
            marketplaceViewsReady.next();
            marketplaceViewsReady.complete();
        }
    }
}
export const walletViews = {
    wallet: null,
    walletHome: null,
    transactions: null,
    coins: null,
    coinsHome: null,
    coinsAdd: null,
    cash: null,
    cashHome: null,
    convertToCoins: null
};
if (!((_b = features === null || features === void 0 ? void 0 : features.wallet) === null || _b === void 0 ? void 0 : _b.exclude)) {
    import("@/views/game/views/wallet/wallet.view").then(() => {
        walletViews.wallet = document.createElement("wallet-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/home/wallet-home.view").then(() => {
        walletViews.walletHome = document.createElement("wallet-home");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/transactions/transactions.view").then(() => {
        walletViews.transactions = document.createElement("transactions-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/coins/coins.view").then(() => {
        walletViews.coins = document.createElement("coins-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/coins/views/home/coins-home.view").then(() => {
        walletViews.coinsHome = document.createElement("coins-home-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/coins/views/add/add.view").then(() => {
        walletViews.coinsAdd = document.createElement("coins-add-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/cash/cash.view").then(() => {
        walletViews.cash = document.createElement("cash-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/cash/views/home/cash-home.view").then(() => {
        walletViews.cashHome = document.createElement("cash-home-view");
        checkWalletViews();
    });
    import("@/views/game/views/wallet/views/cash/views/convert-to-coins/convert-to-coins.view").then(() => {
        walletViews.convertToCoins = document.createElement("convert-to-coins-view");
        checkWalletViews();
    });
    function checkWalletViews() {
        if (Object.keys(walletViews).map(key => walletViews[key]).every(val => val !== null)) {
            walletViewsReady.next();
            walletViewsReady.complete();
        }
    }
}
import "@/views/game/views/login-streak/login-streak.view";
export const loginStreak = document.createElement("login-streak-view");
import "@/views/game/views/login-streak/views/login-streak-onboarding.view";
export const loginStreakOnboarding = document.createElement("login-streak-onboarding-view");
