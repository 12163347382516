var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c, _d, _e;
import ScopedElement from "@/layout/scoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import { choose } from "lit/directives/choose.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import gameCardStyles from "../game-card.css";
import gameCardBackStyles from "./game-card-back.css";
import "@/components/uf-swiper-new/uf-swiper-new";
import { pluralise } from "@/directives/app.directives";
import { FootballSeasonStatsIndex, FootballUSSeasonStatsIndex, SquadItemPosition, SquadItemRating, SquadItemType } from "@/services/api/uf/types/uf.api.types.enums";
import configService from "@/services/config/config";
import placeholders from "../placeholders";
import { squadPositionLabel } from "@/mappings/squad.mappings";
import config from "@/services/config/config";
const { league, season } = config.skin;
const back = (_b = (_a = config.skin.components) === null || _a === void 0 ? void 0 : _a.gameCard) === null || _b === void 0 ? void 0 : _b.back;
const excludeFooter = back === null || back === void 0 ? void 0 : back.excludeFooter;
const padding = (_c = back === null || back === void 0 ? void 0 : back.theme) === null || _c === void 0 ? void 0 : _c.padding;
const paddingImage = (_e = (_d = back === null || back === void 0 ? void 0 : back.theme) === null || _d === void 0 ? void 0 : _d.padding) === null || _e === void 0 ? void 0 : _e.img;
let GameCardBack = class GameCardBack extends ScopedElement {
    render() {
        let type = "";
        let rating = "";
        if (this.squadItem.scoreableType !== null || this.squadItem.scoreableType !== undefined)
            type = SquadItemType[this.squadItem.scoreableType];
        if (this.squadItem.cardRating !== null || this.squadItem.cardRating !== undefined)
            rating = SquadItemRating[this.squadItem.cardRating];
        return html `
      <div class=${classMap({
            [type.toLowerCase()]: true,
            [rating.toLowerCase()]: true,
            "padded": !!padding,
            "padded-with-image": !!paddingImage
        })}>
        <div>
          ${this.renderHeader()}
          ${this.renderBody()}
          ${when(!excludeFooter, () => html `
              ${this.renderFooter()}
            `)}
        </div>
        ${when(paddingImage, () => html `
            <div id="padding-image"></div>
          `)}
      </div>
    `;
    }
    renderHeader() {
        let img = "";
        let roundelImg = "";
        switch (this.squadItem.scoreableType) {
            case SquadItemType.Team:
                if (this.squadItem.externalRef) {
                    img = `${configService.storageBaseUrl()}/headshots/${this.squadItem.externalRef}.png`;
                }
                break;
            case SquadItemType.Player:
                if (this.squadItem.teamExternalRef) {
                    roundelImg = `${configService.storageBaseUrl()}/headshots/${this.squadItem.teamExternalRef}.png`;
                    if (this.squadItem.externalRef) {
                        if (configService.isUF) {
                            // Currently PL player headshot filesnames are prefixed with the team id whereas the NFL ones are not
                            img = `${configService.storageBaseUrl()}/headshots/${this.squadItem.externalRef}_${this.squadItem.teamExternalRef}.png`;
                        }
                        else {
                            img = `${configService.storageBaseUrl()}/headshots/${this.squadItem.externalRef}.png`;
                        }
                    }
                }
                break;
        }
        return html `
      <header>
          <div id="name">
            ${this.squadItem.knownname ? html `
                <p><span>${this.squadItem.knownname}</span></p>
              ` : html `
                ${when(this.squadItem.firstname && this.squadItem.lastname, () => html `
                    <p>${this.squadItem.firstname[0]}. <span>${this.squadItem.lastname}</span></p>
                  `)}
              `}
            ${when(SquadItemType[this.squadItem.scoreableType].toLowerCase() === "player", () => html `
                <p id="team">${this.squadItem.team}</p>
              `)}
          </div>

          ${img ? html `

              <div id="avatar" style="background-image: url('${img}')"></div>
            
            ` : html `

              <div id="avatar" class="placeholder">
                ${choose(this.squadItem.scoreableType, [
            [SquadItemType.Team, () => choose(this.squadItem.cardRating, [
                    [SquadItemRating.Standard, () => unsafeSVG(placeholders.team.standard)
                    ],
                    [SquadItemRating.Silver, () => unsafeSVG(placeholders.team.silver)
                    ],
                    [SquadItemRating.Gold, () => unsafeSVG(placeholders.team.gold)
                    ]
                ])],
            [SquadItemType.Player, () => choose(this.squadItem.cardRating, [
                    [SquadItemRating.Standard, () => unsafeSVG(placeholders.player.standard)
                    ],
                    [SquadItemRating.Silver, () => unsafeSVG(placeholders.player.silver)
                    ],
                    [SquadItemRating.Gold, () => unsafeSVG(placeholders.player.gold)
                    ]
                ])]
        ])}
              </div>
            
            `}

          ${when(this.squadItem.scoreableType === SquadItemType.Player, () => html `

              <div id="label">
                <span>
                  ${squadPositionLabel(this.squadItem)}
                </span>
              </div>

              ${roundelImg ? html `
                  <div id="roundel" style="background-image: url('${roundelImg}')"></div>
                ` : html `
                  <div id="roundel" class="placeholder">
                    ${choose(this.squadItem.cardRating, [
            [SquadItemRating.Standard, () => unsafeSVG(placeholders.roundel.standard)
            ],
            [SquadItemRating.Silver, () => unsafeSVG(placeholders.roundel.silver)
            ],
            [SquadItemRating.Gold, () => unsafeSVG(placeholders.roundel.gold)
            ]
        ])}
                  </div>
                `}

            `)}
        </header>
    `;
    }
    renderBody() {
        return html `
      <section class=${excludeFooter ? "rounded" : ""}>
        ${this.renderStats()}
        ${this.renderPPG()}
      </section>
    `;
    }
    renderStats() {
        return html `
      <div id="stats">
        ${choose(league, [
            ["pl", () => choose(this.squadItem.scoreableType, [
                    [SquadItemType.Team, () => this.renderPLTeamStats()],
                    [SquadItemType.Player, () => this.renderPLPlayerStats()]
                ])],
            ["nfl", () => choose(this.squadItem.scoreableType, [
                    [SquadItemType.Team, () => this.renderNFLTeamStats()],
                    [SquadItemType.Player, () => this.renderNFLPlayerStats()]
                ])]
        ])}
      </div>
    `;
    }
    renderPLTeamStats() {
        return html `
      <ul>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.Win]} <span>${pluralise("Win", this.squadItem.seasonStats[FootballSeasonStatsIndex.Win])}</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.Goal]} <span>${pluralise("Goal", this.squadItem.seasonStats[FootballSeasonStatsIndex.Goal])} Scored</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.GoalConceeded]} <span>${pluralise("Goal", this.squadItem.seasonStats[FootballSeasonStatsIndex.GoalConceeded])} Against</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.CleanSheet]} <span>${pluralise("Clean Sheet", this.squadItem.seasonStats[FootballSeasonStatsIndex.CleanSheet])}</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.TeamSave]} <span>${pluralise("Save", this.squadItem.seasonStats[FootballSeasonStatsIndex.TeamSave])}</span>
        </li>
      </ul>
    `;
    }
    renderPLPlayerStats() {
        return html `
      <ul>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.Goal]} <span>${pluralise("Goal", this.squadItem.seasonStats[FootballSeasonStatsIndex.Goal])}</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.Assist]} <span>${pluralise("Assist", this.squadItem.seasonStats[FootballSeasonStatsIndex.Assist])}</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.ShotOnTarget]} <span>${pluralise("Shot", this.squadItem.seasonStats[FootballSeasonStatsIndex.ShotOnTarget])} on Target</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.TackleWon]} <span>${pluralise("Tackle", this.squadItem.seasonStats[FootballSeasonStatsIndex.TackleWon])} Won</span>
        </li>
        <li>
          ${this.squadItem.seasonStats[FootballSeasonStatsIndex.Appearance]} <span>${pluralise("Appearance", this.squadItem.seasonStats[FootballSeasonStatsIndex.Appearance])}</span>
        </li>
      </ul>
    `;
    }
    renderNFLTeamStats() {
        return html `
      <uf-swiper-new
        name="stats-swiper"
        direction="vertical"
        .content=${[
            html `
            <h5>Game</h5>
            <ul>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Win]} <span>${pluralise("Win", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Win])}</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Loss]} <span>${pluralise("Loss", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Loss])}</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AveragePointsAllowed]} <span>Avg. ${pluralise("Point", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AveragePointsAllowed])} Allowed</span>
              </li>
            </ul>
          `,
            html `
            <h5>Offence</h5>
            <ul>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageFieldGoalMade]} <span>Avg. FG Made</span>
              </li>
            </ul>
          `,
            html `
            <h5>Defense</h5>
            <ul>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageSacks]} <span>Avg. Sacks</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Interceptions]} <span>${pluralise("Interception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Interceptions])}</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.DefenceReturnTouchDown]} <span>${pluralise("Def Return TD", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.DefenceReturnTouchDown])}</span>
              </li>
            </ul>
          `
        ]}
      ></uf-swiper-new>
    `;
    }
    renderNFLPlayerStats() {
        return choose(this.squadItem.position, [
            [SquadItemPosition.Defender, () => html `
        <uf-swiper-new
          direction="vertical"
          .content=${[
                    html `
            <h5>Rushing</h5>
            <ul>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingTouchDown]} <span>Rushing TD</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards]} <span>${pluralise("Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards])}</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards]} <span>${pluralise("Avg. Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards])}</span>
              </li>
            </ul>
          `,
                    html `
            <h5>Receiving</h5>
            <ul>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.ReceivingTouchDown]} <span>Receiving TD</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceivingYards]} <span>${pluralise("Avg. Receiving Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceivingYards])}</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Receptions]} <span>${pluralise("Reception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Receptions])}</span>
              </li>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceptions]} <span>${pluralise("Avg. Reception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceptions])}</span>
              </li>
            </ul>
          `,
                    html `
            <h5>Other</h5>
            <ul>
              <li>
                ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles]} <span>${pluralise("Fumble", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles])}</span>
              </li>
            </ul>
          `
                ]}
        ></uf-swiper-new>
      `],
            [SquadItemPosition.Midfielder, () => html `
        <uf-swiper-new
          direction="vertical"
          .content=${[
                    html `
              <h5>Receiving</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.ReceivingTouchDown]} <span>Receiving TD</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceivingYards]} <span>${pluralise("Avg. Receiving Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceivingYards])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Receptions]} <span>${pluralise("Reception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Receptions])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceptions]} <span>${pluralise("Avg. Reception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceptions])}</span>
                </li>
              </ul>
            `,
                    html `
              <h5>Rushing</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingTouchDown]} <span>Rushing TD</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards]} <span>${pluralise("Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards]} <span>${pluralise("Avg. Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards])}</span>
                </li>
              </ul>
            `,
                    html `
              <h5>Other</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles]} <span>${pluralise("Fumble", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles])}</span>
                </li>
              </ul>
            `
                ]}
        ></uf-swiper-new>
      `],
            [SquadItemPosition.Forward, () => html `
        <uf-swiper-new
          direction="vertical"
          .content=${[
                    html `
              <h5>Receiving</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.ReceivingTouchDown]} <span>Receiving TD</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceivingYards]} <span>${pluralise("Avg. Receiving Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceivingYards])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Receptions]} <span>${pluralise("Reception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Receptions])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceptions]} <span>${pluralise("Avg. Reception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageReceptions])}</span>
                </li>
              </ul>
            `,
                    html `
              <h5>Rushing</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingTouchDown]} <span>Rushing TD</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards]} <span>${pluralise("Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards]} <span>${pluralise("Avg. Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards])}</span>
                </li>
              </ul>
            `,
                    html `
              <h5>Other</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles]} <span>${pluralise("Fumble", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles])}</span>
                </li>
              </ul>
            `
                ]}
        ></uf-swiper-new>
      `],
            [SquadItemPosition.Goalkeeper, () => html `
        <uf-swiper-new
          direction="vertical"
          .content=${[
                    html `
              <h5>Passing</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.PassingTouchDown]} <span>Passing TD</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.PassingYards]} <span>${pluralise("Passing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.PassingYards])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AveragePassingYards]} <span>${pluralise("Avg. Passing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AveragePassingYards])}</span>
                </li>
              </ul>
            `,
                    html `
              <h5>Rushing</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingTouchDown]} <span>Rushing TD</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards]} <span>${pluralise("Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.RushingYards])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards]} <span>${pluralise("Avg. Rushing Yd", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.AverageRushingYards])}</span>
                </li>
              </ul>
            `,
                    html `
              <h5>Other</h5>
              <ul>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Interceptions]} <span>${pluralise("Interception", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Interceptions])}</span>
                </li>
                <li>
                  ${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles]} <span>${pluralise("Fumble", this.squadItem.seasonStats[FootballUSSeasonStatsIndex.Fumbles])}</span>
                </li>
              </ul>
            `
                ]}
        ></uf-swiper-new>
      `]
        ]);
    }
    renderPPG() {
        return html `
      <div id="ppg">
        <span>AVG</span>
        ${choose(league, [
            ["pl", () => html `
              <span id="ppg-number">${this.squadItem.seasonStats[FootballSeasonStatsIndex.PointsPerGame]}</span>
            `],
            ["nfl", () => html `
              <span id="ppg-number">${this.squadItem.seasonStats[FootballUSSeasonStatsIndex.PointsPerGame]}</span>
            `]
        ])}
        <span>UF PPG</span>
      </div>
    `;
    }
    renderFooter() {
        return html `
      <footer>
        <div>
          ${when(league === "pl", () => html `
              <div id="run">
                ${when(this.squadItem.sequence && this.squadItem.run, () => html `
                    <span id="ratio">
                      ${this.squadItem.sequence}/${this.squadItem.run}
                    </span>
                  `)}
                <span id="type">
                  ${this.squadItem.run ? html `
                      ${when(this.squadItem.run && (this.squadItem.run >= 1 && this.squadItem.run < 1000), () => html `
                          ULTRA
                        `)}
                      ${when(this.squadItem.run && (this.squadItem.run >= 1000 && this.squadItem.run < 10000), () => html `
                          SUPER
                        `)}
                    ` : html `
                      COMMON
                    `}
                  S1
                </span>
              </div>
            `)}
        </div>
        <div id="league">
          <span>${league.toUpperCase()} ${season.toUpperCase()}</span>
        </div>
      </footer>
    `;
    }
};
GameCardBack.styles = [ScopedElement.styles, gameCardStyles, gameCardBackStyles];
__decorate([
    property({ type: Object })
], GameCardBack.prototype, "squadItem", void 0);
GameCardBack = __decorate([
    customElement("game-card-back")
], GameCardBack);
export { GameCardBack };
