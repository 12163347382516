import { css } from "lit";
const profileCSS = css `
  :host {
    position: fixed;
    z-index: 1;
  }

  .wrapper {
    overflow: auto;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    background: #151515;
    transform: translateX(100%);
    transition: all 0.35s;
  }

  @media (min-width: 768px) {
    .wrapper {
      width: 20.75em;
    }
  }

  .wrapper.is-open {
    transform: translateX(0);
  }

  .overlay {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s;
  }

  .overlay.is-open {
    visibility: visible;
    opacity: 1;
  }

  .profile-header {
    position: sticky;
    z-index: 1;
    top: 0;
    display: grid;
    place-items: center;
    height: 4rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    background: #151515;
  }

  uf-icon[icon=cancel] {
    position: absolute;
    cursor: pointer;
    top: 50%;
    height: 1em;
    width: 1em;
    left: 0;
    transform: translateY(-50%);
  }

  router-link {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.5em 1em;
  }

  button {
    width: 100%;
  }

  uf-icon[icon=wallet] {
    height: 1.25em;
    width: 1.25em;
    margin-right: 0.5em;
  }
`;
export default profileCSS;
