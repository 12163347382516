var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import globalCSS from "~/styles/global.css";
let UFToast = class UFToast extends LitElement {
    constructor() {
        super(...arguments);
        this.visible = false;
    }
    render() {
        return html `
      <div class=${classMap({ "visible": this.visible })}>
        <slot></slot>
      </div>
    `;
    }
};
UFToast.styles = [globalCSS, css `

    :host {
      position: relative;
      z-index: 20;
    }

    :host > div {
      position: fixed;
      left: 50%;
      bottom: 3.3125em;
      transform: translate(-50%, 300%);
      background-color: var(--color-active);
      border-radius: 1em;
      padding: 1.125em 2.875em;
      color: var(--color-background);
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      user-select: none;
      visibility: hidden;
      transition: transform .3s ease-out, visibility .3s ease-out;
    }
    :host > div.visible {
      visibility: visible;
      transform: translate(-50%, 0%);
    }
    slot {
      font-size: 1.5em;
    }

    @media (max-width: 500px) {
      :host > div {
        width: 80%;
        bottom: 5em;
      }
      slot {
        font-size: 1.25em;
      }
    }

  `];
__decorate([
    property({ type: Boolean })
], UFToast.prototype, "visible", void 0);
UFToast = __decorate([
    customElement("uf-toast")
], UFToast);
export { UFToast };
