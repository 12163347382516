import { css } from "lit";
const loginCSS = css `

  :host {
    padding-bottom: 3em;
  }
  
  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  #logo {
    display: none;
    justify-content: center;
  }
  logo-uf {
    width: 5em;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60%;
    padding-bottom: 2em;
  }

  .input router-link {
    display: block;
    margin-top: 1.5em;
    text-align: right;
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  footer button {
    width: 11.5em;
  }
  low6-user-message {
    margin-top: 1.5em;
  }

  #loading {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  loading-spinner {
    width: 3em;
  }

  uf-modal {
    text-align: center;
  }
  uf-modal p {
    margin-top: 0.5em;
  }
  uf-modal > div {
    display: flex;
    justify-content: center;
  }
  uf-modal button {
    border: none;
  }

  @media (max-height: 600px) and (min-width: 450px) {
    uf-stamp {
      display: none;
    }
    #logo {
      display: flex;
    }
    main {
      height: 75%;
    }
    main > div {
      margin: 1.5em 0;
    }
  }

  @media (max-height: 500px) and (min-width: 450px) {
    form {
      height: auto;
    }
  }

`;
export default loginCSS;
