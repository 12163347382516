var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let DragItem = class DragItem extends LitElement {
    constructor() {
        super(...arguments);
        this.enabled = false;
        this.canBeDisabled = false;
        this.originalMousePosition = { x: 0, y: 0 };
        this.clone = null;
        this.touching = false;
        this.isTouchEvent = false;
        this.startEvent = (e) => {
            const dragStart = (delay) => {
                this.touchTimeout = window.setTimeout(() => {
                    this.touching = true;
                    this.touchTimeout = undefined;
                    // Cloning this element is required to:
                    // 1. Allow the card to (seemingly) be dragged outside of an area it is confined to.
                    // 2. Create a ghost version for visual effect.
                    this.clone = this.cloneNode(true);
                    // Prevent the cloned element from unnecessarily applying this draggable functionality.
                    this.clone.enabled = false;
                    // Apply all the necessary positioning and styles.
                    this.clone.style.position = "fixed";
                    const { left, top } = this.getBoundingClientRect();
                    this.clone.style.left = `${left}px`;
                    this.clone.style.top = `${top}px`;
                    this.clone.style.zIndex = `10`;
                    this.clone.style.opacity = "0.75";
                    this.parentElement.appendChild(this.clone);
                    let event;
                    const touches = e.touches;
                    if (touches) {
                        event = touches[0];
                    }
                    else {
                        event = e;
                    }
                    this.originalMousePosition.x = event.clientX;
                    this.originalMousePosition.y = event.clientY;
                    this.dispatchEvent(new Event("drag"));
                }, delay);
            };
            // The isTouchEvent logic may seem redundant, but it is required as in some cases touch devices fire both the touchstart and mousedown events. This prevents the dragStart being called twice.
            if (e.touches) {
                this.isTouchEvent = true;
                dragStart(100);
            }
            else {
                if (!this.isTouchEvent)
                    dragStart(100);
            }
        };
        this.moveEvent = (e) => {
            this.isTouchEvent = false;
            if (this.touchTimeout !== undefined) {
                window.clearTimeout(this.touchTimeout);
                this.touchTimeout = undefined;
            }
            else {
                if (this.touching) {
                    let newMousePosition = { x: 0, y: 0 };
                    let event;
                    const touches = e.touches;
                    if (touches) {
                        event = touches[0];
                    }
                    else {
                        event = e;
                    }
                    newMousePosition.x = event.clientX;
                    newMousePosition.y = event.clientY;
                    this.clone.style.transform = `translate(${newMousePosition.x - this.originalMousePosition.x}px, ${newMousePosition.y - this.originalMousePosition.y}px)`;
                }
            }
        };
        this.endEvent = () => {
            if (this.touchTimeout !== undefined) {
                window.clearTimeout(this.touchTimeout);
                this.touchTimeout = undefined;
            }
            else {
                if (this.touching) {
                    this.touching = false;
                    this.parentElement.removeChild(this.clone);
                    this.clone = null;
                    this.dispatchEvent(new Event("dragcomplete"));
                }
            }
        };
    }
    updated() {
        this.enabled ? this.enable() : this.disable();
    }
    enable() {
        this.canBeDisabled = true;
        this.originalMousePosition = { x: 0, y: 0 };
        this.clone = null;
        this.touching = false;
        this.isTouchEvent = false;
        this.classList.add("enabled");
        ["mousedown", "touchstart"].forEach(eventType => {
            this.addEventListener(eventType, this.startEvent);
        });
        ["mousemove", "touchmove"].forEach(eventType => {
            window.addEventListener(eventType, this.moveEvent);
        });
        ["mouseup", "touchend"].forEach(eventType => {
            window.addEventListener(eventType, this.endEvent);
        });
    }
    disable() {
        if (this.canBeDisabled) {
            this.classList.remove("enabled");
            ["mousedown", "touchstart"].forEach(eventType => {
                this.removeEventListener(eventType, this.startEvent);
            });
            ["mousemove", "touchmove"].forEach(eventType => {
                window.removeEventListener(eventType, this.moveEvent);
            });
            ["mouseup", "touchend"].forEach(eventType => {
                window.removeEventListener(eventType, this.endEvent);
            });
        }
    }
    render() {
        return html `
      <slot></slot>
    `;
    }
};
DragItem.styles = css `
    :host {
      display: block;
    }
    :host(.enabled) {
      cursor: move;
    }
  `;
__decorate([
    property({ type: Boolean })
], DragItem.prototype, "enabled", void 0);
DragItem = __decorate([
    customElement("drag-item")
], DragItem);
export { DragItem };
