var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconTransfers = class IconTransfers extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.329781 5.61685C0.254508 5.68683 0.22973 5.79575 0.267324 5.8914C0.304918 5.98705 0.397223 6.04995 0.499999 6.04995L4.12245 6.04995L4.12245 23.7999C4.12245 23.938 4.23438 24.0499 4.37245 24.0499L6.95408 24.0499C7.09215 24.0499 7.20408 23.938 7.20408 23.7999L7.20408 6.04995L10.8265 6.04995C10.9293 6.04995 11.0216 5.98705 11.0592 5.8914C11.0968 5.79575 11.072 5.68683 10.9967 5.61685L5.83348 0.816871C5.73753 0.72767 5.58899 0.72767 5.49304 0.816871L0.329781 5.61685Z" stroke-width="0.5" stroke-linejoin="round"/>
        <path d="M18.1669 25.1831C18.2628 25.2723 18.4114 25.2723 18.5073 25.1831L23.6706 20.3831C23.7458 20.3131 23.7706 20.2042 23.733 20.1086C23.6954 20.0129 23.6031 19.95 23.5004 19.95L19.8779 19.95L19.8779 2.20008C19.8779 2.06201 19.766 1.95008 19.6279 1.95008L17.0463 1.95008C16.9082 1.95008 16.7963 2.06201 16.7963 2.20008L16.7963 19.95L13.1738 19.95C13.0711 19.95 12.9787 20.0129 12.9412 20.1086C12.9036 20.2042 12.9283 20.3131 13.0036 20.3831L18.1669 25.1831Z" stroke-width="0.5" stroke-linejoin="round"/>
      </svg>
    `;
    }
};
IconTransfers.styles = [iconCSS, css `
    path {
      fill: var(--color);
      stroke: var(--color);
    }
  `];
IconTransfers = __decorate([
    customElement("icon-transfers")
], IconTransfers);
export { IconTransfers };
