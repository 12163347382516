import SkinnableElement from "./skinnable-element";
/**
 * Removes the applied-by-default Shadow DOM. Used mostly for Views and parts of Views.
 */
export default class UnscopedElement extends SkinnableElement {
    constructor(styles) {
        super(styles);
    }
    createRenderRoot() {
        return this;
    }
}
