var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import globalCSS from "~/styles/global.css";
import modalCSS from "./uf-modal.css";
let UFModal = class UFModal extends LitElement {
    constructor() {
        super(...arguments);
        this.fixedToTop = false;
        this.handlePosition = () => {
            const rect = this.content.getBoundingClientRect();
            this.fixedToTop = rect.height >= (window.innerHeight - 64);
        };
    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("resize", this.handlePosition);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener("resize", this.handlePosition);
    }
    firstUpdated() {
        // This timeout is required to get accurate dimensions (getBoundingClientRect()) after the modal has been painted. Without the timeout the dimensions aren't accurate as the modal has not completed painting.
        setTimeout(this.handlePosition, 0);
    }
    render() {
        return html `
      <div @click=${this.selectBackground} class=${classMap({
            "fixed": this.fixedToTop
        })}>
        <div id="content" @click=${(e) => e.stopPropagation()}>
          <slot></slot>
        </div>
      </div>
    `;
    }
    selectBackground() {
        this.dispatchEvent(new Event("backgroundselect"));
    }
};
UFModal.styles = [globalCSS, modalCSS];
__decorate([
    query("#content")
], UFModal.prototype, "content", void 0);
__decorate([
    state()
], UFModal.prototype, "fixedToTop", void 0);
UFModal = __decorate([
    customElement("uf-modal")
], UFModal);
export { UFModal };
