var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import ConfigurationService from "@/services/config/config";
import "@/components/asset/logo-gamble-aware/logo-gamble-aware";
import formCSS from "@/components/forms/form.css";
import globalCSS from "~/styles/global.css";
const componentStyles = [
    css `
    article {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    div.uf-background {
      display: grid;
      place-items: center;
      width: 4.125em;
      height: 4.125em;
      border-radius: 0.81em;
      background: url("/icon.png");
      background-size: 4.125em 4.125em;

    }

    logo-uf {
      height: 2.5em;
      width: 2.5em;
      
    }

    .game-title {
      line-height: 1;
      margin: 0 0 4px;
    }

    .game-version {
      margin: 0;
      line-height: 1;
      color: rgba(255, 255, 255, 0.5);
    }

    .legal-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;
      padding: 2em 0 4em;
    }

    .gamble-aware-logo {
      width: 50%;
    }

    p {
      font-size: 0.875em;
      font-weight: 500;
      text-align: center;
      line-height: 1.125;
    }

    h2 {
      font-weight: 700;
      font-size: 1em;
      line-height: 94%; 
      margin-bottom: 0.4em;
    }

    h4 {
      color: var(--color-soft-text);
      font-weight: 500;
      font-size: 1em;
      line-height: 94%; 
    }

    button.logout {
      background-color: var(--color-disabled);
      border-color: var(--color-disabled);
    }

    uf-icon[icon="eighteen"] {
      margin: 0 auto;
      width: 2.875em;
      height: 2.875em;
    }

    uf-icon[icon="twentyOne"] {
      margin: 0 auto;
      width: 2.875em;
      height: 2.875em;
    }

    a.gamble-aware-link {
      color: var(--color-primary);
      text-decoration: none;
      font-weight: 500;
    }
  `,
];
let ProfileFooter = class ProfileFooter extends LitElement {
    render() {
        return html `
      <form>
        <article>
          <div class="uf-background">
          </div>
          <div>
            <h2>${ConfigurationService.appNameLong()}</h2>
            <h4>v 1.0.0</h4>
          </div>
        </article>
        <button 
          @click=${this.logout}
          class="logout"
        >Log Out
        </button>
      </form>
      <footer class="legal-footer">
        <uf-icon icon=${ConfigurationService.ageRatingIcon()}></uf-icon>

        ${when(ConfigurationService.showGambleAwareText(), () => html `

          <div class="gamble-aware-logo">
            <logo-gamble-aware></logo-gamble-aware>
          </div>

          <p class="gamble-aware-text">
            This game may contain gambling advertisements.
            If you play with real money, play with real sense.
          </p>
          <a
            class="gamble-aware-link"
            href="https://www.begambleaware.org/advice-for-safer-gambling"
            target="_blank"
          >
            Find Out More
          </a>

          `)}

        ${when(ConfigurationService.showSportsRadarText(), () => html `

          <p class="sports-radar-text">
            Photos Supplied by AP. Live sports feed by Sportradar. 
          </p>

          `)}


      </footer>
    `;
    }
    async logout() {
        await this.updateComplete;
        this.dispatchEvent(new CustomEvent('logout', { bubbles: true, composed: true }));
    }
    ;
};
ProfileFooter.styles = [globalCSS, formCSS, componentStyles];
ProfileFooter = __decorate([
    customElement("profile-footer")
], ProfileFooter);
export { ProfileFooter };
