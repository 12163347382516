var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./lineup.view.css";
import ufApi from "@/services/api/uf/uf.api";
import low6Api from "~/app/services/api/low6/low6.api";
import router from "@/router/router";
import { findCurrentStage } from "~/app/services/api/uf/uf.helpers";
import { deepEqual } from "~/app/services/utils/utils";
import "@/components/header-bar/header-bar";
import "@/components/asset/uf-icon/uf-icon";
import "~/app/views/game/views/leaderboards/components/league-card/league-card";
import "@/router/components/router-link";
import "@/components/game-card/game-card-front-wide/game-card-front-wide";
let LineupView = class LineupView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.lineup = [];
    }
    connectedCallback() {
        var _a, _b;
        super.connectedCallback();
        const dynamicRoutes = router.dynamicRoutes.getValue();
        const userId = (_a = dynamicRoutes.find(route => route.identifier === "userId")) === null || _a === void 0 ? void 0 : _a.value;
        const stageId = (_b = dynamicRoutes.find(route => route.identifier === "stageId")) === null || _b === void 0 ? void 0 : _b.value;
        this.handleStage(stageId);
        ufApi.getStages().catch(error => () => this.handleErrors(error));
        if (userId !== undefined && stageId !== undefined) {
            try {
                this.getUserProfile(+userId, +stageId);
                this.getUserLineup(+userId, +stageId);
            }
            catch (error) {
                this.handleErrors(error);
            }
        }
    }
    handleStage(id) {
        this.stageSubscription = ufApi.stages.subscribe(stages => {
            var _a;
            const currentStage = (_a = findCurrentStage(stages)) === null || _a === void 0 ? void 0 : _a.stage;
            const stage = id !== undefined ? stages.find(stage => stage.id === +id) : currentStage;
            if (stage && !deepEqual(this.stage, stage)) {
                this.stage = stage;
            }
        });
    }
    async getUserProfile(userId, stageId) {
        var _a;
        this.userId = userId;
        const leaderboards = await ufApi.getLeaderboardForStage(stageId);
        const user = (_a = leaderboards.leaderboard) === null || _a === void 0 ? void 0 : _a.find(entry => entry.userId === userId);
        if (user) {
            this.userName = user.name;
            const currentUser = await low6Api.getProfile();
            if (currentUser.username === user.name) {
                this.userThumbnailImage = currentUser.image;
            }
            else {
                const images = (await low6Api.getProfileImage(user.name)).filter(image => image.username === user.name);
                if (images.length) {
                    this.userThumbnailImage = images[images.length - 1].image;
                }
            }
        }
    }
    async getUserLineup(userId, stageId) {
        this.lineup = await ufApi.getUserLineup(userId, stageId);
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        this.clearState();
        (_a = this.stageSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.stageSubscription = undefined;
    }
    clearState() {
        this.userId = undefined;
        this.userName = undefined;
        this.userThumbnailImage = undefined;
        this.lineup = [];
    }
    render() {
        var _a;
        return html `

      <header-bar>
        <div slot="left">
          <uf-icon
            icon='chevron'
            class="clickable"
            @click=${router.back}
          ></uf-icon>
        </div>
        <div slot="center">
          <h1>${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.name}</h1>
        </div>
      </header-bar>

      <header>
        <div id="user-details">
          ${when(this.userThumbnailImage, () => html `
              <div id="user-thumbnail-image" style="background-image: url(${this.userThumbnailImage})"></div>
            `)}
          ${when(this.userName, () => html `
              <p>${this.userName}</p>
            `)}
        </div>
      </header>

      <section>

        <div id="leagues">
          <h2>Leagues</h2>
          <div>
            ${when(this.stage && this.userId !== undefined, () => html `
                <league-card league="Overall" .stage=${this.stage} userId=${this.userId}></league-card>
                <league-card league="Clash" .stage=${this.stage} userId=${this.userId}></league-card>
              `)}
          </div>
        </div>

        <div id="lineup">
          <h2>Lineup</h2>
          <div>
            ${this.lineup.map(item => {
            var _a;
            return html `
                <router-link href="/leaderboard/points/${this.userId}/${item.cardId}/${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.id}">
                  <game-card-front-wide .squadItem=${item}></game-card-front-wide>
                </router-link>
              `;
        })}
          </div>
        </div>

      </section>

      <footer>
        <p class="small-text">Points will be updated after each match has finished.</p>
      </footer>

    `;
    }
};
__decorate([
    state()
], LineupView.prototype, "stage", void 0);
__decorate([
    state()
], LineupView.prototype, "userId", void 0);
__decorate([
    state()
], LineupView.prototype, "userThumbnailImage", void 0);
__decorate([
    state()
], LineupView.prototype, "userName", void 0);
__decorate([
    state()
], LineupView.prototype, "lineup", void 0);
LineupView = __decorate([
    customElement("lineup-view")
], LineupView);
export { LineupView };
