var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import "../router";
import globalCSS from "~/styles/global.css";
import router from "../router";
let RouterLink = class RouterLink extends LitElement {
    constructor() {
        super(...arguments);
        this.shouldReplace = false;
    }
    render() {
        let url = "";
        if (this.href) {
            url = `${window.location.origin}${this.href}`;
        }
        return html `
      <a @click=${this.navigate} href=${url}>
        <slot></slot>
      </a>
    `;
    }
    navigate(e) {
        e.preventDefault();
        router.navigate(this.href.replace("/", ""), this.data, this.shouldReplace);
    }
};
RouterLink.styles = [globalCSS, css `
    a, a:link, a:visited, a:active {
      color: var(--link-color);
      width: 100%;
    }
  `];
__decorate([
    property()
], RouterLink.prototype, "href", void 0);
__decorate([
    property()
], RouterLink.prototype, "data", void 0);
__decorate([
    property({ type: Boolean })
], RouterLink.prototype, "shouldReplace", void 0);
RouterLink = __decorate([
    customElement("router-link")
], RouterLink);
export { RouterLink };
