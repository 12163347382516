var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import globalCSS from "~/styles/global.css";
import swiperCSS from "./uf-swiper.css";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/game-card/game-card-front/game-card-front";
import Swiper, { Pagination } from "swiper";
let swiper;
let swiperTimer;
let resizeTimeout;
let UFSwiper = class UFSwiper extends LitElement {
    constructor() {
        super(...arguments);
        this.images = [];
        this.cards = [];
        this.items = [];
        this.loop = false;
        this.navigation = false;
        this.currentSlide = 0;
        this.resizeEvent = () => {
            if (swiper) {
                if (resizeTimeout)
                    window.clearTimeout(resizeTimeout);
                resizeTimeout = window.setTimeout(() => {
                    this.currentSlide = 0;
                    this.stopSwiperAutoplay();
                    swiper.destroy();
                    swiper = null;
                    this.initSwiper();
                }, 100);
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.images.length || this.cards.length) {
            if (swiper)
                this.startSwiperAuotplay();
            if (!this.items.length) {
                this.items = this.images.length ? this.images : this.cards;
            }
            if (this.swiperDOM) {
                this.initSwiper();
            }
            window.addEventListener("resize", this.resizeEvent);
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.stopSwiperAutoplay();
        swiper.destroy();
        swiper = null;
        window.removeEventListener("resize", this.resizeEvent);
    }
    firstUpdated() {
        if (this.images.length || this.cards.length) {
            this.initSwiper();
        }
    }
    initSwiper() {
        swiper = new Swiper(this.swiperDOM, {
            loop: this.loop,
            modules: [Pagination],
            pagination: {
                el: this.swiperPaginationDOM,
                clickable: true
            }
        });
        if (this.autoplay) {
            swiper.on("touchStart", () => {
                this.stopSwiperAutoplay();
            });
            swiper.on("touchEnd", () => {
                this.startSwiperAuotplay();
            });
            this.startSwiperAuotplay();
        }
        if (this.navigation) {
            swiper.on("slideChange", () => {
                this.currentSlide = swiper.realIndex;
            });
        }
    }
    /**
     * Starts an interval for autoplaying through the swiper.
     */
    startSwiperAuotplay() {
        swiperTimer = window.setInterval(() => {
            this.next();
        }, this.autoplay * 1000);
    }
    /**
     * Clears the interval for the swiper autoplay.
     */
    stopSwiperAutoplay() {
        if (swiperTimer) {
            window.clearInterval(swiperTimer);
            swiperTimer = null;
        }
    }
    render() {
        return html `
      ${when(this.images.length || this.cards.length, () => html `
          <div class="swiper" part="swiper">
            <div class="swiper-wrapper">
              ${this.images.length ? html `
                
                  ${this.images.map((img, i) => html `
                      <div class="swiper-slide">
                        <img src=${img} style="${i === 3 || i === 1 ? 'height: 80%;' : ''}">
                      </div>
                    `)}

                ` : html `
                
                ${this.cards.map(card => html `
                    <div class="swiper-slide">
                      <game-card-front .squadItem=${card}></game-card-front>
                    </div>
                  `)}
                
                `}
              
            </div>
            <div class="swiper-pagination"></div>
          </div>
          ${when(this.navigation, () => html `
              <div id="buttons">
                <div>
                  ${when(this.loop || (this.currentSlide > 0), () => html `
                      <button id="prev" @click=${this.prev}>
                        <uf-icon icon="chevron"></uf-icon>
                      </button>
                    `)}
                </div>
                <div>
                  ${when(this.loop || (this.currentSlide < this.items.length - 1), () => html `
                      <button id="next" @click=${this.next}>
                        <uf-icon icon="chevron"></uf-icon>
                      </button>
                    `)}
                </div>
              </div>
            `)}
        `)}
    `;
    }
    next() {
        swiper.slideNext(500);
    }
    prev() {
        swiper.slidePrev(500);
    }
};
UFSwiper.styles = [globalCSS, swiperCSS];
__decorate([
    property({ type: Array })
], UFSwiper.prototype, "images", void 0);
__decorate([
    property({ type: Array })
], UFSwiper.prototype, "cards", void 0);
__decorate([
    state()
], UFSwiper.prototype, "items", void 0);
__decorate([
    property({ type: Number })
], UFSwiper.prototype, "autoplay", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiper.prototype, "loop", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiper.prototype, "navigation", void 0);
__decorate([
    query(".swiper")
], UFSwiper.prototype, "swiperDOM", void 0);
__decorate([
    query(".swiper-pagination")
], UFSwiper.prototype, "swiperPaginationDOM", void 0);
__decorate([
    state()
], UFSwiper.prototype, "currentSlide", void 0);
UFSwiper = __decorate([
    customElement("uf-swiper")
], UFSwiper);
export { UFSwiper };
