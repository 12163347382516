var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import "./squad-browse.css";
import { formatAsCurrency } from "@/views/game/directives/game.directives";
import { findCurrentRank } from "@/services/api/uf/uf.helpers";
import { deepEqual } from "@/services/utils/utils";
import ufAPI from "@/services/api/uf/uf.api";
import { CountdownController } from "@/views/game/controllers/countdown.controller";
import "~/app/components/squad-collection/squad-collection";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
let SquadBrowseView = class SquadBrowseView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.squad = [];
    }
    connectedCallback() {
        super.connectedCallback();
        this.handleCurrentStage();
        this.handleRanks();
        this.handleSquad();
        ufAPI.getStages().catch(error => () => this.handleErrors(error));
        ufAPI.getSquad().catch(error => () => this.handleErrors(error));
        analytics.recordTag(AnalyticsKeys.LINEUP_BROWSE);
    }
    handleCurrentStage() {
        this.currentStageSubscription = ufAPI.currentStage.subscribe(currentStage => {
            if (currentStage) {
                if (!deepEqual(this.currentStage, currentStage)) {
                    this.currentStage = currentStage;
                }
                if (this.currentStageCountdown) {
                    this.removeController(this.currentStageCountdown);
                }
                if (!this.currentStage.live) {
                    this.currentStageCountdown = new CountdownController(this, new Date(this.currentStage.stage.start * 1000), () => {
                        this.removeController(this.currentStageCountdown);
                        ufAPI.getStages().catch(error => () => this.handleErrors(error));
                    });
                    if (this.headerBarState !== "gap") {
                        this.headerBarState = "gap";
                    }
                }
                else {
                    ufAPI.getLeaderboard().catch(error => () => this.handleErrors(error));
                    if (this.headerBarState !== "live") {
                        this.headerBarState = "live";
                    }
                }
            }
        });
    }
    handleRanks() {
        this.ranksSubscription = ufAPI.ranks.subscribe(ranks => {
            if (ranks.length) {
                ufAPI.currentStage.subscribe(currentStage => {
                    if (currentStage) {
                        const rank = findCurrentRank(ranks, currentStage.stage);
                        if (!deepEqual(this.rank, rank)) {
                            this.rank = rank;
                        }
                    }
                }).unsubscribe();
            }
        });
    }
    handleSquad() {
        this.squadSubscription = ufAPI.squad.subscribe(squad => {
            if (!deepEqual(this.squad, squad)) {
                this.squad = squad;
            }
        });
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.currentStageSubscription) {
            this.currentStageSubscription.unsubscribe();
        }
        if (this.currentStageCountdown) {
            this.removeController(this.currentStageCountdown);
        }
        if (this.ranksSubscription) {
            this.ranksSubscription.unsubscribe();
        }
        if (this.squadSubscription) {
            this.squadSubscription.unsubscribe();
        }
    }
    render() {
        return html `
      ${this.renderHeaderBar()}
      <squad-collection .squad=${this.squad}></squad-collection>
    `;
    }
    renderHeaderBar() {
        return html `
        ${choose(this.headerBarState, [
            ["gap", () => html `
              <header-bar>
                <div slot="left">
                  ${this.renderBack()}
                </div>
                <div slot="center">
                  <h1>My Cards</h1>
                </div>
                <div slot="right">
                  <p>${this.currentStageCountdown.text}</p>
                  <uf-icon icon="countdown"></uf-icon>
                </div>
              </header-bar>
            `],
            ["live", () => when(this.rank, () => html `
              <header-bar>
                <div slot="left">
                  ${this.renderBack()}
                </div>
                <div slot="center">
                  <h1>My Cards</h1>
                </div>
                <div slot="right">
                  <p class="active">
                    ${formatAsCurrency(this.rank.draftWinnings)}
                  </p>
                </div>
              </header-bar>
            `)]
        ], () => html `
            <header-bar class="purple"></header-bar>
          `)}
    `;
    }
    renderBack() {
        return html `
      <router-link href="/squad">
        <uf-icon icon='chevron' class="clickable"></uf-icon>
      </router-link>
    `;
    }
};
__decorate([
    state()
], SquadBrowseView.prototype, "headerBarState", void 0);
__decorate([
    state()
], SquadBrowseView.prototype, "currentStageCountdown", void 0);
__decorate([
    state()
], SquadBrowseView.prototype, "currentStage", void 0);
__decorate([
    state()
], SquadBrowseView.prototype, "rank", void 0);
__decorate([
    state()
], SquadBrowseView.prototype, "squad", void 0);
SquadBrowseView = __decorate([
    customElement("squad-browse-view")
], SquadBrowseView);
export { SquadBrowseView };
