var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import "./icons/icon-chevron/icon-chevron";
import "./icons/icon-help/icon.help";
import "./icons/icon-help-filled/icon-help-filled";
import "./icons/icon-countdown/icon-countdown";
import "./icons/icon-avatar/icon-avatar";
import "./icons/icon-bars/icon-bars";
import "./icons/icon-currency/icon-currency";
import "./icons/icon-currency-us/icon-currency-us";
import "./icons/icon-info/icon-info";
import "./icons/icon-info-filled/icon-info-filled";
import "./icons/icon-search/icon-search";
import "./icons/icon-transfers/icon-transfers";
import "./icons/icon-warning/icon.warning";
import "./icons/icon-cancel/icon-cancel";
import "./icons/icon-coin/icon-coin";
import "./icons/icon-clear/icon-clear";
import "./icons/icon-add/icon-add";
import "./icons/icon-wallet/icon-wallet";
import "./icons/icon-edit/icon.edit";
import "./icons/icon-save/icon-save";
import "./icons/icon-withdraw/icon-withdraw";
import "./icons/icon-bag/icon-bag";
import "./icons/icon-menu/icon-menu";
import "./icons/icon-join/icon-join";
import "./icons/icon-arrow/icon-arrow";
import "./icons/icon-shirt/icon-shirt";
import "./icons/icon-18/icon-18";
import "./icons/icon-21/icon-21";
import "./icons/icon-copy/icon-copy";
import "./icons/icon-share/icon-share";
import "./icons/icon-raf-onboarding/icon-raf-onboarding";
import "./icons/icon-ls-onboarding/icon-ls-onboarding";
import "./icons/icon-tick/icon-tick";
import "./icons/icon-login-streak-ladder/icon_login_streak_ladder";
import "./icons/icon-pack-reward/icon-pack-reward";
import "./icons/icon-grid/icon-grid";
import "./icons/icon-home/icon-home";
import "./icons/icon-jersey/icon-jersey";
import "./icons/icon-bars-square/icon-bars-square";
import "./icons/icon-reward-lozenge/icon-reward-lozenge";
import "./icons/icon-up-triangle/icon-up-triangle";
import "./icons/icon-filter/icon-filter";
let UFIcon = class UFIcon extends LitElement {
    constructor() {
        super(...arguments);
        this.render = () => this.getIcon(this.icon);
    }
    getIcon(icon) {
        const icons = {
            chevron: html `<icon-chevron theme=${ifDefined(this.theme)}></icon-chevron>`,
            help: html `<icon-help></icon-help>`,
            helpFilled: html `<icon-help-filled theme=${ifDefined(this.theme)}></icon-help-filled>`,
            countdown: html `<icon-countdown theme=${ifDefined(this.theme)}></icon-countdown>`,
            avatar: html `<icon-avatar></icon-avatar>`,
            bars: html `<icon-bars theme=${ifDefined(this.theme)}></icon-bars>`,
            currency: html `<icon-currency></icon-currency>`,
            currencyUs: html `<icon-currency-us></icon-currency-us>`,
            info: html `<icon-info></icon-info>`,
            infoFilled: html `<icon-info-filled theme=${ifDefined(this.theme)}></icon-info-filled>`,
            search: html `<icon-search></icon-search>`,
            transfers: html `<icon-transfers></icon-transfers>`,
            warning: html `<icon-warning theme=${ifDefined(this.theme)}></icon-warning>`,
            cancel: html `<icon-cancel></icon-cancel>`,
            coin: html `<icon-coin theme=${ifDefined(this.theme)}></icon-coin>`,
            clear: html `<icon-clear></icon-clear>`,
            add: html `<icon-add></icon-add>`,
            join: html `<icon-join></icon-join>`,
            shirt: html `<icon-shirt></icon-shirt>`,
            arrow: html `<icon-arrow></icon-arrow>`,
            wallet: html `<icon-wallet></icon-wallet>`,
            edit: html `<icon-edit></icon-edit>`,
            save: html `<icon-save theme=${ifDefined(this.theme)}></icon-save>`,
            withdraw: html `<icon-withdraw></icon-withdraw>`,
            bag: html `<icon-bag theme=${ifDefined(this.theme)}></icon-bag>`,
            menu: html `<icon-menu></icon-menu>`,
            eighteen: html `<icon-18 theme=${ifDefined(this.theme)}></icon-18>`,
            twentyOne: html `<icon-21 theme=${ifDefined(this.theme)}></icon-21>`,
            copy: html `<icon-copy theme=${ifDefined(this.theme)}></icon-copy>`,
            share: html `<icon-share theme=${ifDefined(this.theme)}></icon-share>`,
            rafOnboarding: html `<icon-raf-onboarding theme=${ifDefined(this.theme)}></icon-raf-onboarding>`,
            lsOnboarding: html `<icon-ls-onboarding theme=${ifDefined(this.theme)}></icon-ls-onboarding>`,
            tick: html `<icon-tick></icon-tick>`,
            packReward: html `<icon-pack-reward theme=${ifDefined(this.theme)}></icon-pack-reward>`,
            grid: html `<icon-grid></icon-grid>`,
            loginStreakLadder: html `<icon-login-streak-ladder day=${ifDefined(this.value)} theme=${ifDefined(this.theme)}></icon-login-streak-ladder>`,
            home: html `<icon-home theme=${ifDefined(this.theme)}></icon-home>`,
            jersey: html `<icon-jersey theme=${ifDefined(this.theme)}></icon-jersey>`,
            "bars-square": html `<icon-bars-square theme=${ifDefined(this.theme)}></icon-bars-square>`,
            rewardLozenge: html `<icon-reward-lozenge theme=${ifDefined(this.theme)}></icon-reward-lozenge>`,
            upTriangle: html `<icon-up-triangle theme=${ifDefined(this.theme)}></icon-up-triangle>`,
            filter: html `<icon-filter></icon-filter>`
        };
        return icons[icon];
    }
};
UFIcon.styles = [css `
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    :host(.clickable) {
      padding: 0.25em;
      margin: -0.25em;
      cursor: pointer;
    }
  `];
__decorate([
    property()
], UFIcon.prototype, "icon", void 0);
__decorate([
    property()
], UFIcon.prototype, "theme", void 0);
__decorate([
    property({ type: Number })
], UFIcon.prototype, "value", void 0);
UFIcon = __decorate([
    customElement("uf-icon")
], UFIcon);
export { UFIcon };
