var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { map } from "lit/directives/map.js";
import config from "@/services/config/config";
import "./stage.view.css";
import router from "@/router/router";
import ufAPI from '@/services/api/uf/uf.api';
import { deepEqual, removeWhitespace } from "@/services/utils/utils";
import { findCurrentRank } from "@/services/api/uf/uf.helpers";
import { findCurrentStage } from "@/services/api/uf/uf.helpers";
import '@/views/game/views/leaderboards/components/leaderboard-card/leaderboard-card';
import '@/views/game/views/leaderboards/components/stage-card/stage-card';
import '@/views/game/views/leaderboards/components/user-league-card/user-league-card';
import '@/views/game/views/leaderboards/components/dashed-line/dashed-line';
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
let StageView = class StageView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.isCurrentStage = false;
    }
    async connectedCallback() {
        super.connectedCallback();
        const dynamicRoute = router.dynamicRoutes.getValue().find(route => route.identifier === "id");
        const id = dynamicRoute === null || dynamicRoute === void 0 ? void 0 : dynamicRoute.value;
        id !== undefined ? this.handleStage(+id) : this.handleStage();
        this.handleRank();
        ufAPI.getStages().catch(error => () => this.handleErrors(error));
        ufAPI.getLeaderboard().catch(error => () => this.handleErrors(error));
    }
    handleStage(id) {
        if (id !== undefined) {
            this.stageSubscription = ufAPI.stages.subscribe(stages => {
                const requestedStage = stages.find(stage => stage.id === id);
                if (requestedStage) {
                    if (!deepEqual(this.stage, requestedStage)) {
                        this.stage = requestedStage;
                        const currentStage = findCurrentStage(stages);
                        if (requestedStage.id === (currentStage === null || currentStage === void 0 ? void 0 : currentStage.stage.id)) {
                            this.isCurrentStage = true;
                        }
                        analytics.recordTag(AnalyticsKeys.LEADERBOARDS_STAGE_PAGE(removeWhitespace(this.stage.name)));
                    }
                }
            });
        }
        else {
            this.stageSubscription = ufAPI.stages.subscribe(stages => {
                const currentStage = findCurrentStage(stages);
                if (currentStage && !deepEqual(this.stage, currentStage === null || currentStage === void 0 ? void 0 : currentStage.stage)) {
                    this.stage = currentStage === null || currentStage === void 0 ? void 0 : currentStage.stage;
                    this.isCurrentStage = true;
                    analytics.recordTag(AnalyticsKeys.LEADERBOARDS_STAGE_PAGE(removeWhitespace(this.stage.name)));
                }
            });
        }
    }
    handleRank() {
        this.rankSubscription = ufAPI.ranks.subscribe(ranks => {
            if (ranks.length && this.stage) {
                const rank = findCurrentRank(ranks, this.stage);
                if (!deepEqual(this.rank, rank)) {
                    this.rank = rank;
                }
            }
        });
    }
    handleErrors(error) {
        console.log(error);
    }
    async disconnectedCallback() {
        var _a, _b;
        super.disconnectedCallback();
        (_a = this.stageSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.stageSubscription = undefined;
        (_b = this.rankSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        this.rankSubscription = undefined;
    }
    render() {
        return html `
    ${this.renderHeaderBar()}
    <section>
      ${this.renderStage()}
      ${this.renderUserLeagues()}
      ${this.renderLobby()}
      ${this.renderClash()}
      ${this.render18PlusFooter()}
    </section>
    `;
    }
    renderHeaderBar() {
        return html `
      <header-bar>
        <div slot="center"><h3>Leaderboards</h3></div>
      </header-bar>
    `;
    }
    renderStage() {
        return html `
      ${when(this.stage, () => html `
            <router-link href="/leaderboard/stages" id="stages-card-link">
              <stage-card
                .stage=${this.stage}
                ?isCurrentStage=${this.isCurrentStage}
                ?isLink=${true}
                ?showLineupButton=${false}
              ></stage-card>
            </router-link>
          `)}
    `;
    }
    renderUserLeagues() {
        var _a;
        return html `
      <div id="user-leagues">
        <header>
          <h3>Invitational leagues</h3>
          <router-link href="/leaderboard/join">
            <uf-icon icon='join' class="clickable"></uf-icon>
          </router-link>
        </header>
        <section>
          ${when((_a = this.rank) === null || _a === void 0 ? void 0 : _a.userLeagueRanks, () => html `
                ${map(this.rank.userLeagueRanks, (userLeageRank) => this.renderUserLeagueCard(userLeageRank))}`, () => html `
                <p>Create your own or join an existing Invitational League. Start some friendly competition in pursuit of bragging rights amongst your friends and family.</p>
              `)}
          <dashed-line></dashed-line>
        </section>
      </div>
    `;
    }
    renderUserLeagueCard(userLeageRank) {
        var _a;
        return html `
      <router-link href="/leaderboard/positions/${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.id}/userLeague/${userLeageRank.userLeagueDetailId}">
        <user-league-card
          .rank=${this.rank}
          .userLeagueRank=${userLeageRank}
        ></user-league-card>
      </router-link>
    `;
    }
    renderLobby() {
        return html `
      ${when(this.stage, () => {
            var _a;
            return html `
            <router-link href="/leaderboard/positions/${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.id}">
              <leaderboard-card
                .stage=${this.stage}
                .isCurrentStage=${this.isCurrentStage}
                .cardType=${'week'}
              ></leaderboard-card>
            </router-link>
          `;
        })}
    `;
    }
    renderClash() {
        return html `
      ${when(this.stage, () => {
            var _a;
            return html `
            <router-link href="/leaderboard/positions/${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.id}/clash">
              <leaderboard-card
                .stage=${this.stage}
                .isCurrentStage=${this.isCurrentStage}
                .cardType=${'clash'}
              ></leaderboard-card>
            </router-link>
          `;
        })}
    `;
    }
    render18PlusFooter() {
        return html `
      <footer>
        <uf-icon icon="${config.ageRatingIcon()}"></uf-icon>
        <p>All fantasy contests are subject to the <a href="${config.termsAndConditionsLink()}" target="_blank">General Terms</a> and <a href="${config.gameRulesLink()}" target="_blank">Game Rules</a></p>
      </footer>
    `;
    }
};
__decorate([
    state()
], StageView.prototype, "stage", void 0);
__decorate([
    state()
], StageView.prototype, "isCurrentStage", void 0);
__decorate([
    state()
], StageView.prototype, "rank", void 0);
StageView = __decorate([
    customElement("stage-view")
], StageView);
export { StageView };
