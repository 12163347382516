var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
let DashedLine = class DashedLine extends LitElement {
    render() {
        return html `
      <div class="dashedLine"></div>
    `;
    }
};
DashedLine.styles = [globalCSS, css `

    div.dashedLine {
      margin: auto;
      height: 1px;
      width: 3em;
      background: repeating-linear-gradient(to right, var(--color) 0, var(--color) 5px,transparent 5px, transparent 8px)
    } 
  `];
DashedLine = __decorate([
    customElement("dashed-line")
], DashedLine);
export { DashedLine };
