import config from "../config/config";
const branchKeys = config.skin.branch.keys;
const xtremePushKeys = config.skin.xtremepush.keys;
import { AnalyticsKeys } from "./analytics.constants";
/**
 * A class that initializes and manages sending events and tags to Branch.io and XtremePush
 */
class AnalyticsService {
    constructor() {
        this.initBranchIO();
        this.initXtremePush();
    }
    initBranchIO() {
        // @ts-ignore
        (function (b, r, a, n, c, h, _, s, d, k) { if (!b[n] || !b[n]._q) {
            for (; s < _.length;)
                c(h, _[s++]);
            d = r.createElement(a);
            d.async = 1;
            d.src = "https://cdn.branch.io/branch-latest.min.js";
            k = r.getElementsByTagName(a)[0];
            k.parentNode.insertBefore(d, k);
            b[n] = h;
        } })(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
        const branchKey = branchKeys[process.env.NODE_ENV];
        branch.init(branchKey);
    }
    initXtremePush() {
        const xtremePushKey = xtremePushKeys[process.env.NODE_ENV];
        // @ts-ignore
        (function (p, u, s, h, e, r, l, i, b) { p['XtremePushObject'] = s; p[s] = function () { (p[s].q = p[s].q || []).push(arguments); }; i = u.createElement('script'); i.async = 1; i.src = h; b = u.getElementsByTagName('script')[0]; b.parentNode.insertBefore(i, b); })(window, document, 'xtremepush', `https://prod.webpu.sh/${xtremePushKey}/sdk.js`);
    }
    /**
     * Sets the users id in Branch.io and XtremePush. This is so that the user can be linked up for other Low6 apps and sites that they maybe signed up to.
     * @param low6UserId The users Low6 id
     */
    setUserIdentitiy(low6UserId) {
        branch.setIdentity(`${low6UserId}`);
        xtremepush('set', 'user_id', `${low6UserId}`);
    }
    recordCompleteRegistration() {
        branch.logEvent(AnalyticsKeys.COMPLETE_REGISTRATION);
    }
    recordLogin() {
        branch.logEvent(AnalyticsKeys.LOGIN);
        this.recordEvent(AnalyticsKeys.LOGIN);
    }
    recordLogout() {
        branch.logout();
    }
    /**
     * Records an event in XtremePush. NB events should be used sparsley as they incur more costs in the XtremePush service
     * @param name The name of the event
     */
    recordEvent(name) {
        xtremepush('event', `UltimateFan.${name}`, { "AppName": config.xtremePushAppName() });
    }
    /**
     * Records a tag in XtremePush. Ideally all screens should be tagged up.
     * @param name The name of the event
     */
    recordTag(name) {
        xtremepush('tag', `UltimateFan.${name}`, config.xtremePushAppName());
    }
}
export default new AnalyticsService();
