var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import packItemStyles from "./pack-item.css";
import "@/components/asset/uf-icon/uf-icon";
import { packStatics } from "./image.mappings";
let PackItem = class PackItem extends ScopedElement {
    render() {
        return html `

      <div class="pack-img" style="background-image: url(${packStatics.get(this.code)})">
        ${when(this.quantity !== undefined, () => html `
            <div class="quantity">
              <span>
                ${this.quantity > 0 ? `${this.quantity.toLocaleString()} Left` : "Out of Stock"}
              </span>
            </div>
          `)}
      </div>

      ${when(this.name || this.price, () => html `
          <footer>
            ${when(this.name, () => html `
                <span class="small-text">${this.name}</span>
              `)}
            ${when(this.price, () => html `
                <div>
                  <uf-icon icon="coin"></uf-icon>
                  <span class="small-text">${this.price.toLocaleString()}</span>
                </div>
              `)}
          </footer>
        `)}

    `;
    }
};
PackItem.styles = [ScopedElement.styles, packItemStyles];
__decorate([
    property()
], PackItem.prototype, "code", void 0);
__decorate([
    property()
], PackItem.prototype, "name", void 0);
__decorate([
    property({ type: Number })
], PackItem.prototype, "price", void 0);
__decorate([
    property({ type: Number })
], PackItem.prototype, "quantity", void 0);
PackItem = __decorate([
    customElement("pack-item")
], PackItem);
export { PackItem };
