var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js';
const componentStyles = [css `
  :host {
    display: grid;
    gap: 1rem;
    padding: 2rem 1rem;
    background: var(--color-primary);
    color: #f9f9f9;
    margin-bottom: 2rem;
  }

  h2 {
    margin: 0 0 1rem;
    font-size: 1rem;
  }
`];
let FormSummary = class FormSummary extends LitElement {
    render() {
        return html `
      <h2>Sign up failed due to the following error(s):</h2>
      <slot></slot>
    `;
    }
};
FormSummary.styles = [componentStyles];
FormSummary = __decorate([
    customElement("form-summary")
], FormSummary);
export { FormSummary };
