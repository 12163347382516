import { css } from "lit";
const gameCardStyles = css `

  :host {
    display: block;
    --border-radius: 1em;
    --standard-background: linear-gradient(221.24deg, #393939 0%, #464646 48.96%, var(--color-background) 100%);
    --silver-background: linear-gradient(221.24deg, #ACACAC 0%, #F3F3F3 48.96%, #ACACAC 100%);
    --gold-background: linear-gradient(221.24deg, #926F34 0%, #FFD46B 48.96%, #926F34 100%);
    font-size: calc(1em * var(--game-card-scale, 1));
    -webkit-user-select: none;
    user-select: none;
  }

  :host > div {
    width: var(--game-card-width, 6.45em);
    height: var(--game-card-height, 6.7em);
    border-radius: var(--border-radius);
    background: var(--standard-background);
  }
  :host > div.standard {
    background: var(--standard-background);
  }
  :host > div.silver {
    background: var(--silver-background);
  }
  :host > div.gold {
    background: var(--gold-background);
  }

`;
export default gameCardStyles;
