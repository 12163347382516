"use strict";
/**
 * Determines the proper size of avaiable vertical viewport space and sets a CSS variable.
 * Mobile browsers add on the browser bar when specifying vh units so there's an unwanted overflow.
 */
setCSSVar();
window.addEventListener("resize", setCSSVar);
function setCSSVar() {
    document.documentElement.style.setProperty("--real100vh", `${window.innerHeight}px`);
}
