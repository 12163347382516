var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconBarSquare = class IconBarSquare extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      ${this.theme === "filled" ? html `
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1.5C9.44772 1.5 9 1.94772 9 2.5V21.5C9 22.0523 9.44772 22.5 10 22.5H14C14.5523 22.5 15 22.0523 15 21.5V2.5C15 1.94772 14.5523 1.5 14 1.5H10Z" />
            <path d="M1 9.5C1 8.94772 1.44772 8.5 2 8.5H6C6.55228 8.5 7 8.94772 7 9.5V21.5C7 22.0523 6.55228 22.5 6 22.5H2C1.44772 22.5 1 22.0523 1 21.5V9.5Z" />
            <path d="M17 11.5C17 10.9477 17.4477 10.5 18 10.5H22C22.5523 10.5 23 10.9477 23 11.5V21.5C23 22.0523 22.5523 22.5 22 22.5H18C17.4477 22.5 17 22.0523 17 21.5V11.5Z" />
          </svg>
        ` : html `
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="outline" d="M2 9.5H6V21.5H2L2 9.5ZM18 11.5H22V21.5H18V11.5ZM10 2.5L14 2.5V21.5H10V2.5Z" />
          </svg>
        `}
      
    `;
    }
};
IconBarSquare.styles = [iconCSS, css `
    path:not(#outline) {
      fill: var(--color);
    }
    path#outline {
      stroke: var(--color);
      stroke-width: 2;
    }
  `];
__decorate([
    property()
], IconBarSquare.prototype, "theme", void 0);
IconBarSquare = __decorate([
    customElement("icon-bars-square")
], IconBarSquare);
export { IconBarSquare };
