var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "../../../../styles/global.css";
import "../../asset/logo-uf/logo-uf";
let UFStamp = class UFStamp extends LitElement {
    render() {
        return html `
      <div>
        <logo-uf></logo-uf>
        <h1>UltimateFan</h1>
      </div>
    `;
    }
};
UFStamp.styles = [globalCSS, css `
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    logo-uf {
      width: 7em;
    }
    h1 {
      margin-top: 1rem;
    }
  `];
UFStamp = __decorate([
    customElement("uf-stamp")
], UFStamp);
export { UFStamp };
