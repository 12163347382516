var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let LongPress = class LongPress extends LitElement {
    constructor() {
        super(...arguments);
        this.enabled = false;
        this.delay = 200;
        this.isTouchEvent = false;
        this.startEvent = (e) => {
            const start = (delay) => {
                this.touchTimeout = window.setTimeout(() => {
                    this.touchTimeout = undefined;
                    this.dispatchEvent(new Event("longpress"));
                }, delay);
            };
            // The isTouchEvent logic may seem redundant, but it is required as in some cases touch devices fire both the touchstart and mousedown events. This prevents the start function being called twice.
            if (e.touches) {
                this.isTouchEvent = true;
                start(this.delay);
            }
            else {
                if (!this.isTouchEvent)
                    start(this.delay);
            }
        };
        this.moveEvent = () => {
            if (this.touchTimeout !== undefined) {
                window.clearTimeout(this.touchTimeout);
                this.touchTimeout = undefined;
            }
        };
        this.endEvent = () => {
            if (this.touchTimeout !== undefined) {
                window.clearTimeout(this.touchTimeout);
                this.touchTimeout = undefined;
            }
        };
    }
    updated() {
        this.enabled ? this.enable() : this.disable();
    }
    enable() {
        ["mousedown", "touchstart"].forEach(type => {
            this.addEventListener(type, this.startEvent);
        });
        ["mousemove", "touchmove"].forEach(type => {
            window.addEventListener(type, this.moveEvent);
        });
        ["mouseup", "touchend"].forEach(type => {
            window.addEventListener(type, this.endEvent);
        });
    }
    disable() {
        ["mousedown", "touchstart"].forEach(type => {
            this.removeEventListener(type, this.startEvent);
        });
        ["mousemove", "touchmove"].forEach(type => {
            window.removeEventListener(type, this.moveEvent);
        });
        ["mouseup", "touchend"].forEach(type => {
            window.removeEventListener(type, this.endEvent);
        });
    }
    render() {
        return html `
      <slot></slot>
    `;
    }
};
LongPress.styles = css `
    :host {
      display: block;
    }
  `;
__decorate([
    property({ type: Boolean })
], LongPress.prototype, "enabled", void 0);
__decorate([
    property({ type: Number })
], LongPress.prototype, "delay", void 0);
LongPress = __decorate([
    customElement("long-press")
], LongPress);
export { LongPress };
