var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c;
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { choose } from "lit/directives/choose.js";
import "./game-lineup.css";
import config from "@/services/config/config";
import { isDesktop, isTablet } from "@/services/device/device";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/game-card/game-card-front/game-card-front";
import "@/views/game/views/squad/components/long-press/long-press";
import "@/views/game/views/squad/components/drag-drop/drop-area";
import "@/views/game/views/squad/components/drag-drop/drag-item";
import pitchImage from "./assets/football-pitch.svg";
import bengalsPitchImage from "./assets/bengals-pitch.svg";
import chargersPitchImage from "./assets/chargers-pitch.svg";
import modifierGoldenBoot from "@/assets/icon_mod_golden_boot.svg";
import modifierCaptain from "@/assets/icon_modifier_captain.svg";
import modifierGoldenFootball from "@/assets/icon_mod_golden_football.svg";
import modifierGoldenHelmet from "@/assets/icon_mod_golden_helmet.svg";
import lineupPositions from "./lineup-positions";
import { cardCanBePlaced, getMatchedLineupCard, modifierCanBePlaced } from "./game-lineup.helpers";
import { SquadItemModifier, SquadItemSet } from "@/services/api/uf/types/uf.api.types.enums";
import { squadPositionLabel } from "@/mappings/squad.mappings";
import { cardViewCurrent } from "~/app/config/card-view.config";
import cardPositionConfig from "../../config/game-area.config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.squad) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
const skinStyles = [
    { name: "card-selected-outline", value: (colour === null || colour === void 0 ? void 0 : colour.selectedCardOutline) || "#35E06A" }
];
const dragEnabled = isDesktop || isTablet;
let GameLineup = class GameLineup extends UnscopedElement {
    constructor() {
        super(skinStyles);
        this.squad = [];
        this.editable = false;
        this.lineupChanged = false;
        this.selectedCard = null;
    }
    get lineup() {
        return this.squad.filter(item => item.set === SquadItemSet.Lineup);
    }
    render() {
        // Create a mapped list of all squad items that match the given lineup positions.
        const matchedLineupItems = lineupPositions().map(position => getMatchedLineupCard(position, this.lineup));
        return html `

      <header>
        <!-- <button class="no-style">
          <uf-icon icon="info"></uf-icon>
          Info
        </button> -->
        ${when(this.lineupChanged, () => html `
            <button class="active" @click=${this.confirmLineup}>
              Confirm
            </button>
          `)}
      </header>

      <div id="pitch" class=${this.selectedCard || this.selectedModifier ? "dragging" : ""}>
        <div>
          ${choose(config.skin.name, [
            ["default", () => unsafeSVG(pitchImage)],
            ["bengals", () => unsafeSVG(bengalsPitchImage)],
            ["chargers", () => unsafeSVG(chargersPitchImage)],
        ])}
          ${lineupPositions().map((lineupPosition, i) => {
            var _a;
            return html `
              <div
                class=${classMap({
                "lineup-card": true,
                droppable: this.selectedCard && cardCanBePlaced(this.selectedCard, lineupPosition.position) || (this.selectedModifier && !!matchedLineupItems[i] && modifierCanBePlaced(lineupPosition.position))
            })}
                id=${lineupPosition.id}
              >
                ${matchedLineupItems[i] ? html `

                    <div class=${classMap({
                "card": true,
                "active": !dragEnabled && ((_a = cardPositionConfig.current) === null || _a === void 0 ? void 0 : _a.cardId) === matchedLineupItems[i].cardId
            })}>
                      <long-press
                        ?enabled=${!dragEnabled && this.editable}
                        @longpress=${() => this.selectGameCard(matchedLineupItems[i])}
                      >
                        <drop-area
                          ?enabled=${dragEnabled && this.editable}
                          @dropover=${() => this.onDropGameCard(matchedLineupItems[i], lineupPosition)}
                        >
                          <drag-item
                            ?enabled=${dragEnabled && this.editable}
                            @drag=${() => this.selectGameCard(matchedLineupItems[i])}
                            @dragcomplete=${this.onDragEnd}
                          >
                            <game-card-front
                              .squadItem=${matchedLineupItems[i]}
                              @click=${(e) => this.onSelectGameCard(matchedLineupItems[i], lineupPosition, e)}
                            ></game-card-front>
                          </drag-item>
                        </drop-area>
                      </long-press>
                    </div>

                    ${!matchedLineupItems[i].modifier ? html `

                        <div class=${classMap({
                "lineup-card-add-label": true,
                visible: this.selectedModifier
            })}>
                          <span>+</span>
                        </div>

                      ` : html `
                        <div class="modifier-label" @click=${() => this.removeModifier(matchedLineupItems[i])}>
                          ${choose(matchedLineupItems[i].modifier, [
                [SquadItemModifier.Captain, () => unsafeSVG(config.isUF ? modifierCaptain : modifierGoldenHelmet)],
                [SquadItemModifier.GoldenBoot, () => unsafeSVG(config.isUF ? modifierGoldenBoot : modifierGoldenFootball)]
            ])}
                        </div>
                      `}

                  ` : html `
                    <div class="placeholder">
                      <drop-area
                        ?enabled=${dragEnabled && this.editable}
                        @dropover=${() => this.onDropPlaceholder(lineupPosition)}
                      >
                        <game-card-front
                          type=${lineupPosition.type}
                          name=${lineupPosition.name}
                          @click=${(e) => this.onDropPlaceholder(lineupPosition, e)}
                        ></game-card-front>
                      </drop-area>
                    </div>
                    <div class="lineup-card-add-label">
                      <span>
                        <!-- no space between '+' and '$' is intentional, leave it like this -->
                        ${when(this.selectedCard, () => html `
                            +${squadPositionLabel(this.selectedCard)}
                          `)}
                      </span>
                    </div>
                  `}
              </div>
            `;
        })}

        <uf-icon icon="${config.ageRatingIcon()}" theme="${config.isBengals ? "white" : "black"}"></uf-icon>

        </div>

      </div>

    `;
    }
    onDropPlaceholder(lineupItem, e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        if (cardPositionConfig.current !== null) {
            if (cardCanBePlaced(cardPositionConfig.current, lineupItem.position)) {
                this.placeCard(cardPositionConfig.current, lineupItem);
            }
            cardPositionConfig.current = null;
        }
    }
    placeCard(card, lineupItem) {
        this.dispatchEvent(new CustomEvent("squadplace", {
            detail: {
                card,
                position: lineupItem.position
            }
        }));
    }
    onSelectGameCard(item, lineupItem, e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        if (cardPositionConfig.current !== null) {
            this.onDropGameCard(item, lineupItem);
        }
        else {
            this.openCardView(item);
        }
    }
    onDropGameCard(targetCard, lineupItem) {
        if (cardPositionConfig.current !== null) {
            const cardToPlace = cardPositionConfig.current;
            if (cardToPlace.cardId !== targetCard.cardId) {
                if (typeof cardPositionConfig.current !== "number") {
                    this.onDropGameCardSqaud(targetCard, lineupItem);
                }
                else {
                    this.onDropGameCardModifier(targetCard, lineupItem);
                }
                cardPositionConfig.current = null;
            }
        }
    }
    onDropGameCardSqaud(cardToReplace, lineupItem) {
        // Check if the card being dragged can be placed.
        if (cardCanBePlaced(cardPositionConfig.current, lineupItem.position)) {
            // If the dragged card is from the bench.
            if (cardPositionConfig.current.set === SquadItemSet.Squad || cardPositionConfig.current.set === SquadItemSet.Collection) {
                this.swapCard(cardPositionConfig.current, cardToReplace, lineupItem);
            }
            // If the dragged card is from within the lineup, perform another check to determine whether the card being placed can be moved too.
            else if (cardPositionConfig.current.set === SquadItemSet.Lineup) {
                if (cardCanBePlaced(cardToReplace, cardPositionConfig.current.playerPosition)) {
                    this.swapCard(cardPositionConfig.current, cardToReplace, lineupItem);
                }
            }
        }
    }
    swapCard(cardToPlace, cardToReplace, lineupItem) {
        this.dispatchEvent(new CustomEvent("squadswap", {
            detail: {
                cardToPlace,
                cardToReplace,
                position: lineupItem.position
            }
        }));
    }
    onDropGameCardModifier(card, lineupItem) {
        if (modifierCanBePlaced(lineupItem.position)) {
            this.dispatchEvent(new CustomEvent("modifierplace", {
                detail: {
                    modifier: cardPositionConfig.current,
                    card
                }
            }));
        }
    }
    openCardView(card) {
        cardViewCurrent.next(card);
    }
    removeModifier(card) {
        if (!this.lineupChanged) {
            this.dispatchEvent(new Event("editstart"));
        }
        this.dispatchEvent(new CustomEvent("modifierremove", {
            detail: card
        }));
    }
    selectGameCard(card) {
        cardPositionConfig.current = card;
        this.requestUpdate();
        this.dispatchEvent(new Event("editstart"));
        this.dispatchEvent(new CustomEvent("cardselect", {
            detail: card
        }));
    }
    onDragEnd() {
        this.dispatchEvent(new Event("squaddragend"));
        // This timeout allows drop events to pick up cardPositionConfig.current before it is cleared.
        setTimeout(() => {
            cardPositionConfig.current = null;
            this.requestUpdate();
        }, 100);
    }
    confirmLineup() {
        this.dispatchEvent(new Event("lineupconfirm"));
    }
};
__decorate([
    property({ type: Array })
], GameLineup.prototype, "squad", void 0);
__decorate([
    property({ type: Boolean })
], GameLineup.prototype, "editable", void 0);
__decorate([
    property({ type: Boolean })
], GameLineup.prototype, "lineupChanged", void 0);
__decorate([
    property({ type: Object })
], GameLineup.prototype, "selectedCard", void 0);
__decorate([
    property({ type: Number })
], GameLineup.prototype, "selectedModifier", void 0);
GameLineup = __decorate([
    customElement("game-lineup")
], GameLineup);
export { GameLineup };
