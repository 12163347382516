var _a, _b, _c;
import { css, unsafeCSS } from "lit";
import config from "@/services/config/config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.raf) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
const referFriendsCardCSS = css `

  :host {
    --background-raf: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRaf) || "#FF284F")};
    --color-raf: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorRafFont) || "#F6F3EE")};
    --color-button-font: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorButtonFont) || "#FF284F")};
    --background-button: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundButton) || "#F6F3EE")};
    --background-reward-card: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRewardCard) || "#7F1E31")}; 
    --color-reward-card-font: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorRewardCardFont) || "#F6F3EE")};
  }

  router-link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  div.card-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.6em;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--background-raf);
    border-radius: 1.5em;
    padding: 1em;
    color: var(--color-raf)
  }

  div.card-header h2{
    text-align: center;
    font-size: 1.75em
  }

  div.top {
    display: flex;
    justify-content: center;
    text-align: center;
    column-gap: 0.6em;
    position: relative;
  }

  div.top > div {
    display: flex;
    align-items: center;
    column-gap: 0.25em;
  }

  div.top > h3 {
    margin: auto 0;
    font-size:1.25em
  }

  .coin-wrapper {
    display: flex;
    align-items: center;
    column-gap: 0.25em;
    padding: 0.3125em 0.4375em;
    border-radius: 0.3125em;
    color: var(--color-reward-card-font);
    background-color: var(--background-reward-card);
  }

  div.top uf-icon {
    width: 1.25em;
  }

  .coin-wrapper + uf-icon{
    width: 1.5em;
    position: absolute;
    right: 0em;
  }

  div.bottom {
    display: flex;
    justify-content: center;
    text-align: center;
    column-gap: 0.625em;
  }
  
  uf-icon[icon=copy] {
    width: 1.25em;
    margin-left: 0.6em;
  }

  uf-icon[icon=tick] {
    width: 1.25em;
    margin-left: 0.6em;
  }

  div.bottom button.white{
    background-color: var(--background-button);
    border-color: var(--background-button);
    color: var(--color-button-font);
    padding: 0.5em 0.5em 0.6em;
  }

  div.bottom button.green {
      background-color: var(--color-active);
      border-color: var(--color-active);
      color: #262626;
      padding: 0.5em 0.5em 0.6em;
  }

  @media (max-width: 600px) {
    div.card-header {
      row-gap: 0.3em;
      padding: 0.7em;
      border-radius: 1em;
    }

    div.top > h3 {
      font-size:1em;
    }

    div.bottom button{
      font-size: 0.8em;
    }
  }


`;
export default referFriendsCardCSS;
