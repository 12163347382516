var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
const componentStyles = [
    css `
    p {
      font-size: 0.875rem;
      margin: 0 0 0.5rem;
    }

    p ::slotted(a) {
      text-decoration: underline;
    }

    p.is-danger {
      color: var(--color-primary);
    }

    p.is-lighter-text {
      color: rgba(255, 255, 255, 0.57);
    }
  `,
];
let FormP = class FormP extends LitElement {
    constructor() {
        super(...arguments);
        this.isDanger = false;
        this.isLighterText = false;
    }
    render() {
        const { isDanger, isLighterText } = this;
        return html `
      <p
        class=${classMap({
            "is-danger": isDanger,
            "is-lighter-text": isLighterText,
        })}
      >
        <slot></slot>
      </p>
    `;
    }
};
FormP.styles = [componentStyles];
__decorate([
    state()
], FormP.prototype, "isDanger", void 0);
__decorate([
    state()
], FormP.prototype, "isLighterText", void 0);
FormP = __decorate([
    customElement("form-p")
], FormP);
export { FormP };
