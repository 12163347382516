import { BehaviorSubject } from "rxjs";
import { BaseAPI } from "../base.api";
const key = "AIzaSyDA-6B0tNyDUYeiM4ibyZyut1M-lWEkIoU";
class LocationAPI extends BaseAPI {
    constructor() {
        super(...arguments);
        this.locationAuthorizationState = new BehaviorSubject("pending");
    }
    async getLocation() {
        this.tempUrl = `https://www.googleapis.com/geolocation/v1/geolocate?key=${key}`;
        return await this.post();
    }
    async reverseGeocode(locationResponse) {
        this.tempUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${locationResponse.location.lat},${locationResponse.location.lng}&key=${key}`;
        return await this.post();
    }
}
export default new LocationAPI();
