export class AnalyticsKeys {
}
AnalyticsKeys.APP_OPENED = "AppOpened";
AnalyticsKeys.APP_CLOSE = "AppClose";
AnalyticsKeys.FORGOT_PASSWORD_SEND = "ForgotPassword.Send";
AnalyticsKeys.REGISTRATION_CONTACT_DETAILS_PAGE = "Registration.ContactDetailsPage";
AnalyticsKeys.REGISTRATION_SECURITY_PAGE = "Registration.SecurityPage";
AnalyticsKeys.REGISTRATION_PERSONAL_DETAILS_PAGE = "Registration.PersonalDetailsPage";
AnalyticsKeys.REGISTRATION_CONTACT_PERMISSIONS_PAGE = "Registration.ContactPermissionsPage";
AnalyticsKeys.COMPLETE_REGISTRATION = "COMPLETE_REGISTRATION";
AnalyticsKeys.LOGIN_PAGE = "LoginPage";
AnalyticsKeys.LOGIN = "LOGIN";
AnalyticsKeys.LOBBY_PAGE = "LobbyPage";
AnalyticsKeys.WALLET_PAGE = "WalletPage";
AnalyticsKeys.WALLET_COINS_PAGE = "Wallet.CoinsPage";
AnalyticsKeys.WALLET_ADD_COINS_PAGE = "Wallet.AddCoinsPage";
AnalyticsKeys.WALLET_ADD_COINS_CLICK = (amount) => `Wallet.AddCoinsPage.Click.Coins${amount}`;
AnalyticsKeys.WALLET_CASH_PAGE = "Wallet.CashPage";
AnalyticsKeys.WALLET_CASH_WITHDRAW_PAGE = "Wallet.Cash.WithdrawPage";
AnalyticsKeys.WALLET_CASH_WITHDRAW_CLICK = "Wallet.Cash.Withdraw.Click";
AnalyticsKeys.WALLET_CARD_DETAILS_PAGE = "Wallet.CardDetailsPage";
AnalyticsKeys.WALLET_TRANSACTIONS_PAGE = "Wallet.TransactionsPage";
AnalyticsKeys.LEADERBOARDS_PAGE = "LeaderboardsPage";
AnalyticsKeys.LEADERBOARDS_STAGE_PAGE = (stageName) => `Leaderboard.${stageName}Page`;
AnalyticsKeys.LEADERBOARDS_WINNINGS_PAGE = (stageName) => `Leaderboard.${stageName}.WinningsPage`;
AnalyticsKeys.LEADERBOARDS_SCORE_BREAKDOWN_PAGE = (stageName) => `Leaderboard.${stageName}.FinishedScoreBreakdownPage`;
AnalyticsKeys.LINEUP_PAGE = "LineupPage";
AnalyticsKeys.LINEUP_SAVE = "Lineup.Save";
AnalyticsKeys.LINEUP_BROWSE = "Lineup.SearchPage";
AnalyticsKeys.MODIFIER_PREVIEW_PAGE = "ModifierPreviewPage";
AnalyticsKeys.MARKETPLACE_PACKS_PAGE = "Marketplace.PacksPage";
AnalyticsKeys.MARKETPLACE_PACKS_DETAILS_PAGE = "Marketplace.Packs.PackDetailsPage";
AnalyticsKeys.MARKETPLACE_COINS_PAGE = "Marketplace.CoinsPage";
AnalyticsKeys.MARKETPLACE_COINS_CLICK = (amount) => `Marketplace.CoinsPage.Click.Coins${amount}`;
AnalyticsKeys.MARKETPLACE_CARDS_PAGE = "Marketplace.CardsPage";
AnalyticsKeys.MARKETPLACE_CARDS_FILTER_PAGE = "Marketplace.FilterPage";
AnalyticsKeys.MARKETPLACE_CARD_PREVIEW_PAGE = "Marketplace.CardPreviewPage";
AnalyticsKeys.MARKETPLACE_SELL_PAGE = "Marketplace.SellPage";
AnalyticsKeys.MARKETPLACE_SELL_UNLIST_CARD_CLICK = "Marketplace.Sell.UnlistCard.Click";
AnalyticsKeys.MARKETPLACE_SELL_CARD_SELECTION_PAGE = "Marketplace.Sell.CardSelectionPage";
AnalyticsKeys.MARKETPLACE_SELL_CARD_SUMMARY_PAGE = "Marketplace.Sell.CardSummaryPage";
AnalyticsKeys.MARKETPLACE_SELL_CARD_DETAILS_PAGE = "Marketplace.Sell.CardDetailsPage";
AnalyticsKeys.MARKETPLACE_SELL_CARD_SUBMITTED_PAGE = "Marketplace.Sell.CardSubmittedPage";
AnalyticsKeys.MARKETPLACE_PURCHASE_CARD_PAGE = "Marketplace.PurchaseCardPage";
AnalyticsKeys.MARKETPLACE_PURCHASE_CARD_CLICK = "Marketplace.PurchaseCard.Click";
AnalyticsKeys.MARKETPLACE_PURCHASE_CARD_COMPLETE_PAGE = "Marketplace.PurchaseCardCompletePage";
AnalyticsKeys.MARKETPLACE_PURCHASE_PACK_PAGE = "Marketplace.PurchasePackPage";
AnalyticsKeys.MARKETPLACE_PURCHASE_PACK_CLICK = "Marketplace.PurchasePack.Click";
AnalyticsKeys.MARKETPLACE_ONBOARDING_PAGE = "Marketplace.Onboarding";
AnalyticsKeys.PACK_PAGE = "PackPage";
AnalyticsKeys.PACK_OPENED = "PackOpened";
AnalyticsKeys.REFER_FRIENDS_REFER = "ReferFriends.Refer";
AnalyticsKeys.SCORING_INFO_SCORE_BREAKDOWN_PAGE = "ScoringInfo.ScoreBreakdownPage";
AnalyticsKeys.SCORING_INFO_STAGE_DETAILS_PAGE = (stageName) => `ScoringInfo.${stageName}.DetailsPage`;
AnalyticsKeys.USER_LEAGUES_SHARE = "InvitationalLeagues.Share";
