import { SquadItemLinupPosition, SquadItemPosition, SquadItemType } from "@/services/api/uf/types/uf.api.types.enums";
import config from "@/services/config/config";
/**
 * Searches through a lineup to match an item against lineup positions on the pitch.
 * @param lineupItem A lineup item position where a card can be placed.
 * @param lineup The squad lineup to search through.
 * @returns If found, a sqaud item.
 */
export function getMatchedLineupCard(lineupItem, lineup) {
    return lineup.find(item => item.playerPosition === lineupItem.position);
}
/**
 * Determines whether a card can be placed into a lineup position.
 * @param card Squad item.
 * @param lineupPosition Enum for the lineup position to move the card to.
 * @returns A boolean to indicate that the card can be placed.
 */
export function cardCanBePlaced(card, lineupPosition) {
    if (config.isUF) {
        if (card) {
            if (lineupPosition === SquadItemLinupPosition.Team) {
                if (card.scoreableType === SquadItemType.Team) {
                    return true;
                }
            }
            else {
                if (card.scoreableType === SquadItemType.Player) {
                    if (card.position === SquadItemPosition.Any) {
                        return true;
                    }
                    if (lineupPosition === SquadItemLinupPosition.Wild) {
                        return true;
                    }
                    if (lineupPosition === SquadItemLinupPosition.DefenderLeft || lineupPosition === SquadItemLinupPosition.DefenderRight) {
                        if (card.position === SquadItemPosition.Defender) {
                            return true;
                        }
                    }
                    if (lineupPosition === SquadItemLinupPosition.MidfieldLeft || lineupPosition === SquadItemLinupPosition.MidfieldRight) {
                        if (card.position === SquadItemPosition.Midfielder) {
                            return true;
                        }
                    }
                    if (lineupPosition === SquadItemLinupPosition.Forward) {
                        if (card.position === SquadItemPosition.Forward) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    else {
        if (card) {
            if (lineupPosition === SquadItemLinupPosition.Team) {
                if (card.scoreableType === SquadItemType.Team) {
                    return true;
                }
            }
            else {
                if (card.scoreableType === SquadItemType.Player) {
                    if (card.position === SquadItemPosition.Any) {
                        return true;
                    }
                    if (lineupPosition === SquadItemLinupPosition.Wild) {
                        if (card.position !== SquadItemPosition.Goalkeeper) { // Running Back, Tight End or Wide Receiver
                            return true;
                        }
                    }
                    if (lineupPosition === SquadItemLinupPosition.DefenderLeft) {
                        if (card.position === SquadItemPosition.Defender) { // Running Back
                            return true;
                        }
                    }
                    if (lineupPosition === SquadItemLinupPosition.DefenderRight) {
                        if (card.position === SquadItemPosition.Midfielder) { // Tight End
                            return true;
                        }
                    }
                    if (lineupPosition === SquadItemLinupPosition.MidfieldLeft || lineupPosition === SquadItemLinupPosition.MidfieldRight) {
                        if (card.position === SquadItemPosition.Forward) { // Wide Receiver
                            return true;
                        }
                    }
                    if (lineupPosition === SquadItemLinupPosition.Forward) {
                        if (card.position === SquadItemPosition.Goalkeeper) { // Quarterback
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
}
/**
 * Determines whether a modifier can be placed into a lineup position.
 * @param lineupPosition Enum for the lineup position to move the card to.
 * @returns A boolean to indicate that the modifier can be placed.
 */
export function modifierCanBePlaced(lineupPosition) {
    return lineupPosition !== SquadItemLinupPosition.Team;
}
