export const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    mobileNumber: "",
    postcode: "",
    line1: "",
    line2: "",
    county: "",
    city: "",
};
export const initialErrors = {
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    mobileNumber: "",
    postcode: "",
    line1: "",
    line2: "",
    county: "",
    city: "",
};
