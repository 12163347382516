var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconMenu = class IconMenu extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="20" height="3" rx="1.5" />
        <rect x="0.5" y="7.5" width="20" height="3" rx="1.5" />
        <rect x="0.5" y="14.5" width="20" height="3" rx="1.5" />
      </svg>
    `;
    }
};
IconMenu.styles = [iconCSS, css `
    rect {
      fill: var(--color);
    }
  `];
IconMenu = __decorate([
    customElement("icon-menu")
], IconMenu);
export { IconMenu };
