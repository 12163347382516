var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import "./points-breakdown.view.css";
import router from "~/app/router/router";
import ufApi from "~/app/services/api/uf/uf.api";
import { FootballUSSeasonStatsIndex, SquadItemModifier, SquadItemPosition, SquadItemType } from "~/app/services/api/uf/types/uf.api.types.enums";
import { pluralise } from "~/app/directives/app.directives";
import { findCurrentStage } from "~/app/services/api/uf/uf.helpers";
import { deepEqual } from "~/app/services/utils/utils";
import "@/components/header-bar/header-bar";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/game-card/game-card-front-wide/game-card-front-wide";
import config from "~/app/services/config/config";
import modifierGoldenBoot from "@/assets/icon_mod_golden_boot.svg";
import modifierCaptain from "@/assets/icon_modifier_captain.svg";
import modifierGoldenFootball from "@/assets/icon_mod_golden_football.svg";
import modifierGoldenHelmet from "@/assets/icon_mod_golden_helmet.svg";
let PointsBreakdownView = class PointsBreakdownView extends UnscopedElement {
    connectedCallback() {
        var _a, _b, _c;
        super.connectedCallback();
        const dynamicRoutes = router.dynamicRoutes.getValue();
        const userId = (_a = dynamicRoutes.find(route => route.identifier === "userId")) === null || _a === void 0 ? void 0 : _a.value;
        const cardId = (_b = dynamicRoutes.find(route => route.identifier === "cardId")) === null || _b === void 0 ? void 0 : _b.value;
        const stageId = (_c = dynamicRoutes.find(route => route.identifier === "stageId")) === null || _c === void 0 ? void 0 : _c.value;
        this.handleStage(stageId);
        ufApi.getStages().catch(error => () => this.handleErrors(error));
        if (userId !== undefined && stageId !== undefined && cardId !== undefined) {
            try {
                this.getSquadItem(+userId, +stageId, +cardId);
            }
            catch (error) {
                this.handleErrors(error);
            }
        }
    }
    handleStage(id) {
        this.stageSubscription = ufApi.stages.subscribe(stages => {
            var _a;
            const currentStage = (_a = findCurrentStage(stages)) === null || _a === void 0 ? void 0 : _a.stage;
            const stage = id !== undefined ? stages.find(stage => stage.id === +id) : currentStage;
            if (stage && !deepEqual(this.stage, stage)) {
                this.stage = stage;
            }
        });
    }
    async getSquadItem(userId, stageId, cardId) {
        const lineup = await ufApi.getUserLineup(userId, stageId);
        this.squadItem = lineup.find(item => item.cardId === cardId);
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        this.clearState();
        (_a = this.stageSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.stageSubscription = undefined;
    }
    clearState() {
        this.squadItem = undefined;
    }
    render() {
        var _a;
        return html `

      <header-bar>
        <div slot="left">
          <uf-icon
            icon='chevron'
            class="clickable"
            @click=${router.back}
          ></uf-icon>
        </div>
        <div slot="center">
          <h1>${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.name}</h1>
        </div>
      </header-bar>

      ${when(this.squadItem, () => html `
          <header>
            <game-card-front-wide .squadItem=${this.squadItem}></game-card-front-wide>
          </header>
        `)}

      <section>

        ${choose(config.skin.league, [
            ["pl", () => {
                    var _a;
                    return choose((_a = this.squadItem) === null || _a === void 0 ? void 0 : _a.scoreableType, [
                        [SquadItemType.Team, () => this.renderPLTeamPoints()],
                        [SquadItemType.Player, () => this.renderPLPlayerPoints()]
                    ]);
                }],
            ["nfl", () => {
                    var _a;
                    return choose((_a = this.squadItem) === null || _a === void 0 ? void 0 : _a.scoreableType, [
                        [SquadItemType.Team, () => this.renderNFLTeamPoints()],
                        [SquadItemType.Player, () => this.renderNFLPlayerPoints()]
                    ]);
                }]
        ])}

      </section>

      ${when(this.squadItem && this.squadItem.modifier !== SquadItemModifier.None, () => html `
          <section id="modifier">
            <h2>
              <div id="modifier-label">
                ${choose(this.squadItem.modifier, [
            [SquadItemModifier.Captain, () => unsafeSVG(config.isUF ? modifierCaptain : modifierGoldenHelmet)],
            [SquadItemModifier.GoldenBoot, () => unsafeSVG(config.isUF ? modifierGoldenBoot : modifierGoldenFootball)]
        ])}
              </div>
              <span>
                ${choose(this.squadItem.modifier, [
            [SquadItemModifier.Captain, () => config.isUF ? "Captain" : "Golden Helmet"],
            [SquadItemModifier.GoldenBoot, () => config.isUF ? "Golden Boot" : "Golden Football"]
        ])}
                Boost
              </span>
            </h2>

            ${choose(config.skin.league, [
            ["pl", () => {
                    var _a;
                    return choose((_a = this.squadItem) === null || _a === void 0 ? void 0 : _a.scoreableType, [
                        [SquadItemType.Team, () => this.renderPLTeamPoints()],
                        [SquadItemType.Player, () => this.renderPLPlayerPoints()]
                    ]);
                }],
            ["nfl", () => {
                    var _a;
                    return choose((_a = this.squadItem) === null || _a === void 0 ? void 0 : _a.scoreableType, [
                        [SquadItemType.Team, () => this.renderNFLTeamPoints()],
                        [SquadItemType.Player, () => this.renderNFLPlayerPoints()]
                    ]);
                }]
        ])}
          </section>
        `)}

    `;
    }
    renderPLTeamPoints() {
        return html `
      <!-- <div class="teams">
        <div class="heading">
          <p>Teams</p>
          <p>Points</p>
        </div>

        <hr/>

        <div>
          <div class="row"> 
            <p>Win</p>
            <p>5</p>
          </div>
          <div class="row"> 
            <p>Goal Scored</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>Goal Against</p>
            <p class="negative">-1</p>
          </div>
          <div class="row"> 
            <p>Clean Sheet</p>
            <p>3</p>
          </div>
          <div class="row"> 
            <p>Save</p>
            <p>1</p>
          </div>
        </div>
      </div> -->
    `;
    }
    renderPLPlayerPoints() {
        return html `
      <!-- <div class="players">
        <div class="heading">
          <p>Players</p>
          <p>Points</p>
        </div>

        <hr/>

        <div>
          <div class="row"> 
            <p>Goal</p>
            <p>7</p>
          </div>
          <div class="row"> 
            <p>Assist</p>
            <p>4</p>
          </div>
          <div class="row"> 
            <p>Shot on Target</p>
            <p>2</p>
          </div>
          <div class="row"> 
            <p>Tackle Won</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>Clean Sheet (DEF Only)</p>
            <p>3</p>
          </div>
        </div>
      </div> -->
    `;
    }
    renderNFLTeamPoints() {
        const stats = this.squadItem.seasonStats;
        const index = FootballUSSeasonStatsIndex;
        return html `
      <h3>GAME</h3>
      ${this.renderRow(stats[index.Win], "Win", 5)}
      ${this.renderRow(stats[index.Loss], "Loss", -1)}
      
      <h3>FIELD GOAL</h3>
      ${this.renderRow(stats[index.FieldGoal49], "40-49 Yard", 1)}
      ${this.renderRow(stats[index.FieldGoalOver50], "50+ Yard", 2)}
      
      <h3>DEFENCE/SPECIAL TEAMS</h3>
      ${this.renderRow(stats[index.Sacks], "Sack", 1)}
      ${this.renderRow(stats[index.Interceptions], "Interception", 2)}
      ${this.renderRow(stats[index.DefenceReturnTouchDown], "Return Touchdown", 6)}
      
      <h3>POINTS ALLOWED</h3>
      ${this.renderRow(stats[index.PointsAllowedZero], "0 Point", 8)}
      ${this.renderRow(stats[index.PointsAllowedThirteen], "1-13 Point", 2)}
      ${this.renderRow(stats[index.PointsAllowedTwentyEight], "14-28 Point", 0)}
      ${this.renderRow(stats[index.PointsAllowedOverTwentyNine], "29+ Point", -3)}
    `;
    }
    renderNFLPlayerPoints() {
        var _a;
        return html `
    ${choose((_a = this.squadItem) === null || _a === void 0 ? void 0 : _a.position, [
            [SquadItemPosition.Defender, () => html `
          ${this.renderNFLRushing()}
          ${this.renderNFLReceiving()}
          ${this.renderNFLOther()}
        `],
            [SquadItemPosition.Midfielder, () => html `
          ${this.renderNFLReceiving()}
          ${this.renderNFLRushing()}
          ${this.renderNFLOther()}
        `],
            [SquadItemPosition.Forward, () => html `
          ${this.renderNFLReceiving()}
          ${this.renderNFLRushing()}
          ${this.renderNFLOther()}
        `],
            [SquadItemPosition.Goalkeeper, () => html `
          ${this.renderNFLPassing()}
          ${this.renderNFLRushing()}
          ${this.renderNFLOther(true)}
        `]
        ])}
    `;
    }
    renderNFLRushing() {
        const stats = this.squadItem.seasonStats;
        const index = FootballUSSeasonStatsIndex;
        return html `
      <h3>Rushing</h3>
      ${this.renderRow(stats[index.RushingTouchDown], "Touchdown", 6)}
      ${this.renderRow(stats[index.Rushing40], "40+ Yard", 1)}
      ${this.renderRow(stats[index.Rushing70], "70+ Yard", 1)}
      ${this.renderRow(stats[index.Rushing100], "100+ Yard", 3)}
    `;
    }
    renderNFLPassing() {
        const stats = this.squadItem.seasonStats;
        const index = FootballUSSeasonStatsIndex;
        return html `
      <h3>Passing</h3>
      ${this.renderRow(stats[index.PassingTouchDown], "Touchdown", 4)}
      ${this.renderRow(stats[index.Passing200], "200+ Yard", 1)}
      ${this.renderRow(stats[index.Passing300], "300+ Yard", 2)}
      ${this.renderRow(stats[index.Passing350], "350+ Yard", 3)}
    `;
    }
    renderNFLReceiving() {
        const stats = this.squadItem.seasonStats;
        const index = FootballUSSeasonStatsIndex;
        return html `
      <h3>Receiving</h3>
      ${this.renderRow(stats[index.ReceivingTouchDown], "Touchdown", 6)}
      ${this.renderRow(stats[index.Receiving50], "50+ Yard", 1)}
      ${this.renderRow(stats[index.Receiving100], "100+ Yard", 1)}
      ${this.renderRow(stats[index.Receptions], "Reception", 1)}
    `;
    }
    renderNFLOther(goalKeeper = false) {
        const stats = this.squadItem.seasonStats;
        const index = FootballUSSeasonStatsIndex;
        return html `
      <h3>Other</h3>
      ${when(goalKeeper, () => this.renderRow(stats[index.Interceptions], "Interception", -1))}
      ${this.renderRow(stats[index.Fumbles], "Fumble", -1)}
    `;
    }
    renderRow(amount, stat, points) {
        const totalPoints = amount * points;
        return html `
      <div class="row">
        <p>${amount} &nbsp;${pluralise(stat, amount)}</p>
        <p class=${classMap({
            "neutral": totalPoints === 0,
            "negative": totalPoints < 0
        })}>
          ${totalPoints} ${pluralise("pt", totalPoints)}
        </p>
      </div> 
    `;
    }
};
__decorate([
    state()
], PointsBreakdownView.prototype, "stage", void 0);
__decorate([
    state()
], PointsBreakdownView.prototype, "squadItem", void 0);
PointsBreakdownView = __decorate([
    customElement("points-breakdown-view")
], PointsBreakdownView);
export { PointsBreakdownView };
