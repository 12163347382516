var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import ConfigurationService from "@/services/config/config";
import globalCSS from "~/styles/global.css";
import low6Api from "@/services/api/low6/low6.api";
import { formatAsCurrency, prizeFromPrizeBreakdown } from "@/views/game/directives/game.directives";
let LeaderboardPositionEntry = class LeaderboardPositionEntry extends LitElement {
    constructor() {
        super(...arguments);
        this.prizeBreakdown = "";
        this.isRankTied = false;
        this.image = '';
    }
    connectedCallback() {
        super.connectedCallback();
        this.getImage();
    }
    render() {
        return html `
      <label 
        @click=${this.selected}
        for=${this.entry.userId}
        name="leaderboard"
        class=${this.selectedId === this.entry.userId ? 'selected' : ''}
      >
        <input type="radio" id=${this.entry.userId} .value=${JSON.stringify(this.entry)}>
        <p>${this.entry.rank}</p>
        ${this.image ? html `<img src=${this.image}>` : html `<p></p>`}
        <p>${this.entry.name}</p>
        ${ConfigurationService.showMonetryPrizes()
            ?
                html `
            <p>${when(this.entry.draftWinnings > 0, () => html `${formatAsCurrency(this.entry.draftWinnings)}`)}</p>
            <p>${this.entry.score}</p>
          `
            :
                html `
            <p>${when(this.entry.score > 0, () => html `${prizeFromPrizeBreakdown(this.prizeBreakdown, this.entry.rank, this.isRankTied)}`)}</p>
            <p>${this.entry.score}</p>
          `}
      </label>
    `;
    }
    async selected() {
        await this.updateComplete;
        this.dispatchEvent(new CustomEvent('clickedElement', { detail: this.entry.userId, bubbles: true, composed: true }));
    }
    async getImage() {
        if (this.selectedId == this.entry.userId) {
            // The current logged in user cannot see themselves via `getProfileImage`, so instead we get their avatar from their profile
            const response = await low6Api.getProfile();
            this.image = response.image;
        }
        else {
            const response = await low6Api.getProfileImage(this.entry.name);
            if (response.length > 0) {
                this.image = response[response.length - 1].image;
            }
        }
    }
};
LeaderboardPositionEntry.styles = [globalCSS, css `

    input[type=radio] {
      transform: scale(0);
      position: absolute;
    }
    
    label {
      display: grid;
      background-color: var(--color-leaderboard-position-entry);
      grid-template-columns: 1fr 1fr 3fr 3fr 1.5fr;
      border-radius: 0.93em;
      align-items: center;
      justify-content: start;
      padding: 0.5em 1.2em;
      height: 3.5em;
      max-height: 3.5em;
      cursor: pointer;
      margin-bottom: 0.5em;
    }

    label:hover {
      background-color: var(--color-leaderboard-position-entry-selected);
    }

    label.selected {
      background-color: var(--color-leaderboard-position-entry-selected);
    }

    p {
      margin: 0.5em;
      font-size: 0.875em;
    }

    label p {
      color: var(--color-leaderboard-position-entry-text)
    }

    label:hover p {
      color: var(--color-leaderboard-position-entry-text-selected)
    }

    label.selected p {
      color: var(--color-leaderboard-position-entry-text-selected)
    }

    p:nth-child(5) {
      color: var(--color-active);
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: block;
      line-height: 1em; /* a */
      max-height: 2em; /* a x number of line to show (ex : 2 line)  */
    }

    p:last-child {
      justify-self: end;
    }

    img {
      width: 2.4em;
      height: 2.4em;
      border-radius: 100%;
    }

  `];
__decorate([
    state()
], LeaderboardPositionEntry.prototype, "entry", void 0);
__decorate([
    property({ type: String })
], LeaderboardPositionEntry.prototype, "prizeBreakdown", void 0);
__decorate([
    property({ type: Number })
], LeaderboardPositionEntry.prototype, "selectedId", void 0);
__decorate([
    property({ type: Boolean })
], LeaderboardPositionEntry.prototype, "isRankTied", void 0);
__decorate([
    state()
], LeaderboardPositionEntry.prototype, "image", void 0);
LeaderboardPositionEntry = __decorate([
    customElement("leaderboard-position-entry")
], LeaderboardPositionEntry);
export { LeaderboardPositionEntry };
