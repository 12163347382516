export const usaStates = [
    "alaska",
    "alabama",
    "arkansas",
    "american samoa",
    "arizona",
    "california",
    "colorado",
    "connecticut",
    "district of columbia",
    "delaware",
    "florida",
    "georgia",
    "guam",
    "hawaii",
    "iowa",
    "idaho",
    "illinois",
    "indiana",
    "kansas",
    "kentucky",
    "louisiana",
    "massachusetts",
    "maryland",
    "maine",
    "michigan",
    "minnesota",
    "missouri",
    "mississippi",
    "montana",
    "north carolina",
    "north dakota",
    "nebraska",
    "new hampshire",
    "new jersey",
    "new mexico",
    "nevada",
    "new york",
    "ohio",
    "oklahoma",
    "oregon",
    "pennsylvania",
    "puerto rico",
    "rhode island",
    "south carolina",
    "south dakota",
    "tennessee",
    "texas",
    "utah",
    "virginia",
    "virgin islands",
    "vermont",
    "washington",
    "wisconsin",
    "west virginia",
    "wyoming"
];
export const canadaStates = [
    "alberta",
    "british columbia",
    "manitoba",
    "new brunswick",
    "newfoundland and labrador",
    "northwest territories",
    "nova scotia",
    "nunavut",
    "ontario",
    "prince edward island",
    "quebec",
    "saskatchewan",
    "yukon"
];
export const countryList = [
    "usa",
    "canada"
];
