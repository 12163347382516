var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconHome = class IconHome extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      ${this.theme === "filled" ? html `
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3553 1.72122C11.5396 1.57784 11.7665 1.5 12 1.5C12.2335 1.5 12.4604 1.57784 12.6447 1.72122L21.2841 8.44099C21.663 8.73571 21.9695 9.11312 22.1802 9.54439C22.391 9.97567 22.5003 10.4494 22.5 10.9294V20.4001C22.5 20.957 22.2787 21.4911 21.8849 21.8849C21.4911 22.2788 20.957 22.5 20.4 22.5H16.2C15.643 22.5 15.1089 22.2788 14.7151 21.8849C14.3212 21.4911 14.1 20.957 14.1 20.4001V14.1003H9.9V20.4001C9.9 20.957 9.67875 21.4911 9.28492 21.8849C8.8911 22.2788 8.35695 22.5 7.8 22.5H3.6C3.04305 22.5 2.5089 22.2788 2.11508 21.8849C1.72125 21.4911 1.5 20.957 1.5 20.4001V10.9294C1.5 9.95504 1.9494 9.03947 2.718 8.44099L11.3553 1.72122Z" />
          </svg>
          ` : html `
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3553 1.72122C11.5396 1.57784 11.7665 1.5 12 1.5C12.2335 1.5 12.4604 1.57784 12.6447 1.72122L21.2841 8.44099C21.663 8.73571 21.9695 9.11312 22.1802 9.54439C22.391 9.97567 22.5003 10.4494 22.5 10.9294V20.4001C22.5 20.957 22.2787 21.4911 21.8849 21.8849C21.4911 22.2788 20.957 22.5 20.4 22.5H16.2C15.643 22.5 15.1089 22.2788 14.7151 21.8849C14.3212 21.4911 14.1 20.957 14.1 20.4001V14.1003H9.9V20.4001C9.9 20.957 9.67875 21.4911 9.28492 21.8849C8.8911 22.2788 8.35695 22.5 7.8 22.5H3.6C3.04305 22.5 2.5089 22.2788 2.11508 21.8849C1.72125 21.4911 1.5 20.957 1.5 20.4001V10.9294C1.5 9.95504 1.9494 9.03947 2.718 8.44099L11.3553 1.72122V1.72122ZM12 3.87995L4.0053 10.0978C3.87873 10.1963 3.77638 10.3224 3.70613 10.4666C3.63587 10.6107 3.59957 10.769 3.6 10.9294V20.4001H7.8V14.1003C7.8 13.5434 8.02125 13.0092 8.41508 12.6154C8.8089 12.2216 9.34304 12.0004 9.9 12.0004H14.1C14.657 12.0004 15.1911 12.2216 15.5849 12.6154C15.9787 13.0092 16.2 13.5434 16.2 14.1003V20.4001H20.4V10.9294C20.4004 10.769 20.3641 10.6107 20.2939 10.4666C20.2236 10.3224 20.1213 10.1963 19.9947 10.0978L12 3.88205V3.87995Z" />
          </svg>
          `}
    `;
    }
};
IconHome.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }
  `];
__decorate([
    property()
], IconHome.prototype, "theme", void 0);
IconHome = __decorate([
    customElement("icon-home")
], IconHome);
export { IconHome };
