import { css } from "lit";
const signupCSS = css `
  #progress {
    width: 100%;
    background-color: var(--color-background);
    border-radius: 0.625em;
    height: 0.75em;
    overflow: hidden;
    margin-bottom: 1em;
  }
  #progress > div {
    height: 100%;
    background-color: var(--color-primary);
  }
  
  #loading {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  loading-spinner {
    width: 3em;
  }

  uf-icon[icon=eighteen] {
    width: 2.875em;
    margin: 0 auto;
  }

  uf-icon[icon=twentyOne] {
    width: 2.875em;
    margin: 0 auto;
  }
`;
export default signupCSS;
