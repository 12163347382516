var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state, query, property } from "lit/decorators.js";
import { guard } from "lit/directives/guard.js";
import { when } from "lit/directives/when.js";
import "./game-area.css";
import "~/app/components/game-area/parts/game-lineup/game-lineup";
import "~/app/components/squad-collection/squad-collection";
import { SquadItemModifier, SquadItemSet } from "~/app/services/api/uf/types/uf.api.types.enums";
import ufApi from "~/app/services/api/uf/uf.api";
import { deepEqual } from "~/app/services/utils/utils";
import { isDesktop, isTablet } from "@/services/device/device";
import cardPositionConfig from "./config/game-area.config";
import { lineupSearchTerm } from "~/app/config/collection.config";
import { removedCard } from "~/app/config/lineup.config";
let GameArea = class GameArea extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.editable = false;
        this.showBrowseButton = false;
        this.hideActionButtons = false;
        this.hideMods = false;
        this.squad = [];
        this.modifiableSquad = [];
        this.selectedCard = null;
        this.selectedModifier = null;
        this.editingLineup = false;
        this.loading = false;
        this.collectionSearchTerm = "";
        this.deselectCards = () => {
            var _a;
            this.deselectCard();
            this.deselectModifier();
            cardPositionConfig.current = null;
            (_a = this.squadCollection) === null || _a === void 0 ? void 0 : _a.requestUpdate();
        };
    }
    connectedCallback() {
        super.connectedCallback();
        if (!isDesktop && !isTablet) {
            this.addEventListener("click", this.deselectCards);
        }
        this.handleSquad();
        ufApi.getSquad().catch(this.handleError);
        this.collectionSearchSubscription = lineupSearchTerm.subscribe(term => this.collectionSearchTerm = term);
        this.removedCardSubscription = removedCard.subscribe(card => {
            if (card) {
                this.removeCardFromLineup(card);
                this.saveLineup();
            }
        });
    }
    handleSquad() {
        this.squadSubscription = ufApi.squad.subscribe(squad => {
            if (!deepEqual(this.squad, squad)) {
                this.squad = squad;
                this.modifiableSquad = [...squad].map(item => {
                    if (item.set !== SquadItemSet.Lineup && item.modifier) {
                        item.modifier = SquadItemModifier.None;
                    }
                    return item;
                });
            }
        });
    }
    handleError(e) {
        console.log(e);
    }
    disconnectedCallback() {
        var _a, _b, _c;
        super.disconnectedCallback();
        this.removeEventListener("click", this.deselectCards);
        (_a = this.squadSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.squadSubscription = undefined;
        lineupSearchTerm.next("");
        (_b = this.collectionSearchSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        this.collectionSearchSubscription = undefined;
        removedCard.next(null);
        (_c = this.removedCardSubscription) === null || _c === void 0 ? void 0 : _c.unsubscribe();
        this.removedCardSubscription = undefined;
        this.cancelEdit();
    }
    render() {
        return html `

      ${guard([this.modifiableSquad, this.selectedCard, this.selectedModifier, this.editingLineup, this.editable], () => html `
          <game-lineup
            .squad=${this.modifiableSquad}
            ?editable=${this.editable}
            .lineupChanged=${this.editingLineup}
            .selectedCard=${this.selectedCard}
            .selectedModifier=${this.selectedModifier}
            @editstart=${this.editStart}
            @squadplace=${this.addCardToLineup}
            @squadswap=${this.swapCards}
            @cardselect=${this.selectCard}
            @squaddragend=${this.deselectCard}
            @modifierplace=${this.addModifier}
            @modifierremove=${this.removeModifier}
            @lineupconfirm=${this.saveLineup}
          ></game-lineup>
        `)}

      ${guard([this.modifiableSquad, this.editable, this.collectionSearchTerm], () => html `
          <squad-collection
            .squad=${this.modifiableSquad}
            ?editable=${this.editable}
            ?showBrowseButton=${this.showBrowseButton}
            ?hideActionButtons=${this.hideActionButtons}
            ?hideMods=${this.hideMods}
            searchTerm=${this.collectionSearchTerm}
            @editstart=${this.editStart}
            @squaddrop=${this.onRemoveCardFromLineup}
            @cardselect=${this.selectCard}
            @squaddragend=${this.deselectCard}
            @modifierdragend=${this.deselectModifier}
            @modifierselect=${this.selectModifier}
          ></squad-collection>
        `)}

      ${when(this.loading, () => html `
          <div id="loader">
            <loading-spinner></loading-spinner>
          </div>
        `)}

    `;
    }
    editStart() {
        this.editingLineup = true;
        this.dispatchEvent(new Event("editstart"));
    }
    addCardToLineup(e) {
        const cardToPlace = e.detail;
        this.modifiableSquad = this.modifiableSquad.map(item => {
            if (item.cardId !== cardToPlace.card.cardId)
                return item;
            return Object.assign(Object.assign({}, item), { set: SquadItemSet.Lineup, playerPosition: cardToPlace.position });
        });
        this.deselectCard();
    }
    swapCards(e) {
        const cardsToSwap = e.detail;
        if (cardsToSwap.cardToPlace.set === SquadItemSet.Squad || cardsToSwap.cardToPlace.set === SquadItemSet.Collection) {
            this.swapCardFromBench(cardsToSwap);
        }
        if (cardsToSwap.cardToPlace.set === SquadItemSet.Lineup) {
            this.swapCardWithinLineup(cardsToSwap);
        }
        this.deselectCard();
    }
    swapCardFromBench(e) {
        this.modifiableSquad = this.modifiableSquad.map(item => {
            if (item.cardId === e.cardToReplace.cardId) {
                return Object.assign(Object.assign({}, item), { playerPosition: null, set: SquadItemSet.Squad, modifier: SquadItemModifier.None });
            }
            else if (item.cardId === e.cardToPlace.cardId) {
                return Object.assign(Object.assign({}, item), { set: SquadItemSet.Lineup, playerPosition: e.position });
            }
            return item;
        });
    }
    swapCardWithinLineup(e) {
        this.modifiableSquad = this.modifiableSquad.map(item => {
            if (item.cardId === e.cardToReplace.cardId) {
                return Object.assign(Object.assign({}, item), { playerPosition: e.cardToPlace.playerPosition });
            }
            else if (item.cardId === e.cardToPlace.cardId) {
                return Object.assign(Object.assign({}, item), { playerPosition: e.cardToReplace.playerPosition });
            }
            return item;
        });
    }
    deselectCard() {
        this.selectedCard = null;
    }
    deselectModifier() {
        this.selectedModifier = null;
    }
    selectCard(e) {
        this.selectedCard = e.detail;
        this.deselectModifier();
    }
    selectModifier(e) {
        this.selectedModifier = e.detail;
        this.deselectCard();
    }
    addModifier(e) {
        const { card, modifier } = e.detail;
        this.modifiableSquad = this.modifiableSquad.map(item => {
            if (item.modifier === modifier) {
                item.modifier = SquadItemModifier.None;
            } // Remove the modifier from all cards
            if (item.cardId !== card.cardId)
                return item;
            return Object.assign(Object.assign({}, item), { modifier });
        });
        this.deselectModifier();
    }
    removeModifier(e) {
        const card = e.detail;
        this.modifiableSquad = this.modifiableSquad.map(item => {
            if (item.cardId !== card.cardId)
                return item;
            return Object.assign(Object.assign({}, item), { modifier: SquadItemModifier.None });
        });
    }
    onRemoveCardFromLineup(e) {
        const card = e.detail;
        this.removeCardFromLineup(card);
        this.deselectCard();
    }
    removeCardFromLineup(card) {
        this.modifiableSquad = this.modifiableSquad.map(item => {
            if (item.cardId !== card.cardId)
                return item;
            return Object.assign(Object.assign({}, item), { playerPosition: null, set: SquadItemSet.Squad, modifier: SquadItemModifier.None });
        });
    }
    cancelEdit() {
        this.modifiableSquad = [...this.squad];
        this.editingLineup = false;
        this.deselectCards();
    }
    async saveLineup() {
        const lineup = this.modifiableSquad.filter(item => item.set === SquadItemSet.Lineup);
        const updateItems = lineup.map(item => {
            return {
                cardId: item.cardId,
                modifier: !item.modifier ? SquadItemModifier.None : item.modifier,
                playerposition: item.playerPosition
            };
        });
        this.loading = true;
        try {
            await ufApi.updateLineup(updateItems);
            this.editingLineup = false;
            this.loading = false;
            this.dispatchEvent(new Event("updatelineup"));
            await ufApi.getSquad();
        }
        catch (error) {
            this.handleError(error);
            this.dispatchEvent(new Event("updatelineuperror"));
            this.loading = false;
        }
    }
};
__decorate([
    property({ type: Boolean })
], GameArea.prototype, "editable", void 0);
__decorate([
    property({ type: Boolean })
], GameArea.prototype, "showBrowseButton", void 0);
__decorate([
    property({ type: Boolean })
], GameArea.prototype, "hideActionButtons", void 0);
__decorate([
    property({ type: Boolean })
], GameArea.prototype, "hideMods", void 0);
__decorate([
    state()
], GameArea.prototype, "squad", void 0);
__decorate([
    state()
], GameArea.prototype, "modifiableSquad", void 0);
__decorate([
    state()
], GameArea.prototype, "selectedCard", void 0);
__decorate([
    state()
], GameArea.prototype, "selectedModifier", void 0);
__decorate([
    state()
], GameArea.prototype, "editingLineup", void 0);
__decorate([
    state()
], GameArea.prototype, "loading", void 0);
__decorate([
    state()
], GameArea.prototype, "collectionSearchTerm", void 0);
__decorate([
    query("squad-collection")
], GameArea.prototype, "squadCollection", void 0);
GameArea = __decorate([
    customElement("game-area")
], GameArea);
export { GameArea };
