var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconGrid = class IconGrid extends LitElement {
    render() {
        return html `
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="4.85693" height="4.85718" rx="1"/>
        <rect y="6.07141" width="4.85693" height="4.85718" rx="1"/>
        <rect y="12.1428" width="4.85693" height="4.85718" rx="1"/>
        <rect x="6.07056" width="4.85693" height="4.85718" rx="1"/>
        <rect x="6.07056" y="6.07141" width="4.85693" height="4.85718" rx="1"/>
        <rect x="6.07056" y="12.1428" width="4.85693" height="4.85718" rx="1"/>
        <rect x="12.1431" width="4.85693" height="4.85718" rx="1"/>
        <rect x="12.1431" y="6.07141" width="4.85693" height="4.85718" rx="1"/>
        <rect x="12.1431" y="12.1428" width="4.85693" height="4.85718" rx="1"/>
      </svg>    
    `;
    }
};
IconGrid.styles = [iconCSS, css `
    rect {
      fill: var(--color);
    }
  `];
IconGrid = __decorate([
    customElement("icon-grid")
], IconGrid);
export { IconGrid };
