var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import router from "@/router/router";
import globalCSS from "~/styles/global.css";
import referFriendsCardCSS from "./refer-friends-card.css";
import ConfigurationService from "@/services/config/config";
import { when } from "lit/directives/when.js";
let ReferFriendsCard = class ReferFriendsCard extends LitElement {
    constructor() {
        super(...arguments);
        this.isCodeCopied = false;
    }
    render() {
        var _a;
        return html `
            <div class="card-header" >
                <div class="top">
                    <h3>${"Refer Friends"}</h3>
                    ${this.renderRewardCard()} 
                    <uf-icon icon="infoFilled" 
                    theme=${ConfigurationService.isUF ? "white" : "black"} 
                    @click=${this.handleInfoClick}
                    ></uf-icon>
                </div>
            
                <h2>${(_a = this.referralCode) !== null && _a !== void 0 ? _a : ""}</h2>

                <div class="bottom">
                    ${(this.isCodeCopied ? this.renderCodeCopiedButton() : this.renderCodeNotCopiedButton())}
                </div>
            </div>
        `;
    }
    renderRewardCard() {
        return html `
            ${when(ConfigurationService.isUF, () => html `
                <div class="coin-wrapper">
                    <uf-icon icon="coin"></uf-icon>
                    <span class="small-text">${"300"}</span>
                </div>  
            `, () => html `
                <div class="coin-wrapper">
                    <span class="small-text">${"+2"}</span>
                    <uf-icon icon="packReward" theme=${ConfigurationService.isBengals ? "white" : "black"} ></uf-icon>
                </div> 
            `)}
        `;
    }
    handleInfoClick() {
        router.navigate("refer-a-friend/");
    }
    handleCopyCodeClick() {
        this.copyToClipboard();
        this.isCodeCopied = true;
    }
    copyToClipboard() {
        var _a;
        const elem = document.createElement('textarea');
        elem.value = (_a = this.referralCode) !== null && _a !== void 0 ? _a : '';
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
    }
    renderCodeNotCopiedButton() {
        return html `
            <button class="white" @click=${this.handleCopyCodeClick}>
                <span>Copy Code</span>
                <uf-icon icon="copy"
                theme=${ConfigurationService.isUF ? "red" : "white"} 
                ></uf-icon>
            </button>
        `;
    }
    renderCodeCopiedButton() {
        return html `
            <button class="green">
                <span>Copy Code</span>
                <uf-icon icon="tick" ></uf-icon>
            </button>
        `;
    }
};
ReferFriendsCard.styles = [globalCSS, referFriendsCardCSS];
__decorate([
    property()
], ReferFriendsCard.prototype, "referralCode", void 0);
__decorate([
    state()
], ReferFriendsCard.prototype, "isCodeCopied", void 0);
ReferFriendsCard = __decorate([
    customElement("refer-friends-card")
], ReferFriendsCard);
export { ReferFriendsCard };
