var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconInfoFilled = class IconInfoFilled extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="${this.theme}"  d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 18C11.34 18 10.8 17.46 10.8 16.8V12C10.8 11.34 11.34 10.8 12 10.8C12.66 10.8 13.2 11.34 13.2 12V16.8C13.2 17.46 12.66 18 12 18ZM13.2 8.4H10.8V6H13.2V8.4Z"/>
      </svg>
    `;
    }
};
IconInfoFilled.styles = [iconCSS, css `
    path {
      fill: var(--color)
    }
    path.black {
      fill: #292929
    }

    path.white {
      fill: var(--color)
    }
  `];
__decorate([
    property()
], IconInfoFilled.prototype, "theme", void 0);
IconInfoFilled = __decorate([
    customElement("icon-info-filled")
], IconInfoFilled);
export { IconInfoFilled };
