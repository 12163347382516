var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";
import "./lineup.css";
import "~/app/components/game-area/game-area";
import "@/components/uf-tooltip/uf-tooltip";
let OnboardingLineup = class OnboardingLineup extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.tooltip1Position = "left";
        this.tooltip1Visible = true;
        this.tooltip2Visible = false;
        this.mediaQueryChange = (e) => {
            if (e.matches)
                this.tooltip1Position = "top";
            else
                this.tooltip1Position = "left";
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.tooltip1MediaQuery = window.matchMedia("(max-width: 650px)");
        if (this.tooltip1MediaQuery.matches)
            this.tooltip1Position = "top";
        this.tooltip1MediaQuery.addEventListener("change", this.mediaQueryChange);
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.tooltip1MediaQuery) === null || _a === void 0 ? void 0 : _a.removeEventListener("change", this.mediaQueryChange);
        this.tooltip1MediaQuery = undefined;
    }
    render() {
        return html `

      <button class="no-style" id="skip-button" @click=${this.next}>
        <span>Skip</span>
      </button>

      <game-area
        editable
        hideActionButtons
        hideMods
        @editstart=${() => this.tooltip1Visible = false}
        @updatelineup=${this.onLineupUpdate}
        @updatelineuperror=${this.onLineupUpdate}
        class="${ifDefined(this.tooltip1Visible ? 'highlight-collection' : undefined)}"
      ></game-area>

      ${when(this.tooltip1Visible, () => html `
          <div id="background-dim"></div>
          <uf-tooltip position=${this.tooltip1Position} id="tooltip1">
            Long press and drag cards into position to fill your first lineup
          </uf-tooltip>
        `)}

      ${when(this.tooltip2Visible, () => html `
          <div id="background-dim"></div>
          <div id="tooltip2">
            <uf-tooltip position="bottom">
              You'll be able to edit your lineup again later.
            </uf-tooltip>
            <button @click=${this.next}>View Scoring</button>
          </div>
        `)}

    `;
    }
    next() {
        this.dispatchEvent(new Event("next"));
    }
    onLineupUpdate() {
        this.tooltip2Visible = true;
    }
};
__decorate([
    state()
], OnboardingLineup.prototype, "tooltip1Position", void 0);
__decorate([
    state()
], OnboardingLineup.prototype, "tooltip1Visible", void 0);
__decorate([
    state()
], OnboardingLineup.prototype, "tooltip2Visible", void 0);
OnboardingLineup = __decorate([
    customElement("onboarding-lineup")
], OnboardingLineup);
