var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./uf-swiper-new.css";
import "@/components/asset/uf-icon/uf-icon";
let UFSwiperNew = class UFSwiperNew extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.direction = "horizontal";
        this.navigation = false;
        this.navigationnexthidden = false;
        this.navigationprevhidden = false;
        this.notouchmove = false;
        this.pagination = false;
        this.content = [];
        this.activeIndex = 0;
        this.length = 0;
        this.domIsReady = false;
        this.initialising = true;
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.domIsReady)
            this.init();
    }
    firstUpdated() {
        this.domIsReady = true;
        this.init();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.swiper.destroy();
        this.swiper = undefined;
    }
    init() {
        const swiperDOM = this.querySelector(`.swiper`);
        const modules = [];
        let paginationDOM;
        if (this.pagination) {
            paginationDOM = this.querySelector(".swiper-pagination");
            modules.push(Pagination);
        }
        let buttonNext;
        let buttonPrev;
        if (this.navigation) {
            buttonNext = this.querySelector(".swiper-button-next");
            buttonPrev = this.querySelector(".swiper-button-prev");
            modules.push(Navigation);
        }
        this.swiper = new Swiper(swiperDOM, {
            direction: this.direction,
            modules,
            pagination: this.pagination ? {
                el: paginationDOM,
                dynamicBullets: true,
                dynamicMainBullets: 7,
                clickable: true
            } : false,
            navigation: this.navigation ? {
                nextEl: buttonNext,
                prevEl: buttonPrev,
            } : false,
            allowTouchMove: !this.notouchmove
        });
        this.swiper.activeIndex = this.activeIndex;
        this.length = this.swiper.slides.length;
        this.swiper.on("activeIndexChange", swiper => {
            this.activeIndex = swiper.activeIndex;
            this.dispatchEvent(new CustomEvent("indexchange", {
                detail: swiper.activeIndex
            }));
        });
        this.initialising = false;
    }
    render() {
        return html `
      <!-- Slider main container -->
      <div class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          ${this.content.map(content => html `
              <div class="swiper-slide">
                ${content}
              </div>
            `)}
        </div>

        <!-- If we need pagination -->
        ${when(this.pagination, () => {
            var _a;
            return html `
            <div class="swiper-pagination" ?disabled=${!((_a = this.swiper) === null || _a === void 0 ? void 0 : _a.allowTouchMove)}></div>
          `;
        })}

        <!-- If we need navigation buttons -->
        ${when(this.navigation, () => html `
            <div class="swiper-navigation">

              <div class="swiper-button-prev ${!this.navigationprevhidden && (this.initialising || this.activeIndex !== 0) ? "" : "hidden"}">
                <uf-icon icon="chevron"></uf-icon>
              </div>
  
              <div class="swiper-button-next ${!this.navigationnexthidden && (this.initialising || this.activeIndex < this.length - 1) ? "" : "hidden"}">
                <uf-icon icon="chevron"></uf-icon>
              </div>

            </div>
          `)}
      </div>
    `;
    }
    allowTouchMove() {
        this.swiper.allowTouchMove = true;
        this.requestUpdate();
    }
};
__decorate([
    property()
], UFSwiperNew.prototype, "direction", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiperNew.prototype, "navigation", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiperNew.prototype, "navigationnexthidden", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiperNew.prototype, "navigationprevhidden", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiperNew.prototype, "notouchmove", void 0);
__decorate([
    property({ type: Boolean })
], UFSwiperNew.prototype, "pagination", void 0);
__decorate([
    property({ type: Array })
], UFSwiperNew.prototype, "content", void 0);
__decorate([
    property({ type: Number })
], UFSwiperNew.prototype, "activeIndex", void 0);
__decorate([
    state()
], UFSwiperNew.prototype, "length", void 0);
__decorate([
    state()
], UFSwiperNew.prototype, "initialising", void 0);
UFSwiperNew = __decorate([
    customElement("uf-swiper-new")
], UFSwiperNew);
export { UFSwiperNew };
