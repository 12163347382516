var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";
import globalCSS from "~/styles/global.css";
import navbarCSS from "./navbar.css";
import "./parts/profile/profile.view";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/asset/logo-bengals/logo-bengals";
import "@/components/asset/logo-chargers/logo-chargers";
import ConfigurationService from "@/services/config/config";
const { features } = ConfigurationService.skin;
import router from "@/router/router";
let Navbar = class Navbar extends LitElement {
    constructor() {
        var _a;
        super(...arguments);
        this.navItems = [
            { name: "Lobby", path: "/lobby", icon: "home" },
            { name: "Squad", path: "/squad", icon: "jersey" },
            { name: "Leaderboard", path: "/leaderboard", icon: "bars-square" },
            {
                name: "Marketplace",
                path: "/marketplace",
                icon: "bag",
                exclude: (_a = features === null || features === void 0 ? void 0 : features.marketplace) === null || _a === void 0 ? void 0 : _a.exclude
            }
        ];
        this.currentPath = "";
        this.profileIsOpen = false;
        this.mobileNavOpen = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.routeChangeSubscription = router.routeChange.subscribe(route => {
            this.currentPath = route.path;
        });
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.routeChangeSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.routeChangeSubscription = undefined;
    }
    render() {
        return html `
      <nav>

        <div id="links" class=${classMap({
            "open": this.mobileNavOpen
        })}>

          ${when(ConfigurationService.isUF, () => html `<logo-uf></logo-uf>`)}
          ${when(ConfigurationService.isBengals, () => html `<logo-bengals></logo-bengals>`)}
          ${when(ConfigurationService.isChargers, () => html `<logo-chargers></logo-chargers>`)}
          
          <div id="menu">
            <div id="menu-icon" @click=${this.toggleMobileNav}>
              <uf-icon icon="menu"></uf-icon>
            </div>

            <ul>
              ${this.navItems.map((item) => html `
                  ${when(!item.exclude, () => html `
                      <li>
                        <router-link href=${item.path} @click=${this.closeMobileNav}>
                          <div>
                            <uf-icon icon=${item.icon} theme=${ifDefined(this.isActiveRoute(item.path) ? "filled" : undefined)}></uf-icon>
                            <span>${item.name}</span>
                          </div>
                        </router-link>
                      </li>
                    `)}
                `)}
            </ul>
          </div>

          <div id="mobile-overlay" @click=${this.closeMobileNav}></div>
        </div>

        <div>
          <uf-icon
            icon="avatar"
            class="clickable"
            @click=${this.toggleProfile}
          ></uf-icon>
        </div>

        <profile-view
          .isOpen=${this.profileIsOpen}
          @toggleprofile=${this.toggleProfile}
        ></profile-view>
        
      </nav>

    `;
    }
    closeMobileNav() {
        if (this.mobileNavOpen)
            this.toggleMobileNav();
    }
    toggleMobileNav() {
        this.mobileNavOpen = !this.mobileNavOpen;
    }
    toggleProfile() {
        this.profileIsOpen = !this.profileIsOpen;
    }
    isActiveRoute(path) {
        return this.currentPath.includes(path.substring(1));
    }
};
Navbar.styles = [globalCSS, navbarCSS];
__decorate([
    state()
], Navbar.prototype, "currentPath", void 0);
__decorate([
    state()
], Navbar.prototype, "profileIsOpen", void 0);
__decorate([
    state()
], Navbar.prototype, "mobileNavOpen", void 0);
Navbar = __decorate([
    customElement("nav-bar")
], Navbar);
export { Navbar };
