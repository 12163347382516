var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconBars = class IconBars extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="${this.theme}" d="M6.41666 17.7917C6.41666 19.5636 4.98024 21 3.20833 21C1.43642 21 0 19.5636 0 17.7917V10.2083C0 8.43641 1.43642 7 3.20833 7C4.98024 7 6.41666 8.43641 6.41666 10.2083V17.7917ZM14.875 3.20833C14.875 1.43642 13.4386 0 11.6667 0C9.89475 0 8.45833 1.43642 8.45833 3.20833V17.7917C8.45833 19.5636 9.89475 21 11.6667 21C13.4386 21 14.875 19.5636 14.875 17.7917V3.20833ZM23.3333 12.5417C23.3333 10.7697 21.8969 9.33333 20.125 9.33333C18.3531 9.33333 16.9167 10.7697 16.9167 12.5417V17.7917C16.9167 19.5636 18.3531 21 20.125 21C21.8969 21 23.3333 19.5636 23.3333 17.7917V12.5417Z" />
      </svg>
    `;
    }
};
IconBars.styles = [iconCSS, css `
    path {
      fill: var(--color-active)
    }
    path.green {
      fill: #35E06A
    }
    path.red {
      fill: #E03535
    }
    path.none {
      fill: #000000
    }
  `];
__decorate([
    property()
], IconBars.prototype, "theme", void 0);
IconBars = __decorate([
    customElement("icon-bars")
], IconBars);
export { IconBars };
