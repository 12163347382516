var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import "@/router/components/router-link";
import globalCSS from '~/styles/global.css';
import low6Api from "@/services/api/low6/low6.api";
const componentStyles = [
    css `
    :host {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      gap: 1em;
      padding: 2em 0;
    }

    label {
      position: relative;
      overflow: hidden;
      transform: translateZ(0);
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      width: 5em;
      min-width: 5em;
      max-width: 5em;
      height: 5em;
      min-height: 5em;
      max-height: 5em;

      > img {
        width: 5em;
        min-width: 5em;
        max-width: 5em;
        height: 5em;
        min-height: 5em;
        max-height: 5em;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 50%;
        background: #000008;
      }

    }

    span {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.25em;
      font-size: 0.75em;
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
    }

    input {
      position: absolute;
      height: 0;
      width: 0;
    } 

    div {
      margin: 0 auto;
      font-size: 1.25em;
    }

  `,
];
let ProfileImage = class ProfileImage extends LitElement {
    async handleChange(e) {
        const element = e.target;
        if ((element === null || element === void 0 ? void 0 : element.files) && element.files.length > 0) {
            const res = await low6Api.updateProfileImage(element.files);
            if (this.profile) {
                this.profile.image = res.image;
            }
            this.requestUpdate();
        }
    }
    ;
    render() {
        return html `
      <label 
        for="profile-input"
      >
        <img 
          src=${this.profile ? this.profile.image : ''} 
        />

        <span> edit </span>
        
        <input
          id="profile-input"
          name="profile-input"
          type="file"
          @input=${this.handleChange}
        />
      </label>

      <div>
        ${this.profile ? this.profile.username : ''}
      </div>
    `;
    }
};
ProfileImage.styles = [globalCSS, componentStyles];
__decorate([
    state()
], ProfileImage.prototype, "profile", void 0);
ProfileImage = __decorate([
    customElement("profile-image")
], ProfileImage);
export { ProfileImage };
