var _a, _b;
import { css, unsafeCSS } from "lit";
import configurationService from "@/services/config/config";
const theme = (_b = (_a = configurationService.skin.features) === null || _a === void 0 ? void 0 : _a.leaderboard) === null || _b === void 0 ? void 0 : _b.theme;
const colour = theme === null || theme === void 0 ? void 0 : theme.colour;
const image = theme === null || theme === void 0 ? void 0 : theme.image;
const position = theme === null || theme === void 0 ? void 0 : theme.position;
const stageCardCSS = css `

  :host {
    --view-lineup-button-color: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.viewLineupButton) || "var(--color-primary)")};
    --view-lineup-button-image: ${unsafeCSS((image === null || image === void 0 ? void 0 : image.viewLineupButton) ? `url("/${image.viewLineupButton}")` : "none")};
    --view-lineup-button-image-position: ${unsafeCSS((position === null || position === void 0 ? void 0 : position.viewLineupButtonImage) || "center")};
  }

  div.card {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    position: relative;
    border-radius: 1.25em;
    padding: 1em;
    margin: auto;
  } 

  div.card details {
    height: 4.375em;
  }

  div.card.red {
    background-color: var(--color-stage-card);
  }

  div.card.green {
    background-color: var(--color-stage-card);
  }

  div.card.none {
    background-color: var(--color-stage-card);
    opacity: 30%;
  }

  div.top {
    display: flex;
    align-items: top;
    justify-content: space-between;
  }

  div.top p {
    margin: 0;
    font-size: 1.375em;
    font-weight: 700;
    line-height: 1;
  }

  uf-icon[icon=arrow] {
    width: 0.354em;
    height: 0.354em;
    margin-right: 0.5em;
  }

  div.bottom {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  div.bottom-right {
    display: flex;
    align-items: baseline;
  }

  uf-icon[icon=bars] {
    width: 1.458em;
    height: 1.3125em;
    margin-left: 0.5em;
  }

  p.small-text {
    margin: 0;
    color: var(--color-stage-card-subheading);
;
  }

  div.bottom-right p.small-text {
    color: var(--color);
  }

  button {
    width: 100%;
    height: 3.5em;
    margin: 1em 0 0 0;
    column-gap: 0.5em;
    background-color: var(--view-lineup-button-color);
    border: none;
    background-image: var(--view-lineup-button-image);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: var(--view-lineup-button-image-position);
  }

  uf-icon[icon=shirt] {
    width: 1.458em;
    height: 1.3125em;
    margin-left: 0.5em;
  }
`;
export default stageCardCSS;
