import { css } from "lit";
const resetPasswordCSS = css `
  
  :host {
    text-align: center;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  form.loading button {
    opacity: 0.2;
  }
  .input {
    margin: 2.5em 0 3em;
  }

  #logo {
    display: none;
    justify-content: center;
  }
  logo-uf {
    width: 5em;
  }

  #logo + p {
    margin-top: 3em;
  }

  .input {
    margin-top: 4.5em;
  }

  #button {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }
  #buttton button {
    width: 11.5em;
  }
  
  #loading {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  loading-spinner {
    width: 3em;
    position: relative;
    top: 4.7em;
  }

  uf-modal {
    text-align: center;
  }
  uf-modal p {
    margin-top: 0.5em;
  }
  uf-modal > div {
    display: flex;
    justify-content: center;
  }
  uf-modal button {
    border: none;
  }

  @media (max-width: 450px) {
    form {
      display: block;
      padding-top: 7.5em;
    }
  }

  @media (max-height: 600px) and (min-width: 450px) {
    uf-stamp {
      display: none;
    }
    #logo {
      display: flex;
    }
    form {
      top: -2em;
    }
  }

  @media (max-height: 400px) and (min-width: 450px) {
    form {
      top: 0;
    }
  }

  @media (max-height: 350px) and (min-width: 450px) {
    form {
      justify-content: flex-start;
    }
  }

  @media (max-height: 300px) and (min-width: 450px) {
    form {
      height: auto;
    }
  }

`;
export default resetPasswordCSS;
