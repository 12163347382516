var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import ufApi from "@/services/api/uf/uf.api";
import globalCSS from "~/styles/global.css";
import loginStreakOnboardingCSS from "./login-streak-onboarding.css";
import router from "@/router/router";
import "@/components/uf-overlay/uf-overlay";
import '@/views/game/views/login-streak/components/login-streak-card/login-streak-card.ts';
import ConfigurationService from "@/services/config/config";
let LoginStreakOnboardingView = class LoginStreakOnboardingView extends LitElement {
    constructor() {
        super();
        ufApi.loginDetails.subscribe(login => {
            this.loginDays = login.loginDays;
        });
    }
    render() {
        return html `
    <uf-overlay>

      <div>
        <header-bar>
            <uf-icon slot="left" icon="cancel" class="clickable" @click=${this.cancel}></uf-icon>
        </header-bar>

        <section>
          
          ${this.renderHeaderText()}

          <login-streak-card .loginStreak=${this.loginDays} isInfoHidden=false></login-streak-card>

          <h1>The bigger the <br>streak the BIGGER <br>the reward</h1>

          ${this.renderTable()}

          ${this.renderinfoTip()}

          <p>Be warned, if you fail to login one day your streak <br>will return to 1.</p>

          <div class="button">
            <router-link href="/lobby">
                <button>Got It</button>
            </router-link>
          </div>
        </section>
      </div>
    </uf-overlay>
    `;
    }
    renderinfoTip() {
        return html `

    <div class="info-bubble">
        <uf-icon icon="upTriangle" theme="${this.getIconTheme()}"></uf-icon>
        <p>That's right, if you log in for 10 consecutive <br>days you will have been awarded 1 Gold <br>card, 2 Silver cards and 3 Base cards.</p>
    </div>
    `;
    }
    renderHeaderText() {
        return html `
        <div class="header">
            <uf-icon icon="lsOnboarding" theme="${this.getIconTheme()}"></uf-icon>
            <h1>Login Streaks</h1>
            <p>Earn cards by simply opening ${ConfigurationService.appNameLong()} daily. Just by opening the app today you are already on your way to earning more cards.</p>
        </div>
    `;
    }
    getIconTheme() {
        if (ConfigurationService.isUF)
            return "red";
        if (ConfigurationService.isBengals)
            return "orange";
        if (ConfigurationService.isChargers)
            return "yellow";
        return "";
    }
    renderTable() {
        return html `
      <div id="table">
        <div id="header">
          <p>Streak</p>
          <p>Cards</p>
          <p>Base</p>
          <p>Silver</p>
          <p>Gold</p>
        </div>
        <hr>
        ${this.renderStreakRowItem("1", 1, "1", "-", "-")}
        ${this.renderStreakRowItem("4", 2, "2", "-", "-")}
        ${this.renderStreakRowItem("7", 2, "-", "2", "-")}
        ${this.renderStreakRowItem("10", 1, "-", "-", "1")}
      </div>
    `;
    }
    renderStreakRowItem(streak, cardValue, base, silver, gold) {
        return html `
      <div class="row">
          <p>${streak}</p>
          <div class="reward-card">
            ${this.renderRewardCard(cardValue)}
          </div>
          <p class="${this.isTextDash(base)}">${base}</p>
          <p class="${this.isTextDash(silver)}">${silver}</p>
          <p class="${this.isTextDash(gold)}">${gold}</p>
        </div>
      `;
    }
    isTextDash(text) {
        if (text == "-")
            return "dash";
        return "";
    }
    renderRewardCard(value) {
        return html `
      <div class="pack-wrapper" 
      style="color:var(--color-reward-card-font)">
          <span class="small-text">+${value}</span>
          <uf-icon icon="packReward" theme="${ConfigurationService.isChargers ? "black" : "white"}"></uf-icon>
      </div>
      `;
    }
    cancel() {
        router.navigate("lobby");
    }
};
LoginStreakOnboardingView.styles = [globalCSS, loginStreakOnboardingCSS];
__decorate([
    state()
], LoginStreakOnboardingView.prototype, "loginDays", void 0);
LoginStreakOnboardingView = __decorate([
    customElement("login-streak-onboarding-view")
], LoginStreakOnboardingView);
export { LoginStreakOnboardingView };
