var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import globalCSS from "~/styles/global.css";
import locationCSS from "./location.css";
import configurationService from "@/services/config/config";
import "@/components/asset/logo-uf/logo-uf";
import "@/components/asset/logo-bengals/logo-bengals";
import "@/components/asset/logo-chargers/logo-chargers";
let LocationView = class LocationView extends LitElement {
    render() {
        return html `
      <div>

        ${when(configurationService.isUF, () => html `<logo-uf></logo-uf>`)}
        ${when(configurationService.isBengals, () => html `<logo-bengals></logo-bengals>`)}
        ${when(configurationService.isChargers, () => html `<logo-chargers></logo-chargers>`)}

        <div>
          <h1><span>${configurationService.skin.title} is not available here.</span></h1>
          <p class="small-text">Due to laws and regulations ${configurationService.skin.title} can't be accessed in certain locations.</p>
        </div>

      </div>
    `;
    }
};
LocationView.styles = [globalCSS, locationCSS];
LocationView = __decorate([
    customElement("location-view")
], LocationView);
export { LocationView };
