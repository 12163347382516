var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import { choose } from "lit/directives/choose.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import gameCardStyles from "../game-card.css";
import gameCardFrontWideStyles from "./game-card-front-wide.css";
import { SquadItemModifier, SquadItemRating, SquadItemType } from "@/services/api/uf/types/uf.api.types.enums";
import { squadPositionLabel } from "@/mappings/squad.mappings";
import config from "~/app/services/config/config";
import placeholders from "../placeholders";
import modifierGoldenBoot from "@/assets/icon_mod_golden_boot.svg";
import modifierCaptain from "@/assets/icon_modifier_captain.svg";
import modifierGoldenFootball from "@/assets/icon_mod_golden_football.svg";
import modifierGoldenHelmet from "@/assets/icon_mod_golden_helmet.svg";
let GameCardFrontWide = class GameCardFrontWide extends ScopedElement {
    render() {
        let type = "";
        let rating = "";
        let img = "";
        let roundelImg = "";
        switch (this.squadItem.scoreableType) {
            case SquadItemType.Team:
                if (this.squadItem.externalRef) {
                    img = `${config.storageBaseUrl()}/headshots/${this.squadItem.externalRef}.png`;
                }
                break;
            case SquadItemType.Player:
                if (this.squadItem.teamExternalRef) {
                    roundelImg = `${config.storageBaseUrl()}/headshots/${this.squadItem.teamExternalRef}.png`;
                    if (this.squadItem.externalRef) {
                        if (config.isUF) {
                            // Currently PL player headshot filesnames are prefixed with the team id whereas the NFL ones are not
                            img = `${config.storageBaseUrl()}/headshots/${this.squadItem.externalRef}_${this.squadItem.teamExternalRef}.png`;
                        }
                        else {
                            img = `${config.storageBaseUrl()}/headshots/${this.squadItem.externalRef}.png`;
                        }
                    }
                }
                break;
        }
        if (this.squadItem.scoreableType !== null || this.squadItem.scoreableType !== undefined)
            type = SquadItemType[this.squadItem.scoreableType];
        if (this.squadItem.cardRating !== null || this.squadItem.cardRating !== undefined)
            rating = SquadItemRating[this.squadItem.cardRating];
        type;
        rating;
        img;
        roundelImg;
        return html `

      <div class=${classMap({
            [type.toLowerCase()]: true,
            [rating.toLowerCase()]: true
        })}>

        ${when(this.squadItem.modifier !== SquadItemModifier.None, () => html `
            <div id="modifier-label">
              ${choose(this.squadItem.modifier, [
            [SquadItemModifier.Captain, () => unsafeSVG(config.isUF ? modifierCaptain : modifierGoldenHelmet)],
            [SquadItemModifier.GoldenBoot, () => unsafeSVG(config.isUF ? modifierGoldenBoot : modifierGoldenFootball)]
        ])}
            </div>
          `)}

        <div id="img-area">
          ${img ? html `
            
              <div id="img" style="background-image: url('${img}')"></div>
              
            ` : html `
              <div id="img" class="img-placeholder">
                ${choose(this.squadItem.scoreableType, [
            [SquadItemType.Team, () => choose(this.squadItem.cardRating, [
                    [SquadItemRating.Standard, () => unsafeSVG(placeholders.team.standard)
                    ],
                    [SquadItemRating.Silver, () => unsafeSVG(placeholders.team.silver)
                    ],
                    [SquadItemRating.Gold, () => unsafeSVG(placeholders.team.gold)
                    ]
                ])],
            [SquadItemType.Player, () => choose(this.squadItem.cardRating, [
                    [SquadItemRating.Standard, () => unsafeSVG(placeholders.player.standard)
                    ],
                    [SquadItemRating.Silver, () => unsafeSVG(placeholders.player.silver)
                    ],
                    [SquadItemRating.Gold, () => unsafeSVG(placeholders.player.gold)
                    ]
                ])]
        ])}
              </div>
            `}
        </div>

        <div id="name">
          <div>
            ${this.squadItem.knownname ? html `
                <p><span>${this.squadItem.knownname}</span></p>
              ` : html `
                ${when(this.squadItem.firstname && this.squadItem.lastname, () => html `
                    <p>${this.squadItem.firstname[0]}. <span>${this.squadItem.lastname}</span></p>
                  `)}
              `}
            ${when(SquadItemType[this.squadItem.scoreableType].toLowerCase() === "player", () => html `
                <p id="team">${this.squadItem.team}</p>
              `)}
          </div>
        </div>

        <div id="details">
          <div id="icons">
            ${roundelImg ? html `
                <div id="roundel" style="background-image: url('${roundelImg}')"></div>
              ` : html `
                <div id="roundel" class="roundel-placeholder">
                  ${choose(this.squadItem.cardRating, [
            [SquadItemRating.Standard, () => unsafeSVG(placeholders.roundel.standard)
            ],
            [SquadItemRating.Silver, () => unsafeSVG(placeholders.roundel.silver)
            ],
            [SquadItemRating.Gold, () => unsafeSVG(placeholders.roundel.gold)
            ]
        ])}
                </div>
              `}

            <div id="label" class=${rating.toLowerCase()}>
              <span>
                ${squadPositionLabel(this.squadItem)}
              </span>
            </div>
          </div>

          <div id="points">
            <label>Total points</label>
            <span>${this.squadItem.score || 0}</span>
          </div>
        </div>

      </div>
    `;
    }
};
GameCardFrontWide.styles = [ScopedElement.styles, gameCardStyles, gameCardFrontWideStyles];
__decorate([
    property({ type: Object })
], GameCardFrontWide.prototype, "squadItem", void 0);
GameCardFrontWide = __decorate([
    customElement("game-card-front-wide")
], GameCardFrontWide);
export { GameCardFrontWide };
