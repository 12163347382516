var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { map } from "lit/directives/map.js";
import { classMap } from "lit/directives/class-map.js";
import "./leaderboard-position-list.css";
import { appContainer } from "@/services/config/constants";
import config from "@/services/config/config";
import router from "@/router/router";
import ufApi from '@/services/api/uf/uf.api';
import { findCurrentLeaderboardPositionEntry, findCurrentRank, findCurrentStage } from "@/services/api/uf/uf.helpers";
import { isRankTied } from "@/views/game/directives/game.directives";
import { deepEqual } from "@/services/utils/utils";
import '@/components/header-bar/header-bar';
import "@/views/game/views/leaderboards/components/leaderboard-position-entry/leaderboard-position-entry.view";
import "@/views/game/views/leaderboards/components/dashed-line/dashed-line";
import "@/router/components/router-link";
let LeaderboardPositionListView = class LeaderboardPositionListView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.isCurrentStage = false;
        this.type = "overall";
        this.isUserPositionEntryInMainList = false;
        this.isLoading = true;
        this.updateUserPositionEntryElement = () => {
            var _a;
            // Only show floating user position element if the user position element in the main list is off the screen
            if (this.userPositionEntryElement) {
                const rect = (_a = this.userPositionEntryElement) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                if (rect) {
                    if (rect.top > window.innerHeight) {
                        this.userPositionEntryFloatingElement.hidden = false;
                    }
                    else if (rect.bottom < 0) {
                        this.userPositionEntryFloatingElement.hidden = false;
                    }
                    else {
                        this.userPositionEntryFloatingElement.hidden = true;
                    }
                }
                else {
                    this.userPositionEntryFloatingElement.hidden = false;
                }
            }
        };
    }
    async connectedCallback() {
        var _a, _b, _c;
        super.connectedCallback();
        this.type = "overall";
        this.userLeague = undefined;
        this.userLeagueId = undefined;
        const dynamicRoutes = router.dynamicRoutes.getValue();
        const stageId = (_a = dynamicRoutes.find(route => route.identifier === "stageId")) === null || _a === void 0 ? void 0 : _a.value;
        const type = (_b = dynamicRoutes.find(route => route.identifier === "type")) === null || _b === void 0 ? void 0 : _b.value;
        const userLeagueId = (_c = dynamicRoutes.find(route => route.identifier === "userLeagueId")) === null || _c === void 0 ? void 0 : _c.value;
        this.handleStage(stageId);
        ufApi.getStages().catch(error => () => this.handleErrors(error));
        if (type)
            this.type = type;
        if (userLeagueId !== undefined)
            this.userLeagueId = +userLeagueId;
        if (this.stage)
            this.getLeaderboards();
        appContainer.addEventListener('scroll', this.updateUserPositionEntryElement);
    }
    handleStage(id) {
        this.stageSubscription = ufApi.stages.subscribe(stages => {
            var _a;
            const currentStage = (_a = findCurrentStage(stages)) === null || _a === void 0 ? void 0 : _a.stage;
            const stage = id !== undefined ? stages.find(stage => stage.id === +id) : currentStage;
            if (stage && !deepEqual(this.stage, stage)) {
                this.stage = stage;
                this.isCurrentStage = stage.id === (currentStage === null || currentStage === void 0 ? void 0 : currentStage.id);
                this.getLeaderboards();
            }
        });
    }
    async getLeaderboards() {
        var _a;
        ufApi.getLeaderboard().catch(error => () => this.handleErrors(error));
        this.isLoading = true;
        this.leaderboards = await ufApi.getLeaderboardForStage(this.stage.id);
        if (this.type === "userLeague") {
            this.userLeague = (_a = this.leaderboards.userLeagueLeaderboards) === null || _a === void 0 ? void 0 : _a.find(userLeague => userLeague.userLeagueDetailId === this.userLeagueId);
        }
        this.isLoading = false;
        this.getUserPositionEntry();
    }
    getUserPositionEntry() {
        this.rankSubscription = ufApi.ranks.subscribe(ranks => {
            var _a;
            if (ranks.length && this.stage) {
                const rank = findCurrentRank(ranks, this.stage);
                if (this.leaderboards && rank) {
                    let currentUserPositionEntry;
                    switch (this.type) {
                        case "clash":
                            currentUserPositionEntry = findCurrentLeaderboardPositionEntry(this.leaderboards.miniLeagueLeaderboard || [], rank);
                            break;
                        case "userLeague":
                            currentUserPositionEntry = findCurrentLeaderboardPositionEntry(((_a = this.userLeague) === null || _a === void 0 ? void 0 : _a.leaderboard) || [], rank);
                            break;
                        case "overall":
                            currentUserPositionEntry = findCurrentLeaderboardPositionEntry(this.leaderboards.position || [], rank);
                            this.isUserPositionEntryInMainList = findCurrentLeaderboardPositionEntry(this.leaderboards.leaderboard || [], rank) !== undefined;
                            break;
                    }
                    if (currentUserPositionEntry && !deepEqual(this.currentUserPositionEntry, currentUserPositionEntry)) {
                        this.currentUserPositionEntry = currentUserPositionEntry;
                    }
                }
                else {
                    this.currentUserPositionEntry == null;
                }
            }
        });
    }
    handleErrors(error) {
        console.log(error);
    }
    async disconnectedCallback() {
        var _a, _b;
        super.disconnectedCallback();
        (_a = this.stageSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.stageSubscription = undefined;
        (_b = this.rankSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        this.rankSubscription = undefined;
        appContainer.removeEventListener('scroll', this.updateUserPositionEntryElement);
    }
    updated(_changedProperties) {
        // This timeout is required to get accurate dimensions (getBoundingClientRect()) after the modal has been painted. Without the timeout the dimensions aren't accurate as the modal has not completed painting.
        this.updateUserPositionEntryElement();
    }
    render() {
        var _a, _b, _c, _d, _e, _f;
        switch (this.type) {
            case "clash":
                return html `
          ${this.renderHeaderBar(config.clashName())}
          <section>
            ${this.renderStage()}
            ${when(this.isLoading, () => html `${this.renderLoading()}`, () => html `
                ${this.renderTitles()}
                ${when(this.leaderboards, () => html `${this.renderLeaderboardCards(this.leaderboards.miniLeagueLeaderboard)}`)}
                ${this.renderFloatingUserPositionCard()}
              `)}
          </section>
          ${this.renderFooter()}
        `;
            case "userLeague":
                return html `
          ${this.renderHeaderBar((_b = (_a = this.userLeague) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "")}
          <section>
            ${this.renderStage()}
            ${when(this.isLoading, () => html `${this.renderLoading()}`, () => html `
                ${this.renderTitles()}
                ${when(this.userLeague, () => { var _a; return html `${this.renderLeaderboardCards(((_a = this.userLeague) === null || _a === void 0 ? void 0 : _a.leaderboard) || [])}`; })}
                ${this.renderFloatingUserPositionCard()}
              `)}
          </section>
          ${this.renderFooter()}
        `;
            case "overall":
                const segment125 = ((_c = this.leaderboards) === null || _c === void 0 ? void 0 : _c.leaderboardSegments["125"]) || [];
                const segment150 = ((_d = this.leaderboards) === null || _d === void 0 ? void 0 : _d.leaderboardSegments["150"]) || [];
                const segment175 = ((_e = this.leaderboards) === null || _e === void 0 ? void 0 : _e.leaderboardSegments["175"]) || [];
                const segment200 = ((_f = this.leaderboards) === null || _f === void 0 ? void 0 : _f.leaderboardSegments["200"]) || [];
                return html `
          ${this.renderHeaderBar("Overall")}
          <section>
            ${this.renderStage()}
            ${when(this.isLoading, () => html `${this.renderLoading()}`, () => html `
                ${this.renderTitles()}
                ${when(this.leaderboards, () => html `${this.renderLeaderboardCards(this.leaderboards.leaderboard)}`)}

                ${when(!this.isUserPositionEntryInMainList, () => html `
                    ${when(segment125.length > 0, () => html `${this.renderLeaderboardCards(segment125)}`)}
                    ${when(segment150.length > 0, () => html `${this.renderLeaderboardCards(segment150)}`)}
                    ${when(segment175.length > 0, () => html `${this.renderLeaderboardCards(segment175)}`)}
                    ${when(segment200.length > 0, () => html `${this.renderLeaderboardCards(segment200)}`)}
                    ${when(this.leaderboards, () => html `${this.renderLeaderboardCards(this.leaderboards.position)}`)}
                  `)}

                ${this.renderFloatingUserPositionCard()}
              `)}
          </section>
          ${this.renderFooter()}
        `;
        }
    }
    renderHeaderBar(title) {
        return html `
      <header-bar>
        <div slot="left">
          <router-link href="/leaderboard">
            <uf-icon icon='chevron' class="clickable"></uf-icon>
          </router-link>
          ${when(this.stage, () => {
            var _a;
            return html `
              <h3>${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.name}</h3>
            `;
        })}
        </div>  
        <div slot="center"><h3>${title}</h3></div>
        <div slot="right">
          <router-link href="/leaderboard/info/contest" .data=${{ stage: this.stage, type: this.type }}>
            <uf-icon icon='info' class="clickable"></uf-icon>
          </router-link>
        </div>  
      </header-bar>
    `;
    }
    renderStage() {
        return html `
      ${when(this.stage, () => html `
            <stage-card
              .stage=${this.stage}
              ?isCurrentStage=${this.isCurrentStage}
              ?isLink=${true}
              ?showLineupButton=${true}
            ></stage-card>
          `)}
    `;
    }
    renderLoading() {
        return html `
      <div id="loader">
        <loading-spinner></loading-spinner>
      </div>
    `;
    }
    renderTitles() {
        return html `
      <div class="titles">
        <p>Rank</p>
        <p>Prize</p>
        <p>Points</p>
      </div>
    `;
    }
    renderLeaderboardCards(entries) {
        return html `
      ${when(entries === null || entries === void 0 ? void 0 : entries.length, () => html `
          <div class="leaderboard-cards">
            ${map(entries || [], (entry, index) => {
            var _a;
            const isUserEntry = entry.userId === this.currentUserPositionEntry.userId;
            let prizeBreakdown = "";
            if (this.type === "overall") {
                prizeBreakdown = this.stage.breakdown;
            }
            else if (this.type === "clash") {
                prizeBreakdown = "1='FREE CARD'";
            }
            return html `
                  <router-link href="/leaderboard/lineup/${entry.userId}/${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.id}" class=${classMap({ "user-position-entry": isUserEntry })}>
                    <leaderboard-position-entry
                      prizeBreakdown=${prizeBreakdown}
                      selectedId=${this.currentUserPositionEntry.userId}
                      .isRankTied=${this.type === "overall" && isRankTied(entries || [], index)}
                      .entry=${entry}
                    ></leaderboard-position-entry>
                  </router-link>
                `;
        })}
          </div>
          <dashed-line></dashed-line>
        `)}
    `;
    }
    renderFloatingUserPositionCard() {
        return html `
      ${when(this.currentUserPositionEntry, () => {
            var _a, _b;
            return html `
          <router-link href="/leaderboard/lineup/${(_a = this.currentUserPositionEntry) === null || _a === void 0 ? void 0 : _a.userId}/${(_b = this.stage) === null || _b === void 0 ? void 0 : _b.id}" class="user-position-entry-floating">
            <leaderboard-position-entry
              prizeBreakdown=${this.stage.breakdown}
              selectedId=${this.currentUserPositionEntry.userId}
              .entry=${this.currentUserPositionEntry}
            ></leaderboard-position-entry>
          </router-link>
        `;
        })}
    `;
    }
    renderFooter() {
        return html `
      <footer>
        <p>Points will be updated after each match has finished.</p>
      </footer>
    `;
    }
};
__decorate([
    state()
], LeaderboardPositionListView.prototype, "stage", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "isCurrentStage", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "type", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "leaderboards", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "currentUserPositionEntry", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "isUserPositionEntryInMainList", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "userLeague", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "userLeagueId", void 0);
__decorate([
    state()
], LeaderboardPositionListView.prototype, "isLoading", void 0);
__decorate([
    query(".user-position-entry")
], LeaderboardPositionListView.prototype, "userPositionEntryElement", void 0);
__decorate([
    query(".user-position-entry-floating")
], LeaderboardPositionListView.prototype, "userPositionEntryFloatingElement", void 0);
LeaderboardPositionListView = __decorate([
    customElement("leaderboard-position-list-view")
], LeaderboardPositionListView);
export { LeaderboardPositionListView };
