var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import ufTooltipStyles from "./uf-tooltip.css";
let UFTooltip = class UFTooltip extends ScopedElement {
    render() {
        return html `
      <div class="${ifDefined(this.position)}">
        <span>
          <slot></slot>
        </span>
      </div>
    `;
    }
};
UFTooltip.styles = [ScopedElement.styles, ufTooltipStyles];
__decorate([
    property()
], UFTooltip.prototype, "position", void 0);
UFTooltip = __decorate([
    customElement("uf-tooltip")
], UFTooltip);
