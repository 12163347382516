var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c;
import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import config from "@/services/config/config";
const colour = (_a = config.skin.theme) === null || _a === void 0 ? void 0 : _a.colour;
let HeaderBar = class HeaderBar extends LitElement {
    render() {
        return html `
      <div><slot name="left"></slot></div>
      <div><slot name="center"></slot></div>
      <div><slot name="right"></slot></div>
    `;
    }
};
HeaderBar.styles = [globalCSS, css `
    :host {
      --header-bar-height: 3.5em;
      display: grid;
      grid-template-columns: var(--header-bar-columns, 1fr 2fr 1fr);
      align-items: center;
      width: 100%;
      height: var(--header-bar-height);
      border-radius: calc(var(--header-bar-height) / 2);
      padding: var(--header-bar-padding, 0 2em);
      background: var(--header-bar-background, ${unsafeCSS((_b = colour === null || colour === void 0 ? void 0 : colour.statusBar) === null || _b === void 0 ? void 0 : _b.default) || "linear-gradient(to right, #84058f 0%,#55035c 100%)"});
    }
    @media (max-width: 500px) {
      :host {
        --header-bar-height: 3em;
        padding: var(--header-bar-padding, 0 1.25em);
      }
    }
    :host(.tall) {
      --header-bar-height: 5em;
    }
    :host(.active) {
      background: var(--header-bar-background, ${unsafeCSS((_c = colour === null || colour === void 0 ? void 0 : colour.statusBar) === null || _c === void 0 ? void 0 : _c.active) || "linear-gradient(to right, #3dff79 0%,#35e06a 100%)"});
      color: #0F0F0F;
    }
    :host(.clickable) {
      cursor: pointer;
    }
    :host > div {
      display: flex;
    }
    :host > div:nth-of-type(2) {
      justify-self: center;
      justify-content: center;
      text-align: center;
    }
    :host > div:last-of-type {
      justify-content: flex-end;
      text-align: right;
    }
  `];
HeaderBar = __decorate([
    customElement("header-bar")
], HeaderBar);
export { HeaderBar };
