var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c;
import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import globalCSS from "~/styles/global.css";
import config from "@/services/config/config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.raf) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
let ReferralCountCard = class ReferralCountCard extends LitElement {
    constructor() {
        super(...arguments);
        this.coinValue = '0';
        this.header = '';
        this.isCoinIconHidden = true;
    }
    render() {
        return html `
            <div class="card">
                <div class="circle-parent">
                    <p class="circle">${this.coinValue}</p>
                    ${this.renderAwardedIcon()}
                </div>
                <div>
                    <p>${this.header}</p>
                    <p class="small-text">Total</p>
                </div>
            </div>
        `;
    }
    renderAwardedIcon() {
        return html `
            ${when(config.isUF, () => html `
                <uf-icon icon="coin" ?hidden=${this.isCoinIconHidden}></uf-icon>
            `, () => html `
                <div class="packBadge" 
                    ?hidden=${this.isCoinIconHidden}>
                    <uf-icon icon="packReward" 
                    theme="black" ></uf-icon>
                </div>
            `)}
        `;
    }
};
ReferralCountCard.styles = [globalCSS, css `
    
    :host {
        --background-card: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRafCount) || "#FF284F")};
        --background-circle: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundCircleRafCount) || "#7f1e31")};
        --color-bottom-text: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorRafCountBottomText) || "#7f1e31")};
    }

    .card {
        display: flex;
        flex-direction: column;
        row-gap: 0.75em;
        background-repeat: no-repeat;
        background-size: cover;
        background: var(--background-card);
        border-radius: 1.5em;
        margin: 0em 1em 0em;
        padding: 1.3125em 1.5em 1.3125em;
        text-align: center;
    }

    .circle-parent {
        position: relative;
    }

    p.circle {
        display: flex;
        font-weight: bold;
        font-size: 1.75em;
        /* margin: 0 0 0.75em; */
        height: 3.571em;
        width: 3.571em;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;
        background-color: var(--background-circle);
    }

    p {
        margin: 0;
        padding: 0;
    }

    p.small-text {
        color: var(--color-bottom-text);
    }

    .packBadge {
        width: 1.875em;
        height: 1.875em;
        background-color: #FFFFFF;
        border-radius:  50%;
        position: absolute;
        top: 0em;
    }

    uf-icon[icon=coin] {
        width: 2em;
        position: absolute;
        top: 0em;
    }


    uf-icon[icon=packReward]{
        width: 0.91em;
        height: 1.875em;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    `];
__decorate([
    property()
], ReferralCountCard.prototype, "coinValue", void 0);
__decorate([
    property()
], ReferralCountCard.prototype, "header", void 0);
__decorate([
    property({ type: Boolean })
], ReferralCountCard.prototype, "isCoinIconHidden", void 0);
ReferralCountCard = __decorate([
    customElement("referral-count-card")
], ReferralCountCard);
export { ReferralCountCard };
