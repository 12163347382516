var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import betfredImage from "@/components/asset/bengals-stamp/lobby-header-sponsor-betfred.png";
import bengalsImage from "@/components/asset/bengals-stamp/logo-bengals.png";
let BengalsStamp = class BengalsStamp extends LitElement {
    constructor() {
        super(...arguments);
        this.fill = "#FF284F";
        this.isHorizontal = false;
    }
    render() {
        return html `
      <div id="${this.getContainerId()}">
        <img src=${bengalsImage}>
        <div id="sponsor-header">
          <p>POWERED BY</p>
        <img src=${betfredImage}>
      </div>
      </div>
    `;
    }
    getContainerId() {
        if (this.isHorizontal)
            return "container-horizontal";
        else
            return 'container';
    }
};
BengalsStamp.styles = [css `
    :host {
      display: flex;
    }

    #container {
      display: flex;
      column-gap: 3.2em;
      margin: 0 auto;
    }

    #container-horizontal {
      display: flex;
      flex-direction: column;
      row-gap: 2.25em;
      margin: 0 auto;
    }

    #sponsor-header {
      display: flex;
      flex-direction: column;
      row-gap: 0.6em;
      margin: auto;
    }

    #sponsor-header p {
      font-size: 0.625em;
      text-align: center;
      margin: 0 auto;
    }

    svg {
      width: 8.94em;
    }

    img {
      width: 8.94em;
    }

  `];
__decorate([
    property()
], BengalsStamp.prototype, "fill", void 0);
__decorate([
    property()
], BengalsStamp.prototype, "isHorizontal", void 0);
BengalsStamp = __decorate([
    customElement("bengals-stamp")
], BengalsStamp);
export { BengalsStamp };
