var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "@/layout/scoped-element";
import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { choose } from "lit/directives/choose.js";
import leagueCardCSS from "./league-card.css";
import ufApi from "@/services/api/uf/uf.api";
import { formatAsCurrency, formatAsNth, prizeFromPrizeBreakdown } from "@/views/game/directives/game.directives";
import { pluralise } from "@/directives/app.directives";
import config from "@/services/config/config";
let LeagueCard = class LeagueCard extends ScopedElement {
    constructor() {
        super(...arguments);
        this.league = "Overall";
    }
    connectedCallback() {
        super.connectedCallback();
        try {
            this.getUserRanking();
        }
        catch (error) {
            this.handleErrors(error);
        }
    }
    async getUserRanking() {
        const stageLeaderboard = await ufApi.getLeaderboardForUser(this.stage.id, this.userId);
        let entries = stageLeaderboard.leaderboard;
        if (this.league === "Clash")
            entries = stageLeaderboard.miniLeagueLeaderboard;
        this.user = entries === null || entries === void 0 ? void 0 : entries.find(entry => entry.userId === this.userId);
    }
    handleErrors(error) {
        console.log(error);
    }
    render() {
        return html `

      <header>
        <p>${this.league}</p>
      </header>

      <footer>
        <p>
          ${when(this.user, () => html `
              ${formatAsNth(this.user.rank)}
            `)}
        </p>
        <p>
          ${when(this.user, () => html `
              ${this.user.score}${pluralise("pt", this.user.score)}
            `)}
        </p>
        ${choose(this.league, [
            ["Overall", () => {
                    var _a;
                    return html `
              ${((_a = this.user) === null || _a === void 0 ? void 0 : _a.score) ? html `
                  ${config.showMonetryPrizes() ? html `
                      <p>
                        ${formatAsCurrency(this.user.draftWinnings)}
                      </p>
                    ` : html `
                      ${prizeFromPrizeBreakdown(this.stage.breakdown || "", this.user.rank, false) ? html `
                          <p class="has-prize">Prize</p>
                        ` : html `
                          <p>--</p> 
                        `}
                    `}
                ` : html `
                  <p>--</p>
                `}
            `;
                }],
            ["Clash", () => {
                    var _a, _b;
                    return html `
              ${((_a = this.user) === null || _a === void 0 ? void 0 : _a.rank) === 1 && ((_b = this.user) === null || _b === void 0 ? void 0 : _b.score) ? html `
                  <p class="has-prize">Card</p>
                ` : html `
                  <p>--</p>
                `}
            `;
                }]
        ])}
      </footer>  

    `;
    }
};
LeagueCard.styles = [ScopedElement.styles, leagueCardCSS];
__decorate([
    property()
], LeagueCard.prototype, "league", void 0);
__decorate([
    property({ type: Object })
], LeagueCard.prototype, "stage", void 0);
__decorate([
    property({ type: Number })
], LeagueCard.prototype, "userId", void 0);
__decorate([
    state()
], LeagueCard.prototype, "user", void 0);
LeagueCard = __decorate([
    customElement("league-card")
], LeagueCard);
export { LeagueCard };
