/**
 * Handles the functionality for counting down to a time in the future. Has an opinionated text format.
 */
export class CountdownController {
    constructor(host, date, onComplete) {
        this.host = host;
        this.date = date;
        this.onComplete = onComplete;
        this.frequency = 60 * 1000;
        this.text = "";
        this.host.addController(this);
        this.start();
    }
    /**
     * Starts the countdown interval and executes a callback when the timer has complete.
     */
    start() {
        this.updateText();
        this.interval = window.setInterval(() => {
            this.updateText();
            if (this.text === "0m") {
                this.stop();
                window.setTimeout(() => {
                    if (this.onComplete)
                        this.onComplete();
                }, this.frequency);
            }
        }, this.frequency);
    }
    updateText() {
        this.text = this.convertCountdown(this.date);
        this.host.requestUpdate();
    }
    /**
     * Stops the countdown interval.
     */
    stop() {
        if (this.interval) {
            window.clearInterval(this.interval);
            this.interval = undefined;
        }
    }
    hostDisconnected() {
        this.stop();
    }
    /**
     * Converts a future date into a human-readable format, i.e. '2d 12h 34m'.
     * @param date Future date.
     * @returns Formatted string.
     */
    convertCountdown(date) {
        const now = Date.now();
        const distance = date.getTime() - now;
        if (distance < (60 * 1000))
            return "0m";
        const minutes = Math.floor(distance / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;
        const daysText = days > 0 ? `${days}d` : "";
        const hoursText = remainingHours > 0 ? `${remainingHours}h` : "";
        const minsText = remainingMinutes > 0 ? `${remainingMinutes}m` : "";
        return `${daysText} ${hoursText} ${minsText}`.trim();
    }
}
