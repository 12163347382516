import { canadaStates, countryList, usaStates } from "./consts";
export const validateEmail = (email) => {
    const result = String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !!result;
};
export const validateCountry = (input) => {
    return countryList.includes(input.toLowerCase());
};
export const validateState = (country, input) => {
    const c = country.toLowerCase();
    var states = [];
    switch (c) {
        case 'united states':
        case 'usa':
            states = usaStates;
            break;
        case 'canada':
            states = canadaStates;
            break;
        default: break;
    }
    return states.includes(input.toLowerCase());
};
