import { AsyncSubject, BehaviorSubject, map } from "rxjs";
import { BaseAPI } from "../base.api";
import * as ufHelpers from "./uf.helpers";
import skinConfiguration from "@/services/config/config";
const { uf } = skinConfiguration.skin.api.endpoints;
const defaultHeaders = new Headers({
    "apiversion": "2",
    "Content-Type": "application/json"
});
class UFAPI extends BaseAPI {
    constructor(config) {
        super(config);
        this.loginDetails = new AsyncSubject();
        this.stages = new BehaviorSubject([]);
        this.currentStage = this.stages.pipe(map(stages => ufHelpers.findCurrentStage(stages)));
        this.ranks = new BehaviorSubject([]);
        this.squad = new BehaviorSubject([]);
        this.packs = new BehaviorSubject([]);
        this.loginDetails.subscribe(login => {
            const sharedHeaders = new Headers({
                "Authorization": `Bearer ${login.token}`
            });
            this.getHeaders = new Headers();
            this.postHeaders = new Headers();
            this.putHeaders = new Headers();
            sharedHeaders.forEach((value, key) => {
                this.getHeaders.append(key, value);
                this.postHeaders.append(key, value);
                this.putHeaders.append(key, value);
            });
            this.defaultHeaders.forEach((value, key) => {
                this.getHeaders.append(key, value);
                this.postHeaders.append(key, value);
                this.putHeaders.append(key, value);
            });
        });
    }
    /**
     * Logs in via the UF API and retrieves details along with a token for subsequent API calls.
     * @param accessToken Access token retrieved when logging in via the Low6 API.
     * @param homeTeamCode The code to be assigned as the home team for the user. This only gets set when a user is created
     */
    async login(accessToken, homeTeamCode) {
        const customHeaders = new Headers();
        this.defaultHeaders.forEach((value, key) => customHeaders.append(key, value));
        customHeaders.append("Authorization", `Bearer ${accessToken}`);
        let ufLogin;
        if (homeTeamCode != '') {
            const data = { homeTeamCode };
            ufLogin = await this.post("/api/login", data, customHeaders);
        }
        else {
            ufLogin = await this.post("/api/login", null, customHeaders);
        }
        this.packs.next(ufLogin.packs);
        this.squad.next(ufLogin.squad);
        this.loginDetails.next(ufLogin);
        this.loginDetails.complete();
        return ufLogin;
    }
    /**
     * Gets contest data for the current seasons.
     */
    async getStages() {
        const stages = await this.get("/api/stages");
        this.stages.next(stages.stages);
    }
    /**
     * Gets user packs.
     * @returns An array of Packs.
     */
    async getPacks() {
        const packsResponse = await this.get("/api/packs");
        this.packs.next(packsResponse.packs);
        return packsResponse.packs;
    }
    /**
     * Informs the server that a new pack has been opened.
     * @param id Pack id.
     */
    async openPack(id) {
        await this.post(`/api/packs/${id}`);
    }
    /**
     * Gets the start and end dates of the next pack drop.
     * @returns Packdrop schedule.
     */
    async getPackdropSchedule() {
        const schedule = await this.get("/api/packdropschedule");
        return schedule;
    }
    /**
     * Gets leaderboard data.
     */
    async getLeaderboard() {
        const leaderboard = await this.get("/api/leaderboard");
        this.ranks.next(leaderboard.ranks);
    }
    /**
     * Get leaderboard details for a stage - this includes the clash as a 'mini-league'
     * @param stageId Stage ID from stage or rank object
     */
    async getLeaderboardForStage(stageId) {
        return await this.get(`/api/leaderboard/${stageId}`);
    }
    /**
     * Get leaderboard details for a user
     * @param stageId Stage ID from stage or rank object
     * @param userId UF User ID
     */
    async getLeaderboardForUser(stageId, userId) {
        return await this.get(`/api/leaderboard/${stageId}/${userId}`);
    }
    /**
     * Fetch the minileagues for a particular stage
     * @param stageId number of the stage to be requested
     * @returns minileagues as an array of arrays (or blank array if none)
     */
    async getMiniLeaguesForStage(stageId) {
        const miniLeagues = await this.get(`/api/league/user/${stageId}`);
        return miniLeagues;
    }
    /**
     * Collect details for a single minileague using it's code
     * @param code Unique code for the minileague
     * @returns Details for the mini league
     */
    async getMiniLeagueDetails(code) {
        const miniLeague = await this.get(`/api/league/user/detail/${code}`);
        return miniLeague;
    }
    /**
     * Used to add the current logged in user to a league
     * @param code string code of league
     * @returns minileagues that the current user is a member of
     */
    async joinUserLeague(code) {
        const miniLeague = await this.post(`/api/league/user/join/${code}`);
        return miniLeague;
    }
    /**
     * Create a new mini-league by providing a name
     * @param name Name of the mini-league
     * @returns minileagues that the current user is a member of
     */
    async createUserLeague(name) {
        const miniLeague = await this.post(`/api/league/user/create/${name}`);
        return miniLeague;
    }
    /**
     * Checks the UF server for API availaibility. At the end of each stage the server is locked whilst scores are processed.
     * @returns Server availability.
     */
    async getServerAvailability() {
        const availability = await this.get("/api/available");
        return availability;
    }
    /**
     * Gets user squad data.
     * @returns An array of teams and players that comprise the user's squad.
     */
    async getSquad() {
        const squad = await this.get("/api/squad");
        this.squad.next(squad.squad);
        return squad.squad;
    }
    /**
     * Checks to see whether the squad lineup is editable.
     * @returns A boolean indicating whether the lineup is editable.
     */
    async checkLineupEditable() {
        const editable = await this.get("/api/lineup/edit");
        return editable.edit;
    }
    /**
     * Updates the lineup.
     * @param items An array of mapped lineup sqaud items.
     */
    async updateLineup(items) {
        await this.put("/api/lineup", { lineup: items });
    }
    /**
     * Gets referral stats
     * @returns Referral stats
     */
    async getReferralStats() {
        return await this.get("/api/referralStats");
    }
    /**
     * Gets fixtures for a specific stage
     * @param stageId The id of the stage
     * @returns The fixures for the specified stage
     */
    async getFixtures(stageId) {
        return await this.get(`/api/fixtures/${stageId}`);
    }
    /**
     * Gets user lineup data for a given stage.
     * @param userId The user id.
     * @param stageId The stage id.
     * @returns User lineup data.
     */
    async getUserLineup(userId, stageId) {
        const lineupResponse = await this.get(`/api/lineup/user/${userId}/${stageId}`);
        return lineupResponse.lineup;
    }
    /**
     * Transfers squad items in and out of the squad.
     * @param transfers Arrays of transfer in/out card IDs.
     * @returns When successful, the updated squad.
     */
    async transfer(transfers) {
        return await this.put("/api/transfer", transfers);
    }
    /**
     * Sets onboard flag to true for the login response.
     */
    async onboard() {
        return await this.put("/api/onboard");
    }
}
export default new UFAPI({
    urls: {
        development: uf.staging,
        production: uf.production
    },
    defaultHeaders
});
