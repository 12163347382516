var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { html, css } from "lit";
import { customElement } from "lit/decorators.js";
let UFOverlay = class UFOverlay extends ScopedElement {
    render() {
        return html `
      <slot></slot>
    `;
    }
};
UFOverlay.styles = [ScopedElement.styles, css `
    :host {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: var(--real100vh, 100vh);
      background-color: var(--background);
      background-image: url("/game-background.jpg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center top;
      z-index: 2;
      overflow-x: hidden;
      overflow-y: auto;
    }
    @media (min-width: 1000px) {
      :host {
        background-size: 80em;
      }
    }
  `];
UFOverlay = __decorate([
    customElement("uf-overlay")
], UFOverlay);
export { UFOverlay };
