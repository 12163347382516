var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { formatAsNth } from "@/views/game/directives/game.directives";
import globalCSS from "~/styles/global.css";
import userLeagueCardCSS from "./user-league-card.css";
let UserLeagueCard = class UserLeagueCard extends LitElement {
    render() {
        return html `
      <div class="card">
        <div class="top">
          <p>${this.userLeagueRank.name}</p>
        </div>
        <div class="bottom">
          <p>${formatAsNth(this.userLeagueRank.ranking)}</p>
          <p>${this.rank.score} pts</p>
          <p>--</p> <!-- User Leagues do not have prizes at the moment -->
        </div>  
      </div>
    `;
    }
};
UserLeagueCard.styles = [globalCSS, userLeagueCardCSS];
__decorate([
    property({ type: Object })
], UserLeagueCard.prototype, "rank", void 0);
__decorate([
    property({ type: Object })
], UserLeagueCard.prototype, "userLeagueRank", void 0);
UserLeagueCard = __decorate([
    customElement("user-league-card")
], UserLeagueCard);
export { UserLeagueCard };
