import { css } from "lit";
const formCSS = css `
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    padding: 1em;
    background-color: var(--color-background);
    border-radius: 1.25em;
  }

  h2 {
    font-size: 1em;
    line-height: 1.5;
  }

  div {
    position: relative;
  }

  div.icon-background {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-primary);
    border-radius: 100%;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  uf-icon[icon=edit] {
    width: 1em;
    height: 1em;
  }

  uf-icon[icon=save] {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
  }

  label {
    font-size: 0.75em;
    line-height: 1.5;
    color: var(--color-soft-text);
  } 

  button {
    column-gap: 0.5em;
  }

  button[disabled] {
    background-color: var(--color-disabled);
  }

`;
export default formCSS;
