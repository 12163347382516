import teamStandard from "./assets/lineup_team_placeholder.svg";
import teamSilver from "./assets/lineup_team_placeholder_silver.svg";
import teamGold from "./assets/lineup_team_placeholder_gold.svg";
import playerStandard from "./assets/headshot_placeholder_standard.svg";
import playerSilver from "./assets/headshot_placeholder_silver.svg";
import playerGold from "./assets/headshot_placeholder_gold.svg";
import roundelStandard from "./assets/UF_roundel_standard.svg";
import roundelSilver from "./assets/UF_roundel_silver.svg";
import roundelGold from "./assets/UF_roundel_gold.svg";
export default {
    team: {
        standard: teamStandard,
        silver: teamSilver,
        gold: teamGold
    },
    player: {
        standard: playerStandard,
        silver: playerSilver,
        gold: playerGold
    },
    roundel: {
        standard: roundelStandard,
        silver: roundelSilver,
        gold: roundelGold
    }
};
