var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import globalCSS from "~/styles/global.css";
import tabBarCSS from "./tab-bar.css";
import router from "@/router/router";
let TabBar = class TabBar extends LitElement {
    constructor() {
        super(...arguments);
        this.items = [];
        this.view = "";
    }
    connectedCallback() {
        super.connectedCallback();
        this.routeChangeSubscription = router.routeChange.subscribe(route => {
            // Ensure that the route matches one of the routes in the segmented controls items
            const path = `/${route.path}`;
            const item = this.items.find((item) => item.route == path);
            if (item) {
                this.view = `/${route.path}`;
            }
        });
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.routeChangeSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.routeChangeSubscription = undefined;
    }
    render() {
        return html `
      <nav>
        <ul>
          ${this.items.map(item => html `
              <li>
                <router-link class=${classMap({
            "active": this.view === item.route
        })} href=${item.route} .data=${item.data} .shouldReplace=${true}>
                  <span>${item.displayName}</span>
                </router-link>
              </li>
            `)}
        </ul>
      </nav>      
    `;
    }
};
TabBar.styles = [globalCSS, tabBarCSS];
__decorate([
    property({ type: Array })
], TabBar.prototype, "items", void 0);
__decorate([
    state()
], TabBar.prototype, "view", void 0);
TabBar = __decorate([
    customElement("tab-bar")
], TabBar);
export { TabBar };
