import { css } from "lit";
const leaderboardCardCSS = css `
  router-link {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  div.card-header {
    position: relative;
    height: 11.2em;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-leaderboard-card);
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
    margin: auto;
    padding: 1em;
  }

  div.card-header p {
    color: var(--color-leaderboard-card-title)
  }

  div.card-header > uf-icon[icon=bars] {
    position: absolute;
    top: 0.5em;
    right: 1em;
    width: 1.5em;
    height: 1.25em;
  }

  div.card-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1em 1.4em;
    column-gap: 1em;
    align-items: center;
    height: 3.3em;
    background-color: var(--color-leaderboard-card);
    border-bottom-left-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    margin: auto;
  }

  p {
    margin: 0;
  }

  div.card-footer p:last-child {
    place-self: center end;
    color: var(--color-soft-text);
  }

  span.has-prize {
    color: var(--color-leaderboard-card-prize);
  }

  div.card-footer p:nth-child(2) {
    place-self: center center;
  }

  div.card-footer p:first-child {
    place-self: center start;
  }

`;
export default leaderboardCardCSS;
