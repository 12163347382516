import { css } from "lit";
const startContainerCSS = css `

  :host {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    --header-bar-height: 5em;
  }
  header {
    width: 100%;
    max-width: 50em;
  }

  header-bar {
    --header-bar-background: transparent;
  }

  uf-icon[icon=chevron] {
    width: 1.4em;
  }

  h1 {
    margin-top: 0.25em;
    font-size: 1.875em;
  }

  uf-icon[icon=help] {
    width: 2em;
  }

  #container {
    padding: 0;
    width: 25em;
    height: calc(var(--real100vh, 100vh) - var(--header-bar-height));
    max-height: 700px;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 1.8em;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 1em;
    }
  }

  @media (max-width: 450px) {
    :host {
      font-size: 3.6vw;
    }
    #container {
      height: 43em;
    }
  }

`;
export default startContainerCSS;
