var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import ConfigurationService from "@/services/config/config";
import globalCSS from "~/styles/global.css";
import formCSS from "@/components/forms/form.css";
import low6Api from "@/services/api/low6/low6.api";
import { deepCopy, returnString } from "@/services/utils/utils";
const componentStyles = css `
  p {
    margin: 0;
    color: var(--color-soft-text);
    font-size: 0.875em;
  }

  a {
    color: var(--color-soft-text);
  }

  section {
    display: flex;
    width: 100%;

    flex-direction:column;
    row-gap: 1em;

  }

  label {
    display: flex;
    align-items: center;
    font-size: 0.875em;
  }

  label input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.25em;
    width: 1.25em;
    background-color: var(--color-disabled);
    margin: 0 1em;
    border-radius: 0.25em;
    cursor: pointer;
  }

  input:checked ~div {
    background-color: var(--color-primary);
  }

  input:checked ~div:after {
    display: block;
    content: "";
  }

  input[type=checkbox] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }

  div:after {
    display: none;
  }

  div:after {
    content: "";
    width: 0.3em;
    height: 0.75em;
    border: 2px solid #F6F3EE;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-1px, -1px);
  }

  a {
    text-decoration: underline;
  }
`;
let ProfileCheckboxes = class ProfileCheckboxes extends LitElement {
    constructor() {
        super(...arguments);
        this.isDirty = false;
    }
    firstUpdated() {
        if (this.fullProfile) {
            this.profileCopy = deepCopy(this.fullProfile);
        }
    }
    render() {
        return html `
      <form>
        <h2>Contact Permissions</h2>
        <p>
          We would like to contact you about exclusive offers, promotions,
          contests and bonuses from Low6 and promotions and offers from our
          commercial partners. If you agree, we will use your personal
          information to identify the products and services which we think will
          be of interest to you.
        </p>
        <p>
          If you are happy to receive these messages, please opt-in to your
          preferred method of contact below:
        </p>
        <section>

          <label for="email">
            <input 
              type='checkbox'
              name="email"
              id="email"
              .checked=${this.setCheckedValues('email')}
              @click=${this.handleClick} 
            />
            <div></div>Email
          </label>

          <label for="sms">
            <input 
              type='checkbox'
              name="sms"
              id="sms"
              .checked=${this.setCheckedValues('sms')}
              @click=${this.handleClick} 
            />
            <div></div>SMS
          </label>

        </section>
        <p>
          You can opt out of promotional messages at any time from your account
          settings or by contacting us at
          <a href="mailto:privacy@low6.co.uk">privacy@low6.co.uk</a>
        </p>
        <p>
          To learn more about how we use your personal information and your
          rights, see our <a href="${ConfigurationService.low6PrivacyPolicyLink()}" target="_blank">privacy notice here</a>. 
        </p>
        <button
          .disabled=${!this.isDirty}
          @click=${this.updateProfile}
        >
          <uf-icon icon='save' theme=${this.isDirty ? 'white' : 'grey'}></uf-icon>
          Save Changes
        </button>
      </form>
    `;
    }
    async updateProfile(e) {
        e.preventDefault();
        if (this.fullProfile) {
            const updatedProfile = this.convertToUpdateShape(this.fullProfile);
            const updateResponse = await low6Api.updateProfile(updatedProfile);
            if (updateResponse) {
                this.fullProfile = updateResponse;
                this.isDirty = false;
            }
        }
    }
    convertToUpdateShape(user) {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            email: user.email,
            mobileNumber: user.mobileNumber,
            /* ISO String (without time) */
            birthDate: user.birthDate,
            address: {
                city: returnString(user.address.city),
                county: returnString(user.address.county),
                country: returnString(user.address.country),
                line1: returnString(user.address.line1),
                line2: returnString(user.address.line2),
                postcode: returnString(user.address.postcode)
            },
            termsConditionsAccepted: user.termsConditionsAccepted,
            contactPermissions: user.contactPermissions
        };
    }
    handleClick(e) {
        const target = e.target;
        if (this.fullProfile) {
            switch (target.name) {
                case 'sms':
                    this.fullProfile.contactPermissions.smsNotificationsAccepted = !this.fullProfile.contactPermissions.smsNotificationsAccepted;
                    break;
                case 'email':
                    this.fullProfile.contactPermissions.emailNotificationsAccepted = !this.fullProfile.contactPermissions.emailNotificationsAccepted;
                    break;
                default:
                    break;
            }
        }
        this.isDirty = this.checkForDirty();
    }
    checkForDirty() {
        var _a, _b;
        if (JSON.stringify((_a = this.fullProfile) === null || _a === void 0 ? void 0 : _a.contactPermissions) !== JSON.stringify((_b = this.profileCopy) === null || _b === void 0 ? void 0 : _b.contactPermissions)) {
            return true;
        }
        else {
            return false;
        }
    }
    setCheckedValues(type) {
        if (this.fullProfile) {
            switch (type) {
                case 'sms':
                    return this.fullProfile.contactPermissions.smsNotificationsAccepted ? this.fullProfile.contactPermissions.smsNotificationsAccepted : false;
                case 'email':
                    return this.fullProfile.contactPermissions.emailNotificationsAccepted ? this.fullProfile.contactPermissions.emailNotificationsAccepted : false;
                default:
                    return false;
            }
        }
        else {
            return false;
        }
    }
};
ProfileCheckboxes.styles = [globalCSS, formCSS, componentStyles];
__decorate([
    state()
], ProfileCheckboxes.prototype, "isDirty", void 0);
ProfileCheckboxes = __decorate([
    customElement("profile-checkboxes")
], ProfileCheckboxes);
export { ProfileCheckboxes };
