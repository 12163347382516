import { BehaviorSubject } from "rxjs";
export const toast = new BehaviorSubject({
    open: false,
    text: ""
});
export function showToast(text) {
    setTimeout(() => {
        toast.next({ open: true, text });
        setTimeout(() => {
            toast.next({ open: false, text });
        }, 3000);
    }, 500);
}
