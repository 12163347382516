import config from "@/services/config/config";
import gold from "./images/PACK-GOLD.gif";
import silver from "./images/PACK-SILVER.gif";
import regularUF from "./images/PACK-BASE-GREY-3cards.gif";
import regularChargers from "./images/Pack-Chargers-Weekly-Compressed.gif";
import regularBengals from "./images/Pack-Bengals-Weekly-Compressed.gif";
import team from "./images/PACK-POSITION-TEAM.gif";
import defender from "./images/PACK-POSITION-DEF.gif";
import midfielder from "./images/PACK-POSITION-MID.gif";
import forward from "./images/PACK-POSITION-FWD.gif";
import baseUF from "./images/PACK-BASE-GREY-10cards.gif";
import baseChargers from "./images/Pack-Chargers-Squad-Compressed.gif";
import baseBengals from "./images/Pack-Bengals-Squad-15cards-Compressed.gif";
import clash from "./images/PACK-UF-CLASH.gif";
import referralUF from "./images/PACK-REFERRAL.gif";
import referralChargers from "./images/Pack-Chargers-Referral-Compressed.gif";
import referralBengals from "./images/Pack-Bengals-Referral-Compressed.gif";
import streakUF from "./images/PACK-LOGIN-STREAK.gif";
import streakChargers from "./images/Pack-Chargers-LoginStreak-Compressed.gif";
import streakBengals from "./images/Pack-Bengals-LoginStreak-Compressed.gif";
let regular = regularUF;
if (config.isChargers)
    regular = regularChargers;
if (config.isBengals)
    regular = regularBengals;
let base = baseUF;
if (config.isChargers)
    base = baseChargers;
if (config.isBengals)
    base = baseBengals;
let referral = referralUF;
if (config.isChargers)
    referral = referralChargers;
if (config.isBengals)
    referral = referralBengals;
let streak = streakUF;
if (config.isChargers)
    streak = streakChargers;
if (config.isBengals)
    streak = streakBengals;
export const packAnimations = new Map([
    // Purchased
    ["g1", gold],
    ["s1", silver],
    ["r1", regular],
    ["t1", team],
    ["d1", defender],
    ["m1", midfielder],
    ["f1", forward],
    // Given
    ["0", base],
    ["1", regular],
    ["4", clash],
    ["5", referral],
    ["6", streak],
    ["7", streak],
    ["8", streak],
    ["9", streak]
]);
