var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { customElement, state } from "lit/decorators.js";
import "./router-link";
import router, { routes } from "../router";
import { outlets, sliceOutlets } from "../outlets";
let RouterOutlet = class RouterOutlet extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.render = () => this.view;
    }
    connectedCallback() {
        super.connectedCallback();
        this.outletIndex = outlets.push({ element: this }) - 1;
        this.routeChange = router.routeChange.subscribe(route => {
            this.view = this.getView(route.path);
        });
    }
    getView(path) {
        var _a;
        let routePathsFromURL = path.split("/");
        let currentRoutesNode = routes;
        let routeFound;
        for (let i = 0; i <= this.outletIndex; i++) {
            let routePathIndex = i;
            if (this.outletIndex > 0) {
                const parentOutlets = outlets.slice(0, i);
                let parentOutletDynamicRouteValuesLength = 0;
                parentOutlets.forEach(outlet => {
                    var _a;
                    if ((_a = outlet.dynamicRoutes) === null || _a === void 0 ? void 0 : _a.length) {
                        outlet.dynamicRoutes.forEach(route => {
                            if (route.value)
                                parentOutletDynamicRouteValuesLength++;
                        });
                    }
                });
                if (parentOutletDynamicRouteValuesLength) {
                    routePathIndex = i + parentOutletDynamicRouteValuesLength;
                }
            }
            let routePathFromURL = routePathsFromURL[routePathIndex];
            let routePathFromRoute;
            routeFound = currentRoutesNode === null || currentRoutesNode === void 0 ? void 0 : currentRoutesNode.find(route => {
                routePathFromRoute = route.path.split("/")[0];
                if (routePathFromURL)
                    return routePathFromRoute === routePathFromURL;
                return route.path === "";
            });
            if (routeFound && i === this.outletIndex) {
                const dynamicRouteIdentifiers = routeFound.path.split("/").slice(1).map(identifier => identifier.split(":")[1]);
                if (dynamicRouteIdentifiers.length) {
                    outlets[i].dynamicRoutes = dynamicRouteIdentifiers.map(identifier => ({ identifier }));
                    const nextRoutePathsFromURL = routePathsFromURL.slice(routePathIndex + 1);
                    let dynamicRouteValuesProvided;
                    if ((_a = routeFound.children) === null || _a === void 0 ? void 0 : _a.length) {
                        if (!nextRoutePathsFromURL.length) {
                            dynamicRouteValuesProvided = false;
                        }
                        else {
                            const nextPathFoundInChild = routeFound.children.some(childRoute => nextRoutePathsFromURL.some(path => childRoute.path.split("/")[0] === path));
                            if (nextPathFoundInChild && nextRoutePathsFromURL.length === 1) {
                                dynamicRouteValuesProvided = false;
                            }
                            else {
                                dynamicRouteValuesProvided = true;
                            }
                        }
                    }
                    else {
                        if (path.endsWith(routePathFromURL) || path.endsWith(`${routePathFromURL}/`)) {
                            dynamicRouteValuesProvided = false;
                        }
                        else {
                            dynamicRouteValuesProvided = true;
                        }
                    }
                    if (dynamicRouteValuesProvided) {
                        nextRoutePathsFromURL.forEach((nextPath, j) => {
                            var _a;
                            if (!((_a = routeFound.children) === null || _a === void 0 ? void 0 : _a.some(childRoute => childRoute.path.split("/")[0] === nextPath))) {
                                outlets[i].dynamicRoutes[j].value = nextPath;
                            }
                        });
                    }
                }
            }
            currentRoutesNode = routeFound === null || routeFound === void 0 ? void 0 : routeFound.children;
        }
        if (routeFound) {
            router.sendDynamicRoutesNotification();
        }
        return routeFound === null || routeFound === void 0 ? void 0 : routeFound.view;
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.routeChange) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.routeChange = undefined;
        sliceOutlets(this.outletIndex);
        router.sendDynamicRoutesNotification();
    }
};
__decorate([
    state()
], RouterOutlet.prototype, "view", void 0);
RouterOutlet = __decorate([
    customElement("router-outlet")
], RouterOutlet);
export { RouterOutlet };
