var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconRewardLozenge = class IconRewardLozenge extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg width="55" height="36" viewBox="0 0 55 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="${this.theme}" fill-rule="evenodd" clip-rule="evenodd" d="M27.5 0.0333862C27.2309 0.0333862 27.0424 0.319093 26.6653 0.890508L24.7785 3.75002H10C5.28595 3.75002 2.92893 3.75002 1.46447 5.21448C0 6.67895 0 9.03597 0 13.75V25.375C0 30.0891 0 32.4461 1.46447 33.9106C2.92893 35.375 5.28595 35.375 10 35.375H45C49.714 35.375 52.0711 35.375 53.5355 33.9106C55 32.4461 55 30.0891 55 25.375V13.75C55 9.03597 55 6.67895 53.5355 5.21448C52.0711 3.75002 49.714 3.75002 45 3.75002H30.2215L28.3347 0.890508C27.9576 0.319094 27.7691 0.0333862 27.5 0.0333862Z"/>
      </svg>
    `;
    }
};
IconRewardLozenge.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }

    path.white {
      fill: var(--color)
    }

    path.grey {
      fill: #504c42c1
    }

    path.orange {
      fill: #FB4F14
    }

    path.yellow {
      fill: #FFC20E
    }

    path.red {
      fill: #7F1E31
    }

  `];
__decorate([
    property()
], IconRewardLozenge.prototype, "theme", void 0);
IconRewardLozenge = __decorate([
    customElement("icon-reward-lozenge")
], IconRewardLozenge);
export { IconRewardLozenge };
