import { LitElement } from "lit";
/**
 * Provides method to apply custom CSS variables.
 */
export default class SkinnableElement extends LitElement {
    constructor(styles) {
        super();
        this.styles = styles;
        this.stylesSet = false;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.stylesSet && this.styles) {
            this.applySkinStyles(this.styles);
            this.stylesSet = true;
        }
    }
    applySkinStyles(styles) {
        styles.forEach(style => this.style.setProperty(`--${style.name}`, style.value));
    }
}
