var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import ConfigurationService from '@/services/config/config';
import formCSS from '@/components/forms/form.css';
import globalCSS from '~/styles/global.css';
const componentStyles = [css `
    article {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }

    a {
        text-align: center;
        padding: 0;
        border-radius: 0.5em;
        background-color: var(--color-primary);
        color: var(--color);
        font-size: 1em;
        width: 100%;
        padding: 0.75em 0;
    }

    a:last-child {
        grid-column-start: span 2;
    }

    a.do-not-sell-my-data {
        font-size: 0.75em;
        padding: 1.25em 0;
    }
`];
let ProfileHelp = class ProfileHelp extends LitElement {
    render() {
        return html `
        <form>
            <h2>Help Centre</h2>
            <article>
                <a 
                    href="${ConfigurationService.howToPlayLink()}"
                    target="_blank"
                >
                    How To Play
                </a>
                <a
                    href="${ConfigurationService.gameRulesLink()}"
                    target="_blank"
                >
                    Game Rules
                </a>
                <a
                    href="${ConfigurationService.faqsLink()}"
                    target="_blank"
                >
                    Help
                </a>
                <a
                    href="${ConfigurationService.aboutLink()}"
                    target="_blank"
                >
                    About Us
                </a>
                <a
                    href="${ConfigurationService.contactLink()}"
                    target="_blank"
                >
                    Contact Us
                </a>
                <a
                    href="${ConfigurationService.termsAndConditionsLink()}"
                    target="_blank"
                >
                    EULA
                </a>
                <a
                    href="${ConfigurationService.low6PrivacyPolicyLink()}"
                    target="_blank"
                >
                    Low6 Privacy Policy
                </a>
            </article>

            ${when(ConfigurationService.homeTeamPrivacyPolicyLink(), () => html `
                <article>
                    <a
                        href="${ConfigurationService.homeTeamPrivacyPolicyLink()}"
                        target="_blank"
                    >
                    ${ConfigurationService.appNameShort()} Privacy Policy
                    </a>
                </article>
                `)}

            ${when(ConfigurationService.doNotSellMyDataLink(), () => html `
                <article>
                    <a
                        class="do-not-sell-my-data"
                        href="${ConfigurationService.doNotSellMyDataLink()}"
                        target="_blank"
                    >
                    Do Not Sell My Personal Information
                    </a>  
                </article>
                `)}
        </form>
    `;
    }
};
ProfileHelp.styles = [globalCSS, formCSS, componentStyles];
ProfileHelp = __decorate([
    customElement("profile-help")
], ProfileHelp);
export { ProfileHelp };
