var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let Icon18 = class Icon18 extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = "default";
    }
    render() {
        return html `
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4133 15.3L7.57031 16.686L8.38931 19.563L10.9933 18.849V30H14.3533V15.3H11.4133Z" fill="#F6F3EE"/>
        <path d="M25.343 22.335C26.204 21.663 26.75 20.697 26.75 19.374C26.75 16.518 24.566 15.006 21.857 15.006C19.148 15.006 16.964 16.518 16.964 19.374C16.964 20.697 17.51 21.663 18.371 22.335C17.132 23.133 16.46 24.414 16.46 25.863C16.46 28.551 18.392 30.294 21.857 30.294C25.322 30.294 27.254 28.551 27.254 25.863C27.254 24.414 26.582 23.133 25.343 22.335ZM21.857 18.156C22.802 18.156 23.39 18.765 23.39 19.584C23.39 20.403 22.802 21.012 21.857 21.012C20.912 21.012 20.324 20.403 20.324 19.584C20.324 18.765 20.912 18.156 21.857 18.156ZM21.857 27.144C20.681 27.144 19.82 26.493 19.82 25.38C19.82 24.267 20.681 23.616 21.857 23.616C23.033 23.616 23.894 24.267 23.894 25.38C23.894 26.493 23.033 27.144 21.857 27.144Z" fill="#F6F3EE"/>
        <path d="M38.5986 21.18H35.2386V17.82H32.2986V21.18H28.9386V24.12H32.2986V27.48H35.2386V24.12H38.5986V21.18Z" fill="#F6F3EE"/>
        <circle class="${this.theme}" cx="23" cy="23" r="21.5" stroke="#FF284F" stroke-width="3"/>
      </svg>    
    `;
    }
};
Icon18.styles = [iconCSS, css `
    circle.default {
      stroke: "#FF284F"; 
    }
    circle.white {
      stroke: "#F6F3EE"; 
    }
    circle.black {
      stroke: "#000000"; 
    }
  `];
__decorate([
    property()
], Icon18.prototype, "theme", void 0);
Icon18 = __decorate([
    customElement("icon-18")
], Icon18);
export { Icon18 };
