var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { ApiError } from "@/services/error/error";
import router from "@/router/router";
import low6Api from "@/services/api/low6/low6.api";
import ufApi from "@/services/api/uf/uf.api";
import ConfigurationService from "@/services/config/config";
import "./steps/signup-step1";
import "./steps/signup-step2";
import "./steps/signup-step3";
import "./steps/signup-step4";
import "@/components/start-container/start-container";
import { isStep1Valid, isStep2Valid, isStep3Valid, isStep4Valid, } from "./signup.helper";
import globalCSS from "~/styles/global.css";
import signupCSS from "./signup.view.css";
import { formatDate } from "@/services/utils/utils";
import { FormController } from "@/components/forms/form-controller";
import { appContainer } from "@/services/config/constants";
import { initialValues, initialErrors } from "./signup.config";
let SignupView = class SignupView extends LitElement {
    constructor() {
        super(...arguments);
        this.form = new FormController(this, initialValues, initialErrors);
        this.stepNumber = 1;
        this.serverErrors = {};
        this.isSubmitting = false;
        this.values = initialValues;
        this.errors = initialErrors;
        this.setStepNumber = (newStep) => {
            this.stepNumber = newStep;
        };
        this.onChange = (e, valueThatHasToMatch) => {
            this.form.handleChange(e, valueThatHasToMatch);
        };
        this.handleSelectAll = (e) => {
            const { checked } = e.target;
            this.form.values.agreedTerms = checked;
            this.form.values.agreedPrivacy = checked;
            this.form.values.agreedSMS = checked;
            this.form.values.agreedEmail = checked;
            this.requestUpdate();
        };
    }
    connectedCallback() {
        super.connectedCallback();
        if (ConfigurationService.isUF)
            appContainer.id = "intro";
        else
            appContainer.id = "intro-nfl";
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        appContainer.id = "";
    }
    async handleSubmit() {
        const { values } = this;
        this.isSubmitting = true;
        this.serverErrors = null;
        return new Promise(async (resolve) => {
            var _a, _b;
            const address = {
                line1: values.line_1.trim(),
                line2: values.line_2.trim(),
                city: values.city.trim(),
                postcode: values.postcode.trim().replace(/(-\d+$)|(\s)/g, ""),
                county: values.county.trim(),
                country: values.country.trim()
            };
            const contactPermissions = {
                emailNotificationsAccepted: (_a = values.agreedEmail) !== null && _a !== void 0 ? _a : (values.agreedEmail = false),
                smsNotificationsAccepted: (_b = values.agreedSMS) !== null && _b !== void 0 ? _b : (values.agreedSMS = false),
                inAppNotificationsAccepted: false
            };
            const signupData = {
                address: address,
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                username: values.username.trim(),
                email: values.email.trim(),
                mobileNumber: values.mobileNumber.trim(),
                password: values.password,
                pin: values.pin,
                birthDate: formatDate(values.birthDate, ConfigurationService.datePattern().join(",")),
                termsConditionsAccepted: values.agreedTerms,
                email_notifications_accepted: values.agreedEmail,
                contactPermissions: contactPermissions
            };
            if (ConfigurationService.useMinRequirementsAPI()) {
                signupData.state = values.county;
            }
            try {
                ufApi.loginDetails.subscribe(() => {
                    this.isSubmitting = false;
                    router.navigate("lobby");
                });
                if (ConfigurationService.useMinRequirementsAPI()) {
                    await low6Api.signupNonUK(signupData);
                }
                else {
                    await low6Api.signup(signupData);
                }
                location.reload();
            }
            catch (error) {
                this.isSubmitting = false;
                if (error instanceof ApiError) {
                    this.serverErrors = Object.assign(Object.assign({}, error.data.errors), { message: error.data.message });
                }
                else if (error instanceof Error) {
                    this.serverErrors = { message: error.message };
                }
                else {
                    this.serverErrors = { message: "Unknown Error" };
                }
            }
            resolve();
        });
    }
    /*private updateSelection(checked: boolean) {
      this.values.agreedTerms = checked;
      this.values.agreedPrivacy = checked;
      this.values.agreedSMS = checked;
      this.values.agreedEmail = checked;
      
      this.form.setValues({
        ...this.values,
        agreedTerms: checked,
        agreedPrivacy: checked,
        agreedSMS: checked,
        agreedEmail: checked,
      });
      this.requestUpdate();
    }*/
    render() {
        const { form } = this;
        const { values, errors, serverErrors } = form;
        return html `
      <start-container heading="Sign Up">
        <form>
          <div id="progress"><div style="width: ${(this.stepNumber / 4) * 100}%"></div></div>
          ${this.stepNumber === 1
            ? html `<signup-step1
                .values=${values}
                .errors=${errors}
                .handleChange=${this.onChange}
                .setStepNumber=${this.setStepNumber}
                .isStepValid=${isStep1Valid(values, errors)}
              >
              </signup-step1>`
            : null}
          ${this.stepNumber === 2
            ? html `<signup-step2
                .setStepNumber=${this.setStepNumber}
                .values=${values}
                .errors=${errors}
                .handleChange=${this.onChange}
                .isStepValid=${isStep2Valid(values, errors, ConfigurationService.useMinRequirementsAPI())}
              >
              </signup-step2>`
            : null}
          ${this.stepNumber === 3
            ? html `<signup-step3
                .setStepNumber=${this.setStepNumber}
                .values=${values}
                .errors=${errors}
                .handleChange=${this.onChange}
                .isStepValid=${isStep3Valid(values, errors, ConfigurationService.useMinRequirementsAPI())}
              >
              </signup-step3>`
            : null}
          ${this.stepNumber === 4
            ? html `<signup-step4
                .handleSubmit=${this.handleSubmit}
                .setStepNumber=${this.setStepNumber}
                .values=${values}
                .errors=${errors}
                .handleChange=${this.onChange}
                .handleSelectAll=${this.handleSelectAll}
                .isStepValid=${isStep4Valid(values, serverErrors)}
                .isSubmitting=${this.isSubmitting}
              >
              </signup-step4>`
            : null}

          ${when(this.isSubmitting, () => html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            `)}
        </form>
      </start-container>
    `;
    }
};
SignupView.styles = [globalCSS, signupCSS];
__decorate([
    state()
], SignupView.prototype, "stepNumber", void 0);
__decorate([
    state()
], SignupView.prototype, "serverErrors", void 0);
__decorate([
    state()
], SignupView.prototype, "isSubmitting", void 0);
__decorate([
    state()
], SignupView.prototype, "values", void 0);
__decorate([
    state()
], SignupView.prototype, "errors", void 0);
SignupView = __decorate([
    customElement("signup-view")
], SignupView);
export { SignupView };
