import { css } from "lit";
const introCSS = css `

  :host {
    display: block;
    padding-top: 5em;
  }

  #age-rating{
    position: absolute;
    width: 2.875em;
    height: 2.875em;
    right: 2.44em;
  }

  main {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2em;
  }

  main > * {
    flex-shrink: 0;
  }

  header {
    display: flex;
    justify-content: center;
  }

  uf-swiper {
    width: 100vw;
    height: 21em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  
  uf-swiper::part(swiper) {
    padding-bottom: 2.25em;
  }

  #buttons {
    display: flex;
    padding: 0 1.5em;
    justify-content: center;
    column-gap: 1.5em;
  }
  #buttons button {
    padding-left: 0;
    padding-right: 0;
  }
  #buttons button span {
    font-size: 1.125em;
  }
  #buttons router-link {
    width: 11.5em;
  }
  #buttons router-link button {
    width: 100%;
  }

  footer {
    margin-top: 1.5em;
  }

  @media (max-height: 800px) {
    :host {
      padding-top: 2em;
    }
  }

  @media (max-height: 750px) {
    uf-swiper {
      margin: 1em 0;
    }
  }

  @media (max-height: 695px) and (min-width: 450px) {
    uf-swiper {
      display: none;
    }
    main {
      justify-content: space-evenly
    }
  }

  @media (max-height: 500px) and (min-width: 450px) {
    header {
      margin-bottom: 1.5em;
    }
  }

  @media (max-height: 400px) and (min-width: 450px) {
    main {
      justify-content: flex-start;
    }
  }

  @media (max-height: 350px) and (min-width: 450px) {
    main {
      height: auto;
    }
  }
  
`;
export default introCSS;
