var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
let HowtowinSVG = class HowtowinSVG extends ScopedElement {
    render() {
        return html `
      <svg viewBox="0 0 343 497" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5041 11.5H14.5201L11.9761 7.14805C13.2881 6.62005 14.2001 5.34005 14.2001 3.90005C14.2001 1.90005 12.6001 0.300049 10.6001 0.300049H6.12012V11.5H7.96012V7.42005H10.1361L12.5041 11.5ZM7.96012 2.02805H10.6001C11.5761 2.02805 12.3601 2.84405 12.3601 3.90005C12.3601 4.94005 11.5761 5.77205 10.6001 5.77205H7.96012V2.02805Z"
          fill="#F6F3EE" />
        <path
          d="M21.7347 3.50005V4.63605C21.1267 3.80405 20.1827 3.29205 18.9347 3.29205C16.7587 3.29205 14.9507 5.11605 14.9507 7.50005C14.9507 9.86805 16.7587 11.708 18.9347 11.708C20.1827 11.708 21.1267 11.196 21.7347 10.348V11.5H23.4627V3.50005H21.7347ZM19.2067 10.06C17.7667 10.06 16.6787 8.98805 16.6787 7.50005C16.6787 6.01205 17.7667 4.94005 19.2067 4.94005C20.6467 4.94005 21.7347 6.01205 21.7347 7.50005C21.7347 8.98805 20.6467 10.06 19.2067 10.06Z"
          fill="#F6F3EE" />
        <path
          d="M29.6849 3.29205C28.5969 3.29205 27.7329 3.69205 27.2049 4.52405V3.50005H25.4769V11.5H27.2049V7.21205C27.2049 5.58005 28.1009 4.90805 29.2369 4.90805C30.3249 4.90805 31.0289 5.54805 31.0289 6.76405V11.5H32.7569V6.58805C32.7569 4.50805 31.4769 3.29205 29.6849 3.29205Z"
          fill="#F6F3EE" />
        <path
          d="M41.6895 11.5L38.0415 7.42005L41.5615 3.50005H39.4495L36.3455 7.03605V0.300049H34.6175V11.5H36.3455V7.80405L39.6255 11.5H41.6895Z"
          fill="#F6F3EE" />
        <rect y="27.5" width="343" height="45" rx="15" fill="#292929" />
        <path d="M20.152 44.3L17.208 45.34L17.656 46.908L19.912 46.268V55.5H21.752V44.3H20.152Z" fill="#F6F3EE" />
        <path
          d="M306.391 48.876L308.663 45.868V44.3H301.783V46.028H306.471L304.055 49.212L304.791 50.316H305.207C306.471 50.316 307.287 50.988 307.287 52.108C307.287 53.228 306.471 53.916 305.207 53.916C304.087 53.916 303.335 53.42 303.015 52.476L301.447 53.388C302.071 54.956 303.559 55.708 305.207 55.708C307.271 55.708 309.127 54.46 309.127 52.108C309.127 50.364 307.927 49.244 306.391 48.876Z"
          fill="#F6F3EE" />
        <path
          d="M310.106 55.5H317.482V53.724H312.842L315.37 51.196C316.442 50.108 317.274 48.94 317.274 47.548C317.274 45.324 315.53 44.092 313.69 44.092C312.202 44.092 310.794 44.828 310.074 46.364L311.626 47.276C312.058 46.38 312.778 45.884 313.706 45.884C314.666 45.884 315.434 46.492 315.434 47.612C315.434 48.46 314.81 49.276 314.01 50.076L310.106 54.06V55.5Z"
          fill="#F6F3EE" />
        <path
          d="M322.962 55.708C325.778 55.708 327.362 53.356 327.362 49.9C327.362 46.444 325.778 44.092 322.962 44.092C320.146 44.092 318.562 46.444 318.562 49.9C318.562 53.356 320.146 55.708 322.962 55.708ZM322.962 53.916C321.314 53.916 320.402 52.476 320.402 49.9C320.402 47.324 321.314 45.884 322.962 45.884C324.626 45.884 325.522 47.324 325.522 49.9C325.522 52.476 324.626 53.916 322.962 53.916Z"
          fill="#F6F3EE" />
        <path
          d="M114.72 47.5001V48.7041C114.16 47.8501 113.264 47.3181 112.074 47.3181C110.128 47.3181 108.532 48.9281 108.532 51.0001C108.532 53.0721 110.128 54.6821 112.074 54.6821C113.264 54.6821 114.16 54.1501 114.72 53.2961V54.5001H115.938V47.5001H114.72ZM112.228 53.5061C110.828 53.5061 109.75 52.4281 109.75 51.0001C109.75 49.5721 110.828 48.4941 112.228 48.4941C113.642 48.4941 114.72 49.5721 114.72 51.0001C114.72 52.4281 113.642 53.5061 112.228 53.5061Z"
          fill="#F6F3EE" />
        <path
          d="M126.03 47.5001L124.49 52.8341L122.838 47.5001H121.662L120.01 52.8201L118.47 47.5001H117.196L119.366 54.5001H120.612L122.25 49.3201L123.888 54.5001H125.134L127.304 47.5001H126.03Z"
          fill="#F6F3EE" />
        <path
          d="M128.137 54.5001H134.465V53.2401H130.027L132.533 50.7481C133.499 49.7821 134.283 48.7321 134.283 47.5141C134.283 45.5821 132.757 44.5181 131.231 44.5181C129.957 44.5181 128.767 45.1481 128.137 46.4501L129.229 47.0941C129.649 46.2121 130.349 45.7781 131.245 45.7781C132.155 45.7781 132.981 46.3801 132.981 47.5281C132.981 48.3541 132.365 49.1381 131.595 49.9081L128.137 53.4361V54.5001Z"
          fill="#F6F3EE" />
        <path
          d="M138.686 48.5221H137.258L137.426 45.9181H141.612V44.7001H136.25L135.886 49.7821H138.658C139.82 49.7821 140.702 50.4261 140.702 51.6021C140.702 52.7641 139.82 53.4081 138.63 53.4081C137.566 53.4081 136.768 52.9181 136.502 51.9521L135.396 52.5821C135.872 53.9821 137.188 54.6821 138.63 54.6821C140.394 54.6821 141.99 53.5901 141.99 51.6021C141.99 49.6001 140.436 48.5221 138.686 48.5221Z"
          fill="#F6F3EE" />
        <path
          d="M79 34.5C70.168 34.5 63 41.668 63 50.5C63 59.332 70.168 66.5 79 66.5C87.832 66.5 95 59.332 95 50.5C95 41.668 87.832 34.5 79 34.5ZM79 39.3C81.656 39.3 83.8 41.444 83.8 44.1C83.8 46.756 81.656 48.9 79 48.9C76.344 48.9 74.2 46.756 74.2 44.1C74.2 41.444 76.344 39.3 79 39.3ZM79 62.02C75 62.02 71.464 59.972 69.4 56.868C69.448 53.684 75.8 51.94 79 51.94C82.184 51.94 88.552 53.684 88.6 56.868C86.536 59.972 83 62.02 79 62.02Z"
          fill="#F6F3EE" />
        <rect y="80.5" width="343" height="45" rx="15" fill="#292929" />
        <path
          d="M17.4959 108.5H24.8719V106.724H20.2319L22.7599 104.196C23.8319 103.108 24.6639 101.94 24.6639 100.548C24.6639 98.324 22.9199 97.092 21.0799 97.092C19.5919 97.092 18.1839 97.828 17.4639 99.364L19.0159 100.276C19.4479 99.38 20.1679 98.884 21.0959 98.884C22.0559 98.884 22.8239 99.492 22.8239 100.612C22.8239 101.46 22.1999 102.276 21.3999 103.076L17.4959 107.06V108.5Z"
          fill="#F6F3EE" />
        <path
          d="M310.266 101.876L312.538 98.868V97.3H305.658V99.028H310.346L307.93 102.212L308.666 103.316H309.082C310.346 103.316 311.162 103.988 311.162 105.108C311.162 106.228 310.346 106.916 309.082 106.916C307.962 106.916 307.21 106.42 306.89 105.476L305.322 106.388C305.946 107.956 307.434 108.708 309.082 108.708C311.146 108.708 313.002 107.46 313.002 105.108C313.002 103.364 311.802 102.244 310.266 101.876Z"
          fill="#F6F3EE" />
        <path d="M316.481 97.3L313.537 98.34L313.985 99.908L316.241 99.268V108.5H318.081V97.3H316.481Z" fill="#F6F3EE" />
        <path
          d="M323.682 101.524H322.274L322.466 99.028H327.01V97.3H320.77L320.338 103.316H323.602C324.818 103.316 325.65 103.988 325.65 105.108C325.65 106.228 324.818 106.916 323.57 106.916C322.402 106.916 321.634 106.388 321.346 105.396L319.762 106.308C320.354 107.924 321.874 108.708 323.57 108.708C325.634 108.708 327.49 107.46 327.49 105.108C327.49 102.772 325.714 101.524 323.682 101.524Z"
          fill="#F6F3EE" />
        <path
          d="M109.848 99.2259C110.296 99.2259 110.66 98.8619 110.66 98.4139C110.66 97.9659 110.296 97.5879 109.848 97.5879C109.386 97.5879 109.022 97.9659 109.022 98.4139C109.022 98.8619 109.386 99.2259 109.848 99.2259ZM109.232 107.92C109.232 109.012 108.756 109.39 107.748 109.306V110.482C109.512 110.608 110.45 109.726 110.45 107.92V100.5H109.232V107.92Z"
          fill="#F6F3EE" />
        <path
          d="M113.071 107.654C113.589 107.654 113.981 107.248 113.981 106.744C113.981 106.24 113.589 105.834 113.071 105.834C112.567 105.834 112.161 106.24 112.161 106.744C112.161 107.248 112.567 107.654 113.071 107.654Z"
          fill="#F6F3EE" />
        <path
          d="M118.679 101.676V100.5H116.817V98.5399L115.599 98.9039V100.5H114.213V101.676H115.599V105.47C115.599 107.22 116.579 107.78 118.679 107.5V106.408C117.433 106.464 116.817 106.534 116.817 105.47V101.676H118.679Z"
          fill="#F6F3EE" />
        <path
          d="M124.992 100.5V104.21C124.992 105.862 124.082 106.52 122.962 106.52C121.884 106.52 121.226 105.876 121.226 104.728V100.5H120.008V104.798C120.008 106.59 121.086 107.682 122.724 107.682C123.704 107.682 124.474 107.318 124.992 106.492V107.5H126.21V100.5H124.992Z"
          fill="#F6F3EE" />
        <path
          d="M129.283 101.676V100.5H128.065V107.5H129.283V103.79C129.283 102.138 130.417 101.606 131.467 101.648V100.374C130.585 100.374 129.731 100.696 129.283 101.676Z"
          fill="#F6F3EE" />
        <path
          d="M138.58 107.5L135.122 103.888L138.468 100.5H136.9L133.876 103.594V97.6999H132.658V107.5H133.876V104.182L137.068 107.5H138.58Z"
          fill="#F6F3EE" />
        <path
          d="M79 86.5C70.168 86.5 63 93.668 63 102.5C63 111.332 70.168 118.5 79 118.5C87.832 118.5 95 111.332 95 102.5C95 93.668 87.832 86.5 79 86.5ZM79 91.3C81.656 91.3 83.8 93.444 83.8 96.1C83.8 98.756 81.656 100.9 79 100.9C76.344 100.9 74.2 98.756 74.2 96.1C74.2 93.444 76.344 91.3 79 91.3ZM79 114.02C75 114.02 71.464 111.972 69.4 108.868C69.448 105.684 75.8 103.94 79 103.94C82.184 103.94 88.552 105.684 88.6 108.868C86.536 111.972 83 114.02 79 114.02Z"
          fill="#F6F3EE" />
        <rect id="highlight" y="133.5" width="343" height="45" rx="15" />
        <path
          d="M22.3444 154.876L24.6164 151.868V150.3H17.7364V152.028H22.4244L20.0084 155.212L20.7444 156.316H21.1604C22.4244 156.316 23.2404 156.988 23.2404 158.108C23.2404 159.228 22.4244 159.916 21.1604 159.916C20.0404 159.916 19.2884 159.42 18.9684 158.476L17.4004 159.388C18.0244 160.956 19.5124 161.708 21.1604 161.708C23.2244 161.708 25.0804 160.46 25.0804 158.108C25.0804 156.364 23.8804 155.244 22.3444 154.876Z"
          fill="#F6F3EE" />
        <path
          d="M308.907 154.876L311.179 151.868V150.3H304.299V152.028H308.987L306.571 155.212L307.307 156.316H307.723C308.987 156.316 309.803 156.988 309.803 158.108C309.803 159.228 308.987 159.916 307.723 159.916C306.603 159.916 305.851 159.42 305.531 158.476L303.963 159.388C304.587 160.956 306.075 161.708 307.723 161.708C309.787 161.708 311.643 160.46 311.643 158.108C311.643 156.364 310.443 155.244 308.907 154.876Z"
          fill="#F6F3EE" />
        <path d="M315.121 150.3L312.177 151.34L312.625 152.908L314.881 152.268V161.5H316.721V150.3H315.121Z" fill="#F6F3EE" />
        <path
          d="M322.962 161.708C325.778 161.708 327.362 159.356 327.362 155.9C327.362 152.444 325.778 150.092 322.962 150.092C320.146 150.092 318.562 152.444 318.562 155.9C318.562 159.356 320.146 161.708 322.962 161.708ZM322.962 159.916C321.314 159.916 320.402 158.476 320.402 155.9C320.402 153.324 321.314 151.884 322.962 151.884C324.626 151.884 325.522 153.324 325.522 155.9C325.522 158.476 324.626 159.916 322.962 159.916Z"
          fill="#F6F3EE" />
        <path
          d="M118.22 150.7H116.75L113.642 155.88L110.52 150.7H109.05V160.5H110.352V152.856L113.558 158.176H113.726L116.932 152.842V160.5H118.22V150.7Z"
          fill="#F6F3EE" />
        <path
          d="M121.043 157.56H126.909C126.937 157.378 126.951 157.196 126.951 157.014C126.951 155.054 125.579 153.318 123.465 153.318C121.281 153.318 119.797 154.928 119.797 157C119.797 159.1 121.295 160.682 123.549 160.682C124.949 160.682 126.013 160.052 126.629 159.1L125.593 158.512C125.243 159.086 124.543 159.534 123.577 159.534C122.275 159.534 121.281 158.82 121.043 157.56ZM123.465 154.466C124.515 154.466 125.509 155.11 125.719 156.496H121.043C121.239 155.278 122.149 154.466 123.465 154.466Z"
          fill="#F6F3EE" />
        <path
          d="M79 140.5C70.168 140.5 63 147.668 63 156.5C63 165.332 70.168 172.5 79 172.5C87.832 172.5 95 165.332 95 156.5C95 147.668 87.832 140.5 79 140.5ZM79 145.3C81.656 145.3 83.8 147.444 83.8 150.1C83.8 152.756 81.656 154.9 79 154.9C76.344 154.9 74.2 152.756 74.2 150.1C74.2 147.444 76.344 145.3 79 145.3ZM79 168.02C75 168.02 71.464 165.972 69.4 162.868C69.448 159.684 75.8 157.94 79 157.94C82.184 157.94 88.552 159.684 88.6 162.868C86.536 165.972 83 168.02 79 168.02Z"
          fill="#F6F3EE" />
        <g opacity="0.25">
          <rect y="186.5" width="343" height="45" rx="15" fill="#292929" />
          <path
            d="M26.0082 210.772H24.7282V207.86H22.8722V210.772H19.3682L23.2242 203.3H21.2242L17.3682 210.772V212.484H22.8722V214.5H24.7282V212.484H26.0082V210.772Z"
            fill="#F6F3EE" />
          <path
            d="M305.985 207.876L308.257 204.868V203.3H301.377V205.028H306.065L303.649 208.212L304.385 209.316H304.801C306.065 209.316 306.881 209.988 306.881 211.108C306.881 212.228 306.065 212.916 304.801 212.916C303.681 212.916 302.929 212.42 302.609 211.476L301.041 212.388C301.665 213.956 303.153 214.708 304.801 214.708C306.865 214.708 308.721 213.46 308.721 211.108C308.721 209.364 307.521 208.244 305.985 207.876Z"
            fill="#F6F3EE" />
          <path
            d="M314.322 214.708C317.138 214.708 318.722 212.356 318.722 208.9C318.722 205.444 317.138 203.092 314.322 203.092C311.506 203.092 309.922 205.444 309.922 208.9C309.922 212.356 311.506 214.708 314.322 214.708ZM314.322 212.916C312.674 212.916 311.762 211.476 311.762 208.9C311.762 206.324 312.674 204.884 314.322 204.884C315.986 204.884 316.882 206.324 316.882 208.9C316.882 211.476 315.986 212.916 314.322 212.916Z"
            fill="#F6F3EE" />
          <path
            d="M323.682 207.524H322.274L322.466 205.028H327.01V203.3H320.77L320.338 209.316H323.602C324.818 209.316 325.65 209.988 325.65 211.108C325.65 212.228 324.818 212.916 323.57 212.916C322.402 212.916 321.634 212.388 321.346 211.396L319.762 212.308C320.354 213.924 321.874 214.708 323.57 214.708C325.634 214.708 327.49 213.46 327.49 211.108C327.49 208.772 325.714 207.524 323.682 207.524Z"
            fill="#F6F3EE" />
          <path
            d="M109.848 205.226C110.296 205.226 110.66 204.862 110.66 204.414C110.66 203.966 110.296 203.588 109.848 203.588C109.386 203.588 109.022 203.966 109.022 204.414C109.022 204.862 109.386 205.226 109.848 205.226ZM109.232 213.92C109.232 215.012 108.756 215.39 107.748 215.306V216.482C109.512 216.608 110.45 215.726 110.45 213.92V206.5H109.232V213.92Z"
            fill="#F6F3EE" />
          <path
            d="M118.097 206.5V207.704C117.537 206.85 116.641 206.318 115.451 206.318C113.505 206.318 111.909 207.928 111.909 210C111.909 212.072 113.505 213.682 115.451 213.682C116.641 213.682 117.537 213.15 118.097 212.296V213.5H119.315V206.5H118.097ZM115.605 212.506C114.205 212.506 113.127 211.428 113.127 210C113.127 208.572 114.205 207.494 115.605 207.494C117.019 207.494 118.097 208.572 118.097 210C118.097 211.428 117.019 212.506 115.605 212.506Z"
            fill="#F6F3EE" />
          <path
            d="M126.046 206.5L124.03 211.988L121.65 206.5H120.348L123.4 213.472L123.274 213.738C122.91 214.662 122.322 215.236 121.384 215.166V216.3C122.812 216.398 123.904 215.502 124.492 213.934L127.348 206.5H126.046Z"
            fill="#F6F3EE" />
          <path
            d="M135.925 206.318C135.015 206.318 134.217 206.71 133.727 207.508C133.321 206.752 132.621 206.318 131.669 206.318C130.815 206.318 130.087 206.654 129.583 207.438V206.5H128.365V213.5H129.583V209.608C129.583 208.096 130.437 207.48 131.375 207.48C132.285 207.48 132.859 208.082 132.859 209.188V213.5H134.077V209.608C134.077 208.096 134.819 207.48 135.799 207.48C136.723 207.48 137.339 208.082 137.339 209.188V213.5H138.557V209.146C138.557 207.396 137.479 206.318 135.925 206.318Z"
            fill="#F6F3EE" />
          <path
            d="M140.862 205.226C141.31 205.226 141.674 204.862 141.674 204.414C141.674 203.966 141.31 203.588 140.862 203.588C140.4 203.588 140.036 203.966 140.036 204.414C140.036 204.862 140.4 205.226 140.862 205.226ZM140.246 213.5H141.464V206.5H140.246V213.5Z"
            fill="#F6F3EE" />
          <path
            d="M147.13 207.676V206.5H145.268V204.54L144.05 204.904V206.5H142.664V207.676H144.05V211.47C144.05 213.22 145.03 213.78 147.13 213.5V212.408C145.884 212.464 145.268 212.534 145.268 211.47V207.676H147.13Z"
            fill="#F6F3EE" />
          <path
            d="M151.507 213.682C152.879 213.682 154.069 212.954 154.657 211.862L153.621 211.26C153.229 211.988 152.459 212.478 151.507 212.478C150.107 212.478 149.043 211.4 149.043 210C149.043 208.586 150.107 207.508 151.507 207.508C152.445 207.508 153.229 207.998 153.579 208.74L154.601 208.152C154.069 207.046 152.893 206.318 151.507 206.318C149.393 206.318 147.825 207.928 147.825 210C147.825 212.072 149.393 213.682 151.507 213.682Z"
            fill="#F6F3EE" />
          <path
            d="M159.346 206.318C158.366 206.318 157.596 206.682 157.078 207.508V203.7H155.86V213.5H157.078V209.79C157.078 208.138 157.988 207.48 159.108 207.48C160.186 207.48 160.844 208.124 160.844 209.272V213.5H162.062V209.202C162.062 207.41 160.984 206.318 159.346 206.318Z"
            fill="#F6F3EE" />
          <path opacity="0.25"
            d="M79 192.5C70.168 192.5 63 199.668 63 208.5C63 217.332 70.168 224.5 79 224.5C87.832 224.5 95 217.332 95 208.5C95 199.668 87.832 192.5 79 192.5ZM79 197.3C81.656 197.3 83.8 199.444 83.8 202.1C83.8 204.756 81.656 206.9 79 206.9C76.344 206.9 74.2 204.756 74.2 202.1C74.2 199.444 76.344 197.3 79 197.3ZM79 220.02C75 220.02 71.464 217.972 69.4 214.868C69.448 211.684 75.8 209.94 79 209.94C82.184 209.94 88.552 211.684 88.6 214.868C86.536 217.972 83 220.02 79 220.02Z"
            fill="#F6F3EE" />
        </g>
        <g opacity="0.25">
          <rect y="239.5" width="343" height="45" rx="15" fill="#292929" />
          <path
            d="M21.4005 260.524H19.9925L20.1845 258.028H24.7285V256.3H18.4885L18.0565 262.316H21.3205C22.5365 262.316 23.3685 262.988 23.3685 264.108C23.3685 265.228 22.5365 265.916 21.2885 265.916C20.1205 265.916 19.3525 265.388 19.0645 264.396L17.4805 265.308C18.0725 266.924 19.5925 267.708 21.2885 267.708C23.3525 267.708 25.2085 266.46 25.2085 264.108C25.2085 261.772 23.4325 260.524 21.4005 260.524Z"
            fill="#F6F3EE" />
          <path
            d="M304.548 260.876L306.82 257.868V256.3H299.94V258.028H304.628L302.212 261.212L302.948 262.316H303.364C304.628 262.316 305.444 262.988 305.444 264.108C305.444 265.228 304.628 265.916 303.364 265.916C302.244 265.916 301.492 265.42 301.172 264.476L299.604 265.388C300.228 266.956 301.716 267.708 303.364 267.708C305.428 267.708 307.284 266.46 307.284 264.108C307.284 262.364 306.084 261.244 304.548 260.876Z"
            fill="#F6F3EE" />
          <path
            d="M312.884 267.708C315.7 267.708 317.284 265.356 317.284 261.9C317.284 258.444 315.7 256.092 312.884 256.092C310.068 256.092 308.484 258.444 308.484 261.9C308.484 265.356 310.068 267.708 312.884 267.708ZM312.884 265.916C311.236 265.916 310.324 264.476 310.324 261.9C310.324 259.324 311.236 257.884 312.884 257.884C314.548 257.884 315.444 259.324 315.444 261.9C315.444 264.476 314.548 265.916 312.884 265.916Z"
            fill="#F6F3EE" />
          <path
            d="M322.962 267.708C325.778 267.708 327.362 265.356 327.362 261.9C327.362 258.444 325.778 256.092 322.962 256.092C320.146 256.092 318.562 258.444 318.562 261.9C318.562 265.356 320.146 267.708 322.962 267.708ZM322.962 265.916C321.314 265.916 320.402 264.476 320.402 261.9C320.402 259.324 321.314 257.884 322.962 257.884C324.626 257.884 325.522 259.324 325.522 261.9C325.522 264.476 324.626 265.916 322.962 265.916Z"
            fill="#F6F3EE" />
          <path
            d="M114.72 259.5V260.704C114.16 259.85 113.264 259.318 112.074 259.318C110.128 259.318 108.532 260.928 108.532 263C108.532 265.072 110.128 266.682 112.074 266.682C113.264 266.682 114.16 266.15 114.72 265.296V266.5H115.938V259.5H114.72ZM112.228 265.506C110.828 265.506 109.75 264.428 109.75 263C109.75 261.572 110.828 260.494 112.228 260.494C113.642 260.494 114.72 261.572 114.72 263C114.72 264.428 113.642 265.506 112.228 265.506Z"
            fill="#F6F3EE" />
          <path
            d="M125.344 259.318C124.434 259.318 123.636 259.71 123.146 260.508C122.74 259.752 122.04 259.318 121.088 259.318C120.234 259.318 119.506 259.654 119.002 260.438V259.5H117.784V266.5H119.002V262.608C119.002 261.096 119.856 260.48 120.794 260.48C121.704 260.48 122.278 261.082 122.278 262.188V266.5H123.496V262.608C123.496 261.096 124.238 260.48 125.218 260.48C126.142 260.48 126.758 261.082 126.758 262.188V266.5H127.976V262.146C127.976 260.396 126.898 259.318 125.344 259.318Z"
            fill="#F6F3EE" />
          <path
            d="M131.01 265.338L134.594 260.312V259.5H129.484V260.662H132.914L129.344 265.688V266.5H134.734V265.338H131.01Z"
            fill="#F6F3EE" />
          <path
            d="M140.05 259.318C138.86 259.318 137.964 259.85 137.404 260.704V256.7H136.186V266.5H137.404V265.296C137.964 266.15 138.86 266.682 140.05 266.682C141.996 266.682 143.592 265.072 143.592 263C143.592 260.928 141.996 259.318 140.05 259.318ZM139.896 265.506C138.482 265.506 137.404 264.428 137.404 263C137.404 261.572 138.482 260.494 139.896 260.494C141.296 260.494 142.374 261.572 142.374 263C142.374 264.428 141.296 265.506 139.896 265.506Z"
            fill="#F6F3EE" />
          <path
            d="M148.335 266.682C150.379 266.682 152.031 265.072 152.031 263C152.031 260.928 150.379 259.318 148.335 259.318C146.291 259.318 144.653 260.928 144.653 263C144.653 265.072 146.291 266.682 148.335 266.682ZM148.335 265.492C146.949 265.492 145.871 264.414 145.871 263C145.871 261.586 146.949 260.508 148.335 260.508C149.735 260.508 150.813 261.586 150.813 263C150.813 264.414 149.735 265.492 148.335 265.492Z"
            fill="#F6F3EE" />
          <path
            d="M156.953 259.318C155.973 259.318 155.203 259.682 154.685 260.508V259.5H153.467V266.5H154.685V262.79C154.685 261.138 155.595 260.48 156.715 260.48C157.793 260.48 158.451 261.124 158.451 262.272V266.5H159.669V262.202C159.669 260.41 158.591 259.318 156.953 259.318Z"
            fill="#F6F3EE" />
          <path
            d="M167.166 256.7V260.704C166.606 259.85 165.71 259.318 164.52 259.318C162.574 259.318 160.978 260.928 160.978 263C160.978 265.072 162.574 266.682 164.52 266.682C165.71 266.682 166.606 266.15 167.166 265.296V266.5H168.384V256.7H167.166ZM164.674 265.506C163.274 265.506 162.196 264.428 162.196 263C162.196 261.572 163.274 260.494 164.674 260.494C166.088 260.494 167.166 261.572 167.166 263C167.166 264.428 166.088 265.506 164.674 265.506Z"
            fill="#F6F3EE" />
          <path opacity="0.25"
            d="M79 245.5C70.168 245.5 63 252.668 63 261.5C63 270.332 70.168 277.5 79 277.5C87.832 277.5 95 270.332 95 261.5C95 252.668 87.832 245.5 79 245.5ZM79 250.3C81.656 250.3 83.8 252.444 83.8 255.1C83.8 257.756 81.656 259.9 79 259.9C76.344 259.9 74.2 257.756 74.2 255.1C74.2 252.444 76.344 250.3 79 250.3ZM79 273.02C75 273.02 71.464 270.972 69.4 267.868C69.448 264.684 75.8 262.94 79 262.94C82.184 262.94 88.552 264.684 88.6 267.868C86.536 270.972 83 273.02 79 273.02Z"
            fill="#F6F3EE" />
        </g>
        <g opacity="0.25">
          <rect y="292.5" width="343" height="45" rx="15" fill="#292929" />
          <path
            d="M21.3684 313.06H21.1604L23.7204 309.3H21.6244L18.0724 314.628C17.6404 315.252 17.4004 316.02 17.4004 316.884C17.4004 319.14 19.0324 320.708 21.3684 320.708C23.6884 320.708 25.3204 319.14 25.3204 316.884C25.3204 314.628 23.6884 313.06 21.3684 313.06ZM21.3684 318.964C20.1364 318.964 19.2404 318.164 19.2404 316.884C19.2404 315.604 20.1364 314.804 21.3684 314.804C22.5844 314.804 23.4804 315.604 23.4804 316.884C23.4804 318.148 22.5844 318.964 21.3684 318.964Z"
            fill="#F6F3EE" />
          <path
            d="M302.98 320.5H310.356V318.724H305.716L308.244 316.196C309.316 315.108 310.148 313.94 310.148 312.548C310.148 310.324 308.404 309.092 306.564 309.092C305.076 309.092 303.668 309.828 302.948 311.364L304.5 312.276C304.932 311.38 305.652 310.884 306.58 310.884C307.54 310.884 308.308 311.492 308.308 312.612C308.308 313.46 307.684 314.276 306.884 315.076L302.98 319.06V320.5Z"
            fill="#F6F3EE" />
          <path
            d="M319.195 312.916C319.195 310.66 317.563 309.092 315.243 309.092C312.923 309.092 311.275 310.66 311.275 312.916C311.275 315.172 312.923 316.74 315.243 316.74H315.435L312.875 320.5H314.987L318.523 315.172C318.955 314.548 319.195 313.78 319.195 312.916ZM313.115 312.916C313.115 311.636 314.027 310.836 315.243 310.836C316.459 310.836 317.355 311.636 317.355 312.916C317.355 314.196 316.459 314.996 315.243 314.996C314.027 314.996 313.115 314.18 313.115 312.916Z"
            fill="#F6F3EE" />
          <path
            d="M323.681 313.524H322.273L322.465 311.028H327.009V309.3H320.769L320.337 315.316H323.601C324.817 315.316 325.649 315.988 325.649 317.108C325.649 318.228 324.817 318.916 323.569 318.916C322.401 318.916 321.633 318.388 321.345 317.396L319.761 318.308C320.353 319.924 321.873 320.708 323.569 320.708C325.633 320.708 327.489 319.46 327.489 317.108C327.489 314.772 325.713 313.524 323.681 313.524Z"
            fill="#F6F3EE" />
          <path
            d="M113.754 312.5V316.21C113.754 317.862 112.844 318.52 111.724 318.52C110.646 318.52 109.988 317.876 109.988 316.728V312.5H108.77V316.798C108.77 318.59 109.848 319.682 111.486 319.682C112.466 319.682 113.236 319.318 113.754 318.492V319.5H114.972V312.5H113.754Z"
            fill="#F6F3EE" />
          <path
            d="M120.424 310.694V309.518C118.422 309.378 117.358 310.428 117.358 312.29V312.5H116.238V313.676H117.358V319.5H118.576V313.676H120.424V312.5H118.576V312.29C118.576 311.072 119.206 310.596 120.424 310.694Z"
            fill="#F6F3EE" />
          <path
            d="M125.215 312.318C124.025 312.318 123.129 312.85 122.569 313.704V312.5H121.351V322.3H122.569V318.296C123.129 319.15 124.025 319.682 125.215 319.682C127.161 319.682 128.757 318.072 128.757 316C128.757 313.928 127.161 312.318 125.215 312.318ZM125.061 318.506C123.647 318.506 122.569 317.428 122.569 316C122.569 314.572 123.647 313.494 125.061 313.494C126.461 313.494 127.539 314.572 127.539 316C127.539 317.428 126.461 318.506 125.061 318.506Z"
            fill="#F6F3EE" />
          <path
            d="M131.429 313.676V312.5H130.211V319.5H131.429V315.79C131.429 314.138 132.563 313.606 133.613 313.648V312.374C132.731 312.374 131.877 312.696 131.429 313.676Z"
            fill="#F6F3EE" />
          <path
            d="M137.712 319.682C139.756 319.682 141.408 318.072 141.408 316C141.408 313.928 139.756 312.318 137.712 312.318C135.668 312.318 134.03 313.928 134.03 316C134.03 318.072 135.668 319.682 137.712 319.682ZM137.712 318.492C136.326 318.492 135.248 317.414 135.248 316C135.248 314.586 136.326 313.508 137.712 313.508C139.112 313.508 140.19 314.586 140.19 316C140.19 317.414 139.112 318.492 137.712 318.492Z"
            fill="#F6F3EE" />
          <path
            d="M149.745 316.42H148.555V313.648H147.253V316.42H143.655L147.169 309.7H145.769L142.255 316.42V317.638H147.253V319.5H148.555V317.638H149.745V316.42Z"
            fill="#F6F3EE" />
          <path
            d="M150.476 319.5H156.804V318.24H152.366L154.872 315.748C155.838 314.782 156.622 313.732 156.622 312.514C156.622 310.582 155.096 309.518 153.57 309.518C152.296 309.518 151.106 310.148 150.476 311.45L151.568 312.094C151.988 311.212 152.688 310.778 153.584 310.778C154.494 310.778 155.32 311.38 155.32 312.528C155.32 313.354 154.704 314.138 153.934 314.908L150.476 318.436V319.5Z"
            fill="#F6F3EE" />
          <path opacity="0.25"
            d="M79 298.5C70.168 298.5 63 305.668 63 314.5C63 323.332 70.168 330.5 79 330.5C87.832 330.5 95 323.332 95 314.5C95 305.668 87.832 298.5 79 298.5ZM79 303.3C81.656 303.3 83.8 305.444 83.8 308.1C83.8 310.756 81.656 312.9 79 312.9C76.344 312.9 74.2 310.756 74.2 308.1C74.2 305.444 76.344 303.3 79 303.3ZM79 326.02C75 326.02 71.464 323.972 69.4 320.868C69.448 317.684 75.8 315.94 79 315.94C82.184 315.94 88.552 317.684 88.6 320.868C86.536 323.972 83 326.02 79 326.02Z"
            fill="#F6F3EE" />
        </g>
        <g opacity="0.25">
          <rect y="345.5" width="343" height="45" rx="15" fill="#292929" />
          <path d="M17.2402 362.3V364.06H22.3602L18.4882 373.5H20.4242L24.3602 363.9V362.3H17.2402Z" fill="#F6F3EE" />
          <path
            d="M301.308 373.5H308.684V371.724H304.044L306.572 369.196C307.644 368.108 308.476 366.94 308.476 365.548C308.476 363.324 306.732 362.092 304.892 362.092C303.404 362.092 301.996 362.828 301.276 364.364L302.828 365.276C303.26 364.38 303.98 363.884 304.908 363.884C305.868 363.884 306.636 364.492 306.636 365.612C306.636 366.46 306.012 367.276 305.212 368.076L301.308 372.06V373.5Z"
            fill="#F6F3EE" />
          <path
            d="M317.523 365.916C317.523 363.66 315.891 362.092 313.571 362.092C311.251 362.092 309.603 363.66 309.603 365.916C309.603 368.172 311.251 369.74 313.571 369.74H313.763L311.203 373.5H313.315L316.851 368.172C317.283 367.548 317.523 366.78 317.523 365.916ZM311.443 365.916C311.443 364.636 312.355 363.836 313.571 363.836C314.787 363.836 315.683 364.636 315.683 365.916C315.683 367.196 314.787 367.996 313.571 367.996C312.355 367.996 311.443 367.18 311.443 365.916Z"
            fill="#F6F3EE" />
          <path
            d="M322.962 373.708C325.778 373.708 327.362 371.356 327.362 367.9C327.362 364.444 325.778 362.092 322.962 362.092C320.146 362.092 318.562 364.444 318.562 367.9C318.562 371.356 320.146 373.708 322.962 373.708ZM322.962 371.916C321.314 371.916 320.402 370.476 320.402 367.9C320.402 365.324 321.314 363.884 322.962 363.884C324.626 363.884 325.522 365.324 325.522 367.9C325.522 370.476 324.626 371.916 322.962 371.916Z"
            fill="#F6F3EE" />
          <path
            d="M112.788 365.318C111.598 365.318 110.702 365.85 110.142 366.704V362.7H108.924V372.5H110.142V371.296C110.702 372.15 111.598 372.682 112.788 372.682C114.734 372.682 116.33 371.072 116.33 369C116.33 366.928 114.734 365.318 112.788 365.318ZM112.634 371.506C111.22 371.506 110.142 370.428 110.142 369C110.142 367.572 111.22 366.494 112.634 366.494C114.034 366.494 115.112 367.572 115.112 369C115.112 370.428 114.034 371.506 112.634 371.506Z"
            fill="#F6F3EE" />
          <path
            d="M119.001 366.676V365.5H117.783V372.5H119.001V368.79C119.001 367.138 120.135 366.606 121.185 366.648V365.374C120.303 365.374 119.449 365.696 119.001 366.676Z"
            fill="#F6F3EE" />
          <path
            d="M122.848 369.56H128.714C128.742 369.378 128.756 369.196 128.756 369.014C128.756 367.054 127.384 365.318 125.27 365.318C123.086 365.318 121.602 366.928 121.602 369C121.602 371.1 123.1 372.682 125.354 372.682C126.754 372.682 127.818 372.052 128.434 371.1L127.398 370.512C127.048 371.086 126.348 371.534 125.382 371.534C124.08 371.534 123.086 370.82 122.848 369.56ZM125.27 366.466C126.32 366.466 127.314 367.11 127.524 368.496H122.848C123.044 367.278 123.954 366.466 125.27 366.466Z"
            fill="#F6F3EE" />
          <path
            d="M130.997 369.56H136.863C136.891 369.378 136.905 369.196 136.905 369.014C136.905 367.054 135.533 365.318 133.419 365.318C131.235 365.318 129.751 366.928 129.751 369C129.751 371.1 131.249 372.682 133.503 372.682C134.903 372.682 135.967 372.052 136.583 371.1L135.547 370.512C135.197 371.086 134.497 371.534 133.531 371.534C132.229 371.534 131.235 370.82 130.997 369.56ZM133.419 366.466C134.469 366.466 135.463 367.11 135.673 368.496H130.997C131.193 367.278 132.103 366.466 133.419 366.466Z"
            fill="#F6F3EE" />
          <path
            d="M139.322 371.338L142.906 366.312V365.5H137.796V366.662H141.226L137.656 371.688V372.5H143.046V371.338H139.322Z"
            fill="#F6F3EE" />
          <path
            d="M149.384 365.5L147.368 370.988L144.988 365.5H143.686L146.738 372.472L146.612 372.738C146.248 373.662 145.66 374.236 144.722 374.166V375.3C146.15 375.398 147.242 374.502 147.83 372.934L150.686 365.5H149.384Z"
            fill="#F6F3EE" />
          <path d="M153.551 362.7L150.975 363.596L151.297 364.716L153.383 364.1V372.5H154.671V362.7H153.551Z"
            fill="#F6F3EE" />
          <path opacity="0.25"
            d="M79 351.5C70.168 351.5 63 358.668 63 367.5C63 376.332 70.168 383.5 79 383.5C87.832 383.5 95 376.332 95 367.5C95 358.668 87.832 351.5 79 351.5ZM79 356.3C81.656 356.3 83.8 358.444 83.8 361.1C83.8 363.756 81.656 365.9 79 365.9C76.344 365.9 74.2 363.756 74.2 361.1C74.2 358.444 76.344 356.3 79 356.3ZM79 379.02C75 379.02 71.464 376.972 69.4 373.868C69.448 370.684 75.8 368.94 79 368.94C82.184 368.94 88.552 370.684 88.6 373.868C86.536 376.972 83 379.02 79 379.02Z"
            fill="#F6F3EE" />
        </g>
        <g opacity="0.15">
          <rect y="398.5" width="343" height="45" rx="15" fill="#292929" />
          <path
            d="M23.7676 420.676C24.5356 420.18 25.0476 419.38 25.0476 418.308C25.0476 416.148 23.3516 415.092 21.4956 415.092C19.6236 415.092 17.9436 416.148 17.9436 418.308C17.9436 419.38 18.4556 420.18 19.2076 420.676C18.1836 421.252 17.5596 422.228 17.5596 423.396C17.5596 425.476 19.1596 426.708 21.4956 426.708C23.8316 426.708 25.4156 425.476 25.4156 423.396C25.4156 422.212 24.8076 421.252 23.7676 420.676ZM21.4956 416.836C22.4236 416.836 23.2076 417.348 23.2076 418.388C23.2076 419.38 22.4236 419.94 21.4956 419.94C20.5516 419.94 19.7836 419.38 19.7836 418.388C19.7836 417.348 20.5516 416.836 21.4956 416.836ZM21.4956 424.964C20.2796 424.964 19.3996 424.356 19.3996 423.22C19.3996 422.068 20.2796 421.476 21.4956 421.476C22.6956 421.476 23.5756 422.068 23.5756 423.22C23.5756 424.356 22.6956 424.964 21.4956 424.964Z"
            fill="#F6F3EE" />
          <path
            d="M302.496 426.5H309.872V424.724H305.232L307.76 422.196C308.832 421.108 309.664 419.94 309.664 418.548C309.664 416.324 307.92 415.092 306.08 415.092C304.592 415.092 303.184 415.828 302.464 417.364L304.016 418.276C304.448 417.38 305.168 416.884 306.096 416.884C307.056 416.884 307.824 417.492 307.824 418.612C307.824 419.46 307.2 420.276 306.4 421.076L302.496 425.06V426.5Z"
            fill="#F6F3EE" />
          <path
            d="M317.158 420.676C317.926 420.18 318.438 419.38 318.438 418.308C318.438 416.148 316.742 415.092 314.886 415.092C313.014 415.092 311.334 416.148 311.334 418.308C311.334 419.38 311.846 420.18 312.598 420.676C311.574 421.252 310.95 422.228 310.95 423.396C310.95 425.476 312.55 426.708 314.886 426.708C317.222 426.708 318.806 425.476 318.806 423.396C318.806 422.212 318.198 421.252 317.158 420.676ZM314.886 416.836C315.814 416.836 316.598 417.348 316.598 418.388C316.598 419.38 315.814 419.94 314.886 419.94C313.942 419.94 313.174 419.38 313.174 418.388C313.174 417.348 313.942 416.836 314.886 416.836ZM314.886 424.964C313.67 424.964 312.79 424.356 312.79 423.22C312.79 422.068 313.67 421.476 314.886 421.476C316.086 421.476 316.966 422.068 316.966 423.22C316.966 424.356 316.086 424.964 314.886 424.964Z"
            fill="#F6F3EE" />
          <path
            d="M323.681 419.524H322.273L322.465 417.028H327.009V415.3H320.769L320.337 421.316H323.601C324.817 421.316 325.649 421.988 325.649 423.108C325.649 424.228 324.817 424.916 323.569 424.916C322.401 424.916 321.633 424.388 321.345 423.396L319.761 424.308C320.353 425.924 321.873 426.708 323.569 426.708C325.633 426.708 327.489 425.46 327.489 423.108C327.489 420.772 325.713 419.524 323.681 419.524Z"
            fill="#F6F3EE" />
          <path
            d="M109.778 422.56H115.644C115.672 422.378 115.686 422.196 115.686 422.014C115.686 420.054 114.314 418.318 112.2 418.318C110.016 418.318 108.532 419.928 108.532 422C108.532 424.1 110.03 425.682 112.284 425.682C113.684 425.682 114.748 425.052 115.364 424.1L114.328 423.512C113.978 424.086 113.278 424.534 112.312 424.534C111.01 424.534 110.016 423.82 109.778 422.56ZM112.2 419.466C113.25 419.466 114.244 420.11 114.454 421.496H109.778C109.974 420.278 110.884 419.466 112.2 419.466Z"
            fill="#F6F3EE" />
          <path d="M117.086 425.5H118.304V415.28H117.086V425.5Z" fill="#F6F3EE" />
          <path d="M120.163 425.5H121.381V415.28H120.163V425.5Z" fill="#F6F3EE" />
          <path
            d="M123.855 417.226C124.303 417.226 124.667 416.862 124.667 416.414C124.667 415.966 124.303 415.588 123.855 415.588C123.393 415.588 123.029 415.966 123.029 416.414C123.029 416.862 123.393 417.226 123.855 417.226ZM123.239 425.5H124.457V418.5H123.239V425.5Z"
            fill="#F6F3EE" />
          <path
            d="M127.169 422.56H133.035C133.063 422.378 133.077 422.196 133.077 422.014C133.077 420.054 131.705 418.318 129.591 418.318C127.407 418.318 125.923 419.928 125.923 422C125.923 424.1 127.421 425.682 129.675 425.682C131.075 425.682 132.139 425.052 132.755 424.1L131.719 423.512C131.369 424.086 130.669 424.534 129.703 424.534C128.401 424.534 127.407 423.82 127.169 422.56ZM129.591 419.466C130.641 419.466 131.635 420.11 131.845 421.496H127.169C127.365 420.278 128.275 419.466 129.591 419.466Z"
            fill="#F6F3EE" />
          <path
            d="M135.254 420.39C135.254 419.76 135.87 419.452 136.5 419.452C137.116 419.452 137.732 419.718 137.998 420.348L139.02 419.774C138.572 418.864 137.648 418.318 136.5 418.318C135.086 418.318 134.022 419.172 134.022 420.404C134.022 422.938 137.942 422.084 137.942 423.582C137.942 424.268 137.298 424.534 136.556 424.534C135.688 424.534 135.044 424.114 134.82 423.442L133.77 424.044C134.176 425.01 135.156 425.682 136.556 425.682C138.054 425.682 139.174 424.884 139.174 423.582C139.174 421.006 135.254 421.888 135.254 420.39Z"
            fill="#F6F3EE" />
          <path
            d="M141.163 417.226C141.611 417.226 141.975 416.862 141.975 416.414C141.975 415.966 141.611 415.588 141.163 415.588C140.701 415.588 140.337 415.966 140.337 416.414C140.337 416.862 140.701 417.226 141.163 417.226ZM140.547 425.5H141.765V418.5H140.547V425.5Z"
            fill="#F6F3EE" />
          <path
            d="M151.183 418.318C150.273 418.318 149.475 418.71 148.985 419.508C148.579 418.752 147.879 418.318 146.927 418.318C146.073 418.318 145.345 418.654 144.841 419.438V418.5H143.623V425.5H144.841V421.608C144.841 420.096 145.695 419.48 146.633 419.48C147.543 419.48 148.117 420.082 148.117 421.188V425.5H149.335V421.608C149.335 420.096 150.077 419.48 151.057 419.48C151.981 419.48 152.597 420.082 152.597 421.188V425.5H153.815V421.146C153.815 419.396 152.737 418.318 151.183 418.318Z"
            fill="#F6F3EE" />
          <path opacity="0.25"
            d="M79 404.5C70.168 404.5 63 411.668 63 420.5C63 429.332 70.168 436.5 79 436.5C87.832 436.5 95 429.332 95 420.5C95 411.668 87.832 404.5 79 404.5ZM79 409.3C81.656 409.3 83.8 411.444 83.8 414.1C83.8 416.756 81.656 418.9 79 418.9C76.344 418.9 74.2 416.756 74.2 414.1C74.2 411.444 76.344 409.3 79 409.3ZM79 432.02C75 432.02 71.464 429.972 69.4 426.868C69.448 423.684 75.8 421.94 79 421.94C82.184 421.94 88.552 423.684 88.6 426.868C86.536 429.972 83 432.02 79 432.02Z"
            fill="#F6F3EE" />
        </g>
        <g opacity="0.1">
          <rect y="452" width="343" height="45" rx="15" fill="#292929" />
          <path
            d="M25.3204 472.416C25.3204 470.16 23.6884 468.592 21.3684 468.592C19.0484 468.592 17.4004 470.16 17.4004 472.416C17.4004 474.672 19.0484 476.24 21.3684 476.24H21.5604L19.0004 480H21.1124L24.6484 474.672C25.0804 474.048 25.3204 473.28 25.3204 472.416ZM19.2404 472.416C19.2404 471.136 20.1524 470.336 21.3684 470.336C22.5844 470.336 23.4804 471.136 23.4804 472.416C23.4804 473.696 22.5844 474.496 21.3684 474.496C20.1524 474.496 19.2404 473.68 19.2404 472.416Z"
            fill="#F6F3EE" />
          <path
            d="M301.058 480H308.434V478.224H303.794L306.322 475.696C307.394 474.608 308.226 473.44 308.226 472.048C308.226 469.824 306.482 468.592 304.642 468.592C303.154 468.592 301.746 469.328 301.026 470.864L302.578 471.776C303.01 470.88 303.73 470.384 304.658 470.384C305.618 470.384 306.386 470.992 306.386 472.112C306.386 472.96 305.762 473.776 304.962 474.576L301.058 478.56V480Z"
            fill="#F6F3EE" />
          <path
            d="M315.721 474.176C316.489 473.68 317.001 472.88 317.001 471.808C317.001 469.648 315.305 468.592 313.449 468.592C311.577 468.592 309.897 469.648 309.897 471.808C309.897 472.88 310.409 473.68 311.161 474.176C310.137 474.752 309.513 475.728 309.513 476.896C309.513 478.976 311.113 480.208 313.449 480.208C315.785 480.208 317.369 478.976 317.369 476.896C317.369 475.712 316.761 474.752 315.721 474.176ZM313.449 470.336C314.377 470.336 315.161 470.848 315.161 471.888C315.161 472.88 314.377 473.44 313.449 473.44C312.505 473.44 311.737 472.88 311.737 471.888C311.737 470.848 312.505 470.336 313.449 470.336ZM313.449 478.464C312.233 478.464 311.353 477.856 311.353 476.72C311.353 475.568 312.233 474.976 313.449 474.976C314.649 474.976 315.529 475.568 315.529 476.72C315.529 477.856 314.649 478.464 313.449 478.464Z"
            fill="#F6F3EE" />
          <path
            d="M322.962 480.208C325.778 480.208 327.362 477.856 327.362 474.4C327.362 470.944 325.778 468.592 322.962 468.592C320.146 468.592 318.562 470.944 318.562 474.4C318.562 477.856 320.146 480.208 322.962 480.208ZM322.962 478.416C321.314 478.416 320.402 476.976 320.402 474.4C320.402 471.824 321.314 470.384 322.962 470.384C324.626 470.384 325.522 471.824 325.522 474.4C325.522 476.976 324.626 478.416 322.962 478.416Z"
            fill="#F6F3EE" />
          <path d="M110.338 477.838L113.922 472.812V472H108.812V473.162H112.242L108.672 478.188V479H114.062V477.838H110.338Z"
            fill="#F6F3EE" />
          <path
            d="M116.13 470.726C116.578 470.726 116.942 470.362 116.942 469.914C116.942 469.466 116.578 469.088 116.13 469.088C115.668 469.088 115.304 469.466 115.304 469.914C115.304 470.362 115.668 470.726 116.13 470.726ZM115.514 479H116.732V472H115.514V479Z"
            fill="#F6F3EE" />
          <path d="M120.004 477.838L123.588 472.812V472H118.478V473.162H121.908L118.338 478.188V479H123.728V477.838H120.004Z"
            fill="#F6F3EE" />
          <path d="M126.443 477.838L130.027 472.812V472H124.917V473.162H128.347L124.777 478.188V479H130.167V477.838H126.443Z"
            fill="#F6F3EE" />
          <path
            d="M132.235 470.726C132.683 470.726 133.047 470.362 133.047 469.914C133.047 469.466 132.683 469.088 132.235 469.088C131.773 469.088 131.409 469.466 131.409 469.914C131.409 470.362 131.773 470.726 132.235 470.726ZM131.619 479H132.837V472H131.619V479Z"
            fill="#F6F3EE" />
          <path
            d="M138.195 473.218L140.323 470.348V469.2H134.443V470.418H138.797L136.529 473.484L137.047 474.282H137.383C138.559 474.282 139.441 474.926 139.441 476.102C139.441 477.264 138.559 477.908 137.383 477.908C136.319 477.908 135.549 477.46 135.255 476.55L134.163 477.194C134.667 478.51 135.941 479.182 137.383 479.182C139.147 479.182 140.743 478.09 140.743 476.102C140.743 474.478 139.609 473.484 138.195 473.218Z"
            fill="#F6F3EE" />
          <path
            d="M145.551 473.218L147.679 470.348V469.2H141.799V470.418H146.153L143.885 473.484L144.403 474.282H144.739C145.915 474.282 146.797 474.926 146.797 476.102C146.797 477.264 145.915 477.908 144.739 477.908C143.675 477.908 142.905 477.46 142.611 476.55L141.519 477.194C142.023 478.51 143.297 479.182 144.739 479.182C146.503 479.182 148.099 478.09 148.099 476.102C148.099 474.478 146.965 473.484 145.551 473.218Z"
            fill="#F6F3EE" />
          <path opacity="0.25"
            d="M79 458C70.168 458 63 465.168 63 474C63 482.832 70.168 490 79 490C87.832 490 95 482.832 95 474C95 465.168 87.832 458 79 458ZM79 462.8C81.656 462.8 83.8 464.944 83.8 467.6C83.8 470.256 81.656 472.4 79 472.4C76.344 472.4 74.2 470.256 74.2 467.6C74.2 464.944 76.344 462.8 79 462.8ZM79 485.52C75 485.52 71.464 483.472 69.4 480.368C69.448 477.184 75.8 475.44 79 475.44C82.184 475.44 88.552 477.184 88.6 480.368C86.536 483.472 83 485.52 79 485.52Z"
            fill="#F6F3EE" />
        </g>
        <path
          d="M297.826 0.300041H293.698V11.5H295.538V7.66004H297.826C299.938 7.66004 301.538 6.04404 301.538 3.98004C301.538 1.90004 299.938 0.300041 297.826 0.300041ZM297.826 5.93204H295.538V2.02804H297.826C298.914 2.02804 299.698 2.84404 299.698 3.98004C299.698 5.10004 298.914 5.93204 297.826 5.93204Z"
          fill="#F6F3EE" />
        <path
          d="M306.237 11.708C308.589 11.708 310.461 9.86804 310.461 7.50004C310.461 5.11604 308.589 3.29204 306.237 3.29204C303.901 3.29204 302.013 5.11604 302.013 7.50004C302.013 9.86804 303.901 11.708 306.237 11.708ZM306.237 10.028C304.829 10.028 303.741 8.95604 303.741 7.50004C303.741 6.04404 304.829 4.97204 306.237 4.97204C307.645 4.97204 308.733 6.04404 308.733 7.50004C308.733 8.95604 307.645 10.028 306.237 10.028Z"
          fill="#F6F3EE" />
        <path
          d="M312.888 2.30004C313.496 2.30004 313.992 1.78804 313.992 1.19604C313.992 0.588041 313.496 0.092041 312.888 0.092041C312.28 0.092041 311.784 0.588041 311.784 1.19604C311.784 1.78804 312.28 2.30004 312.888 2.30004ZM312.024 11.5H313.752V3.50004H312.024V11.5Z"
          fill="#F6F3EE" />
        <path
          d="M319.966 3.29204C318.878 3.29204 318.014 3.69204 317.486 4.52404V3.50004H315.758V11.5H317.486V7.21204C317.486 5.58004 318.382 4.90804 319.518 4.90804C320.606 4.90804 321.31 5.54804 321.31 6.76404V11.5H323.038V6.58804C323.038 4.50804 321.758 3.29204 319.966 3.29204Z"
          fill="#F6F3EE" />
        <path
          d="M329.363 5.16404V3.50004H327.379V1.26004L325.651 1.77204V3.50004H324.179V5.16404H325.651V9.00404C325.651 11.084 326.707 11.82 329.363 11.5V9.94804C328.051 10.012 327.379 10.028 327.379 9.00404V5.16404H329.363Z"
          fill="#F6F3EE" />
        <path
          d="M332.185 5.70804C332.185 5.13204 332.729 4.86004 333.353 4.86004C334.009 4.86004 334.569 5.14804 334.857 5.77204L336.313 4.95604C335.753 3.90004 334.665 3.29204 333.353 3.29204C331.721 3.29204 330.425 4.23604 330.425 5.74004C330.425 8.60404 334.729 7.80404 334.729 9.22804C334.729 9.86804 334.137 10.124 333.369 10.124C332.489 10.124 331.849 9.69204 331.593 8.97204L330.105 9.83604C330.617 10.988 331.769 11.708 333.369 11.708C335.097 11.708 336.489 10.844 336.489 9.24404C336.489 6.28404 332.185 7.16404 332.185 5.70804Z"
          fill="#F6F3EE" />
      </svg>
    `;
    }
};
HowtowinSVG.styles = [ScopedElement.styles, css `
    :host {
      display: contents;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    rect#highlight {
      fill: var(--color-primary);
    }
  `];
HowtowinSVG = __decorate([
    customElement("howtowin-svg")
], HowtowinSVG);
