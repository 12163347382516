var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c;
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import { choose } from "lit/directives/choose.js";
import ufAPI from "@/services/api/uf/uf.api";
import "./lobby.css";
import session from "@/services/session/session";
import "@/components/uf-overlay-dimmed/uf-overlay-dimmed";
import "@/components/header-bar/header-bar";
import "@/components/open-pack-overlay/open-pack-overlay";
import betfredHeaderImage from "./assets/lobby-header-sponsor-betfred.png";
import { CountdownController } from "@/views/game/controllers/countdown.controller";
import { formatAsCurrency, prizeFromPrizeBreakdown } from "@/views/game/directives/game.directives";
import { findCurrentRank } from "@/services/api/uf/uf.helpers";
import { deepEqual } from "@/services/utils/utils";
import ufApi from "@/services/api/uf/uf.api";
import ConfigurationService from "@/services/config/config";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
import skinConfiguration from "@/services/config/config";
const colour = (_c = (_b = (_a = skinConfiguration.skin.features) === null || _a === void 0 ? void 0 : _a.lobby) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
const skinStyles = [
    { name: "color-grid-item-bar", value: (colour === null || colour === void 0 ? void 0 : colour.gridItemBar) || "#36023A" },
    { name: "color-grid-item-bar-font", value: (colour === null || colour === void 0 ? void 0 : colour.gridItemBarFont) || "#F6F3EE" },
    { name: "color-grid-item-bar-small", value: (colour === null || colour === void 0 ? void 0 : colour.gridItemBarSmall) || "#FF284F" },
    { name: "color-grid-item-bar-font-small", value: (colour === null || colour === void 0 ? void 0 : colour.gridItemBarSmallFont) || "#F6F3EE" },
    { name: "color-grid-item-bar-font-small-prize", value: (colour === null || colour === void 0 ? void 0 : colour.gridItemBarSmallFontPrize) || "#35E06A" },
    { name: "color-countdown", value: (colour === null || colour === void 0 ? void 0 : colour.colorCountdown) || "#F6F3EE" }
];
let LobbyView = class LobbyView extends UnscopedElement {
    constructor() {
        var _a;
        super(skinStyles);
        this.headerBarVisible = false;
        this.packsAvailable = 0;
        this.animateLoginStreak = false;
        this.showPackOverlay = false;
        this.showLoginStreakFloatingPanel = false;
        this.newPacks = [];
        this.loginDays = (_a = session.getSavedLoginStreak()) !== null && _a !== void 0 ? _a : 1;
    }
    connectedCallback() {
        super.connectedCallback();
        this.handleCurrentStage();
        this.handleRanks();
        this.handlePacks();
        ufApi.loginDetails.subscribe(login => {
            this.referralCode = login.referralCode;
        });
        this.handleLoginStreak();
        ufAPI.getStages().catch(error => () => this.handleErrors(error));
        ufAPI.getPacks().catch(error => () => this.handleErrors(error));
        analytics.recordTag(AnalyticsKeys.LOBBY_PAGE);
    }
    handleCurrentStage() {
        this.currentStageSubscription = ufAPI.currentStage.subscribe(currentStage => {
            if (currentStage) {
                if (!deepEqual(this.currentStage, currentStage)) {
                    this.currentStage = currentStage;
                }
                if (this.currentStageCountdown) {
                    this.removeController(this.currentStageCountdown);
                }
                if (!currentStage.live) {
                    this.currentStageCountdown = new CountdownController(this, new Date(currentStage.stage.start * 1000), () => {
                        this.removeController(this.currentStageCountdown);
                        ufAPI.getStages().catch(error => () => this.handleErrors(error));
                    });
                }
                else {
                    ufAPI.getLeaderboard().catch(error => () => this.handleErrors(error));
                }
            }
        });
    }
    handleRanks() {
        this.ranksSubscription = ufAPI.ranks.subscribe(ranks => {
            if (ranks.length) {
                ufAPI.currentStage.subscribe(currentStage => {
                    if (currentStage) {
                        const rank = findCurrentRank(ranks, currentStage.stage);
                        if (!deepEqual(this.rank, rank)) {
                            this.rank = rank;
                        }
                    }
                }).unsubscribe();
            }
        });
    }
    handlePacks() {
        this.packsSubscription = ufAPI.packs.subscribe(async (packs) => {
            const newPacks = packs.filter(pack => pack.isNew);
            if (newPacks.length) {
                this.newPacks = newPacks;
                if (this.packsAvailable !== newPacks.length) {
                    this.packsAvailable = newPacks.length;
                }
                if (this.headerBarState !== "available") {
                    this.headerBarState = "available";
                }
                if (!this.headerBarVisible) {
                    this.headerBarVisible = true;
                }
            }
            else {
                try {
                    const serverAvailability = await ufAPI.getServerAvailability();
                    if (!serverAvailability.available) {
                        if (this.headerBarState !== "processing") {
                            this.headerBarState = "processing";
                        }
                        if (!this.headerBarVisible) {
                            this.headerBarVisible = true;
                        }
                    }
                    else {
                        if (this.headerBarVisible) {
                            this.headerBarVisible = false;
                        }
                        if (this.headerBarState) {
                            this.headerBarState = null;
                        }
                    }
                }
                catch (error) {
                    this.handleErrors(error);
                }
            }
        });
    }
    handleLoginStreak() {
        ufApi.loginDetails.subscribe(login => {
            var _a;
            const savedLoginStreak = session.getSavedLoginStreak();
            if (savedLoginStreak != login.loginDays) {
                this.animateLoginStreak = true;
                this.newLoginDays = login.loginDays;
                this.showLoginStreakFloatingPanel = true;
                session.setLoginStreak((_a = login.loginDays) !== null && _a !== void 0 ? _a : 0);
            }
        });
    }
    handleErrors(error) {
        if (this.headerBarVisible) {
            this.headerBarVisible = false;
        }
        if (this.headerBarState) {
            this.headerBarState = null;
        }
        console.log(error);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.currentStageSubscription) {
            this.currentStageSubscription.unsubscribe();
        }
        if (this.currentStageCountdown) {
            this.removeController(this.currentStageCountdown);
        }
        if (this.ranksSubscription) {
            this.ranksSubscription.unsubscribe();
        }
        if (this.packsSubscription) {
            this.packsSubscription.unsubscribe();
        }
    }
    render() {
        return html `
      ${this.renderLayout()}
      ${this.renderPackOverlay()}
      ${this.renderLoginStreakFloatingPanel()}
    `;
    }
    renderHeaderBar() {
        return html `
      <header-bar id="header-bar" class=${classMap({
            active: this.headerBarState === "available",
            clickable: this.headerBarState === "available"
        })}
      @click=${this.openPacks}
      >
        ${choose(this.headerBarState, [
            ["processing", () => html `
              <div slot="left">
                <p>Processing scores</p>
              </div>
              <div slot="right">
                <p>Please wait</p>
                <uf-icon icon="countdown"></uf-icon>
              </div>
            `],
            ["available", () => html `
              <div slot="left">
                <p>${this.packsAvailable} pack${this.packsAvailable > 1 ? "s" : null} available</p>
              </div>
              <div slot="right">
                <p>Open now</p>
                <uf-icon icon="chevron" theme="dark"></uf-icon>
              </div>
            `]
        ])}
      </header-bar>
    `;
    }
    openPacks() {
        this.showPackOverlay = true;
    }
    renderLoginStreakFloatingPanel() {
        return html `
      <uf-overlay-dimmed ?hidden=${!this.showLoginStreakFloatingPanel} @click=${this.hideLoginStreakPanel}>
        <div class="floating-panel">
          <login-streak-card .loginStreak=${this.loginDays} .newLoginStreak=${this.newLoginDays} .isAnimate=${this.animateLoginStreak}></login-streak-card>
        </div>
      </uf-overlay-dimmed>
    `;
    }
    hideLoginStreakPanel() {
        this.showLoginStreakFloatingPanel = false;
        this.openPacks();
    }
    renderPackOverlay() {
        return html `
      ${when(this.showPackOverlay && this.packsAvailable, () => html `
          <open-pack-overlay
            .packs=${this.newPacks}
            @cancel=${this.closeOpenPackOverlay}
          ></open-pack-overlay>
        `)}
    `;
    }
    closeOpenPackOverlay() {
        this.showPackOverlay = false;
        // this.purchasedPack = null;
    }
    renderLayout() {
        if (ConfigurationService.isBengals)
            return html `${this.renderBengalsGrid()}`;
        if (ConfigurationService.isChargers)
            return html `${this.renderChargersGrid()}`;
        return html `${this.renderPLGrid()}`;
    }
    renderPLGrid() {
        return html `
      ${when(this.headerBarVisible && this.headerBarState, () => this.renderHeaderBar())}
      <div class="grid" id="pl">
        ${this.renderMyTeam()}
        ${this.renderReferFriendsCard(2)}
        ${this.renderDiscord(`Join Now`, 3)}
        ${this.renderClash(3)}
        ${this.renderOffers()}
        ${this.renderAgeRatingIcon()}
      </div>
    `;
    }
    renderBengalsGrid() {
        return html `
      ${this.renderSponsorHeader()}
      ${when(this.headerBarVisible && this.headerBarState, () => this.renderHeaderBar())}
      <div class="grid" id="bengals">
        ${this.renderMyTeam()}
        ${this.renderSponsorTile()}
        <!-- ${this.renderReferFriendsCard(3)} -->
        ${this.renderLoginStreakCard(3)}
        ${this.renderDiscord(`Step-By-Step Guide`, 4)}
        ${this.renderClash(3)}
        ${this.renderAgeRatingIcon()}
      </div>
    `;
    }
    renderChargersGrid() {
        return html `
    ${when(this.headerBarVisible && this.headerBarState, () => this.renderHeaderBar())}
      <div class="grid" id="chargers">
        ${this.renderMyTeam()}
        <!-- ${this.renderReferFriendsCard(2)} -->
        ${this.renderLoginStreakCard(2)}
        ${this.renderDiscord(`Step-By-Step Guide`, 3)}
        ${this.renderClash(3)}
        ${this.renderAgeRatingIcon()}
      </div>
    `;
    }
    renderSponsorHeader() {
        return html `
      <div id="sponsor-header">
        <p>POWERED BY</p>
        <img src=${betfredHeaderImage}>
      </div>
    `;
    }
    renderMyTeam() {
        const stageName = this.currentStage ? this.currentStage.stage.name : null;
        const random = `${Math.random()}`;
        let myTeamImage;
        if (ConfigurationService.isUF)
            myTeamImage = `${ConfigurationService.storageTeamUrl()}/my_team.png?random=${random}`;
        else
            myTeamImage = `${ConfigurationService.storageTeamUrl()}/contests_competition_1.png?random=${random}`;
        return html `
      <router-link id="squad" href="/squad">
          <div class=${classMap({
            "grid-item-image": true,
            full: !this.currentStage
        })} style="background-image: url(${myTeamImage})"></div>
          ${when(this.currentStage, () => html `
              <div class="grid-item-bar">
                <p>${stageName}</p>
                <p>
                  ${when(this.currentStage.live && this.rank, () => `${this.rank.score}pts`)}
                </p>
                <p>
                  ${!this.currentStage.live ? `
                      Play Now
                    ` : when(this.rank, () => {
            var _a;
            if (this.rank.score > 0) {
                if (ConfigurationService.showMonetryPrizes()) {
                    return html `<span class="${classMap({ "has-prize": this.rank.draftWinnings > 0 })}">${formatAsCurrency(this.rank.draftWinnings)}</span>`;
                }
                else {
                    const prize = prizeFromPrizeBreakdown(((_a = this.currentStage.stage) === null || _a === void 0 ? void 0 : _a.breakdown) || "", this.rank.ranking, false); // isRankTied is set to false as we only need to show if the user has a prize and not which specific prize it is
                    if (prize) {
                        return html `<span class="has-prize">Prize</span>`;
                    }
                    else {
                        return html `<span>--</span>`;
                    }
                }
            }
            else {
                return html `<span>--</span>`;
            }
        })}
                </p>
              </div>
              <aside>
                ${!this.currentStage.live ? html `
                  <span id="countdown">${this.currentStageCountdown.text}</span>
                  ${when(ConfigurationService.isChargers, () => html `
                      <uf-icon icon="countdown" theme="black"></uf-icon>
                    `, () => html `
                      <uf-icon icon="countdown"></uf-icon>
                    `)}
                  ` : html `
                    <uf-icon icon="bars"></uf-icon>
                  `}
              </aside>
            `)}          
        </router-link>
    `;
    }
    renderReferFriendsCard(gridStartRow) {
        return html `
        <refer-friends-card .referralCode=${this.referralCode}
        style="grid-row-start: ${gridStartRow}"
        ></refer-friends-card>
    `;
    }
    renderLoginStreakCard(gridStartRow) {
        return html `
      <login-streak-card .loginStreak=${this.loginDays} .newLoginStreak=${this.newLoginDays} .isAnimate=${this.animateLoginStreak}
        style="grid-row-start: ${gridStartRow}">
      </login-streak-card>
    `;
    }
    renderDiscord(gridText, gridStartRow) {
        const random = `${Math.random()}`;
        let discordImage = `${ConfigurationService.storageTeamUrl()}/lobby_thumbnail_1.png?random=${random}`;
        return html `
      <a id="discord" href="${this.getDiscordOrDownloadUrl()}" target="_blank"
      style="grid-row-start: ${gridStartRow}"
      >
          <div class="grid-item-image-small" 
          style="background-image: url(${discordImage})"
          ></div>
          <div class="grid-item-bar-small">
            <p>${gridText}</p>
          </div>
      </a>
    `;
    }
    renderClash(gridStartRow) {
        var _a;
        const random = `${Math.random()}`;
        let clashImage = `${ConfigurationService.storageTeamUrl()}/lobby_thumbnail_2.png?random=${random}`;
        return html `
      <router-link href="/leaderboard/positions/${(_a = this.currentStage) === null || _a === void 0 ? void 0 : _a.stage.id}/clash">
        <div class="grid-item-image-small" style="background-image: url(${clashImage}); grid-row-start: ${gridStartRow}"
        ></div>
        <div class="grid-item-bar-small">
          <p>1 in 5 Chance to Win</p>
        </div>
      </router-link>
    `;
    }
    renderSponsorTile() {
        return html `
      <router-link id="sponsor">
        <div class="grid-item-image-small"></div>
        <div class="grid-item-bar-small">
          <p>21+. Gambling Problem? Call +1 800 589 9966</p>
        </div>
      </router-link>
    `;
    }
    renderOffers() {
        return html `
      <router-link id="offers">
        <div class="grid-item-image-small"></div>
        <div class="grid-item-bar-small">
          <p>Join UF+ Today!</p>
        </div>
      </router-link>
    `;
    }
    renderAgeRatingIcon() {
        return html `
      <div id="age-rating">
        <uf-icon icon=${ConfigurationService.ageRatingIcon()}></uf-icon>
      </div>
    `;
    }
    getDiscordOrDownloadUrl() {
        if (ConfigurationService.isUF)
            return "https://discord.com/invite/p8pXBTuXNA";
        else
            return "https://www.ultimatefan.com/us/how-to-play/";
    }
};
__decorate([
    state()
], LobbyView.prototype, "currentStage", void 0);
__decorate([
    state()
], LobbyView.prototype, "headerBarVisible", void 0);
__decorate([
    state()
], LobbyView.prototype, "headerBarState", void 0);
__decorate([
    state()
], LobbyView.prototype, "packsAvailable", void 0);
__decorate([
    state()
], LobbyView.prototype, "currentStageCountdown", void 0);
__decorate([
    state()
], LobbyView.prototype, "rank", void 0);
__decorate([
    state()
], LobbyView.prototype, "referralCode", void 0);
__decorate([
    state()
], LobbyView.prototype, "loginDays", void 0);
__decorate([
    state()
], LobbyView.prototype, "newLoginDays", void 0);
__decorate([
    state()
], LobbyView.prototype, "animateLoginStreak", void 0);
__decorate([
    state()
], LobbyView.prototype, "showPackOverlay", void 0);
__decorate([
    state()
], LobbyView.prototype, "showLoginStreakFloatingPanel", void 0);
__decorate([
    state()
], LobbyView.prototype, "newPacks", void 0);
LobbyView = __decorate([
    customElement("lobby-view")
], LobbyView);
export { LobbyView };
