import { AsyncSubject, BehaviorSubject } from "rxjs";
import { BaseAPI } from "../base.api";
import session from "../../session/session";
import skinConfiguration from "@/services/config/config";
import analytics from "../../utils/analytics";
const { low6 } = skinConfiguration.skin.api.endpoints;
const defaultHeaders = new Headers({
    "x-app-id": `${skinConfiguration.tenants()}/3.3.3`,
    "Content-Type": "application/json",
    "Accept": "application/json"
});
class Low6API extends BaseAPI {
    constructor(config) {
        super(config);
        this.loginDetails = new AsyncSubject();
        this.coinBalance = new BehaviorSubject(null);
        this.cashBalance = new BehaviorSubject(null);
        this.coinStore = new BehaviorSubject([]);
        this.loginDetails.subscribe(login => {
            const sharedHeaders = new Headers({
                "Authorization": `Bearer ${login.access_token}`
            });
            this.getHeaders = new Headers();
            this.postHeaders = new Headers();
            this.putHeaders = new Headers();
            sharedHeaders.forEach((value, key) => {
                this.getHeaders.append(key, value);
                this.postHeaders.append(key, value);
                this.putHeaders.append(key, value);
            });
            this.defaultHeaders.forEach((value, key) => {
                this.getHeaders.append(key, value);
                this.postHeaders.append(key, value);
                this.putHeaders.append(key, value);
            });
        });
    }
    clearAuthentication() {
        var _a, _b, _c;
        (_a = this.getHeaders) === null || _a === void 0 ? void 0 : _a.delete("Authorization");
        (_b = this.postHeaders) === null || _b === void 0 ? void 0 : _b.delete("Authorization");
        (_c = this.putHeaders) === null || _c === void 0 ? void 0 : _c.delete("Authorization");
    }
    /**
     * Checks if email is available
     * @param email
     * @returns
     */
    async validateEmail(email) {
        const response = await this.get(`/users/email/${email}`);
        return response.available;
    }
    /**
     * Checks if username is available
     * @param username
     * @returns
     */
    async validateUsername(username) {
        const response = await this.get(`/users/username/${username}`);
        return response.available;
    }
    /**
     * Creates a user on the Low6 service based on the user inputs
     * @param signupData
     * @returns
     */
    async signup(signupData) {
        this.clearAuthentication();
        const response = await this.post("/auth/register", signupData);
        this.loginDetails.next(response);
        this.loginDetails.complete();
        session.clear();
        session.createNew(response);
        analytics.recordCompleteRegistration();
        return response;
    }
    /**
   * Creates a user on the Low6 service for non uk users based on the user inputs
   * @param signupData
   * @returns
   */
    async signupNonUK(signupData) {
        this.clearAuthentication();
        const response = await this.post("/auth/register-non-uk", signupData);
        this.loginDetails.next(response);
        this.loginDetails.complete();
        session.clear();
        session.createNew(response);
        analytics.recordCompleteRegistration();
        return response;
    }
    /**
     * Logs in and retrieves tokens for subsequent API calls.
     * @param emailAddress Email address.
     * @param password Password.
     * @returns Low6 access tokens.
     */
    async login(emailAddress, password) {
        this.clearAuthentication();
        const userData = { username: emailAddress, password };
        const low6Login = await this.post("/auth/login", userData);
        this.loginDetails.next(low6Login);
        this.loginDetails.complete();
        session.clear();
        session.createNew(low6Login);
        analytics.recordLogin();
        return low6Login;
    }
    /**
     * Submits a reset password request.
     * @param emailAddress Email address.
     */
    async forgotPassword(emailAddress) {
        this.clearAuthentication();
        await this.post("/auth/passwords/forgot", { email: emailAddress });
    }
    /**
     * Refreshes access tokens for when current ones have expired.
     * @param refreshToken The refresh token from login.
     * @returns New Low6 access tokens.
     */
    async refreshTokens(refreshToken) {
        const low6Login = await this.post("/auth/refresh", { refresh_token: refreshToken });
        session.clear();
        session.createNew(low6Login);
        return low6Login;
    }
    /**
     * Gets current coin balance.
     */
    async getCoinBalance() {
        const coinBalance = await this.get("/ultimatefan/wallet/coins/balance");
        this.coinBalance.next(coinBalance.data);
    }
    /**
     * Gets current cash balance.
     */
    async getCashBalance() {
        const cashBalance = await this.get("/wallet");
        this.cashBalance.next(cashBalance.data);
    }
    /**
     * Gets transactions.
     * @param type The type of transactions.
     * @param page The page number to retrieve.
     * @param perPage The number of transactions per page.
     * @returns List of all transactions.
     */
    async getTransactions(type, page = 1, perPage = 20) {
        const transactions = await this.get(`/ultimatefan/wallet/transactions/${type}?page=${page}&per_page=${perPage}`);
        return transactions.data;
    }
    /**
     * Gets a generated Worldpay URL to add or edit a card.
     * @returns URL.
     */
    async getCardLink() {
        const cardLink = await this.get("/wallet/hosted/card");
        return cardLink;
    }
    /**
     * For buying coins or converting cash to coins. Gets coin amounts and their corresponding cash prices.
     */
    async getCoinStore() {
        const coinStore = await this.get("/ultimatefan/wallet/store/items?type=web");
        this.coinStore.next(coinStore.data.storeItems);
    }
    /**
     * Buys a specified amount of coins.
     * @param amount The amount of coins to purchase.
     * @returns The coin transaction of the purchase.
     */
    async buyCoins(amount) {
        const buyCoinResponse = await this.post("/ultimatefan/wallet/coins/buy", { amount });
        return buyCoinResponse.data.transaction;
    }
    /**
     * Gets user profile image.
     * @param username Username.
     * @returns Profile image data.
     */
    async getProfileImage(username) {
        const profileImageResponse = await this.get(`/following/search?q=${username}`);
        return profileImageResponse.data;
    }
    /**
    * Get User Profile
    */
    async getProfile() {
        const profileResponse = await this.get("/me");
        analytics.setUserIdentitiy(profileResponse.data.id);
        return profileResponse.data;
    }
    /**
     * Update user profile image - upload the image to Low6
     * @param files One or more image files in an array
     * @returns Users entire profile (with new image link)
     */
    async updateProfileImage(files) {
        const formData = new FormData();
        formData.append("image", files[0]);
        const customHeaders = new Headers();
        this.postHeaders.forEach((value, key) => customHeaders.append(key, value));
        customHeaders.delete('Content-Type');
        const imageUpdateResponse = await this.post("/me/image", formData, customHeaders);
        return imageUpdateResponse.data;
    }
    /**
     * Updates the users profile
     * @param profile Low6ProfileUpdate includes less properties that the full response
     * @returns the Full updated user details
     */
    async updateProfile(profile) {
        const profileUpdateResponse = await this.put("/me", profile);
        return profileUpdateResponse.data;
    }
    /**
   * Updates the user's password
   * @param passwords Low6PasswordChangeRequest
   * @returns the Full updated user details
   */
    async changePassword(passwords) {
        await this.put("/auth/password", passwords);
    }
    /**
     * Converts in-game cash to coins.
     * @param amount The amount of coins to convert.
     */
    async convertToCoins(amount) {
        await this.post("/ultimatefan/wallet/coins/convert-from-cash", { amount });
    }
}
export default new Low6API({
    urls: {
        development: low6.staging,
        production: low6.production
    },
    defaultHeaders
});
