import { SquadItemPosition, SquadItemType } from "@/services/api/uf/types/uf.api.types.enums";
import config from "@/services/config/config";
const { league } = config.skin;
export function squadPositionLabel(squadItem) {
    switch (league) {
        case "pl":
            if (squadItem.scoreableType === SquadItemType.Team) {
                return 'T';
            }
            else {
                switch (squadItem.position) {
                    case SquadItemPosition.Any: return 'A';
                    case SquadItemPosition.Defender: return 'D';
                    case SquadItemPosition.Midfielder: return 'M';
                    case SquadItemPosition.Forward: return 'F';
                    default: return '';
                }
            }
        case "nfl":
            if (squadItem.scoreableType === SquadItemType.Team) {
                return 'T';
            }
            else {
                switch (squadItem.position) {
                    case SquadItemPosition.Any: return 'A';
                    case SquadItemPosition.Defender: return 'RB';
                    case SquadItemPosition.Midfielder: return 'TE';
                    case SquadItemPosition.Forward: return 'WR';
                    case SquadItemPosition.Goalkeeper: return 'QB';
                    default: return '';
                }
            }
    }
}
