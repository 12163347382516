/**
 * Checks whether a user is located in the UK or Ireland
 * @param geoLocation A reverse geo location response representing the location of the user
 * @returns Whether the user is located in the UK or Ireland
 */
export function checkGeoLocationIsInUKAndIreland(geoLocation) {
    return checkGeoLocationContainsCountryCode(geoLocation, ['GB', 'IE']);
}
/**
 * Checks whether a user is located in the US or Canada
 * @param geoLocation A reverse geo location response representing the location of the user
 * @returns Whether the user is located in the US or Canada
 */
export function checkGeoLocationIsInUSAndCanda(geoLocation) {
    return checkGeoLocationContainsCountryCode(geoLocation, ['US', 'CA']);
}
/**
 * Checks whether a user is located in the US
 * @param geoLocation A reverse geo location response representing the location of the user
 * @returns Whether the user is located in the US
 */
export function checkGeoLocationIsInUS(geoLocation) {
    return checkGeoLocationContainsCountryCode(geoLocation, ['US']);
}
/**
 * Checks whether a user is located in a set of countries
 * @param geoLocation A reverse geo location response representing the location of the user
 * @param countryCodes Short form country codes of the countries to check
 * @returns Whether the user is located in the countries
 */
function checkGeoLocationContainsCountryCode(geoLocation, countryCodes) {
    for (const result of geoLocation.results) {
        if (result.types.includes("country")) {
            for (const addressComponent of result.address_components) {
                if (countryCodes.includes(addressComponent.short_name)) {
                    return true;
                }
            }
        }
    }
    return false;
}
