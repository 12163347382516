var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import chargersImage from "@/components/asset/chargers-stamp/logo-chargers.png";
let ChargersStamp = class ChargersStamp extends LitElement {
    constructor() {
        super(...arguments);
        this.fill = "#FF284F";
    }
    render() {
        return html `
      <div>
        ${this.renderChargersLogo()}
      </div>
    `;
    }
    renderChargersLogo() {
        return html `
      <img src=${chargersImage}>
    `;
    }
};
ChargersStamp.styles = [css `
    :host {
      display: flex;
    }

    div {
      display: flex;
      margin: 0 auto;
    }

    img {
      width: 11.75em;
    }

  `];
__decorate([
    property()
], ChargersStamp.prototype, "fill", void 0);
ChargersStamp = __decorate([
    customElement("chargers-stamp")
], ChargersStamp);
export { ChargersStamp };
