import { css } from "lit";
const gameCardFrontWideStyles = css `

  :host {
    --border-radius: 1.25em;
    --standard-background: linear-gradient(255.06deg, #292929 6.99%, #454545 86.86%);
    --silver-background: linear-gradient(255.06deg, #ACACAC 6.99%, #F3F3F3 86.86%);
    --gold-background: linear-gradient(255.06deg, #926F34 6.99%, #FFD46B 86.86%);
    font-size: 1em;
  }

  :host > div {
    width: 100%;
    height: 4em;
    padding-left: 6.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  :host > div:not(.standard) {
    border: 0.1875em solid var(--color);
  }

  #modifier-label {
    --size: 1.6875em;
    position: absolute;
    top: -0.8em;
    left: -0.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    background-color: #fff;
    padding: 0.25em;
    cursor: pointer;
  }

  #img-area {
    --size: 5.5em;
    position: absolute;
    left: 0.6em;
    bottom: 0;
    width: var(--size);
    height: var(--size);
    overflow: hidden;
  }

  #img {
    --team-img-width: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 85%;
    bottom: -0.2em;
  }
  :host > div.team #img {
    background-size: var(--team-img-width);
    bottom: -0.6em;
  }

  #img.img-placeholder {
    bottom: -0.6em;
  }
  #img.img-placeholder svg {
    width: 100%;
    height: 100%;
  }
  :host > div.team #img.img-placeholder {
    width: 2.6em;
    left: 1.4em;
    bottom: -0.5em;
  }

  #name p {
    margin: 0;
    line-height: 1;
    font-size: 1.1em;
  }
  :host > div.silver #name {
    color: #383838;
  }

  #name p span {
    font-weight: 700;
  }

  #name p#team {
    font-size: 0.75em;
    font-weight: 500;
  }

  #details {
    display: flex;
    column-gap: 0.5em;
  }

  #icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.375em;
  }

  #roundel {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    --roundel-size: 1.0625em;
    width: var(--roundel-size);
    height: var(--roundel-size);
  }

  #label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.0625em;
    height: 0.625em;
    background-color: var(--color-background);
    border-radius: 0.1875em;
    line-height: 1;
  }
  #label.silver {
    background-color: #868585;
  }
  #label.gold {
    background-color: #926F34;
  }
  #label > span {
    font-weight: 700;
    font-size: 0.55em;
    margin-top: 0.1em;
  }

  #points {
    background-color: #0F0F0F;
    border-radius: 0.625em;
    width: 4.25em;
    height: 2.875em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.25em;
    text-align: center;
    margin-right: 0.5em;
    line-height: 1;
  }

  #points label {
    color: #858585;
    font-size: 0.55em;
    font-weight: 500;
  }

  #points span {
    font-size: 1.25em;
    font-weight: 700;
  }

`;
export default gameCardFrontWideStyles;
