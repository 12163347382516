var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./card-view.modal.css";
import "@/components/uf-modal/uf-modal";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/uf-swiper-new/uf-swiper-new";
import "@/components/game-card/game-card-flippable/game-card-flippable";
import { SquadItemSet } from "@/services/api/uf/types/uf.api.types.enums";
import { sortSquadByRating } from "~/app/helpers/squad.helpers";
import ufApi from "@/services/api/uf/uf.api";
import { deepEqual } from "@/services/utils/utils";
import { cardViewCurrent } from "~/app/config/card-view.config";
import { removedCard } from "~/app/config/lineup.config";
import router from "@/router/router";
import { lineupSearchTerm } from "~/app/config/collection.config";
let CardViewModal = class CardViewModal extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.squad = [];
        this.cards = [];
        this.cardViewIndex = 0;
        this.lineupIsEditable = false;
    }
    get activeSquad() {
        return this.squad.filter(item => item.set !== SquadItemSet.Collection);
    }
    connectedCallback() {
        super.connectedCallback();
        this.handleSquad();
        ufApi.getSquad().catch(error => () => this.handleErrors(error));
        this.handleCardView();
        this.checkLineupEditable();
    }
    handleSquad() {
        this.squadSubscription = ufApi.squad.subscribe(squad => {
            if (!deepEqual(this.squad, squad)) {
                this.squad = squad;
                this.cards = sortSquadByRating(this.squad.filter(item => item.set !== SquadItemSet.Collection));
            }
        });
    }
    handleCardView() {
        this.cardViewSubscription = cardViewCurrent.subscribe(current => {
            // Find the location of the selected card in this.activeSquad.
            const index = this.cards.findIndex(card => card.cardId === (current === null || current === void 0 ? void 0 : current.cardId));
            // Default to 0 if not found.
            this.cardViewIndex = index >= 0 ? index : 0;
            this.cardViewCurrent = current;
        });
    }
    async checkLineupEditable() {
        try {
            this.lineupIsEditable = await ufApi.checkLineupEditable();
        }
        catch (e) {
            this.handleErrors(e);
        }
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        var _a, _b;
        super.disconnectedCallback();
        (_a = this.squadSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.squadSubscription = undefined;
        (_b = this.cardViewSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        this.cardViewSubscription = undefined;
    }
    render() {
        return html `
      ${when(this.cardViewCurrent, () => html `
          <uf-modal id="card-view-modal" class=${!this.lineupIsEditable ? "padded" : ""}>
            <header>
              <uf-icon icon="cancel" class="clickable" @click=${this.closeCardView}></uf-icon>
            </header>

            <uf-swiper-new
              navigation
              pagination
              activeIndex=${this.cardViewIndex}
              @indexchange=${(e) => cardViewCurrent.next(this.cards[e.detail])}
              .content=${this.cards.map(card => html `
                <game-card-flippable .squadItem=${card}></game-card-flippable>
              `)}
            ></uf-swiper-new>

            ${when(this.lineupIsEditable, () => {
            var _a;
            return html `
                <footer>
                  ${when(this.activeSquad.length >= 50, () => {
                var _a;
                return html `
                      ${((_a = this.cardViewCurrent) === null || _a === void 0 ? void 0 : _a.set) === SquadItemSet.Collection ? html `
                          <button @click=${() => this.goToTransfers("in", this.cardViewCurrent.cardId)}>
                            <uf-icon icon="transfers"></uf-icon>
                            Transfer In
                          </button>
                        ` : html `
                          <button @click=${() => this.goToTransfers("out", this.cardViewCurrent.cardId)}>
                            <uf-icon icon="transfers"></uf-icon>
                            Transfer Out
                          </button>
                        `}
                    `;
            })}
                  ${((_a = this.cardViewCurrent) === null || _a === void 0 ? void 0 : _a.set) === SquadItemSet.Lineup ? html `
                      <button @click=${this.removeFromLineup}>
                        <uf-icon icon="jersey" theme="filled"></uf-icon>
                        Remove from Lineup
                      </button>
                    ` : html `
                      <button @click=${this.addToLineup}>
                        <uf-icon icon="jersey" theme="filled"></uf-icon>
                        Add to Lineup
                      </button>
                    `}
                </footer>
              `;
        })}

          </uf-modal>
        `)}
    `;
    }
    closeCardView() {
        cardViewCurrent.next(null);
    }
    addToLineup() {
        const route = router.routeChange.getValue();
        if (route.path.includes("browse"))
            router.navigate("squad");
        const current = this.cardViewCurrent;
        lineupSearchTerm.next(current.knownname ? current.knownname : current.lastname ? `${current.lastname}` : "");
        this.closeCardView();
    }
    removeFromLineup() {
        const current = this.cardViewCurrent;
        removedCard.next(current);
        this.closeCardView();
    }
    goToTransfers(transfer, cardId) {
        this.closeCardView();
        router.navigate("squad/transfer", {
            transfer, cardId
        });
    }
};
__decorate([
    state()
], CardViewModal.prototype, "cards", void 0);
__decorate([
    state()
], CardViewModal.prototype, "cardViewCurrent", void 0);
__decorate([
    state()
], CardViewModal.prototype, "cardViewIndex", void 0);
__decorate([
    state()
], CardViewModal.prototype, "lineupIsEditable", void 0);
CardViewModal = __decorate([
    customElement("card-view-modal")
], CardViewModal);
export { CardViewModal };
