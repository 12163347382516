var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconWarning = class IconWarning extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = "triangle";
    }
    render() {
        switch (this.theme) {
            case "triangle": return this.renderTriangle();
            case "circle": return this.renderCircle();
            default: return this.renderTriangle();
        }
    }
    renderTriangle() {
        return html `
      <svg id="triangle" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.14973 0.59752C5.68976 -0.199174 4.53983 -0.199173 4.07986 0.597521L0.161888 7.38364C-0.298084 8.18034 0.276882 9.1762 1.19682 9.1762H9.03276C9.9527 9.1762 10.5277 8.18034 10.0677 7.38364L6.14973 0.59752ZM5.57589 6.05667H4.65401L4.40998 3.77242V2.04846H5.81089V3.77242L5.57589 6.05667ZM5.88319 7.4617C5.88319 7.86683 5.53974 8.19438 5.11495 8.19438C4.69016 8.19438 4.34671 7.86683 4.34671 7.4617C4.34671 7.05657 4.69016 6.72902 5.11495 6.72902C5.53974 6.72902 5.88319 7.05657 5.88319 7.4617Z" />
      </svg>
    `;
    }
    renderCircle() {
        return html `
      <svg id="circle" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.5" cy="21.5" r="21.5" />
        <path
          d="M20.4815 23.6507H22.5215L23.0735 16.9307V12.1307H19.9535V16.9307L20.4815 23.6507ZM21.5135 29.2187C22.4975 29.2187 23.3135 28.4027 23.3135 27.4187C23.3135 26.4107 22.4975 25.6187 21.5135 25.6187C20.5055 25.6187 19.7135 26.4107 19.7135 27.4187C19.7135 28.4027 20.5055 29.2187 21.5135 29.2187Z" />
      </svg>
    `;
    }
};
IconWarning.styles = [iconCSS, css `
    #triangle path {
      fill: var(--color);
    }
    #circle circle {
      fill: #FF284F;
    }
    #circle path {
      fill: #ffffff;
    }
  `];
__decorate([
    property()
], IconWarning.prototype, "theme", void 0);
IconWarning = __decorate([
    customElement("icon-warning")
], IconWarning);
export { IconWarning };
