import { css } from "lit";
const gameCardFrontStyles = css `

  :host > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    position: relative;
  }
  
  :host > div.modifier > div {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 0.15em solid #fff;
    border-bottom: none;
    border-radius: var(--border-radius) var(--border-radius) calc(var(--border-radius) * 1.2) calc(var(--border-radius) * 1.2);
  }

  .img {
    --team-img-width: 45%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 85%;
    bottom: 1.6em;
  }
  :host > div.team .img {
    background-size: var(--team-img-width);
    bottom: 1.15em;
  }
  :host > div.modifier svg {
    position: absolute;
    left: 50%;
    top: 37.5%;
    transform: translate(-50%, -50%);
    width: 70%;
  }


  .img-placeholder {
    height: 100%;
    bottom: 0.5em;
  }
  .img-placeholder svg {
    width: 100%;
    height: 100%;
  }
  :host > div.team .img-placeholder {
    bottom: 0.6em;
  }
  :host > div.team .img-placeholder svg {
    width: var(--team-img-width);
  }

  .roundel {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    --roundel-size: 1.0625em;
    width: var(--roundel-size);
    height: var(--roundel-size);
    left: 0.5em;
    top: 0.5em;
  }

  .label {
    position: absolute;
    top: 0.75em;
    right: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.0625em;
    height: 0.625em;
    background-color: var(--color-background);
    border-radius: 0.1875em;
    line-height: 1;
  }
  .label.silver {
    background-color: #868585;
  }
  .label.gold {
    background-color: #926F34;
  }
  .label > span {
    font-weight: 700;
    font-size: 0.55em;
    margin-top: 0.1em;
  }

  footer {
    position: relative;
    z-index: 1;
  }
  footer > div:first-child {
    background-color: #1e1e1e;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    padding: 0.3125em;
    min-height: 1.5em;
    line-height: 1.2;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* footer > div:first-child > div {

  } */
  footer > div:first-child.warn {
    border-top-right-radius: 1em;
  }
  :host > div.silver footer > div:first-child {
    background-color: rgba(134, 133, 133, 1);
  }
  :host > div.gold footer > div:first-child {
    background-color: rgba(184, 140, 65, 1);
  }
  footer p {
    font-size: 0.6875em;
    margin: 0;
  }
  footer p span {
    font-weight: 700;
  }
  footer p.team {
    font-size: 0.55em;
  }

  footer .warning {
    position: absolute;
    width: 1.5em;
    height: 1.85em;
    top: -0.9em;
    right: 0;
    z-index: -1;
    background: linear-gradient(0deg, rgba(238,0,9,1) 0%, rgba(242,115,10,1) 100%);
    border-top-left-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  footer .warning uf-icon {
    width: 0.625em;
    margin-top: 0.225em;
    margin-left: 0.075em;
  }

`;
export default gameCardFrontStyles;
