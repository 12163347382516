var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./end.view.css";
import config from "~/app/services/config/config";
let EndView = class EndView extends UnscopedElement {
    render() {
        return html `
      <div>

        ${when(config.isUF, () => html `<logo-uf></logo-uf>`)}
        ${when(config.isBengals, () => html `<logo-bengals></logo-bengals>`)}
        ${when(config.isChargers, () => html `<logo-chargers></logo-chargers>`)}

        <div>
          <h1>The 2022 game has finished</h1>
          <p>Thank you for playing ${config.skin.displayName || config.skin.title} through the regular season.</p>
          <p>Keep your eye on your emails for news and updates ahead of the 2023 ${config.skin.league.toUpperCase()} Season.</p>
        </div>

      </div>
    `;
    }
};
EndView = __decorate([
    customElement("end-view")
], EndView);
export { EndView };
