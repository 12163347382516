/**
 * Determines the current contest and whether you are in a gap between contests.
 * @param stages Array of all stages of the competition.
 * @returns If found, returns the current stage wrapped in an object that also has a property for whether the current stage is live or not.
 */
export function findCurrentStage(stages) {
    if (!stages.length)
        return null;
    const now = Math.round(Date.now() / 1000);
    let live;
    let foundStage;
    stages.some(stage => {
        if (!stage.completed) {
            live = now >= stage.start;
            return foundStage = stage;
        }
        return;
    });
    return { live, stage: foundStage };
}
/**
 * Determines the ranking corresponding to the current stage.
 * @param ranks All stage rankings for the user.
 * @param currentStage The current stage.
 * @returns If found, returns the user ranking for the current stage.
 */
export function findCurrentRank(ranks, currentStage) {
    return ranks.find(rank => rank.stageId === currentStage.id);
}
export function findCurrentLeaderboardPositionEntry(positionEntries, rank) {
    return positionEntries.find(entry => entry.userId === rank.ultimateFanUserId);
}
