import ConfigurationService from "@/services/config/config";
/**
 * Formats a number as currency (GBP).
 * @param amount Amount in pennies.
 * @returns Amount formatted as GBP.
 */
export function formatAsCurrency(amount) {
    amount = amount / 100;
    return `${amount.toLocaleString(ConfigurationService.locale(), {
        style: "currency",
        currency: ConfigurationService.currencyLocale(),
        // Ensures that that fractional amounts are shown with the full number of decimal points and non-fractional amounts are not.
        minimumFractionDigits: Math.floor(amount) === amount ? 0 : 2
    })}`;
}
/**
 * Appends an appropriate 'nth' value to the end of a given number.
 * @param number Number to be appended.
 * @returns The amount formatted with 'nth' appended. E.g. 1st, 25th, 32nd.
 */
export function formatAsNth(number) {
    let nth = "th";
    if (number !== 11 && number !== 12 && number !== 13) {
        const amountString = number.toString();
        const amountEndDigit = +amountString[amountString.length - 1];
        switch (amountEndDigit) {
            case 1:
                nth = "st";
                break;
            case 2:
                nth = "nd";
                break;
            case 3:
                nth = "rd";
                break;
        }
    }
    return `${number.toLocaleString()}${nth}`;
}
/**
 * Formats two dates in the format MM dd - dd. e.g Jan 01 - 10, Feb 02 - Mar 01
 * @param start The first date to show in ms
 * @param end The second date to show in ms
 * @returns The formatted date as a string
 */
export function formatAsStageDate(start, end) {
    const startDate = new Date(start * 1000);
    const endDate = new Date(end * 1000);
    if (startDate.getMonth() == endDate.getMonth()) {
        const month = startDate.toLocaleDateString("en-GB", { month: 'short' });
        const startDay = startDate.toLocaleDateString("en-GB", { day: '2-digit' });
        const endDay = endDate.toLocaleDateString("en-GB", { day: '2-digit' });
        return `${month} ${startDay} - ${endDay}`;
    }
    else {
        const startMonth = startDate.toLocaleDateString("en-GB", { month: 'short' });
        const endMonth = startDate.toLocaleDateString("en-GB", { month: 'short' });
        const startDay = startDate.toLocaleDateString("en-GB", { day: '2-digit' });
        const endDay = endDate.toLocaleDateString("en-GB", { day: '2-digit' });
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
    }
}
/**
 * Formats date to Today, Yesterday or in format dd MMM yyyy
 * @param date The date to format
 * @returns The formatted date as a string
 */
export function formatAsReferralDate(date) {
    const today = new Date();
    const todayMonth = today.getMonth();
    const todayDay = today.getDate();
    const dateMonth = date.getMonth();
    const dateDay = date.getDate();
    if (todayMonth == dateMonth && dateDay == todayDay) {
        return 'Today';
    }
    else if (todayMonth == dateMonth && (dateDay == todayDay - 1)) {
        return 'Yesterday';
    }
    else {
        const day = date.toLocaleDateString("en-GB", { day: '2-digit' });
        const month = date.toLocaleDateString("en-GB", { month: 'short' });
        const year = date.toLocaleDateString("en-GB", { year: 'numeric' });
        return `${day} ${month} ${year}`;
    }
}
/**
 * Finds the prize in the breakdown for a specific rank. NB User the Position Entries 'Draft Winnings' field if the game engine is setup to use monetary prizes (such as PL)
 * @param stageBreakdown The stage breakdown in the format '1='Season Tickets',2='Fan Merch'...'
 * @param position The position to find the prize for
 * @returns The prize for the associated position entry
 */
export function prizeFromPrizeBreakdown(stageBreakdown, position, isRankTied) {
    // if (position.score === 0) { return undefined } // If the score is 0 then don't show any prize as this is the start of the competition where everyone has a position of 1 so the leaderboard would show all poeple having won a prize
    const prizes = stageBreakdown.split(',');
    var expandedPrizeList = [];
    for (const prize of prizes) {
        // Prize breakdown is in the form '1=100,2=50,3=25...' or '1='Season Tickets',2='Fan Merch'...
        const prizeBreakdown = prize.split("=");
        const positions = prizeBreakdown[0].split('-');
        const prizeAmount = (+prizeBreakdown[1])
            ?
                formatAsCurrency(+prizeBreakdown[1] * 100)
            :
                prizeBreakdown[1].replace(/^\'+|\'+$/g, ''); // Trim off the ' at the start and end of the prize
        if (positions.length == 1) {
            expandedPrizeList.push(prizeAmount);
        }
        else {
            let startPosition = +positions[0];
            let endPosition = +positions[1];
            for (var i = startPosition; i < endPosition; i++) {
                expandedPrizeList.push(prizeAmount);
            }
        }
    }
    let prize = expandedPrizeList[position - 1];
    return prize && isRankTied ? "Tie" : prize;
}
/**
 * Checks whether an entry in a list of entries has the same rank as another entry in the list
 * @param entries All entries to check. Must be in ascending rank order
 * @param entryIndex The index of the entry to check
 * @returns Whether the entry at entryIndex is tied in rank to another entry
 */
export function isRankTied(entries, entryIndex) {
    // Since entries are in order by rank we only need to check the entries either side
    const entry = entries[entryIndex];
    const prevEntry = entries[entryIndex - 1];
    const nextEntry = entries[entryIndex + 1];
    if (!entry) {
        return false;
    }
    if (entry.rank === (prevEntry === null || prevEntry === void 0 ? void 0 : prevEntry.rank) || entry.rank === (nextEntry === null || nextEntry === void 0 ? void 0 : nextEntry.rank)) {
        return true;
    }
    return false;
}
