import low6Api from "../api/low6/low6.api";
import router from "@/router/router";
import analytics from "../utils/analytics";
class SessionService {
    constructor() {
        this.low6SessionKey = "low6Login";
        this.low6LoginStreakKey = "low6LoginStreak";
    }
    async getExisting() {
        let session = this.getLocal(this.low6SessionKey);
        let login = null;
        if (session) {
            const now = Date.now();
            if (now >= session.expiryDate) {
                login = await low6Api.refreshTokens(session.login.refresh_token);
            }
            else {
                login = session.login;
            }
        }
        return login;
    }
    createNew(low6Login) {
        const session = {
            login: low6Login,
            expiryDate: Date.now() + (low6Login.expires_in * 1000)
        };
        this.setLocal(this.low6SessionKey, session);
    }
    setLoginStreak(loginStreak) {
        this.setLocal(this.low6LoginStreakKey, loginStreak);
    }
    getSavedLoginStreak() {
        var _a;
        return (_a = this.getLocal(this.low6LoginStreakKey)) !== null && _a !== void 0 ? _a : 0;
    }
    getLocal(key) {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }
    setLocal(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    }
    clear() {
        localStorage.clear();
    }
    logout() {
        analytics.recordLogout();
        this.clear();
        low6Api.clearAuthentication();
        router.navigate('/');
    }
}
export default new SessionService();
