var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconCountdown = class IconCountdown extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="${this.theme}" d="M12.816 5.184C11.763 4.131 10.386 3.6 9 3.6V9L5.184 12.816C7.29 14.922 10.71 14.922 12.825 12.816C14.931 10.71 14.931 7.29 12.816 5.184ZM9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.022 16.2 1.8 12.978 1.8 9C1.8 5.022 5.022 1.8 9 1.8C12.978 1.8 16.2 5.022 16.2 9C16.2 12.978 12.978 16.2 9 16.2Z" />
      </svg>
    `;
    }
};
IconCountdown.styles = [iconCSS, css `
    path {
      fill: var(--color)
    }
    path.black {
      fill: #151515
    }
  `];
__decorate([
    property()
], IconCountdown.prototype, "theme", void 0);
IconCountdown = __decorate([
    customElement("icon-countdown")
], IconCountdown);
export { IconCountdown };
