var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import globalCSS from "~/styles/global.css";
import loginStreakCardCSS from "./login-streak-card.css";
import ConfigurationService from "@/services/config/config";
let LoginStreakCard = class LoginStreakCard extends LitElement {
    constructor() {
        super(...arguments);
        this.loginStreak = 1;
        this.newLoginStreak = 0;
        this.isInfoHidden = false;
        this.isAnimate = false;
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.isAnimate) {
            setTimeout(() => {
                this.loginStreak = this.newLoginStreak;
            }, 1000);
        }
    }
    render() {
        var _a;
        return html `
            <div class="card-header" >
                <div class="top">
                    <!-- <uf-icon icon="share" theme=${this.getIconTheme()}></uf-icon></button> -->
                    <div slot="center">
                        <h1>${(_a = this.loginStreak) === null || _a === void 0 ? void 0 : _a.toString()}</h1>
                        <h3>Day Login Streak</h3>
                    </div>
                    
                    <router-link href="/login-streak/onboarding">
                        <uf-icon icon="helpFilled" theme=${this.getIconTheme()} ?hidden=${this.isInfoHidden}></uf-icon></button>
                    </router-link>
                </div>

                <div class="body" >
                    <div class="ladder-container">
                        <uf-icon icon="loginStreakLadder" 
                        value=${this.loginStreak} 
                        theme=${this.getLadderTheme()}></uf-icon>
                        <div class="ladder">
                            <p class="${this.isNumberSelected(1) ? 'day-selected' : ''}">1</p>
                            <p class="${this.isNumberSelected(2) ? 'day-selected' : ''}">2</p>
                            <p class="${this.isNumberSelected(3) ? 'day-selected' : ''}">3</p>
                            <p class="${this.isNumberSelected(4) ? 'day-selected' : ''}">4</p>
                            <p class="${this.isNumberSelected(5) ? 'day-selected' : ''}">5</p>
                            <p class="${this.isNumberSelected(6) ? 'day-selected' : ''}">6</p>
                            <p class="${this.isNumberSelected(7) ? 'day-selected' : ''}">7</p>
                            <p class="${this.isNumberSelected(8) ? 'day-selected' : ''}">8</p>
                            <p class="${this.isNumberSelected(9) ? 'day-selected' : ''}">9</p>
                            <p class="${this.isNumberSelected(10) ? 'day-selected' : ''}">10</p>
                        </div>
                        <div class="reward-card" id="reward1">
                            ${this.renderPackRewardCard(1, 1)}
                        </div>
                        <div class="reward-card" id="reward2">
                            ${this.renderPackRewardCard(2, 2)}
                        </div>
                        <div class="reward-card" id="reward3">
                            ${this.renderPackRewardCard(3, 2)}
                        </div>
                        <div class="reward-card" id="reward4">
                            ${this.renderPackRewardCard(4, 1)}
                        </div>
                    </div>

                    
                </div>
            </div>
        `;
    }
    isNumberSelected(value) {
        return this.loginStreak >= value;
    }
    getIconTheme() {
        return ConfigurationService.isUF ? "white" : "black";
    }
    getLadderTheme() {
        if (ConfigurationService.isBengals)
            return "orange";
        else if (ConfigurationService.isChargers)
            return "yellow";
        else
            return "white";
    }
    getSelectedLozengeTheme() {
        if (ConfigurationService.isBengals)
            return "orange";
        else if (ConfigurationService.isChargers)
            return "yellow";
        else
            return "red";
    }
    renderPackRewardCard(index, rewardAmount) {
        return html `
            ${when(this.isRewardCardSelected(index), () => html `
                    <div class="pack-wrapper" 
                    style="color:var(--color-reward-card-font)">
                        <uf-icon icon="rewardLozenge" theme="${this.getSelectedLozengeTheme()}"></uf-icon>
                        <span class="small-text">+${rewardAmount}</span>
                        <uf-icon icon="packReward" theme="${ConfigurationService.isChargers ? "black" : "white"}"></uf-icon>
                    </div>
                `, () => html `
                    <div class="pack-wrapper" 
                    style="color:var(--color-reward-card-font-disabled)">
                        <uf-icon icon="rewardLozenge" theme="${ConfigurationService.isUF ? "red" : "grey"}"></uf-icon>
                        <span class="small-text">+${rewardAmount}</span>
                        <uf-icon icon="packReward"></uf-icon>
                    </div>
                    
                `)}
        `;
    }
    isRewardCardSelected(value) {
        switch (value) {
            case 2:
                return (this.loginStreak) >= 4;
            case 3:
                return (this.loginStreak) >= 7;
            case 4:
                return (this.loginStreak) >= 10;
            default:
                return (this.loginStreak) >= 1;
        }
    }
};
LoginStreakCard.styles = [globalCSS, loginStreakCardCSS];
__decorate([
    property({ type: Number })
], LoginStreakCard.prototype, "loginStreak", void 0);
__decorate([
    property({ type: Number })
], LoginStreakCard.prototype, "newLoginStreak", void 0);
__decorate([
    property({ type: Boolean })
], LoginStreakCard.prototype, "isInfoHidden", void 0);
__decorate([
    property({ type: Boolean })
], LoginStreakCard.prototype, "isAnimate", void 0);
LoginStreakCard = __decorate([
    customElement("login-streak-card")
], LoginStreakCard);
export { LoginStreakCard };
