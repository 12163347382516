var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconFilter = class IconFilter extends LitElement {
    render() {
        return html `
      <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="16" width="8" height="2" rx="1" />
        <rect x="4" y="8" width="16" height="2" rx="1" />
        <rect width="24" height="2" rx="1" />
      </svg>    
    `;
    }
};
IconFilter.styles = [iconCSS, css `
    rect {
      fill: var(--color);
    }
  `];
IconFilter = __decorate([
    customElement("icon-filter")
], IconFilter);
export { IconFilter };
