var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { choose } from "lit/directives/choose.js";
import "./open-pack-overlay.css";
import "@/components/uf-overlay/uf-overlay";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/header-bar/header-bar";
import "@/components/pack-item/pack-item";
import "@/components/game-card/game-card-flippable/game-card-flippable";
import "@/components/uf-tooltip/uf-tooltip";
import { packAnimations } from "./image.mappings";
import ufApi from "@/services/api/uf/uf.api";
import { SquadItemRating } from "~/app/services/api/uf/types/uf.api.types.enums";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
import { sortSquadByRating } from "~/app/helpers/squad.helpers";
let OpenPackOverlay = class OpenPackOverlay extends UnscopedElement {
    constructor() {
        var _a;
        super(...arguments);
        this.packs = [];
        this.current = 0;
        this.currentCardRating = (_a = this.currentPackCardsSorted[0]) === null || _a === void 0 ? void 0 : _a.cardRating;
        this.currentPackCardIndex = 0;
        this.heading = "Pack";
        this.animationReady = false;
        this.animationStarted = false;
        this.packHidden = false;
        this.cardsVisible = false;
        this.navigationNextHidden = true;
        this.firstSignupCardFlipped = false;
        this.firstSignupCardFlipComplete = false;
    }
    get currentPack() {
        return this.packs[this.current];
    }
    get currentPackCardsSorted() {
        var _a;
        return sortSquadByRating(((_a = this.currentPack) === null || _a === void 0 ? void 0 : _a.cards) || []);
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.currentPack && this.heading === "Pack") {
            this.heading = this.currentPack.name;
        }
        this.readyImage();
        analytics.recordTag(AnalyticsKeys.PACK_PAGE);
        this.swiper;
    }
    readyImage() {
        if (this.currentPack) {
            this.gifImg = packAnimations.get(this.currentPack.code);
            this.heading = this.currentPack.name;
            this.updateGifImgFilename();
            if (this.gifImg) {
                const img = document.createElement("img");
                img.src = this.gifImg;
                img.addEventListener("load", () => this.animationReady = true);
            }
        }
    }
    render() {
        var _a;
        return html `
      <uf-overlay>
        <div>

          <header-bar>
            ${when(((_a = this.currentPack) === null || _a === void 0 ? void 0 : _a.code) !== "0", () => html `
                <uf-icon slot="left" icon="cancel" class="clickable" @click=${this.cancel}></uf-icon>
              `)}
            <h2 slot="center">${this.heading}</h2>
          </header-bar>

          <div id="content">

            ${when(!this.packHidden, () => {
            var _a;
            return html `
                <div id="pack-area">
                  <p class=${classMap({ "hidden": this.animationStarted })}>
                    ${((_a = this.currentPack) === null || _a === void 0 ? void 0 : _a.code) === "0" ? html `
                        Open your FREE ${this.currentPack.cards.length}-card pack
                      ` : html `
                        Open your pack
                      `}
                  </p>
      
                  <div id="pack">
                    ${when(this.currentPack && !this.animationStarted, () => html `
                        <pack-item code=${this.currentPack.code} @click=${this.openPack}></pack-item>
                      `)}
                    ${when(this.gifImg && this.animationStarted, () => html `
                        <div id="animation" style="background-image: url(${this.gifImg})"></div>
                      `)}
                  </div>
      
                  ${when(this.animationReady && !this.animationStarted, () => html `
                      <p>click to open</p>
                    `)}
                </div>
              `;
        })}

            ${when(this.currentPack && this.cardsVisible, () => html `
                <div id="cards-area">
                  <div>
                    <uf-swiper-new
                      navigation
                      ?navigationnexthidden=${this.navigationNextHidden}
                      pagination
                      ?notouchmove=${this.currentPack.code === "0"}
                      .content=${this.currentPackCardsSorted.map((card, i) => html `
                        <game-card-flippable .squadItem=${card} @flipend=${() => this.cardFlipped(i)}></game-card-flippable>
                      `)}
                      @indexchange=${this.nextCard}
                    ></uf-swiper-new>

                    ${this.currentPack.code === "0" ? html `
                        ${when(this.currentPackCardIndex === this.currentPack.cards.length - 1, () => html `
                            <button @click=${this.next}>Next</button>
                          `)}
                      ` : html `
                        ${this.current >= this.packs.length - 1 ? html `
                            <router-link href="/squad">
                              <button @click=${this.cancel}>Manage Squad</button>
                            </router-link>
                          ` : html `
                            <button @click=${this.openNextpack}>Open Next Pack</button>
                          `}
                      `}

                    ${when(this.currentPack.code === "0", () => html `
                        ${when(this.currentPackCardIndex === 0, () => html `
                            ${when(!this.firstSignupCardFlipComplete, () => html `
                                ${when(!this.firstSignupCardFlipped, () => html `
                                    <uf-tooltip position="bottom">
                                      Click a player card to view stats
                                    </uf-tooltip>
                                  `)}
                                ${when(this.firstSignupCardFlipped, () => html `
                                    <uf-tooltip position="bottom">
                                      Click card to flip back
                                    </uf-tooltip>
                                  `)}
                              `)}
                          `)}
                        ${when(this.firstSignupCardFlipComplete, () => choose(this.currentCardRating, [
            [SquadItemRating.Gold, () => html `
                              <uf-tooltip position="bottom">
                                Our special edition Gold cards are likely to perform well for you
                              </uf-tooltip>
                            `],
            [SquadItemRating.Silver, () => html `
                              <uf-tooltip position="bottom">
                                Our Silver cards are likely to perform well for you
                              </uf-tooltip>
                            `]
        ]))}
                      `)}
                  </div>
                </div>
              `)}

          </div>

        </div>
      </uf-overlay>
    `;
    }
    // Append a random string to 'reset' the animation
    updateGifImgFilename() {
        this.gifImg = `${this.gifImg}?x=${Math.random()}`;
    }
    openNextpack() {
        this.current++;
        this.readyImage();
        this.packHidden = false;
        this.cardsVisible = false;
        this.animationStarted = false;
        this.animationReady = false;
    }
    cancel() {
        this.dispatchEvent(new Event("cancel"));
    }
    async openPack() {
        // analytics.recordTag(AnalyticsKeys.PACK_OPENED);
        if (this.currentPack) {
            this.animationStarted = true;
            setTimeout(() => {
                this.heading = "View your cards";
                this.cardsVisible = true;
                this.dispatchEvent(new Event("packopen"));
            }, 600);
            setTimeout(() => {
                this.packHidden = true;
            }, 1200);
            try {
                await ufApi.openPack(this.currentPack.packId);
                await ufApi.getSquad();
            }
            catch (error) {
                console.log(error);
            }
        }
    }
    nextCard(e) {
        this.currentPackCardIndex = e.detail;
        const card = this.currentPackCardsSorted[e.detail];
        this.currentCardRating = card.cardRating;
    }
    cardFlipped(i) {
        if (i === 0) {
            if (!this.firstSignupCardFlipped) {
                this.firstSignupCardFlipped = true;
            }
            else {
                this.firstSignupCardFlipComplete = true;
                this.currentCardRating = this.currentPackCardsSorted[i].cardRating;
                this.swiper.allowTouchMove();
                this.navigationNextHidden = false;
            }
        }
    }
    next() {
        this.dispatchEvent(new Event("next"));
    }
};
__decorate([
    property({ type: Array })
], OpenPackOverlay.prototype, "packs", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "current", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "currentCardRating", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "currentPackCardIndex", void 0);
__decorate([
    property()
], OpenPackOverlay.prototype, "heading", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "gifImg", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "animationReady", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "animationStarted", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "packHidden", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "cardsVisible", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "navigationNextHidden", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "firstSignupCardFlipped", void 0);
__decorate([
    state()
], OpenPackOverlay.prototype, "firstSignupCardFlipComplete", void 0);
__decorate([
    query("uf-swiper-new")
], OpenPackOverlay.prototype, "swiper", void 0);
OpenPackOverlay = __decorate([
    customElement("open-pack-overlay")
], OpenPackOverlay);
export { OpenPackOverlay };
