var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
const componentStyles = [css `
  .wrapper {
    display: flex;
    align-items: center;
    gap: 0.5em;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }

  .input {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    color: #f9f9f9;
    background: transparent;
    border: 0;
    outline: none;
    font-size: 1em;
    line-height: 1.5;
    font-family: inherit;
  }

  .wrapper.form-error {
    border-color: var(--color-primary);
  }

  .input:focus:not(.form-error) {
    border-color: #f9f9f9;
  }

  .error-message {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
`];
let FormTextBox = class FormTextBox extends LitElement {
    constructor() {
        super(...arguments);
        this.value = "";
        this.placeholder = "";
        this.name = "";
        this.customPrefix = "";
        this.id = "";
        this.type = "text";
        this.inputmode = "";
        this.pattern = "";
        this.required = false;
        this.minlength = 0;
        this.maxlength = 50;
        this.error = "";
        this.handleChange = () => null;
        this.onBlur = () => null;
        this.isTouched = false;
        this.errorMessage = "";
        this.onChange = (event) => {
            this.handleChange(event);
        };
        this.getWrapperClass = (originalClass) => {
            return `
      ${originalClass}
      ${this.errorMessage && " form-error"}
    `;
        };
        this.getClass = (originalClass) => {
            return `
      ${originalClass}
      ${this.errorMessage && " form-error"}
    `;
        };
    }
    render() {
        return html `
    <div class="wrapper" class=${this.getWrapperClass("wrapper")}>
      ${this.customPrefix && html `<span class="custom-prefix">${this.customPrefix}</span>`}
      <input 
        name=${this.name} 
        id=${this.id} 
        .type=${this.type} 
        placeholder=${this.placeholder} 
        value=${this.value}
        pattern=${this.pattern}
        inputmode=${this.inputmode}
        @blur=${this.onBlur} 
        @input=${this.onChange}
        class=${this.getClass("input")} 
        minLength=${this.minlength} 
        maxLength=${this.maxlength} 
        .required=${this.required}
      />
    </div>
    ${this.errorMessage ? html `<div class="error-message">${this.errorMessage}</div>` : null}
    `;
    }
};
FormTextBox.styles = [componentStyles];
__decorate([
    state()
], FormTextBox.prototype, "value", void 0);
__decorate([
    property()
], FormTextBox.prototype, "placeholder", void 0);
__decorate([
    property()
], FormTextBox.prototype, "name", void 0);
__decorate([
    property()
], FormTextBox.prototype, "customPrefix", void 0);
__decorate([
    property()
], FormTextBox.prototype, "id", void 0);
__decorate([
    property()
], FormTextBox.prototype, "type", void 0);
__decorate([
    property()
], FormTextBox.prototype, "inputmode", void 0);
__decorate([
    property()
], FormTextBox.prototype, "pattern", void 0);
__decorate([
    state()
], FormTextBox.prototype, "required", void 0);
__decorate([
    state()
], FormTextBox.prototype, "minlength", void 0);
__decorate([
    state()
], FormTextBox.prototype, "maxlength", void 0);
__decorate([
    property()
], FormTextBox.prototype, "error", void 0);
__decorate([
    state()
], FormTextBox.prototype, "handleChange", void 0);
__decorate([
    state()
], FormTextBox.prototype, "onBlur", void 0);
__decorate([
    state()
], FormTextBox.prototype, "isTouched", void 0);
__decorate([
    state()
], FormTextBox.prototype, "errorMessage", void 0);
FormTextBox = __decorate([
    customElement("form-textbox")
], FormTextBox);
export { FormTextBox };
