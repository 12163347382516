var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconRAFOnboarding = class IconRAFOnboarding extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
    <svg width="79" height="61" viewBox="0 0 79 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="${this.theme}" d="M30 30.5C38.2875 30.5 45 23.6756 45 15.25C45 6.82437 38.2875 0 30 0C21.7125 0 15 6.82437 15 15.25C15 23.6756 21.7125 30.5 30 30.5ZM30 38.125C19.9875 38.125 0 43.2337 0 53.375V57.1875C0 59.2844 1.6875 61 3.75 61H56.25C58.3125 61 60 59.2844 60 57.1875V53.375C60 43.2337 40.0125 38.125 30 38.125Z"/>
    <path class="${this.theme}" d="M75.5443 19.5789H68.4211V12.4558C68.4211 10.5516 66.8696 9 64.9653 9H64.8595C62.9201 9 61.3685 10.5516 61.3685 12.4558V19.5789H54.2806C52.3764 19.5789 50.8248 21.1305 50.7896 23.0347V23.1405C50.7896 25.08 52.3411 26.6316 54.2806 26.6316H61.3685V33.7195C61.3685 35.6237 62.9201 37.2105 64.8595 37.1753H64.9653C66.8696 37.1753 68.4211 35.6237 68.4211 33.7195V26.6316H75.5443C77.4485 26.6316 79.0001 25.08 79.0001 23.1758V23.0347C79.0001 21.1305 77.4485 19.5789 75.5443 19.5789Z"/>
    </svg>
    `;
    }
};
IconRAFOnboarding.styles = [iconCSS, css `
    path {
      fill: #FF284F;
    }

    path.orange {
      fill: #FB4F14;
    }

    path.yellow {
      fill: #FFC20E;
    }
  `];
__decorate([
    property()
], IconRAFOnboarding.prototype, "theme", void 0);
IconRAFOnboarding = __decorate([
    customElement("icon-raf-onboarding")
], IconRAFOnboarding);
export { IconRAFOnboarding };
