var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement } from "lit/decorators.js";
const componentStyles = [
    css `
    :host {
      display: flex;
      gap: 1rem;
    }

    h2 {
      font-size: 1rem;
      margin: 1rem 0 0;
    }
  `,
];
let FormH2 = class FormH2 extends LitElement {
    render() {
        return html `
      <h2>
        <slot></slot>
      </h2>
    `;
    }
};
FormH2.styles = [componentStyles];
FormH2 = __decorate([
    customElement("form-h2")
], FormH2);
export { FormH2 };
