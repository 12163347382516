var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, state, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import config from "@/services/config/config";
import myTeamDefaultImage from "../../../lobby/assets/my_team-default.png";
import '@/router/components/router-link';
import '@/components/asset/uf-icon/uf-icon';
import ufAPI from '@/services/api/uf/uf.api';
import { deepEqual } from "@/services/utils/utils";
import { formatAsCurrency, formatAsNth, prizeFromPrizeBreakdown } from "@/views/game/directives/game.directives";
import { findCurrentRank } from "@/services/api/uf/uf.helpers";
import globalCSS from "~/styles/global.css";
import leaderboardCardCSS from "./leaderboard-card.css";
let LeaderboardCard = class LeaderboardCard extends LitElement {
    constructor() {
        super(...arguments);
        this.isCurrentStage = false;
        this.cardType = 'lobby';
    }
    async connectedCallback() {
        super.connectedCallback();
        this.getRank();
    }
    getRank() {
        this.rankSubscription = ufAPI.ranks.subscribe(ranks => {
            if (ranks.length && this.stage) {
                const rank = findCurrentRank(ranks, this.stage);
                if (!deepEqual(this.rank, rank)) {
                    this.rank = rank;
                }
            }
        });
    }
    decideStageStatus() {
        const now = Date.now();
        if (this.stage) {
            if (this.isCurrentStage) {
                return 'green';
            }
            else if (now > this.stage.end * 1000) {
                return 'red';
            }
            else {
                return 'none';
            }
        }
        else {
            return 'none';
        }
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.rankSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.rankSubscription = undefined;
    }
    render() {
        return html `${this.renderCard()}`;
    }
    renderCard() {
        this.stageStatus = this.decideStageStatus();
        return html `
      ${when(this.stage, () => html `
            ${this.renderCardHeader()}
            ${this.renderCardFooter()}
          `)}
    `;
    }
    renderCardHeader() {
        let title = "";
        let image = myTeamDefaultImage;
        if (this.stage) {
            switch (this.cardType) {
                case 'week':
                case 'lobby':
                    title = "Overall";
                    image = `${config.storageTeamUrl()}/contests_lobby_competition_${this.stage.id}.png`;
                    break;
                case 'clash':
                    title = `${config.clashName()}`;
                    image = `${config.storageTeamUrl()}/contests_ufclash_competition_${this.stage.id}.png`;
            }
        }
        return html `          
      <div 
        class="card-header" 
        style="background-image: url(${image});">
        <p>${title}</p>
        ${this.renderStageIcon()}
      </div>
    `;
    }
    renderStageIcon() {
        return html `
      ${this.stageStatus && this.stageStatus !== 'none' ? html `<uf-icon icon="bars" theme="${this.stageStatus}"></uf-icon>` : ''}
    `;
    }
    renderCardFooter() {
        return html `
      <div class="card-footer">
        ${when(this.stage, () => html `
              <p>${this.renderRanking()}</p>
              <p>${this.renderScore()}</p>
              <p>${this.renderWinnings()}</p>
            `, () => html `
              <p>-</p>
              <p>-</p>
              <p>-</p>
            `)}  
      </div> 
    `;
    }
    renderRanking() {
        if (this.rank) {
            switch (this.cardType) {
                case 'week':
                case 'lobby':
                    return html `<span>${formatAsNth(this.rank.ranking)}</span>`;
                case 'clash':
                    return html `<span>${formatAsNth(this.rank.miniLeagueRanking)}</span>`;
            }
        }
        else {
            return html `<span>-</span>`;
        }
    }
    renderScore() {
        if (this.rank) {
            return html `<span>${this.rank.score} pts</span>`;
        }
        else {
            return html `<span>-</span>`;
        }
    }
    renderWinnings() {
        var _a, _b;
        if (this.rank) {
            switch (this.cardType) {
                case 'week':
                case 'lobby':
                    if (this.rank.score > 0) {
                        if (config.showMonetryPrizes()) {
                            return html `<span class="${classMap({ "has-prize": this.rank.draftWinnings > 0 })}">${formatAsCurrency(this.rank.draftWinnings)}</span>`;
                        }
                        else {
                            const prize = prizeFromPrizeBreakdown(((_a = this.stage) === null || _a === void 0 ? void 0 : _a.breakdown) || "", (_b = this.rank) === null || _b === void 0 ? void 0 : _b.ranking, false); // isRankTied is set to false as we only need to show if the user has a prize and not which specific prize it is
                            if (prize) {
                                return html `<span class="has-prize">Prize</span>`;
                            }
                            else {
                                return html `<span>--</span>`;
                            }
                        }
                    }
                    else {
                        return html `<span>--</span>`;
                    }
                case 'clash':
                    if (this.rank.miniLeagueRanking == 1 && this.rank.score > 0) {
                        return html `<span class="has-prize">CARD</span>`;
                    }
                    else {
                        return html `<span>--</span>`;
                    }
            }
        }
        else {
            return html `<span>--</span>`;
        }
    }
};
LeaderboardCard.styles = [globalCSS, leaderboardCardCSS];
__decorate([
    property({ type: Object })
], LeaderboardCard.prototype, "stage", void 0);
__decorate([
    property({ type: Boolean })
], LeaderboardCard.prototype, "isCurrentStage", void 0);
__decorate([
    property()
], LeaderboardCard.prototype, "cardType", void 0);
__decorate([
    state()
], LeaderboardCard.prototype, "rank", void 0);
LeaderboardCard = __decorate([
    customElement("leaderboard-card")
], LeaderboardCard);
export { LeaderboardCard };
