var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./info.css";
import router from "@/router/router";
import "@/components/header-bar/header-bar";
import "@/components/tab-bar/tab-bar";
let InfoView = class InfoView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.type = "overall";
    }
    async connectedCallback() {
        super.connectedCallback();
        this.routerSubscription = router.routeChange.subscribe({
            next: route => {
                const relevantRoute = route.path === "leaderboard/info" || route.path === "leaderboard/info/contest";
                if (relevantRoute && route.data) {
                    const routeData = route.data;
                    this.stage = routeData.stage;
                    this.type = routeData.type;
                }
            }
        });
    }
    disconnectedCallback() {
        var _a;
        super.connectedCallback();
        (_a = this.routerSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.routerSubscription = undefined;
    }
    render() {
        const tabBarItems = [
            {
                displayName: "Contest",
                route: "/leaderboard/info/contest",
                data: { stage: this.stage, type: this.type }
            },
            {
                displayName: "Scoring",
                route: "/leaderboard/info/scoring"
            }
        ];
        return html `

      <header-bar class="clickable">
        <div slot="left">
          <uf-icon icon="chevron" class="clickable" @click=${router.back}></uf-icon>
          ${when(this.stage, () => { var _a; return html `<h3>${(_a = this.stage) === null || _a === void 0 ? void 0 : _a.name}</h3>`; })}
        </div>
        <div slot="center">
          <tab-bar .items=${tabBarItems}></tab-bar>
        </div>
      </header-bar>

      <router-outlet></router-outlet>

    `;
    }
};
__decorate([
    state()
], InfoView.prototype, "stage", void 0);
__decorate([
    state()
], InfoView.prototype, "type", void 0);
InfoView = __decorate([
    customElement("info-view")
], InfoView);
export { InfoView };
