var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import "./info.css";
import "@/components/game-scoring/game-scoring";
import "@/components/uf-tooltip/uf-tooltip";
import "./assets/points-svg/points-svg";
import "./assets/howtowin-svg/howtowin-svg";
let OnboardingInfo = class OnboardingInfo extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.section = 0;
    }
    render() {
        return html `
      <div>
        ${choose(this.section, [
            [0, () => this.renderScoring()],
            [1, () => this.renderSquadPoints()],
            [2, () => this.renderHowToWin()]
        ])}
      </div>
    `;
    }
    renderScoring() {
        return html `
      <h1>Scoring</h1>
      <game-scoring></game-scoring>
      <footer>
        <uf-tooltip position="bottom">
          Earn points based on the real-life NFL performances from your squad.
        </uf-tooltip>
        <button @click=${this.next}>Next</button>
      </footer>
    `;
    }
    renderSquadPoints() {
        return html `
      <h1>Squad Points</h1>
      <points-svg></points-svg>
      <footer>
        <uf-tooltip position="bottom">
          View how your NFL squad is performing.
        </uf-tooltip>
        <button @click=${this.next}>View Leaderboard</button>
      </footer>
    `;
    }
    renderHowToWin() {
        return html `
      <h1>How To Win</h1>
      <div id="howtowin-graphic">
        <howtowin-svg></howtowin-svg>
        <uf-tooltip position="bottom">
          Earn the most points from your squad to win! Check leaderboards to see how you perform.
        </uf-tooltip>
      </div>
      <footer>
        <button @click=${this.next}>Continue</button>
      </footer>
    `;
    }
    next() {
        if (this.section < 2)
            this.section++;
        else
            this.dispatchEvent(new Event("next"));
    }
};
__decorate([
    state()
], OnboardingInfo.prototype, "section", void 0);
OnboardingInfo = __decorate([
    customElement("onboarding-info")
], OnboardingInfo);
