var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import "./squad-lineup.css";
import config from "@/services/config/config";
import "@/components/header-bar/header-bar";
import "@/components/asset/uf-icon/uf-icon";
import "~/app/components/game-area/game-area";
import { CountdownController } from "@/views/game/controllers/countdown.controller";
import { formatAsCurrency, formatAsNth, prizeFromPrizeBreakdown } from "@/views/game/directives/game.directives";
import { deepEqual } from "@/services/utils/utils";
import ufApi from "@/services/api/uf/uf.api";
import { findCurrentRank } from "@/services/api/uf/uf.helpers";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
let SquadLineupView = class SquadLineupView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.editingLineup = false;
        this.editable = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.handleCurrentStage();
        this.handleRanks();
        this.checkLineupEditable();
        ufApi.getStages().catch(error => () => this.handleErrors(error));
        analytics.recordTag(AnalyticsKeys.LINEUP_PAGE);
    }
    handleCurrentStage() {
        this.currentStageSubscription = ufApi.currentStage.subscribe(currentStage => {
            if (currentStage) {
                if (!deepEqual(this.currentStage, currentStage)) {
                    this.currentStage = currentStage;
                }
                if (this.currentStageCountdown) {
                    this.removeController(this.currentStageCountdown);
                }
                if (!this.currentStage.live) {
                    this.currentStageCountdown = new CountdownController(this, new Date(this.currentStage.stage.start * 1000), () => {
                        this.removeController(this.currentStageCountdown);
                        ufApi.getStages().catch(error => () => this.handleErrors(error));
                    });
                    if (this.headerBarState !== "gap") {
                        this.headerBarState = "gap";
                    }
                }
                else {
                    if (this.headerBarState !== "live") {
                        this.headerBarState = "live";
                    }
                    ufApi.getLeaderboard().catch(error => () => this.handleErrors(error));
                }
            }
        });
    }
    handleRanks() {
        this.ranksSubscription = ufApi.ranks.subscribe(ranks => {
            if (ranks.length) {
                ufApi.currentStage.subscribe(currentStage => {
                    if (currentStage) {
                        const rank = findCurrentRank(ranks, currentStage.stage);
                        if (!deepEqual(this.rank, rank)) {
                            this.rank = rank;
                        }
                    }
                }).unsubscribe();
            }
        });
    }
    async checkLineupEditable() {
        try {
            this.editable = await ufApi.checkLineupEditable();
        }
        catch (error) {
            this.handleErrors(error);
        }
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        var _a, _b;
        super.disconnectedCallback();
        (_a = this.currentStageSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.currentStageSubscription = undefined;
        if (this.currentStageCountdown) {
            this.removeController(this.currentStageCountdown);
        }
        (_b = this.ranksSubscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        this.ranksSubscription = undefined;
    }
    render() {
        return html `
      ${this.renderHeaderBar()}
      <game-area ?editable=${this.editable} showBrowseButton></game-area>
    `;
    }
    renderHeaderBar() {
        return html `
      <header-bar id="lineup-header">
        ${choose(this.headerBarState, [
            ["gap", () => html `
              <div slot="left">
                ${!this.editingLineup ? html `
                    ${when(config.showMonetryPrizes(), () => html `
                        <uf-icon icon="${config.isUF ? "currency" : "currencyUs"}"></uf-icon>
                        <p class="active">
                          ${formatAsCurrency(this.currentStage.stage.prizePool)} Prize Pool
                        </p>
                        `, () => html `
                        <p class="active">
                          Prize Pool
                        </p>
                      `)}
                  ` : html `
                    <uf-icon icon="cancel" class="clickable" @click=${this.cancelEdit}></uf-icon>
                    <p>Edit Lineup</p>
                  `}
              </div>
              <div slot="center">
                <h1>${this.currentStage.stage.name}</h1>
              </div>
              <div slot="right">
                <p>${this.currentStageCountdown.text}</p>
                <uf-icon icon="countdown"></uf-icon>
              </div>
            `],
            ["live", () => when(this.rank, () => html `
              <div slot="left">
                <p>${formatAsNth(this.rank.ranking)}</p>
              </div>
              <div slot="center">
                <p>${this.rank.score.toLocaleString()}pts</p>
              </div>
              <div slot="right">
                <p class="active">
                  ${when(this.rank, () => {
                    var _a;
                    if (this.rank.score > 0) {
                        if (config.showMonetryPrizes()) {
                            return html `<span class="${classMap({ "has-prize": this.rank.draftWinnings > 0 })}">${formatAsCurrency(this.rank.draftWinnings)}</span>`;
                        }
                        else {
                            const prize = prizeFromPrizeBreakdown(((_a = this.currentStage.stage) === null || _a === void 0 ? void 0 : _a.breakdown) || "", this.rank.ranking, false); // isRankTied is set to false as we only need to show if the user has a prize and not which specific prize it is
                            if (prize) {
                                return html `<span class="has-prize">Prize</span>`;
                            }
                            else {
                                return html `<span>--</span>`;
                            }
                        }
                    }
                    else {
                        return html `<span>--</span>`;
                    }
                })}
                </p>
              </div>
            `)]
        ])}
      </header-bar>
    `;
    }
    cancelEdit() {
        this.gameArea.cancelEdit();
    }
};
__decorate([
    state()
], SquadLineupView.prototype, "currentStage", void 0);
__decorate([
    state()
], SquadLineupView.prototype, "headerBarState", void 0);
__decorate([
    state()
], SquadLineupView.prototype, "currentStageCountdown", void 0);
__decorate([
    state()
], SquadLineupView.prototype, "rank", void 0);
__decorate([
    state()
], SquadLineupView.prototype, "editingLineup", void 0);
__decorate([
    state()
], SquadLineupView.prototype, "editable", void 0);
__decorate([
    query("game-area")
], SquadLineupView.prototype, "gameArea", void 0);
SquadLineupView = __decorate([
    customElement("squad-lineup-view")
], SquadLineupView);
export { SquadLineupView };
