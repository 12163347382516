var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "@/layout/scoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import "@/components/game-card/game-card-front/game-card-front";
import "@/components/game-card/game-card-back/game-card-back";
import gameCardFlippableStyles from "./game-card-flippable.css";
let GameCardFlippable = class GameCardFlippable extends ScopedElement {
    render() {
        return html `
      <div @click=${this.flip}>
        <game-card-back .squadItem=${this.squadItem}></game-card-back>
        <game-card-front .squadItem=${this.squadItem}></game-card-front>
      </div>
    `;
    }
    flip() {
        this.classList.add("flipping");
        this.classList.toggle("flipped");
        this.dispatchEvent(new Event("flipstart"));
        setTimeout(() => {
            this.classList.remove("flipping");
            this.dispatchEvent(new Event("flipend"));
        }, 200);
    }
};
GameCardFlippable.styles = [ScopedElement.styles, gameCardFlippableStyles];
__decorate([
    property()
], GameCardFlippable.prototype, "squadItem", void 0);
GameCardFlippable = __decorate([
    customElement("game-card-flippable")
], GameCardFlippable);
