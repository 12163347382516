var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconUpTriangle = class IconUpTriangle extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="${this.theme}"d="M9.55121 1.28857C10.3486 0.168472 12.0124 0.168472 12.8098 1.28857L21.2115 13.0901C22.1542 14.4141 21.2076 16.25 19.5823 16.25H2.77873C1.15342 16.25 0.206828 14.4141 1.14945 13.0901L9.55121 1.28857Z"/>
      </svg>
    `;
    }
};
IconUpTriangle.styles = [iconCSS, css `
    path {
      fill: #FF284F;
    }

    path.orange {
      fill: #FB4F14;
    }

    path.yellow {
      fill: #FFC20E;
    }

  `];
__decorate([
    property()
], IconUpTriangle.prototype, "theme", void 0);
IconUpTriangle = __decorate([
    customElement("icon-up-triangle")
], IconUpTriangle);
export { IconUpTriangle };
