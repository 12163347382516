var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconShare = class IconShare extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="${this.theme}" d="M13.125 6.36572H11.25C10.7344 6.36572 10.3125 6.7876 10.3125 7.30322C10.3125 7.81885 10.7344 8.24072 11.25 8.24072H13.125V18.5532H1.875V8.24072H3.75C4.26562 8.24072 4.6875 7.81885 4.6875 7.30322C4.6875 6.7876 4.26562 6.36572 3.75 6.36572H1.875C0.84375 6.36572 0 7.20947 0 8.24072V18.5532C0 19.5845 0.84375 20.4282 1.875 20.4282H13.125C14.1562 20.4282 15 19.5845 15 18.5532V8.24072C15 7.20947 14.1562 6.36572 13.125 6.36572Z"/>
      <path class="${this.theme}" d="M7.49996 13.8656C8.01558 13.8656 8.43746 13.4438 8.43746 12.9281V3.55313H10.1156C10.5375 3.55313 10.7437 3.04688 10.4437 2.75625L7.82808 0.140625C7.64058 -0.046875 7.34996 -0.046875 7.16246 0.140625L4.54683 2.75625C4.25621 3.04688 4.46246 3.55313 4.88433 3.55313H6.56246V12.9281C6.56246 13.4438 6.98433 13.8656 7.49996 13.8656Z"/>
      </svg>
    `;
    }
};
IconShare.styles = [iconCSS, css `
    path {
      fill: #FFFFFF;
    }

    path.red {
      fill: #FF284F;
    }

    path.black {
      fill: #292929;
    }
  `];
__decorate([
    property()
], IconShare.prototype, "theme", void 0);
IconShare = __decorate([
    customElement("icon-share")
], IconShare);
export { IconShare };
