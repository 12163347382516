import { css } from "lit";
const userLeagueCardCSS = css `

  div.card {
    position: relative;
    margin: auto;
  } 

  div.top {
    height: 3em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-user-league-card-top);
    border-top-left-radius: 1.25em;
    border-top-right-radius: 1.25em;
  }

  div.top p {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
  }

  div.bottom {
    height: 3em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-user-league-card-bottom);
    border-bottom-left-radius: 1.25em;
    border-bottom-right-radius: 1.25em;
  }

  div.bottom p {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
  }

  div.bottom p:last-child {
    color: #A07EA4;
  }
`;
export default userLeagueCardCSS;
