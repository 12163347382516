import { css } from "lit";
const packItemStyles = css `

  .pack-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105%;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
  @supports not (aspect-ratio: 1 / 1) {
    .pack-img::before {
      content: "";
      float: left;
      padding-top: 100%;
    }
    .pack-img::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  :host([disabled]) .pack-img {
    opacity: 0.4;
  }

  .quantity {
    position: absolute;
    top: 10%;
    right: 13%;
    background-color: rgba(0, 0, 0, 0.8);
    height: 1em;
    border-radius: calc(1em / 2);
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 0.6875em;
  }
  .quantity span {
    font-size: 0.625em;
  }

  footer {
    padding: 0 1.3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3em;
  }
  footer > div {
    display: flex;
    align-items: center;
    column-gap: 0.25em;
  }
  footer uf-icon {
    width: 1.25em;
  }
  footer > div span {
    font-weight: 500;
  }

`;
export default packItemStyles;
