var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "../../../styles/global.css";
import ConfigurationService from "@/services/config/config";
import "../asset/logo-low6/logo-low6";
let Low6UserMessage = class Low6UserMessage extends LitElement {
    render() {
        return html `
      <logo-low6></logo-low6>
      <p class="small-text">user? Use your login for ${this.getAppName()}.</p>
    `;
    }
    getAppName() {
        if (ConfigurationService.isBengals)
            return "UltimateBengals";
        if (ConfigurationService.isChargers)
            return "UltimateChargers";
        return "UltimateFan";
    }
};
Low6UserMessage.styles = [globalCSS, css `
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    logo-low6 {
      width: 2.375em;
      margin-right: 0.375em;
      position: relative;
      top: 0.15em;
    }
    p {
      margin: 0;
    }
  `];
Low6UserMessage = __decorate([
    customElement("low6-user-message")
], Low6UserMessage);
export { Low6UserMessage };
