var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import "./transfer.view.css";
import "./parts/transfer-in-out/transfer-in-out";
import "./parts/transfer-summary/transfer-summary";
import ufApi from "~/app/services/api/uf/uf.api";
import { SquadItemSet } from "~/app/services/api/uf/types/uf.api.types.enums";
import { deepEqual } from "~/app/services/utils/utils";
import { sortSquadByRating } from "@/helpers/squad.helpers";
import router from "~/app/router/router";
let TransferView = class TransferView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.viewIndex = 0;
        this.transfer = "in";
        this.squad = [];
        this.activeSquadSelections = [];
        this.collectionSelections = [];
    }
    get activeSquadSelected() {
        return this.activeSquadSelections.filter(selection => selection.selected);
    }
    get collectionSelected() {
        return this.collectionSelections.filter(selection => selection.selected);
    }
    connectedCallback() {
        super.connectedCallback();
        this.viewIndex = 0;
        this.handleSquad();
        ufApi.getSquad().catch(error => () => this.handleErrors(error));
        const route = router.routeChange.getValue();
        if (route.path === "squad/transfer" || route.path === "squad/transfer/") {
            if (route.data) {
                const routeData = route.data;
                this.transfer = routeData.transfer;
                let selections;
                switch (routeData.transfer) {
                    case "in":
                        selections = this.collectionSelections;
                        break;
                    case "out": selections = this.activeSquadSelections;
                }
                const card = selections.find(card => card.item.cardId === routeData.cardId);
                if (card)
                    card.selected = true;
            }
        }
    }
    handleSquad() {
        this.squadSubscription = ufApi.squad.subscribe(squad => {
            if (!deepEqual(this.squad, squad)) {
                this.squad = squad;
                squad = sortSquadByRating(squad);
                this.activeSquadSelections = squad.filter(item => item.set === SquadItemSet.Lineup || item.set === SquadItemSet.Squad).map(item => ({ item, selected: false }));
                this.collectionSelections = squad.filter(item => item.set === SquadItemSet.Collection).map(item => ({ item, selected: false }));
            }
        });
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.squadSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.squadSubscription = undefined;
    }
    render() {
        return html `
      ${this.viewIndex < 2 ? html `
          <transfer-in-out
            index=${this.viewIndex}
            transfer=${this.transfer}
            .activeSquadSelections=${this.activeSquadSelections}
            .collectionSelections=${this.collectionSelections}
            @next=${this.next}
            @back=${this.back}
            @cancel=${this.cancel}
          ></transfer-in-out>
        ` : html `
          <transfer-summary
            index=${this.viewIndex}
            transfer=${this.transfer}
            .activeSquadSelections=${this.activeSquadSelections}
            .collectionSelections=${this.collectionSelections}
            @next=${this.next}
            @back=${this.back}
            @cancel=${this.cancel}
          ></transfer-summary>
        `}
    `;
    }
    next() {
        this.viewIndex++;
        this.navigate();
    }
    back() {
        this.viewIndex--;
        this.navigate();
    }
    navigate() {
        this.transfer = this.transfer === "in" ? "out" : "in";
    }
    cancel() {
        this.viewIndex = 0;
        this.transfer = "in";
        [this.activeSquadSelections, this.collectionSelections].forEach(selections => selections.forEach(card => card.selected = false));
        this.requestUpdate();
        router.navigate("squad");
    }
};
__decorate([
    state()
], TransferView.prototype, "viewIndex", void 0);
__decorate([
    state()
], TransferView.prototype, "transfer", void 0);
__decorate([
    state()
], TransferView.prototype, "squad", void 0);
TransferView = __decorate([
    customElement("transfer-view")
], TransferView);
export { TransferView };
