import { BehaviorSubject } from "rxjs";
import * as views from "./router.views";
import { appContainer } from "@/services/config/constants";
import config from "@/services/config/config";
import { outlets } from "./outlets";
const { features } = config.skin;
export const routes = [
    { path: "", view: views.intro },
    { path: "login", view: views.login },
    { path: "signup", view: views.signup },
    { path: "reset-password", view: views.resetPassword },
    { path: "lobby/", view: views.lobby },
    { path: "login-streak",
        view: views.loginStreak,
        children: [
            { path: "", view: views.loginStreak },
            { path: "onboarding", view: views.loginStreakOnboarding }
        ]
    },
    {
        path: "squad/",
        view: views.squad,
        children: [
            { path: "", view: views.squadLineup },
            { path: "browse", view: views.squadBrowse },
            { path: "transfer", view: views.transfer }
        ]
    },
    {
        path: "refer-a-friend",
        view: views.referAFriend,
        children: [
            { path: "", view: views.referAFriendHome },
            { path: "onboarding", view: views.referAFriendOnboarding }
        ]
    },
    {
        path: "leaderboard",
        view: views.leaderboard,
        children: [
            { path: "", view: views.stageView },
            { path: "stage/:id", view: views.stageView },
            { path: "stages", view: views.stageListView },
            { path: "positions/:stageId/:type/:userLeagueId", view: views.leaderboardPositionListView },
            { path: "create", view: views.createLeague },
            { path: "join", view: views.joinLeague },
            { path: "mini", view: views.miniLeagueMain },
            {
                path: "info",
                view: views.info,
                children: [
                    { path: "", view: views.infoContest },
                    { path: "contest", view: views.infoContest },
                    { path: "scoring", view: views.infoScoring }
                ]
            },
            {
                path: "lineup/:userId/:stageId",
                view: views.userLineup
            },
            {
                path: "points/:userId/:cardId/:stageId",
                view: views.pointsBreakdownView
            }
        ]
    },
    { path: "change-password", view: views.changePassword }
];
const redirects = [
    {
        from: "card-result/success",
        to: `wallet/${localStorage.getItem("update-card-origin")}`,
        data: "card-success"
    },
    {
        from: "card-result/failure",
        to: `wallet/${localStorage.getItem("update-card-origin")}`,
        data: "card-failed"
    },
    {
        from: "marketplace",
        to: `marketplace/packs`
    }
];
class Router {
    constructor() {
        var _a, _b;
        this.routeChange = new BehaviorSubject({
            path: this.currentPath
        });
        this.dynamicRoutes = new BehaviorSubject([]);
        this.routeChange.subscribe(() => {
            this.clearDynamicRoutes();
            appContainer.scrollTo(0, 0);
        });
        window.addEventListener("popstate", () => {
            this.routeChange.next({ path: this.currentPath });
        });
        if (!((_a = features === null || features === void 0 ? void 0 : features.marketplace) === null || _a === void 0 ? void 0 : _a.exclude)) {
            views.marketplaceViewsReady.subscribe(() => {
                routes.push({
                    path: "marketplace",
                    view: views.marketplaceViews.main,
                    children: [
                        { path: "", view: views.marketplaceViews.packs },
                        { path: "packs", view: views.marketplaceViews.packs },
                        { path: "coins", view: views.marketplaceViews.coins },
                        { path: "cards", view: views.marketplaceViews.cards },
                        { path: "sell", view: views.marketplaceViews.sell }
                    ]
                });
            });
        }
        if (!((_b = features === null || features === void 0 ? void 0 : features.marketplace) === null || _b === void 0 ? void 0 : _b.exclude)) {
            views.walletViewsReady.subscribe(() => {
                routes.push({
                    path: "wallet",
                    view: views.walletViews.wallet,
                    children: [
                        { path: "", view: views.walletViews.walletHome },
                        { path: "transactions", view: views.walletViews.transactions },
                        {
                            path: "coins",
                            view: views.walletViews.coins,
                            children: [
                                { path: "", view: views.walletViews.coinsHome },
                                { path: "add", view: views.walletViews.coinsAdd }
                            ]
                        },
                        {
                            path: "cash",
                            view: views.walletViews.cash,
                            children: [
                                { path: "", view: views.walletViews.cashHome },
                                { path: "convert-to-coins", view: views.walletViews.convertToCoins }
                                // { path: "withdraw", component: views.cashHome }
                            ]
                        }
                    ]
                });
            });
        }
        this.handleRedirects();
    }
    get currentPath() {
        return location.pathname.replace("/", "");
    }
    /**
     * Handles any redirects if configured.
     */
    handleRedirects() {
        const redirect = redirects.find(redirect => redirect.from === this.currentPath);
        if (redirect)
            this.navigate(redirect.to, redirect.data, true);
    }
    /**
     * Handles navigation for views.
     * @param path The path to route to exluding the leading slash.
     * @param data Optional. Pass custom data.
     */
    navigate(path, data, shouldReplace = false) {
        // Only navigate if not currently on route.
        if (path !== this.currentPath) {
            // Update the browser history.
            shouldReplace ? history.replaceState(null, "", `/${path}`) : history.pushState(null, "", `/${path}`);
            let route = { path: this.currentPath };
            // If there is custom data to pass.
            if (data)
                route.data = data;
            // Send notification.
            this.routeChange.next(route);
            this.handleRedirects();
        }
    }
    sendDynamicRoutesNotification() {
        const outletsWithDynamicRoutes = outlets.filter(outlet => {
            var _a;
            return ((_a = outlet.dynamicRoutes) === null || _a === void 0 ? void 0 : _a.length) ? outlet.dynamicRoutes.some(route => route.value !== undefined) : false;
        });
        if (outletsWithDynamicRoutes.length) {
            const outletDynamicRoutes = outletsWithDynamicRoutes.map(outlet => outlet.dynamicRoutes);
            let dynamicRoutes = [];
            outletDynamicRoutes.forEach(routes => {
                routes.forEach(route => {
                    if (route.value)
                        dynamicRoutes.push(route);
                });
            });
            this.dynamicRoutes.next(dynamicRoutes);
        }
    }
    clearDynamicRoutes() {
        for (let outlet of outlets)
            delete outlet.dynamicRoutes;
        this.dynamicRoutes.next([]);
    }
    back() {
        history.back();
    }
}
export default new Router();
