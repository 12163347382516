var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import ufApi from "@/services/api/uf/uf.api";
import '@/views/game/views/refer-a-friend/components/refer-friends-card/refer-friends-card.ts';
import '@/views/game/views/refer-a-friend/components/referral-count-card/referral-count-card.ts';
import '@/views/game/views/refer-a-friend/components/referral-card/referral-card.ts';
import { map } from "lit/directives/map.js";
import { formatAsReferralDate } from "@/views/game/directives/game.directives";
import ConfigurationService from "@/services/config/config";
let ReferAFriendHomeView = class ReferAFriendHomeView extends LitElement {
    constructor() {
        super();
        this.referralCount = 0;
        this.referralCoinsAwarded = 0;
        ufApi.loginDetails.subscribe(login => {
            this.referralCode = login.referralCode;
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.getReferralStats();
    }
    async getReferralStats() {
        try {
            const response = await ufApi.getReferralStats();
            this.referralStatsResponse = response;
            this.referralCount = response === null || response === void 0 ? void 0 : response.data.referrals.length;
            if (ConfigurationService.isUF) {
                this.referralCoinsAwarded = (response === null || response === void 0 ? void 0 : response.data.referrals.filter(referral => referral.reward == 'Coins').length) * 300;
            }
            else {
                this.referralCoinsAwarded = (response === null || response === void 0 ? void 0 : response.data.referrals.length) * 2;
            }
        }
        catch (error) {
            console.log("Error", error);
        }
    }
    render() {
        return html `
        ${this.renderHeaderBar()}
        <section>
            ${this.renderReferralCountViews()}
            ${this.renderReferFriendsCard()}
            ${this.renderReferralViews()}
        </section>
        `;
    }
    renderHeaderBar() {
        return html `
            <header-bar>
                <div slot="left">${this.renderBack()}</div>
                <div slot="center"><h3>Refer Friends</h3></div>
                <div slot="right">${this.renderHelp()}</div>
            </header-bar>
        `;
    }
    renderBack() {
        return html `
          <router-link href="/lobby">
            <uf-icon icon='chevron' class="clickable"></uf-icon>
          </router-link>
        `;
    }
    renderHelp() {
        return html `
          <router-link href="/refer-a-friend/onboarding">
            <uf-icon icon='help' class="clickable"></uf-icon>
          </router-link>
        `;
    }
    renderReferralCountViews() {
        var _a;
        return html `   
            <div class="referral-count-views">
                <referral-count-card header="REFERRALS" 
                    .coinValue="${(_a = (this.referralCount.toString())) !== null && _a !== void 0 ? _a : '0'}">
                </referral-count-card>
                <referral-count-card header="AWARDED" 
                    .isCoinIconHidden="${false}" 
                    coinValue="${(this.formatAwardedText())}">
                </referral-count-card>
            </div>
        `;
    }
    formatAwardedText() {
        if (ConfigurationService.isUF)
            return "+" + this.referralCoinsAwarded.toString();
        else
            return this.referralCoinsAwarded.toString();
    }
    renderReferFriendsCard() {
        return html `
            <refer-friends-card .referralCode=${this.referralCode}></refer-friends-card>
        `;
    }
    renderReferralViews() {
        if (this.referralCount == 0) {
            return this.renderNoReferralsView();
        }
        else {
            return this.renderReferralsListView();
        }
    }
    renderNoReferralsView() {
        return html `        
            <div class="no-referral-views">
                <h3>No Referrals</h3>
                <p>Invite your friends to play UltimateFan and for each friend who signs up with your unique code you'll both receive 300 UF Coin.</p>
            </div>
        `;
    }
    renderReferralsListView() {
        var _a;
        return html `    
            <div class="referral-list-views">
                <h3>Your Referrals</h3>
                <div class="referral-list">
                    ${map((_a = this.referralStatsResponse) === null || _a === void 0 ? void 0 : _a.data.referrals, (referral) => html `
                            <referral-card 
                                username="${referral.username}"
                                date="${formatAsReferralDate(new Date(referral.referredDate))}">
                            </referral-card>`)}
                </div>
            </div>
        `;
    }
};
ReferAFriendHomeView.styles = [globalCSS, css `

    :host {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
  
    @media (max-width: 500px) {
        header-bar h3 {
            font-size: 1em;
        }
    }

    section {
        display: flex;
        width: 100%;
        max-width: 38em;
        flex-direction: column;
        row-gap: 2em;
        justify-content: center;
        margin: 1em auto;
    }

    uf-icon[icon=chevron] {
        width: 1.25em;
    }

    uf-icon[icon=help] {
        width: 2em;
    }

    div.referral-count-views {
        display: flex;
        justify-content: center;
    }

    div.no-referral-views {
        margin-top: 1.69em;
        text-align: center;
    }

    div.no-referral-views p {
        margin: 0em auto;
        width: 25.56em;
    }

    div.referral-list-views {
        margin-top: 1.69em;
    }

    div.referral-list {
        display: flex;
        flex-direction: column;
        row-gap: 0.5em;
        margin-top: 1em;
    }

    `];
__decorate([
    state()
], ReferAFriendHomeView.prototype, "referralStatsResponse", void 0);
__decorate([
    state()
], ReferAFriendHomeView.prototype, "referralCode", void 0);
__decorate([
    state()
], ReferAFriendHomeView.prototype, "referralCount", void 0);
__decorate([
    state()
], ReferAFriendHomeView.prototype, "referralCoinsAwarded", void 0);
ReferAFriendHomeView = __decorate([
    customElement("refer-a-friend-home-view")
], ReferAFriendHomeView);
export { ReferAFriendHomeView };
