var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import config from "@/services/config/config";
import { isDesktop, isTablet } from "@/services/device/device";
import "./squad-collection.css";
import "@/components/asset/uf-icon/uf-icon";
import "@/views/game/views/squad/components/long-press/long-press";
import "@/views/game/views/squad/components/drag-drop/drop-area";
import "@/views/game/views/squad/components/drag-drop/drag-item";
import "@/components/game-card/game-card-front/game-card-front";
import { SquadItemModifier, SquadItemSet } from "@/services/api/uf/types/uf.api.types.enums";
import { squadPositions, filterSquadBySearch, filterSquadByPosition } from "~/app/views/game/views/squad/helpers/squad.helpers";
import { sortSquadByRating } from "~/app/helpers/squad.helpers";
import { cardViewCurrent } from "~/app/config/card-view.config";
import cardPositionConfig from "../game-area/config/game-area.config";
const dragEnabled = isDesktop || isTablet;
let SquadCollection = class SquadCollection extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.editable = false;
        this.squad = [];
        this.showBrowseButton = false;
        this.hideActionButtons = false;
        this.hideMods = false;
        this.searchTerm = "";
        this.scrollable = true;
        this.benchFilter = "ALL";
    }
    get activeSquad() {
        return filterSquadBySearch(sortSquadByRating(filterSquadByPosition(this.squad.filter(item => item.set !== SquadItemSet.Collection), this.benchFilter)), this.searchTerm);
    }
    render() {
        return html `

      <nav>
        <ul id="search">
          <uf-icon icon="search"></uf-icon>
          <input
            type="text"
            placeholder="Search Player or Team"
            @input=${this.onSearchChange}
            .value=${this.searchTerm}
          >
          <uf-icon icon="filter" class="clickable"></uf-icon>
        </ul>

        <ul id="filters">
          ${when(this.showBrowseButton, () => html `
              <li>
                <router-link href="/squad/browse">
                  <uf-icon icon="grid" class="clickable"></uf-icon>
                </router-link>
              </li>
            `)}
          ${(this.hideMods ? squadPositions() : [...squadPositions(), "MOD"]).map(tab => html `
              <li class=${this.benchFilter === tab ? "selected" : ""} @click=${() => this.applyBenchFilter(tab)}>
                ${tab}
              </li>
            `)}
        </ul>
      </nav>

      <div id="game-cards">

        <header>
          <h1>Active 50-Card Squad</h1>
          <p>${this.activeSquad.length}</p>
        </header>

        <drop-area
          class=${!this.scrollable ? "scroll-disabled" : ""}
          ?enabled=${dragEnabled && this.editable}
          @dropover=${this.placeCard}
        >
          <div @click=${this.placeCard}>

            ${when(this.editable && !this.hideActionButtons && this.activeSquad.length >= 50, () => html `
                <div class="card">
                  <router-link href="/squad/transfer" id="transfer">
                    <div>
                      <uf-icon icon="transfers"></uf-icon>
                      Transfers
                    </div>
                  </router-link>
                </div>
              `)}

            ${this.activeSquad.map(item => {
            var _a;
            return html `
                <div class=${classMap({
                "card": true,
                "active": !dragEnabled && ((_a = cardPositionConfig.current) === null || _a === void 0 ? void 0 : _a.cardId) === item.cardId
            })}>
                  <long-press
                    ?enabled=${!dragEnabled && this.editable}
                    @longpress=${() => this.selectGameCard(item)}
                  >
                    <drag-item
                      ?enabled=${dragEnabled && this.editable}
                      @drag=${() => this.selectGameCard(item)}
                      @dragcomplete=${this.onDragEnd}
                    >
                      <game-card-front
                        .squadItem=${item}
                        @click=${() => this.openCardView(item)}
                      ></game-card-front>
                    </drag-item>
                  </long-press>
                </div>
              `;
        })}

            ${when(!this.hideMods && this.shouldShowGoldenBoot(), () => html `
                <div class=${classMap({
            "card": true,
            "active": !dragEnabled && cardPositionConfig.current === SquadItemModifier.GoldenBoot
        })}>
                  <long-press
                    ?enabled=${!dragEnabled && this.editable}
                    @longpress=${() => this.selectModifier(SquadItemModifier.GoldenBoot)}
                  >
                    <drag-item
                      ?enabled=${dragEnabled && this.editable}
                      @drag=${() => this.selectModifier(SquadItemModifier.GoldenBoot)}
                      @dragcomplete=${this.onModifierDragEnd}
                    >
                      <game-card-front
                        type="modifier"
                        name="${config.isUF ? "Golden Boot" : "Golden Football"}"
                        modifier=${SquadItemModifier.GoldenBoot}
                      ></game-card-front>
                    </drag-item>
                  </long-press>
                </div>
              `)}
            ${when(!this.hideMods && this.shouldShowCaptain(), () => html `
                <div class=${classMap({
            "card": true,
            "active": !dragEnabled && cardPositionConfig.current === SquadItemModifier.Captain
        })}>
                  <long-press
                    ?enabled=${!dragEnabled && this.editable}
                    @longpress=${() => this.selectModifier(SquadItemModifier.Captain)}
                  >
                    <drag-item
                      ?enabled=${dragEnabled && this.editable}
                      @drag=${() => this.selectModifier(SquadItemModifier.Captain)}
                      @dragcomplete=${this.onModifierDragEnd}
                    >
                      <game-card-front
                        type="modifier"
                        name="${config.isUF ? "Captain" : "Golden Helmet"}"
                        modifier=${SquadItemModifier.Captain}
                      ></game-card-front>
                    </drag-item>
                  </long-press>
                </div>
              `)}
          </div>
        </drop-area>

      </div>
    `;
    }
    onSearchChange(e) {
        const { value } = e.target;
        this.searchTerm = value;
    }
    ;
    applyBenchFilter(filter) {
        this.benchFilter = filter;
    }
    shouldShowCaptain() {
        return (this.benchFilter === "ALL" || this.benchFilter === "MOD") && "captain modifier".includes(this.searchTerm.toLowerCase());
    }
    shouldShowGoldenBoot() {
        return (this.benchFilter === "ALL" || this.benchFilter === "MOD") && "golden boot modifier".includes(this.searchTerm.toLowerCase());
    }
    selectGameCard(card) {
        if (dragEnabled)
            this.scrollable = false;
        this.dispatchEvent(new Event("editstart"));
        cardPositionConfig.current = card;
        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("cardselect", {
            detail: card
        }));
    }
    onDragEnd() {
        this.scrollable = true;
        this.dispatchEvent(new Event("squaddragend"));
        // This timeout allows drop events to pick up cardPositionConfig.current before it is cleared.
        setTimeout(() => {
            cardPositionConfig.current = null;
            this.requestUpdate();
        }, 100);
    }
    onModifierDragEnd() {
        this.scrollable = true;
        this.dispatchEvent(new Event("modifierdragend"));
    }
    selectModifier(modifier) {
        if (dragEnabled)
            this.scrollable = false;
        this.dispatchEvent(new Event("editstart"));
        cardPositionConfig.current = modifier;
        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("modifierselect", {
            detail: modifier
        }));
    }
    placeCard(e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        if (cardPositionConfig.current !== null) {
            if (cardPositionConfig.current.set === SquadItemSet.Lineup) {
                this.dispatchEvent(new CustomEvent("squaddrop", {
                    detail: cardPositionConfig.current
                }));
                cardPositionConfig.current = null;
            }
        }
    }
    openCardView(card) {
        if (cardPositionConfig.current === null) {
            cardViewCurrent.next(card);
        }
    }
};
__decorate([
    property({ type: Boolean })
], SquadCollection.prototype, "editable", void 0);
__decorate([
    property({ type: Array })
], SquadCollection.prototype, "squad", void 0);
__decorate([
    property({ type: Boolean })
], SquadCollection.prototype, "showBrowseButton", void 0);
__decorate([
    property({ type: Boolean })
], SquadCollection.prototype, "hideActionButtons", void 0);
__decorate([
    property({ type: Boolean })
], SquadCollection.prototype, "hideMods", void 0);
__decorate([
    property()
], SquadCollection.prototype, "searchTerm", void 0);
__decorate([
    state()
], SquadCollection.prototype, "scrollable", void 0);
__decorate([
    state()
], SquadCollection.prototype, "benchFilter", void 0);
SquadCollection = __decorate([
    customElement("squad-collection")
], SquadCollection);
export { SquadCollection };
