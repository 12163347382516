import { css } from "lit";
const leagueCardCSS = css `

  :host {
    display: block;
    position: relative;
    margin: auto;
  }

  header {
    height: 3em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-user-league-card-top);
    border-top-left-radius: 1.25em;
    border-top-right-radius: 1.25em;
  }

  header p {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
  }

  footer {
    height: 3em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-user-league-card-bottom);
    border-bottom-left-radius: 1.25em;
    border-bottom-right-radius: 1.25em;
  }

  footer p {
    width: calc(100% / 3);
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
  }

  footer p:nth-child(2) {
    text-align: center;
  }

  footer p:last-child {
    text-align: right;
  }

  footer p:last-child {
    color: var(--color-leaderboard-card-prize);
  }

`;
export default leagueCardCSS;
