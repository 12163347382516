/**
 * Pluralises a word based on a given amount.
 * @param word Word to pluralise.
 * @param amount Total number of 'words'.
 */
export function pluralise(word, amount) {
    if (amount === 1)
        return word;
    let plural = "s";
    if (word[word.length - 1] === "s")
        plural = "es";
    return `${word}${plural}`;
}
