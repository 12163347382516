var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { formatAsStageDate } from "@/views/game/directives/game.directives";
import globalCSS from "~/styles/global.css";
import stageCardCSS from "./stage-card.css";
import ufAPI from '@/services/api/uf/uf.api';
import { findCurrentRank } from "@/services/api/uf/uf.helpers";
import { deepEqual } from "@/services/utils/utils";
let StageCard = class StageCard extends LitElement {
    constructor() {
        super(...arguments);
        this.stage = null;
        this.isCurrentStage = false;
        this.isLink = true;
        this.showLineupButton = false;
    }
    async connectedCallback() {
        super.connectedCallback();
        this.getRank();
    }
    getRank() {
        this.rankSubscription = ufAPI.ranks.subscribe(ranks => {
            if (ranks.length && this.stage) {
                const rank = findCurrentRank(ranks, this.stage);
                if (!deepEqual(this.rank, rank)) {
                    this.rank = rank;
                }
            }
        });
    }
    decideStageStatus() {
        const now = Date.now();
        if (this.stage) {
            if (this.isCurrentStage) {
                return 'green';
            }
            else if (now > this.stage.end * 1000) {
                return 'red';
            }
            else {
                return 'none';
            }
        }
        else {
            return 'none';
        }
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.rankSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this.rankSubscription = undefined;
    }
    render() {
        this.stageStatus = this.decideStageStatus();
        return html `
      ${this.renderCard()}
    `;
    }
    renderCard() {
        return html `
      ${when(this.stage, () => html `
            <div class="card ${this.stageStatus}">
              <div class="details">
                <div class="top">
                  <p>${this.stage.name}</p>
                  ${when(this.isLink, () => html `<uf-icon icon="arrow"></uf-icon>`)}
                </div>

                <div class="bottom">
                  <p class="small-text">${formatAsStageDate(this.stage.start, this.stage.end)}</p>
                  <div class="bottom-right">
                    ${when(this.rank, () => { var _a; return html `<p class="small-text">${(_a = this.rank) === null || _a === void 0 ? void 0 : _a.score} pts</p>`; }, () => html `<p class="small-text">${this.stageStatus === 'none' ? "Coming Soon" : "- pts"}</p>`)}
                    ${this.renderStageIcon()}
                  </div>
                </div>

                ${when(this.showLineupButton, () => html `
                      <router-link href="/squad">
                        <button>
                          <uf-icon icon='shirt'></uf-icon> View Lineup
                        </button>
                      </router-link>
                    `)}

              </div>
            </div>
          `)}
    `;
    }
    renderStageIcon() {
        return html `
      <uf-icon icon="bars" theme="${this.stageStatus}"></uf-icon>
    `;
    }
};
StageCard.styles = [globalCSS, stageCardCSS];
__decorate([
    property({ attribute: false })
], StageCard.prototype, "stage", void 0);
__decorate([
    property({ type: Boolean })
], StageCard.prototype, "isCurrentStage", void 0);
__decorate([
    property({ type: Boolean })
], StageCard.prototype, "isLink", void 0);
__decorate([
    property({ type: Boolean })
], StageCard.prototype, "showLineupButton", void 0);
__decorate([
    state()
], StageCard.prototype, "rank", void 0);
__decorate([
    state()
], StageCard.prototype, "stageStatus", void 0);
StageCard = __decorate([
    customElement("stage-card")
], StageCard);
export { StageCard };
