var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import "./complete.css";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/game-card/game-card-front/game-card-front";
import config from "~/app/services/config/config";
import { SquadItemModifier } from "~/app/services/api/uf/types/uf.api.types.enums";
let OnboardingComplete = class OnboardingComplete extends UnscopedElement {
    render() {
        let captainModifierName = "";
        switch (config.skin.league) {
            case "pl":
                captainModifierName = "Captain";
                break;
            case "nfl": captainModifierName = "Golden Helmet";
        }
        let goldenBootModifierName = "";
        switch (config.skin.league) {
            case "pl":
                goldenBootModifierName = "Golden Boot";
                break;
            case "nfl": goldenBootModifierName = "Golden Football";
        }
        return html `
      <div>

        <div id="complete">
          <div id="complete-icon">
            <uf-icon icon="tick"></uf-icon>
          </div>
          <span>Training Complete</span>
        </div>

        <h1>Congratulations!</h1>
        <p>You've won 2 modifiers! Assign to players in your squad to increase their points.</p>

        <div id="modifiers">
          <div>
            <game-card-front
              type="modifier"
              name=${captainModifierName}
              modifier=${SquadItemModifier.Captain}
            ></game-card-front>
            <h2>${captainModifierName}</h2>
            <p>Your selected player will earn you double points.</p>
          </div>

          <div>
            <game-card-front
              type="modifier"
              name=${goldenBootModifierName}
              modifier=${SquadItemModifier.GoldenBoot}
            ></game-card-front>
            <h2>${goldenBootModifierName}</h2>
            <p>Your selected player will earn you double points if they score a touchdown.</p>
          </div>
        </div>

        <button @click=${this.continue}>Let's Play</button>
        <a href=${config.howToPlayLink()} target="_blank">Need Help?</a>
      </div>
    `;
    }
    continue() {
        this.dispatchEvent(new Event("continue"));
    }
};
OnboardingComplete = __decorate([
    customElement("onboarding-complete")
], OnboardingComplete);
