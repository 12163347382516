import { css } from "lit";
const modalCSS = css `

  :host {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  :host > div {
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
  :host > div.fixed {
    padding: 2em 0;
    display: flex;
    align-items: flex-start;
  }

  #content {
    position: relative;
    border-radius: 1em;
    background-color: var(--modal-background, var(--color-background));
    padding: var(--modal-padding, 1.5em 1.25em);
    width: var(--modal-width, 20em);
    max-width: 90%;
    height: var(--modal-height, auto);
  }
  
`;
export default modalCSS;
