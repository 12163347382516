/**
 * Performs a deep comparison of two values.
 * @param a First value.
 * @param b Second value.
 * @returns A boolean to indicate whether the values are the same.
 */
export function deepEqual(a, b) {
    if (a === b)
        return true;
    if (a == null || typeof a != "object" || b == null || typeof b != "object")
        return false;
    const keysA = Object.keys(a), keysB = Object.keys(b);
    if (keysA.length != keysB.length)
        return false;
    for (let key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key]))
            return false;
    }
    return true;
}
/**
 * Creates a deep copy of an item (to prevent passing by reference)
 * @param object Anything!
 * @returns An exact copy of the Anything
 */
export function deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
}
/**
 * Makes sure you're returning a string
 * @param item An item to confirm it's a string!
 * @returns The string or an empty string ''
 */
export function returnString(item) {
    if (item && typeof item === 'string') {
        return item;
    }
    else {
        return '';
    }
}
export const formatDate = (date, format) => {
    const formatOrder = format.toLowerCase().split(",");
    const splitDate = date.split("/");
    if (formatOrder.length === 3 && splitDate.length === 3) {
        switch (formatOrder[0]) {
            case "d": return dateInDMY(splitDate);
            case "m": return dateInMDY(splitDate);
            default: return "";
        }
    }
    else {
        return "";
    }
};
function dateInDMY(date) {
    let d = new Date(`${date[2]}/${date[1]}/${date[0]}`);
    if (!isNaN(d.getTime())) {
        return d.toISOString();
    }
    else {
        return "";
    }
}
function dateInMDY(date) {
    let d = new Date(`${date[2]}/${date[0]}/${date[1]}`);
    if (!isNaN(d.getTime())) {
        return d.toISOString();
    }
    else {
        return "";
    }
}
/**
 * Groups an array of items by a specific property
 * @param list A list of items to group
 * @param key The property to group the items by
 * @returns A dictionary of all items in the list grouped by the property
 */
export function groupBy(list, key) {
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}
;
/**
 * Removes whitespace from a string. E.g "a string with whitespace" become "astringwithwhitespace"
 * @param string The string to remove whitespace from
 * @returns A string with whitespace removed
 */
export function removeWhitespace(string) {
    return string.replace(/\s/g, "");
}
