var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { repeat } from 'lit/directives/repeat.js';
import { classMap } from "lit/directives/class-map.js";
import "./transfer-in-out.css";
import "@/components/header-bar/header-bar";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/game-card/game-card-front/game-card-front";
import "@/views/game/views/squad/views/transfer/components/transfer-tray/transfer-tray";
import { squadPositions, filterSquadItem } from "~/app/views/game/views/squad/helpers/squad.helpers";
const maxSquadMembers = 50;
let TransferInOut = class TransferInOut extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.index = 0;
        this.transfer = "out";
        this.currentPosition = "ALL";
        this.activeSquadSelections = [];
        this.collectionSelections = [];
    }
    get activeSquadSelected() {
        return this.activeSquadSelections.filter(selection => selection.selected);
    }
    get collectionSelected() {
        return this.collectionSelections.filter(selection => selection.selected);
    }
    get filteredSelections() {
        const selections = this.transfer === "in" ? this.collectionSelections : this.activeSquadSelections;
        return selections.filter(selection => filterSquadItem(selection.item, this.currentPosition));
    }
    get selected() {
        return this.transfer === "in" ? this.collectionSelected : this.activeSquadSelected;
    }
    render() {
        const squadMembers = this.activeSquadSelections.length + this.collectionSelected.length - this.activeSquadSelected.length;
        return html `

      <header-bar class="tall">
        <uf-icon icon="cancel" class="clickable" slot="left" @click=${this.cancelChanges}></uf-icon>
        <div slot="center">
          <h1>Transfer ${this.transfer}</h1>
          <span class=${classMap({
            "error": squadMembers > maxSquadMembers
        })}>
            Squad Members ${squadMembers}/${maxSquadMembers}
          </span>
        </div>
      </header-bar>

      <nav>
        <ul>
        ${squadPositions().map(position => html `
            <li class=${this.currentPosition === position ? "selected" : ""} @click=${() => this.currentPosition = position}>
              <span>${position}</span>
            </li>
          `)}
        </ul>
      </nav>

      <section>

        <header>
          <h2>All</h2>
          <span>${this.filteredSelections.length}</span>
        </header>

        <div id="selection">
          ${repeat(this.filteredSelections, card => card.item.cardId, card => html `
              <div @click=${() => this.selectCard(card)}>
                <game-card-front .squadItem=${card.item}></game-card-front>
                <input type="checkbox" ?checked=${card.selected}>
                <div><uf-icon icon="tick"></uf-icon></div>
              </div>
            `)}
        </div>

      </section>

      <transfer-tray>
        <div slot="body">
        ${this.selected.length ? html `
              <div>
                ${repeat(this.selected, card => card.item.cardId, card => html `
                    <game-card-front .squadItem=${card.item}></game-card-front>
                  `)}
              </div>
            ` : html `
              <p><span>Select the cards you wish to transfer ${this.transfer}</span></p>
            `}
        </div>
        <div slot="buttons">
          <button class="dark" ?disabled=${!this.index} @click=${this.back}>
            <span>Go Back</span>
          </button>
          <button ?disabled=${!!this.index && ((squadMembers > maxSquadMembers) || (!this.activeSquadSelected.length && !this.collectionSelected.length))} @click=${this.next}>
            <span>Next</span>
          </button>
        </div>
      </transfer-tray>

    `;
    }
    async selectCard(card) {
        card.selected = !card.selected;
        this.requestUpdate();
        await this.updateComplete;
        this.trayBody.scrollTo(this.trayBody.scrollWidth, 0);
    }
    next() {
        this.currentPosition = "ALL";
        this.dispatchEvent(new Event("next"));
    }
    back() {
        this.currentPosition = "ALL";
        this.dispatchEvent(new Event("back"));
    }
    cancelChanges() {
        this.dispatchEvent(new Event("cancel"));
    }
};
__decorate([
    property({ type: Number })
], TransferInOut.prototype, "index", void 0);
__decorate([
    property()
], TransferInOut.prototype, "transfer", void 0);
__decorate([
    state()
], TransferInOut.prototype, "currentPosition", void 0);
__decorate([
    property({ type: Object })
], TransferInOut.prototype, "activeSquadSelections", void 0);
__decorate([
    property({ type: Object })
], TransferInOut.prototype, "collectionSelections", void 0);
__decorate([
    query("transfer-tray [slot=body]")
], TransferInOut.prototype, "trayBody", void 0);
TransferInOut = __decorate([
    customElement("transfer-in-out")
], TransferInOut);
export { TransferInOut };
