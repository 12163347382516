import { SquadItemRating } from "@/services/api/uf/types/uf.api.types.enums";
/**
 * Sorts squad items by card rating: Gold -> Silver -> Standard.
 * @param squad An array of squad items.
 * @returns The squad item array sorted by card rating.
 */
export function sortSquadByRating(squad) {
    const gold = squad.filter(item => item.cardRating === SquadItemRating.Gold);
    const silver = squad.filter(item => item.cardRating === SquadItemRating.Silver);
    const standard = squad.filter(item => item.cardRating === SquadItemRating.Standard);
    [gold, silver, standard].forEach(group => {
        group.sort((a, b) => (a.team > b.team) ? 1 : -1);
    });
    return [...gold, ...silver, ...standard];
}
