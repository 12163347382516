var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import "./stage-list.css";
import '@/views/game/views/leaderboards/components/stage-card/stage-card';
import '@/components/header-bar/header-bar';
import ufApi from '@/services/api/uf/uf.api';
import { findCurrentStage } from "@/services/api/uf/uf.helpers";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
import UnscopedElement from "~/app/layout/unscoped-element";
import router from "~/app/router/router";
let StageListView = class StageListView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.stages = [];
    }
    async connectedCallback() {
        super.connectedCallback();
        this.getStages();
        ufApi.getStages().catch(error => () => this.handleErrors(error));
        analytics.recordTag(AnalyticsKeys.LEADERBOARDS_PAGE);
    }
    getStages() {
        this.stageSubscription = ufApi.stages.subscribe(stages => {
            this.stages = stages.sort(this.sortStages);
            this.currentStage = findCurrentStage(stages);
        });
    }
    sortStages(a, b) {
        return a.start - b.start;
    }
    handleErrors(error) {
        console.log(error);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.stageSubscription) {
            this.stageSubscription.unsubscribe();
        }
    }
    render() {
        return html `
      ${this.renderHeaderBar()}
      ${this.renderStages()}
    `;
    }
    renderHeaderBar() {
        return html `
      <header-bar>
        <div slot="center">
          <h3>All Weeks</h3>
        </div>
        <div slot="left">
          <uf-icon icon='chevron' class="clickable" @click=${router.back}></uf-icon>
        </div>
      </header-bar>
    `;
    }
    renderStages() {
        return html `
      <section>
        ${map(this.stages, stage => {
            var _a, _b;
            return html `
              <router-link href="/leaderboard/stage/${stage.id}" .data=${{ stage: stage, isCurrentStage: stage.id === ((_a = this.currentStage) === null || _a === void 0 ? void 0 : _a.stage.id) }}>
                <stage-card
                  .stage=${stage}
                  ?isCurrentStage=${stage.id === ((_b = this.currentStage) === null || _b === void 0 ? void 0 : _b.stage.id)}
                  ?isLink=${false}
                  ?showLineupButton=${false}
                ></stage-card>
              </router-link>
            `;
        })}
      </section>
    `;
    }
};
__decorate([
    state()
], StageListView.prototype, "stages", void 0);
__decorate([
    state()
], StageListView.prototype, "currentStage", void 0);
StageListView = __decorate([
    customElement("stage-list-view")
], StageListView);
export { StageListView };
