var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import transferTrayStyle from "./transfer-tray.css";
let TransferTray = class TransferTray extends ScopedElement {
    render() {
        return html `
      <slot name="body"></slot>
      <slot name="buttons"></slot>
    `;
    }
};
TransferTray.styles = [ScopedElement.styles, transferTrayStyle];
TransferTray = __decorate([
    customElement("transfer-tray")
], TransferTray);
