var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconCancel = class IconCancel extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 2.11143L14.3886 0.5L8 6.88857L1.61143 0.5L0 2.11143L6.38857 8.5L0 14.8886L1.61143 16.5L8 10.1114L14.3886 16.5L16 14.8886L9.61143 8.5L16 2.11143Z" />
      </svg>
    `;
    }
};
IconCancel.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }
  `];
IconCancel = __decorate([
    customElement("icon-cancel")
], IconCancel);
export { IconCancel };
