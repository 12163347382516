import config from "@/services/config/config";
import gold from "./images/PACK-GOLD.png";
import silver from "./images/PACK-SILVER.png";
import regularUF from "./images/PACK-BASE-GREY-3cards.png";
import regularChargers from "./images/PACK-WEEKLY.png";
import regularBengals from "./images/PACK-WEEKLY-BENGALS-BETFRED.png";
import team from "./images/PACK-POSITION-TEAM.png";
import defender from "./images/PACK-POSITION-DEF.png";
import midfielder from "./images/PACK-POSITION-MID.png";
import forward from "./images/PACK-POSITION-FWD.png";
import baseUF from "./images/PACK-BASE-GREY-10cards.png";
import baseChargers from "./images/PACK-SQUAD.png";
import baseBengals from "./images/PACK-SQUAD-BENGALS-BETFRED.png";
import clash from "./images/PACK-UF-CLASH.png";
import referralUF from "./images/PACK-REFERRAL.png";
import referralChargers from "./images/PACK-Chargers-Referral.png";
import referralBengals from "./images/PACK-BENGALS-Referral.png";
import streakUF from "./images/PACK-LOGIN-STREAK.png";
import streakChargers from "./images/PACK-Chargers-Login-streak.png";
import streakBengals from "./images/PACK-BENGALS-Login-streak.png";
let regular = regularUF;
if (config.isChargers)
    regular = regularChargers;
if (config.isBengals)
    regular = regularBengals;
let base = baseUF;
if (config.isChargers)
    base = baseChargers;
if (config.isBengals)
    base = baseBengals;
let referral = referralUF;
if (config.isChargers)
    referral = referralChargers;
if (config.isBengals)
    referral = referralBengals;
let streak = streakUF;
if (config.isChargers)
    streak = streakChargers;
if (config.isBengals)
    streak = streakBengals;
export const packStatics = new Map([
    // Purchased
    ["g1", gold],
    ["s1", silver],
    ["r1", regular],
    ["t1", team],
    ["d1", defender],
    ["m1", midfielder],
    ["f1", forward],
    // Given
    ["0", base],
    ["1", regular],
    ["4", clash],
    ["5", referral],
    ["6", streak],
    ["7", streak],
    ["8", streak],
    ["9", streak]
]);
