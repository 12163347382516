var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import low6Api from "@/services/api/low6/low6.api";
import { deepCopy, returnString } from "@/services/utils/utils";
import { FormController } from "@/components/forms/form-controller";
import { initialValues, initialErrors, } from "./profile-form.config";
import globalCSS from "~/styles/global.css";
import formCSS from "@/components/forms/form.css";
import ConfigurationService from "@/services/config/config";
let ProfileForm = class ProfileForm extends LitElement {
    constructor() {
        super();
        this.isSubmitting = false;
        this.values = initialValues;
        this.errors = initialValues;
        this.onChange = (e) => {
            if (this.form) {
                this.form.handleChange(e);
            }
        };
    }
    updated() {
        this.resetForm(false);
    }
    render() {
        if (this.form) {
            return html `
      <form>
        <div>
          <h2>Personal Details</h2>
          <div class="icon-background">
            <uf-icon icon='edit'></uf-icon>
          </div>
        </div>
        <section>
          <label>first name</label>
          <form-textbox
            name="firstName"
            .minlength=${2}
            .maxlength=${100}
            .handleChange=${this.onChange}
            .value=${this.values.firstName}
            .errorMessage=${this.errors.firstName}
            placeholder="first name"
          >
          </form-textbox>
          <label>last name</label>
          <form-textbox
            name="lastName"
            .minlength=${2}
            .maxlength=${100}
            .handleChange=${this.onChange}
            .value=${this.values.lastName}
            .errorMessage=${this.errors.lastName}
            placeholder="last name"
          >
          </form-textbox>
          <label>email</label>
          <form-textbox
            name="email"
            .minlength=${2}
            .maxlength=${100}
            .handleChange=${this.onChange}
            .value=${this.values.email}
            .errorMessage=${this.errors.email}
            placeholder="email"
          >
          </form-textbox>
          <label>telephone number</label>
          <form-textbox
            name="mobileNumber"
            .minlength=${2}
            .maxlength=${100}
            .handleChange=${this.onChange}
            .value=${this.values.mobileNumber}
            .errorMessage=${this.errors.mobileNumber}
            placeholder="telephone number"
          >
          </form-textbox>

          ${!ConfigurationService.isUF ?
                html `
          <label>state</label>
          <form-textbox
            name="county"
            .minlength=${2}
            .maxlength=${20}
            .handleChange=${this.onChange}
            .value=${this.values.county}
            .errorMessage=${this.errors.county}
            placeholder="state">
          </form-textbox>
        ` : null}

        </section>

        ${ConfigurationService.isUF ?
                html `
        <div>
          <h2>Address</h2>
          <label>where you are billed</label>
          <div class="icon-background">
            <uf-icon icon='edit'></uf-icon>
          </div>
        </div>
        
        <section>
          <label>address line 1</label>
          <form-textbox
          name="line1"
          .minlength=${2}
          .maxlength=${100}
          .handleChange=${this.onChange}
          .value=${this.values.line1}
          .errorMessage=${this.errors.line1}
          placeholder="address line 1">
        </form-textbox>

        <label>address line 2</label>
        <form-textbox
          name="line2"
          .minlength=${2}
          .maxlength=${100}
          .handleChange=${this.onChange}
          .value=${this.values.line2}
          .errorMessage=${this.errors.line2}
          placeholder="address line 2">
        </form-textbox>

        <label>city</label>
        <form-textbox
          name="city"
          .minlength=${2}
          .maxlength=${100}
          .handleChange=${this.onChange}
          .value=${this.values.city}
          .errorMessage=${this.errors.city}
          placeholder="city">
        </form-textbox>

        <label>county</label>
        <form-textbox
          name="county"
          .minlength=${2}
          .maxlength=${100}
          .handleChange=${this.onChange}
          .value=${this.values.county}
          .errorMessage=${this.errors.county}
          placeholder="county">
        </form-textbox>
        
        <label>postcode</label>
        <form-textbox
          name="postcode"
          .minlength=${5}
          .maxlength=${7}
          .handleChange=${this.onChange}
          .value=${this.values.postcode}
          .errorMessage=${this.errors.postcode}
          placeholder="zipcode / postcode">
      </form-textbox>
      </section>
      ` : null}


        <button
          .disabled=${!this.form.isDirty}
          .isLoading=${this.isSubmitting}
          @click=${this.handleSubmit}
        >
          <uf-icon icon='save' theme=${this.form.isDirty ? 'white' : 'grey'}></uf-icon>
          Save Changes
        </button>
      </form>
    `;
        }
        else {
            return html ``;
        }
    }
    resetForm(force) {
        var _a;
        if (!((_a = this.values) === null || _a === void 0 ? void 0 : _a.username) || force) {
            this.values = this.setupFormValuesFromProfile(this.fullProfile);
            this.form = new FormController(this, this.setupFormValuesFromProfile(this.fullProfile), initialErrors);
        }
    }
    async handleSubmit(e) {
        e.preventDefault();
        if (this.fullProfile && this.form) {
            const profileUpdate = this.setFormValuesToFullProfile(this.form.values, this.fullProfile);
            const updateToSend = this.createProfileUpdateObject(profileUpdate);
            this.isSubmitting = true;
            const updateResponse = await low6Api.updateProfile(updateToSend);
            if (updateResponse) {
                this.fullProfile = updateResponse;
                this.resetForm(true);
            }
            this.isSubmitting = false;
        }
    }
    setupFormValuesFromProfile(user) {
        if (user) {
            return {
                email: user.email.trim(),
                firstName: user.firstName.trim(),
                lastName: user.lastName.trim(),
                username: user.username.trim(),
                mobileNumber: user.mobileNumber.trim(),
                postcode: user.address.postcode ? user.address.postcode.trim() : '',
                line1: user.address.line1 ? user.address.line1.trim() : '',
                line2: user.address.line2 ? user.address.line2.trim() : '',
                county: user.address.county ? user.address.county.trim() : '',
                city: user.address.city ? user.address.city.trim() : '',
            };
        }
        else {
            return initialValues;
        }
    }
    setFormValuesToFullProfile(values, user) {
        const valueCopy = deepCopy(values);
        user.email = valueCopy.email;
        user.firstName = valueCopy.firstName;
        user.lastName = valueCopy.lastName;
        user.username = valueCopy.username;
        user.mobileNumber = valueCopy.mobileNumber;
        user.address.postcode = valueCopy.postcode;
        user.address.line1 = valueCopy.line1;
        user.address.line2 = valueCopy.line2;
        user.address.county = valueCopy.county;
        user.address.city = valueCopy.city;
        return user;
    }
    createProfileUpdateObject(user) {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            email: user.email,
            mobileNumber: user.mobileNumber,
            /* ISO String (without time) */
            birthDate: user.birthDate,
            address: {
                city: returnString(user.address.city),
                county: returnString(user.address.county),
                country: returnString(user.address.country),
                line1: returnString(user.address.line1),
                line2: returnString(user.address.line2),
                postcode: returnString(user.address.postcode)
            },
            termsConditionsAccepted: user.termsConditionsAccepted,
            contactPermissions: user.contactPermissions
        };
    }
};
ProfileForm.styles = [globalCSS, formCSS];
__decorate([
    state()
], ProfileForm.prototype, "values", void 0);
__decorate([
    state()
], ProfileForm.prototype, "errors", void 0);
__decorate([
    state()
], ProfileForm.prototype, "fullProfile", void 0);
ProfileForm = __decorate([
    customElement("profile-form")
], ProfileForm);
export { ProfileForm };
