var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let DropArea = class DropArea extends LitElement {
    constructor() {
        super(...arguments);
        this.enabled = false;
        this.canBeDisabled = false;
        this.dropEvent = (e) => {
            const { left, top, width, height } = this.getBoundingClientRect();
            let event;
            const touches = e.changedTouches;
            if (touches) {
                event = touches[0];
            }
            else {
                event = e;
            }
            if (event.clientX >= left && event.clientX <= (left + width) && event.clientY >= top && event.clientY <= (top + height)) {
                this.dispatchEvent(new Event("dropover"));
            }
        };
    }
    updated() {
        this.enabled ? this.enable() : this.disable();
    }
    enable() {
        this.canBeDisabled = true;
        ["mouseup", "touchend"].forEach(type => {
            window.addEventListener(type, this.dropEvent);
        });
    }
    disable() {
        if (this.canBeDisabled) {
            ["mouseup", "touchend"].forEach(type => {
                window.removeEventListener(type, this.dropEvent);
            });
        }
    }
    render() {
        return html `
      <slot></slot>
    `;
    }
};
DropArea.styles = css `
    :host {
      display: block;
    }
  `;
__decorate([
    property({ type: Boolean })
], DropArea.prototype, "enabled", void 0);
DropArea = __decorate([
    customElement("drop-area")
], DropArea);
export { DropArea };
