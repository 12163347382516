var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconCoin = class IconCoin extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = "gold";
    }
    render() {
        return html `
      ${choose(this.theme, [
            ["gold", this.renderGoldCoin],
            ["grey", this.renderGreyCoin]
        ])}
    `;
    }
    renderGoldCoin() {
        return html `
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15.5" fill="url(#paint0_linear_20_670)" stroke="url(#paint1_linear_20_670)" />
        <g filter="url(#filter0_i_20_670)">
          <path
            d="M15.3502 18.2822C15.1777 19.2559 14.5632 20.1145 13.1614 20.457C11.7402 20.8043 11.3943 20.1811 11.5673 19.2074L13.0789 10.7412L9.641 11.5818L8.07175 20.3785C7.59248 23.1418 9.55151 24.5819 12.8353 23.7788C15.7925 23.0554 18.1547 20.9313 18.7105 17.777L18.92 16.5287L23.5908 15.3867L24.0931 12.4493L16.0001 14.4284L15.3502 18.2822Z"
            fill="url(#paint2_linear_20_670)" />
          <path d="M16.7368 10.0206L16.2469 12.868L24.5129 10.8466L25 8L16.7368 10.0206Z" fill="url(#paint3_linear_20_670)" />
        </g>
        <path
          d="M15.3994 18.2909L15.3995 18.2905L16.0439 14.4692L24.031 12.5159L23.5471 15.3459L18.9081 16.4802L18.8761 16.488L18.8707 16.5205L18.6613 17.7684C18.6613 17.7684 18.6613 17.7685 18.6613 17.7686C18.1093 20.9002 15.7645 23.0108 12.8234 23.7302C11.1887 24.13 9.89521 23.9685 9.07159 23.3755C8.25106 22.7847 7.88365 21.7556 8.12101 20.3871L9.6845 11.6226L13.0161 10.808L11.518 19.1986L11.518 19.1986C11.431 19.6887 11.4701 20.111 11.7318 20.3636C11.994 20.6166 12.4592 20.6801 13.1733 20.5056C13.8822 20.3324 14.3954 20.0277 14.7535 19.6402C15.1115 19.2528 15.3118 18.7854 15.3994 18.2909ZM24.9379 8.06667L24.4691 10.8058L16.3091 12.8013L16.7805 10.0613L24.9379 8.06667Z"
          stroke="url(#paint4_linear_20_670)" stroke-width="0.1" />
        <defs>
          <filter id="filter0_i_20_670" x="8" y="8" width="17.5" height="16.5" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dx="0.5" dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_20_670" />
          </filter>
          <linearGradient id="paint0_linear_20_670" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFF500" />
            <stop offset="0.498264" stop-color="#FF9F00" />
            <stop offset="1" stop-color="#D58D01" />
          </linearGradient>
          <linearGradient id="paint1_linear_20_670" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFF8D1" />
            <stop offset="0.122393" stop-color="#FFD600" />
            <stop offset="1" stop-color="#C17900" />
          </linearGradient>
          <linearGradient id="paint2_linear_20_670" x1="16.5" y1="8" x2="16.5" y2="24" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D98802" />
            <stop offset="1" stop-color="#D98802" />
          </linearGradient>
          <linearGradient id="paint3_linear_20_670" x1="16.5" y1="8" x2="16.5" y2="24" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D98802" />
            <stop offset="1" stop-color="#D98802" />
          </linearGradient>
          <linearGradient id="paint4_linear_20_670" x1="16.5" y1="8" x2="16.5" y2="24" gradientUnits="userSpaceOnUse">
            <stop stop-color="#9E6200" />
            <stop offset="1" stop-color="#FFC401" />
          </linearGradient>
        </defs>
      </svg>
    `;
    }
    renderGreyCoin() {
        return html `
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15.5" fill="#6D6D6D" stroke="url(#paint0_linear_467_14620)" />
        <g filter="url(#filter0_i_467_14620)">
          <path
            d="M15.3502 18.2822C15.1777 19.2559 14.5632 20.1145 13.1614 20.457C11.7402 20.8043 11.3943 20.1811 11.5673 19.2074L13.0789 10.7412L9.641 11.5818L8.07175 20.3785C7.59248 23.1418 9.55151 24.5819 12.8353 23.7788C15.7925 23.0554 18.1547 20.9313 18.7105 17.777L18.92 16.5287L23.5908 15.3867L24.0931 12.4493L16.0001 14.4284L15.3502 18.2822Z"
            fill="#5B5B5B" />
          <path d="M16.7368 10.0206L16.2469 12.868L24.5129 10.8466L25 8L16.7368 10.0206Z" fill="#5B5B5B" />
        </g>
        <path
          d="M15.3994 18.2909L15.3995 18.2905L16.0439 14.4692L24.031 12.5159L23.5471 15.3459L18.9081 16.4802L18.8761 16.488L18.8707 16.5205L18.6613 17.7684C18.6613 17.7684 18.6613 17.7685 18.6613 17.7686C18.1093 20.9002 15.7645 23.0108 12.8234 23.7302C11.1887 24.13 9.89521 23.9685 9.07159 23.3755C8.25106 22.7847 7.88365 21.7556 8.12101 20.3871L9.6845 11.6226L13.0161 10.808L11.518 19.1986L11.518 19.1986C11.431 19.6887 11.4701 20.111 11.7318 20.3636C11.994 20.6166 12.4592 20.6801 13.1733 20.5056C13.8822 20.3324 14.3954 20.0277 14.7535 19.6402C15.1115 19.2528 15.3118 18.7854 15.3994 18.2909ZM24.9379 8.06667L24.4691 10.8058L16.3091 12.8013L16.7805 10.0613L24.9379 8.06667Z"
          stroke="url(#paint1_linear_467_14620)" stroke-width="0.1" />
        <defs>
          <filter id="filter0_i_467_14620" x="8" y="8" width="17.5" height="16.5" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha" />
            <feOffset dx="0.5" dy="0.5" />
            <feGaussianBlur stdDeviation="0.25" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_467_14620" />
          </filter>
          <linearGradient id="paint0_linear_467_14620" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stop-color="#898988" />
            <stop offset="1" stop-color="#484848" />
          </linearGradient>
          <linearGradient id="paint1_linear_467_14620" x1="16.5" y1="8" x2="16.5" y2="24" gradientUnits="userSpaceOnUse">
            <stop stop-color="#575757" />
            <stop offset="1" stop-color="#A5A5A5" />
          </linearGradient>
        </defs>
      </svg>
    `;
    }
};
IconCoin.styles = iconCSS;
__decorate([
    property()
], IconCoin.prototype, "theme", void 0);
IconCoin = __decorate([
    customElement("icon-coin")
], IconCoin);
export { IconCoin };
