var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import ufApi from "@/services/api/uf/uf.api";
import globalCSS from "~/styles/global.css";
import "@/components/uf-overlay/uf-overlay";
import ConfigurationService from "@/services/config/config";
let ReferAFriendOnboardingView = class ReferAFriendOnboardingView extends LitElement {
    constructor() {
        super();
        ufApi.loginDetails.subscribe(login => {
            this.referralCode = login.referralCode;
        });
    }
    render() {
        return html `
        <uf-overlay>
            <section>
                ${this.renderHeaderText()}
                <refer-friends-card .referralCode=${this.referralCode}></refer-friends-card>
                ${this.renderBack()}
            </section>
        </uf-overlay>
        `;
    }
    renderHeaderText() {
        return html `
            <div class="header">
                <uf-icon icon="rafOnboarding" theme="${this.getIconTheme()}"></uf-icon>
                <h1>Refer Friends For Rewards</h1>
                <p>Invite your friends to play UltimateFan and for each friend who signs up with your unique code you'll both receive 300 UF Coin.</p>
            </div>
        `;
    }
    renderBack() {
        return html `
            <router-link href="/refer-a-friend">
                <button>Got It</button>
            </router-link>
        `;
    }
    getIconTheme() {
        if (ConfigurationService.isUF)
            return "red";
        if (ConfigurationService.isBengals)
            return "orange";
        if (ConfigurationService.isChargers)
            return "yellow";
        return "";
    }
};
ReferAFriendOnboardingView.styles = [globalCSS, css `

    :host {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }

    section {
        display: flex;
        width: 38em;
        max-width: 100%;
        flex-direction: column;
        row-gap: 2em;
        justify-content: center;
        margin: 5em auto;
    }

    div.header {
        text-align: center;
        width: 33.1em;
        max-width: 100%;
        margin: 0em auto;
    }

    uf-icon[icon=rafOnboarding] {
        width: 4.94em;
        margin: 0em auto;
    }

    div.header h1 {
        margin-top: 2.5em
    }

    p {
        margin: 0em auto;
    }

    button {
        margin: 3.8125em auto 0em;
    }

    `];
__decorate([
    state()
], ReferAFriendOnboardingView.prototype, "referralCode", void 0);
ReferAFriendOnboardingView = __decorate([
    customElement("refer-a-friend-onboarding-view")
], ReferAFriendOnboardingView);
export { ReferAFriendOnboardingView };
