import { css } from "lit";
const tabBarCSS = css `

  :host {
    display: block;
  }

  nav {
    position: relative;
    padding: 0.125em;
    width: max-content;
  }
  nav::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #29282D;
    mix-blend-mode: luminosity;
    border-radius: 0.375em;
  }
  nav ul {
    position: relative;
    display: flex;
  }
  nav ul li {
    flex-grow: 1;
  }
  nav ul li router-link {
    display: block;
    width: 7em;
    height: 2.5em;
    border-radius: 0.375em;
  }
  nav ul li router-link.active {
    background-color: rgba(255, 255, 255, 0.25); 
  }
  nav ul li router-link span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
  }

  @media (max-width: 500px) {
    nav ul li router-link {
      width: 5.25em;
    }
  }

`;
export default tabBarCSS;
