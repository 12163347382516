var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "../../../styles/global.css";
import "../asset/logo-uf/logo-uf";
let IntroFooterText = class IntroFooterText extends LitElement {
    render() {
        return html `
      <div class="wrapper">
        <p>Owned and operated by</p>
        <logo-uf theme="grey"></logo-uf>
        <p>UltimateFan. A trading name of Low6 USA Inc.</p>
      </div>
    `;
    }
};
IntroFooterText.styles = [globalCSS, css `
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .wrapper {
      margin-top: 0.5em;
      display: flex;
      justify-content: center;
      column-gap: 0.3em;
    }

    logo-uf {
      width: 0.8em;
      height: 0.8em;
      position: relative;
      top: 0.15em;
    }
    p {
      margin: 0;
      color: #858585;
      font-size: 0.7em;
    }
  `];
IntroFooterText = __decorate([
    customElement("intro-footer-text")
], IntroFooterText);
export { IntroFooterText };
