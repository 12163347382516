var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconSearch = class IconSearch extends LitElement {
    render() {
        return html `
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7415 15.4843L12.5507 11.2762C13.6283 10.0396 14.2186 8.48359 14.2186 6.86374C14.2186 3.07913 11.0293 0 7.10932 0C3.1893 0 0 3.07913 0 6.86374C0 10.6484 3.1893 13.7275 7.10932 13.7275C8.58095 13.7275 9.98334 13.299 11.1823 12.4854L15.405 16.7255C15.5815 16.9024 15.8189 17 16.0732 17C16.314 17 16.5425 16.9114 16.7159 16.7502C17.0843 16.4079 17.0961 15.8403 16.7415 15.4843ZM7.10932 1.79054C10.0068 1.79054 12.364 4.06632 12.364 6.86374C12.364 9.66117 10.0068 11.9369 7.10932 11.9369C4.21181 11.9369 1.85461 9.66117 1.85461 6.86374C1.85461 4.06632 4.21181 1.79054 7.10932 1.79054Z" fill="#F6F3EE" />
      </svg>
    `;
    }
};
IconSearch.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }
  `];
IconSearch = __decorate([
    customElement("icon-search")
], IconSearch);
export { IconSearch };
