export var Configurations;
(function (Configurations) {
    Configurations[Configurations["UF"] = 0] = "UF";
    Configurations[Configurations["Bengals"] = 1] = "Bengals";
    Configurations[Configurations["Chargers"] = 2] = "Chargers";
})(Configurations || (Configurations = {}));
;
class ConfigurationService {
    constructor(skin) {
        // change this to alter the confiiguration
        this.current = Configurations.UF;
        this.appName = () => {
            switch (this.current) {
                case Configurations.UF: return "UF";
                case Configurations.Bengals: return "Bengals";
                case Configurations.Chargers: return "Chargers";
            }
        };
        this.appNameShort = () => {
            switch (this.current) {
                case Configurations.UF: return "UF";
                case Configurations.Bengals: return "Bengals";
                case Configurations.Chargers: return "Chargers";
            }
        };
        this.appNameLong = () => {
            switch (this.current) {
                case Configurations.UF: return "UltimateFan";
                case Configurations.Bengals: return "UltimateBengals";
                case Configurations.Chargers: return "UltimateChargers";
            }
        };
        this.tenants = () => {
            switch (this.current) {
                case Configurations.UF: return "Low6-ULTFAN";
                case Configurations.Bengals: return "Low6-ULTFANCBEN";
                case Configurations.Chargers: return "Low6-ULTCHARG";
            }
        };
        this.useMinRequirementsAPI = () => {
            return this.current != Configurations.UF;
        };
        this.minAgeValidation = () => {
            switch (this.current) {
                case Configurations.UF: return 18;
                case Configurations.Bengals: return 21;
                case Configurations.Chargers: return 18;
            }
        };
        this.datePattern = () => {
            switch (this.current) {
                case Configurations.UF: return ['d', 'm', 'Y'];
                case Configurations.Bengals:
                case Configurations.Chargers:
                    return ['m', 'd', 'Y'];
            }
        };
        this.datePlaceHolder = () => {
            switch (this.current) {
                case Configurations.UF: return "DD/MM/YYYY";
                case Configurations.Bengals:
                case Configurations.Chargers:
                    return "MM/DD/YYYY";
            }
        };
        this.ageRatingIcon = () => {
            switch (this.current) {
                case Configurations.UF: return "eighteen";
                case Configurations.Bengals: return "twentyOne";
                case Configurations.Chargers: return "eighteen";
            }
        };
        this.storageBaseUrl = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://ufpremier22prodstorage.blob.core.windows.net';
                case Configurations.Bengals: return 'https://ufnflstorage.blob.core.windows.net';
                case Configurations.Chargers: return 'https://ufnflstorage.blob.core.windows.net';
            }
        };
        this.storageTeamUrl = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://ufpremier22prodstorage.blob.core.windows.net/ultimatefan';
                case Configurations.Bengals: return 'https://ufnflstorage.blob.core.windows.net/bengals';
                case Configurations.Chargers: return 'https://ufnfl2storage.blob.core.windows.net/chargers';
            }
        };
        this.homeTeamCode = () => {
            switch (this.current) {
                case Configurations.UF: return '';
                case Configurations.Bengals: return 'cin';
                case Configurations.Chargers: return 'lac';
            }
        };
        this.locale = () => {
            switch (this.current) {
                case Configurations.UF: return 'en-UK';
                case Configurations.Bengals: return 'en-US';
                case Configurations.Chargers: return 'en-US';
            }
        };
        this.currencyLocale = () => {
            switch (this.current) {
                case Configurations.UF: return 'GBR';
                case Configurations.Bengals: return 'USD';
                case Configurations.Chargers: return 'USD';
            }
        };
        this.showGambleAwareText = () => {
            switch (this.current) {
                case Configurations.UF: return true;
                case Configurations.Bengals: return false;
                case Configurations.Chargers: return false;
            }
        };
        this.showSportsRadarText = () => {
            switch (this.current) {
                case Configurations.UF: return false;
                case Configurations.Bengals: return true;
                case Configurations.Chargers: return true;
            }
        };
        this.showMonetryPrizes = () => {
            switch (this.current) {
                case Configurations.UF: return true;
                case Configurations.Bengals: return false;
                case Configurations.Chargers: return false;
            }
        };
        this.clashName = () => {
            switch (this.current) {
                case Configurations.UF: return "UFClash";
                case Configurations.Bengals: return "Clash";
                case Configurations.Chargers: return "Clash";
            }
        };
        // Links:
        this.aboutLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/about/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/about/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/about/';
            }
        };
        this.contactLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/contact/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/contact/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/contact/';
            }
        };
        this.doNotSellMyDataLink = () => {
            switch (this.current) {
                case Configurations.UF: return null;
                case Configurations.Bengals: return null;
                case Configurations.Chargers: return 'https://consent-form.netlify.app/?tenant=Low6-LACNFL';
            }
        };
        this.faqsLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/faqs/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/faqs/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/faqs/';
            }
        };
        this.gameRulesLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/game-rules/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/game-rules/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/game-rules/';
            }
        };
        this.helpLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/help/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/help/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/help/';
            }
        };
        this.howToPlayLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/how-to-play/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/how-to-play/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/how-to-play/';
            }
        };
        this.low6PrivacyPolicyLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/privacy-policy/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/privacy-policy/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/privacy-policy/';
            }
        };
        this.homeTeamPrivacyPolicyLink = () => {
            switch (this.current) {
                case Configurations.UF: return null;
                case Configurations.Bengals: return 'https://www.bengals.com/team/privacypolicy/';
                case Configurations.Chargers: return 'https://www.chargers.com/privacy-policy/';
            }
        };
        this.termsAndConditionsLink = () => {
            switch (this.current) {
                case Configurations.UF: return 'https://www.ultimatefan.com/uk/terms/';
                case Configurations.Bengals: return 'https://www.ultimatefan.com/us/terms/';
                case Configurations.Chargers: return 'https://www.ultimatefan.com/us/terms/';
            }
        };
        // XtremePush
        this.xtremePushAppName = () => {
            switch (this.current) {
                case Configurations.UF: return 'UltimateFan';
                case Configurations.Bengals: return 'UltimateBengals';
                case Configurations.Chargers: return 'UltimateChargers';
            }
        };
        this.skin = skin;
        if (skin.title)
            document.title = skin.title;
        switch (skin.name) {
            case "default":
                this.current = Configurations.UF;
                break;
            case "bengals":
                this.current = Configurations.Bengals;
                break;
            case "chargers":
                this.current = Configurations.Chargers;
                break;
            default: this.current = Configurations.UF;
        }
    }
    get isUF() {
        return this.current === Configurations.UF;
    }
    get isBengals() {
        return this.current === Configurations.Bengals;
    }
    get isChargers() {
        return this.current === Configurations.Chargers;
    }
}
export default new ConfigurationService(skinConfig);
