import { css } from "lit";
const transferTrayStyle = css `

  :host {
    display: block;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: var(--selection-tray-margin);
    width: 48.75em;
    max-width: 90%;
    height: var(--selection-tray-height);
    background-color: var(--color-background);
    z-index: 1;
    padding: 1.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1.25em;
    box-shadow: 0 0 3em #000000;
    border-radius: 1em; 
  }

  @media (max-width: 500px) {
    :host {
      font-size: 0.75em;
    }
  }

`;
export default transferTrayStyle;
