var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { ifDefined } from "lit/directives/if-defined.js";
import ConfigurationService from "@/services/config/config";
import globalCSS from "~/styles/global.css";
import startContainerCSS from "./start-container.css";
import "@/components/header-bar/header-bar";
import "@/components/asset/uf-icon/uf-icon";
import router from "~/app/router/router";
let StartContainer = class StartContainer extends LitElement {
    constructor() {
        super(...arguments);
        this.hideHeader = false;
        this.backRoute = "/";
        this.historicBack = false;
        this.noHelp = false;
    }
    render() {
        return html `

      ${when(!this.hideHeader, () => html `
          <header>
            <header-bar class="tall">
              ${this.historicBack ?
            html `
                    <uf-icon slot="left" icon="chevron" class="clickable" @click=${router.back}></uf-icon>
                  `
            :
                html `
                    <router-link slot="left" href=${ifDefined(this.backRoute)}>
                      <uf-icon icon="chevron" class="clickable"></uf-icon>
                    </router-link>
                  `}

  
              ${when(this.heading, () => html `
                  <h1 slot="center">${this.heading}</h1>
                `)}
  
              ${when(!this.noHelp, () => html `
                  <a slot="right" href="${ConfigurationService.helpLink()}" target="_blank">
                    <uf-icon icon="help" class="clickable"></uf-icon>
                  </a>
                `)}
            </header-bar>
          </header>
        `)}

      <div id="container">
        <slot></slot>
      </div>

    `;
    }
};
StartContainer.styles = [globalCSS, startContainerCSS];
__decorate([
    property({ type: Boolean })
], StartContainer.prototype, "hideHeader", void 0);
__decorate([
    property()
], StartContainer.prototype, "backRoute", void 0);
__decorate([
    property({ type: Boolean })
], StartContainer.prototype, "historicBack", void 0);
__decorate([
    property({ type: Boolean })
], StartContainer.prototype, "noHelp", void 0);
__decorate([
    property()
], StartContainer.prototype, "heading", void 0);
StartContainer = __decorate([
    customElement("start-container")
], StartContainer);
export { StartContainer };
