var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
import { css, unsafeCSS } from "lit";
import config from "@/services/config/config";
const theme = (_c = (_b = (_a = config.skin.components) === null || _a === void 0 ? void 0 : _a.gameCard) === null || _b === void 0 ? void 0 : _b.back) === null || _c === void 0 ? void 0 : _c.theme;
const textColorStandard = (_d = theme === null || theme === void 0 ? void 0 : theme.textColor) === null || _d === void 0 ? void 0 : _d.standard;
const textColorSilver = (_e = theme === null || theme === void 0 ? void 0 : theme.textColor) === null || _e === void 0 ? void 0 : _e.silver;
const textColorGold = (_f = theme === null || theme === void 0 ? void 0 : theme.textColor) === null || _f === void 0 ? void 0 : _f.gold;
const labelBackgroundColorStandard = (_g = theme === null || theme === void 0 ? void 0 : theme.labelBackgroundColor) === null || _g === void 0 ? void 0 : _g.standard;
const labelBackgroundColorSilver = (_h = theme === null || theme === void 0 ? void 0 : theme.labelBackgroundColor) === null || _h === void 0 ? void 0 : _h.silver;
const labelBackgroundColorGold = (_j = theme === null || theme === void 0 ? void 0 : theme.labelBackgroundColor) === null || _j === void 0 ? void 0 : _j.gold;
const labelTextColor = theme === null || theme === void 0 ? void 0 : theme.labelTextColor;
const statsTextColorStandard = (_k = theme === null || theme === void 0 ? void 0 : theme.statsTextColor) === null || _k === void 0 ? void 0 : _k.standard;
const statsTextColorSilver = (_l = theme === null || theme === void 0 ? void 0 : theme.statsTextColor) === null || _l === void 0 ? void 0 : _l.silver;
const statsTextColorGold = (_m = theme === null || theme === void 0 ? void 0 : theme.statsTextColor) === null || _m === void 0 ? void 0 : _m.gold;
const statsAccentTextColorStandard = (_o = theme === null || theme === void 0 ? void 0 : theme.statsAccentTextColor) === null || _o === void 0 ? void 0 : _o.standard;
const statsAccentTextColorSilver = (_p = theme === null || theme === void 0 ? void 0 : theme.statsAccentTextColor) === null || _p === void 0 ? void 0 : _p.silver;
const statsAccentTextColorGold = (_q = theme === null || theme === void 0 ? void 0 : theme.statsAccentTextColor) === null || _q === void 0 ? void 0 : _q.gold;
const standardBackground = (_r = theme === null || theme === void 0 ? void 0 : theme.background) === null || _r === void 0 ? void 0 : _r.standard;
const silverBackground = (_s = theme === null || theme === void 0 ? void 0 : theme.background) === null || _s === void 0 ? void 0 : _s.silver;
const goldBackground = (_t = theme === null || theme === void 0 ? void 0 : theme.background) === null || _t === void 0 ? void 0 : _t.gold;
const contentBackgroundImage = theme === null || theme === void 0 ? void 0 : theme.contentBackgroundImage;
const statsBackgroundStandard = (_u = theme === null || theme === void 0 ? void 0 : theme.statsBackground) === null || _u === void 0 ? void 0 : _u.standard;
const statsBackgroundSilver = (_v = theme === null || theme === void 0 ? void 0 : theme.statsBackground) === null || _v === void 0 ? void 0 : _v.silver;
const statsBackgroundGold = (_w = theme === null || theme === void 0 ? void 0 : theme.statsBackground) === null || _w === void 0 ? void 0 : _w.gold;
const padding = theme === null || theme === void 0 ? void 0 : theme.padding;
const paddingImage = (_x = padding === null || padding === void 0 ? void 0 : padding.img) === null || _x === void 0 ? void 0 : _x.src;
const paddingImageWidth = (_y = padding === null || padding === void 0 ? void 0 : padding.img) === null || _y === void 0 ? void 0 : _y.width;
const gameCardBackStyles = css `

  :host {
    --content-padding: 0.55em;
    --gold-text: #5E4822;
  }

  :host > div {
    position: relative;
  }

  :host > div > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${unsafeCSS(contentBackgroundImage ? `url('/${contentBackgroundImage}')` : "none")};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  :host > div.standard {
    color: ${unsafeCSS(textColorStandard || "var(--color)")};
    background: ${unsafeCSS(standardBackground || "var(--standard-background)")};
  }
  :host > div.silver {
    color: ${unsafeCSS(textColorSilver || "#868585")};
    background: ${unsafeCSS(silverBackground || "var(--silver-background)")};
  }
  :host > div.gold {
    color: ${unsafeCSS(textColorGold || "var(--gold-text)")};
    background: ${unsafeCSS(goldBackground || "var(--gold-background)")};
  }

  :host > div.padded {
    padding: 0.18em;
  }

  :host > div.padded.padded-with-image {
    --padding-bottom: 1em;
    padding-bottom: var(--padding-bottom);
  }

  :host > div.padded > div {
    border-radius: calc((var(--border-radius) / 10) * 9);
    overflow: hidden;
  }

  :host > div.padded section.rounded {
    border-radius: 0 0 calc((var(--border-radius) / 10) * 9) calc((var(--border-radius) / 10) * 9);
  }

  header {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 2.125em;
    overflow: hidden;
    padding-left: var(--content-padding);
  }

  #name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3.7em;
    height: 100%;
    padding-top: 0.15em;
  }
  #name p {
    font-size: 0.45em;
    margin: 0;
    line-height: 1.4;
  }
  #name p span {
    font-weight: 700;
  }
  #name p#team {
    font-size: 0.25em;
    font-weight: 300;
  }

  #avatar {
    position: absolute;
    width: var(--avatar-width);
    height: var(--avatar-height);
    right: var(--avatar-right);
    bottom: var(--avatar-bottom);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .player #avatar {
    --avatar-width: 2.3em;
    --avatar-height: calc((var(--avatar-width) / 100) * 85);
    --avatar-right: 0.35em;
    --avatar-bottom: 0;
  }
  .team #avatar {
    --avatar-width: 1.5em;
    --avatar-height: var(--avatar-width);
    --avatar-right: 0.6em;
    --avatar-bottom: 0.27em;
  }
  .player #avatar.placeholder {
    --avatar-width: 1.8em;
    --avatar-height: calc((var(--avatar-width) / 259) * 253);
    --avatar-right: 0.5em;
    --avatar-bottom: -0.2em;
  }

  #label {
    position: absolute;
    top: 1.15em;
    right: 0.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.36em;
    height: 0.1875em;
    border-radius: 0.0625em;
    line-height: 1;
  }
  .standard #label {
    background-color: ${unsafeCSS(labelBackgroundColorStandard || "var(--color-background)")};
  }
  .silver #label {
    background-color: ${unsafeCSS(labelBackgroundColorSilver || "#868585")};
  }
  .gold #label {
    background-color: ${unsafeCSS(labelBackgroundColorGold || "#926F34")};
  }
  #label > span {
    font-weight: 700;
    font-size: 0.18em;
    margin-top: 0.04em;
    color: ${unsafeCSS(labelTextColor || "var(--color)")};
  }

  #roundel {
    --roundel-size: 0.4em;
    position: absolute;
    right: 0.38em;
    top: 0.6em;
    width: var(--roundel-size);
    height: var(--roundel-size);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  section {
    flex-grow: 1;
    padding: 0 var(--content-padding);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  section.rounded {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
  .standard section {
    background-color: ${unsafeCSS(statsBackgroundStandard || "#202020")};
  }
  .silver section {
    background-color: ${unsafeCSS(statsBackgroundSilver || "#3b383c")};
  }
  .gold section {
    background-color: ${unsafeCSS(statsBackgroundGold || "#785B2B")};
  }

  #stats {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
  .standard #stats {
    color: ${unsafeCSS(statsTextColorStandard || "var(--color)")};
  }
  .silver #stats {
    color: ${unsafeCSS(statsTextColorSilver || "var(--color)")};
  }
  .gold #stats {
    color: ${unsafeCSS(statsTextColorGold || "var(--color)")};
  }
  #stats uf-swiper-new {
    height: 3.3em;
  }
  #stats .swiper {
    height: 100%;
  }
  #stats .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #stats h5 {
    font-size: 0.3125em;
    margin: 0;
  }
  #stats ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  #stats ul > li {
    font-size: 0.3125em;
    font-weight: 500;
  }
  .standard #stats ul > li span {
    color: ${unsafeCSS(statsAccentTextColorStandard || "rgb(133, 131, 132)")};
  }
  .silver #stats ul > li span {
    color: ${unsafeCSS(statsAccentTextColorSilver || "rgb(132, 129, 133)")};
  }
  .gold #stats ul > li span {
    color: ${unsafeCSS(statsAccentTextColorGold || "#C49C5A")};
  }

  #ppg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  #ppg span {
    font-size: 0.3125em;
    line-height: 1;
    font-weight: 500;
  }
  .standard #ppg span {
    color: ${unsafeCSS(statsAccentTextColorStandard || "rgb(133, 131, 132)")};
  }
  .silver #ppg span {
    color: ${unsafeCSS(statsAccentTextColorSilver || "rgb(132, 129, 133)")};
  }
  .gold #ppg span {
    color: ${unsafeCSS(statsAccentTextColorGold || "#C49C5A")};
  }
  #ppg span#ppg-number {
    font-size: 2em;
    font-weight: 700;
  }
  .standard #ppg span#ppg-number {
    color: ${unsafeCSS(statsTextColorStandard || "var(--color)")};
  }
  .silver #ppg span#ppg-number {
    color: ${unsafeCSS(statsTextColorSilver || "var(--color)")};
  }
  .gold #ppg span#ppg-number {
    color: ${unsafeCSS(statsTextColorGold || "var(--color)")};
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.125em;
    padding: 0 var(--content-padding);
    font-weight: 700;
  }

  footer div {
    height: 100%;
    display: flex;
    align-items: center;
  }

  footer span {
    font-size: 0.25em;
  }

  #ratio {
    display: flex;
    align-items: center;
  }

  #ratio::after {
    --bullet-size: 0.3em;
    content: '';
    width: var(--bullet-size);
    height: var(--bullet-size);
    border-radius: calc(var(--bullet-size) / 2);
    background-color: var(--gold-text);
    margin: 0 var(--bullet-size);
  }

  #league {
    text-align: right;
  }

  #padding-image {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--padding-bottom);
    background-image: ${unsafeCSS(paddingImage ? `url('/${paddingImage}')` : "none")};
    background-size: ${unsafeCSS(paddingImageWidth ? `${paddingImageWidth}em` : "2em")};
    background-repeat: no-repeat;
    background-position: center;
  }

`;
export default gameCardBackStyles;
