/*export const initialValues = {
  email: "nine@hotmail.com",
  firstName: "nine",
  lastName: "nine",
  username: "ninenine",
  birthDate: "01/01/1980",
  mobileNumber: "212312312123",
  postcode: "",
  line_1: "",
  line_2: "",
  city: "",
  county: "ohio",
  country: "usa",
  password: "Equinox999",
  passwordConfirm: "Equinox999",
  pin: "",
  pinConfirm: "",
  agreedTerms: false,
  agreedPrivacy: false,
  agreedSMS: false,
  agreedEmail: false,
};*/
export const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    birthDate: "",
    mobileNumber: "",
    postcode: "",
    line_1: "",
    line_2: "",
    city: "",
    county: "",
    country: "",
    password: "",
    passwordConfirm: "",
    pin: "",
    pinConfirm: "",
    agreedTerms: false,
    agreedPrivacy: false,
    agreedSMS: false,
    agreedEmail: false,
};
export const initialErrors = {
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    birthDate: "",
    mobileNumber: "",
    postcode: "",
    line_1: "",
    line_2: "",
    city: "",
    county: "",
    country: "",
    password: "",
    passwordConfirm: "",
    pin: "",
    pinConfirm: "",
    agreedTerms: "",
    agreedPrivacy: "",
    agreedSMS: "",
    agreedEmail: "",
};
