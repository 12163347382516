var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "~/app/layout/scoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import gameScoringStyles from "./game-scoring.css";
import config from "@/services/config/config";
let GameScoring = class GameScoring extends ScopedElement {
    render() {
        return html `
      ${choose(config.skin.league, [
            ["pl", () => this.renderPL()],
            ["nfl", () => this.renderNFL()]
        ])}
    `;
    }
    renderPL() {
        return html `
      <div class="players">

        <div class="heading">
          <p>Players</p>
          <p>Points</p>
        </div>

        <hr>

        <div class="rows">
          <div class="row"> 
            <p>Goal</p>
            <p>7</p>
          </div>
          <div class="row"> 
            <p>Assist</p>
            <p>4</p>
          </div>
          <div class="row"> 
            <p>Shot on Target</p>
            <p>2</p>
          </div>
          <div class="row"> 
            <p>Tackle Won</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>Clean Sheet (DEF Only)</p>
            <p>3</p>
          </div>
        </div>

      </div>

      <div class="teams">

        <div class="heading">
          <p>Teams</p>
          <p>Points</p>
        </div>

        <hr>

        <div class="rows">
          <div class="row"> 
            <p>Win</p>
            <p>5</p>
          </div>
          <div class="row"> 
            <p>Goal Scored</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>Goal Against</p>
            <p class="negative">-1</p>
          </div>
          <div class="row"> 
            <p>Clean Sheet</p>
            <p>3</p>
          </div>
          <div class="row"> 
            <p>Save</p>
            <p>1</p>
          </div>
        </div>

      </div>
    `;
    }
    renderNFL() {
        return html `
      <div class="players">
    
        <div class="heading">
          <p>Players</p>
          <p>Points</p>
        </div>

        <hr>

        <div class="rows">
          <p>PASSING</p>
          <div class="row"> 
            <p>Touchdown</p>
            <p>4</p>
          </div>
          <div class="row"> 
            <p>200+ Yards</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>300+ Yards</p>
            <p>2</p>
          </div>
          <div class="row"> 
            <p>350+ Yards</p>
            <p>3</p>
          </div>

          <p>RUSHING</p>
          <div class="row"> 
            <p>Touchdown</p>
            <p>6</p>
          </div>
          <div class="row"> 
            <p>40+ Yards</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>70+ Yards</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>100+ Yards</p>
            <p>3</p>
          </div>

          <p>RECEIVING</p>
          <div class="row"> 
            <p>Touchdown</p>
            <p>6</p>
          </div>
          <div class="row"> 
            <p>50+ Yards</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>100+ Yards</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>Reception</p>
            <p>1</p>
          </div>

          <p>OTHER</p>
          <div class="row"> 
            <p>Interception</p>
            <p class="negative">-1</p>
          </div>
          <div class="row"> 
            <p>Fumble</p>
            <p class="negative">-1</p>
          </div>
        </div>
      </div>

      <div class="teams">
        <div class="heading">
          <p>Teams</p>
          <p>Points</p>
        </div>

        <hr>

        <div class="rows">
          <p>GAME</p>
          <div class="row"> 
            <p>Win</p>
            <p>5</p>
          </div>
          <div class="row"> 
            <p>Loss</p>
            <p class="negative">-1</p>
          </div>

          <p>FIELD GOAL</p>
          <div class="row"> 
            <p>40-49 Yards</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>50+ Yards</p>
            <p>2</p>
          </div>

          <p>DEFENCE/SPECIAL TEAMS</p>
          <div class="row"> 
            <p>Sack</p>
            <p>1</p>
          </div>
          <div class="row"> 
            <p>Interception</p>
            <p>2</p>
          </div>
          <div class="row"> 
            <p>Return Touchdown</p>
            <p>6</p>
          </div>

          <p>POINTS ALLOWED</p>
          <div class="row"> 
            <p>0 Points</p>
            <p>8</p>
          </div>
          <div class="row"> 
            <p>1-13 Points</p>
            <p>2</p>
          </div>
          <div class="row"> 
            <p>14-28 Points</p>
            <p>0</p>
          </div>
          <div class="row"> 
            <p>29+ Points</p>
            <p class="negative">-3</p>
          </div>
        </div>

      </div>
    `;
    }
};
GameScoring.styles = [ScopedElement.styles, gameScoringStyles];
GameScoring = __decorate([
    customElement("game-scoring")
], GameScoring);
