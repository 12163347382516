/**
 * Returns a client, server, or generic (void) error type based on a HTTP response status code.
 * @param status Response status code.
 */
export function getErrorType(status) {
    let errorType = "other";
    if (status >= 400 && status < 500) {
        errorType = "client";
    }
    else if (status >= 500) {
        errorType = "server";
    }
    return errorType;
}
export const throwFetchError = (error) => {
    console.log("fetch error", error);
};
export class ApiError extends Error {
    constructor(message, data) {
        // 'Error' breaks prototype chain here
        super(message);
        this.data = data;
    }
}
