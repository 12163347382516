export var SquadItemRating;
(function (SquadItemRating) {
    SquadItemRating[SquadItemRating["Standard"] = 0] = "Standard";
    SquadItemRating[SquadItemRating["Silver"] = 1] = "Silver";
    SquadItemRating[SquadItemRating["Gold"] = 2] = "Gold";
})(SquadItemRating || (SquadItemRating = {}));
export var SquadItemModifier;
(function (SquadItemModifier) {
    SquadItemModifier[SquadItemModifier["None"] = 0] = "None";
    SquadItemModifier[SquadItemModifier["Captain"] = 1] = "Captain";
    SquadItemModifier[SquadItemModifier["GoldenBoot"] = 2] = "GoldenBoot";
})(SquadItemModifier || (SquadItemModifier = {}));
export var SquadItemPosition;
(function (SquadItemPosition) {
    SquadItemPosition[SquadItemPosition["Any"] = 0] = "Any";
    SquadItemPosition[SquadItemPosition["Defender"] = 1] = "Defender";
    SquadItemPosition[SquadItemPosition["Midfielder"] = 2] = "Midfielder";
    SquadItemPosition[SquadItemPosition["Forward"] = 3] = "Forward";
    SquadItemPosition[SquadItemPosition["Goalkeeper"] = 4] = "Goalkeeper";
})(SquadItemPosition || (SquadItemPosition = {}));
export var SquadItemLinupPosition;
(function (SquadItemLinupPosition) {
    SquadItemLinupPosition[SquadItemLinupPosition["Team"] = 0] = "Team";
    SquadItemLinupPosition[SquadItemLinupPosition["DefenderLeft"] = 1] = "DefenderLeft";
    SquadItemLinupPosition[SquadItemLinupPosition["DefenderRight"] = 2] = "DefenderRight";
    SquadItemLinupPosition[SquadItemLinupPosition["MidfieldLeft"] = 3] = "MidfieldLeft";
    SquadItemLinupPosition[SquadItemLinupPosition["MidfieldRight"] = 4] = "MidfieldRight";
    SquadItemLinupPosition[SquadItemLinupPosition["Forward"] = 5] = "Forward";
    SquadItemLinupPosition[SquadItemLinupPosition["Wild"] = 6] = "Wild";
})(SquadItemLinupPosition || (SquadItemLinupPosition = {}));
export var SquadItemType;
(function (SquadItemType) {
    SquadItemType[SquadItemType["Team"] = 0] = "Team";
    SquadItemType[SquadItemType["Player"] = 1] = "Player";
})(SquadItemType || (SquadItemType = {}));
export var SquadItemSet;
(function (SquadItemSet) {
    SquadItemSet[SquadItemSet["Collection"] = 0] = "Collection";
    SquadItemSet[SquadItemSet["Squad"] = 1] = "Squad";
    SquadItemSet[SquadItemSet["Lineup"] = 2] = "Lineup";
})(SquadItemSet || (SquadItemSet = {}));
export var FootballSeasonStatsIndex;
(function (FootballSeasonStatsIndex) {
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["Goal"] = 0] = "Goal";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["Assist"] = 1] = "Assist";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["ShotOnTarget"] = 2] = "ShotOnTarget";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["TackleWon"] = 3] = "TackleWon";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["CleanSheet"] = 4] = "CleanSheet";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["Appearance"] = 5] = "Appearance";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["PointsPerGame"] = 6] = "PointsPerGame";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["Win"] = 7] = "Win";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["GoalConceeded"] = 8] = "GoalConceeded";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["ShootoutSaved"] = 9] = "ShootoutSaved";
    FootballSeasonStatsIndex[FootballSeasonStatsIndex["TeamSave"] = 10] = "TeamSave";
})(FootballSeasonStatsIndex || (FootballSeasonStatsIndex = {}));
export var FootballUSSeasonStatsIndex;
(function (FootballUSSeasonStatsIndex) {
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PassingTouchDown"] = 0] = "PassingTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["ReceivingTouchDown"] = 1] = "ReceivingTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["RushingTouchDown"] = 2] = "RushingTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PassingYards"] = 3] = "PassingYards";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AveragePassingYards"] = 4] = "AveragePassingYards";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AverageReceivingYards"] = 5] = "AverageReceivingYards";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["RushingYards"] = 6] = "RushingYards";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AverageRushingYards"] = 7] = "AverageRushingYards";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Receptions"] = 8] = "Receptions";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AverageReceptions"] = 9] = "AverageReceptions";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Interceptions"] = 10] = "Interceptions";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Fumbles"] = 11] = "Fumbles";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Win"] = 12] = "Win";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Loss"] = 13] = "Loss";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AveragePointsAllowed"] = 14] = "AveragePointsAllowed";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AverageFieldGoalMade"] = 15] = "AverageFieldGoalMade";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["AverageSacks"] = 16] = "AverageSacks";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["DefenceReturnTouchDown"] = 17] = "DefenceReturnTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["ReceivingYards"] = 18] = "ReceivingYards";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Appearance"] = 19] = "Appearance";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Sacks"] = 20] = "Sacks";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["FieldGoals"] = 21] = "FieldGoals";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PointsAllowed"] = 22] = "PointsAllowed";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["SpecialTeamsTouchDown"] = 23] = "SpecialTeamsTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["InterceptionTouchDown"] = 24] = "InterceptionTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["FumbleTouchDown"] = 25] = "FumbleTouchDown";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PointsAllowedZero"] = 26] = "PointsAllowedZero";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PointsAllowedThirteen"] = 27] = "PointsAllowedThirteen";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PointsAllowedTwentyEight"] = 28] = "PointsAllowedTwentyEight";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PointsAllowedOverTwentyNine"] = 29] = "PointsAllowedOverTwentyNine";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Passing200"] = 30] = "Passing200";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Passing300"] = 31] = "Passing300";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Passing350"] = 32] = "Passing350";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Rushing40"] = 33] = "Rushing40";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Rushing70"] = 34] = "Rushing70";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Rushing100"] = 35] = "Rushing100";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Receiving50"] = 36] = "Receiving50";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["Receiving100"] = 37] = "Receiving100";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["FieldGoal49"] = 38] = "FieldGoal49";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["FieldGoalOver50"] = 39] = "FieldGoalOver50";
    FootballUSSeasonStatsIndex[FootballUSSeasonStatsIndex["PointsPerGame"] = 40] = "PointsPerGame";
})(FootballUSSeasonStatsIndex || (FootballUSSeasonStatsIndex = {}));
