var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a;
import UnscopedElement from "./layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./app-container.css";
import router, { routes } from "@/router/router";
import "@/router/components/router-outlet";
import "@/components/loading-spinner/loading-spinner";
import "@/views/location/location.view";
import "@/parts/onboarding/onboarding";
import "@/views/game/game.view";
import "@/views/end/end.view";
import config from "@/services/config/config";
import session from "@/services/session/session";
import analytics from "@/services/utils/analytics";
import locationService from "@/services/location/location";
import low6Api from "@/services/api/low6/low6.api";
import ufApi from "@/services/api/uf/uf.api";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
const colour = (_a = config.skin.theme) === null || _a === void 0 ? void 0 : _a.colour;
const skinStyles = [
    { name: "color-primary", value: (colour === null || colour === void 0 ? void 0 : colour.primary) || "#FF284F" },
    { name: "color-secondary", value: (colour === null || colour === void 0 ? void 0 : colour.secondary) || "#36023A" },
    { name: "color-active", value: (colour === null || colour === void 0 ? void 0 : colour.active) || "#35E06A" },
    { name: "color-error", value: (colour === null || colour === void 0 ? void 0 : colour.error) || "#E03535" },
    { name: "color-background", value: (colour === null || colour === void 0 ? void 0 : colour.background) || "#292929" },
    { name: "color-disabled", value: (colour === null || colour === void 0 ? void 0 : colour.disabled) || "#0F0F0F" },
    { name: "color-soft-text", value: (colour === null || colour === void 0 ? void 0 : colour.softText) || "#858585" }
];
let AppContainer = class AppContainer extends UnscopedElement {
    constructor() {
        super(skinStyles);
        this.loading = true;
        this.locationErrorVisible = false;
        this.onboardingVisible = false;
        router.routeChange.subscribe(route => this.currentRoute = route.path);
        this.checkSession();
        analytics.recordTag(AnalyticsKeys.APP_OPENED);
    }
    /**
     * Whether or not the current route is a start page.
     */
    get isStartView() {
        switch (this.currentRoute) {
            case "":
            case "login":
            case "reset-password":
            case "signup":
                return true;
        }
        return false;
    }
    /**
     * Game view (logged in view) that is fullscreen.
     */
    get isFullScreenGameView() {
        if (!this.isStartView) {
            switch (this.currentRoute) {
                case "change-password":
                    return true;
                case "change-password/":
                    return true;
                case "squad/transfer":
                    return true;
                case "squad/transfer/":
                    return true;
            }
        }
        return false;
    }
    /**
     * Whether the current route (location.pathname) does not have an associated view.
     */
    get unknownRoute() {
        const path = this.currentRoute.split("/")[0];
        return routes.every(route => route.path.split("/")[0] !== path);
    }
    /**
     * Checks if there is an active session and redirects accordingly.
     */
    async checkSession() {
        try {
            const low6Session = await session.getExisting();
            // If there IS a session.
            if (low6Session) {
                low6Api.loginDetails.next(low6Session);
                low6Api.loginDetails.complete();
                // We are logging into UF on every refresh of the app to get recent data.
                const ufLogin = await ufApi.login(low6Session.access_token, config.homeTeamCode());
                this.onboardingVisible = !ufLogin.isOnboard;
                // Get profile data so that we can check the user's email address against the location service whitelist.
                const profile = await low6Api.getProfile();
                if (process.env.NODE_ENV === "development" || locationService.checkWhiteList(profile.email)) {
                    this.startApp();
                }
                else {
                    if (await locationService.checklocation()) {
                        this.startApp();
                    }
                    else {
                        this.locationErrorVisible = true;
                    }
                }
            }
            // If there isn't a session AND if the current route isn't a start page, navigate to the Intro view.
            else if (!this.isStartView) {
                router.navigate("");
            }
            this.loading = false;
        }
        catch (error) {
            this.onSessionError(error);
        }
    }
    /**
     * Navigates to the initial logged in view (e.g. Lobby) if not currently at a logged in (game) view.
     */
    startApp() {
        // If not currently at a game view, redirect to the lobby.
        if (!this.onboardingVisible && (this.isStartView || this.unknownRoute))
            router.navigate("lobby");
    }
    onSessionError(error) {
        console.log(error);
        if (!this.isStartView)
            router.navigate("");
        this.loading = false;
    }
    render() {
        return html `
      ${when(this.loading, () => html `

          <div id="app-loader">
            <loading-spinner></loading-spinner>
          </div>

        `, () => when(config.skin.league === "nfl", () => html `

          <end-view></end-view>

        `, () => when(this.locationErrorVisible, () => html `

          <location-view></location-view>
          
        `, () => when(this.onboardingVisible, () => html `

          <app-onboarding @complete=${this.onOnboardComplete}></app-onboarding>

        `, () => when(this.isStartView, () => html `

          <router-outlet></router-outlet>

        `, () => when(this.isFullScreenGameView, () => html `

          <div id="fullscreen">
            <router-outlet></router-outlet>
          </div>

        `, () => html `

          <game-view></game-view>

        `))))))}
    `;
    }
    onOnboardComplete() {
        router.navigate("lobby");
        this.onboardingVisible = false;
        ufApi.onboard().catch((e) => console.log(e));
    }
};
__decorate([
    state()
], AppContainer.prototype, "loading", void 0);
__decorate([
    state()
], AppContainer.prototype, "currentRoute", void 0);
__decorate([
    state()
], AppContainer.prototype, "locationErrorVisible", void 0);
__decorate([
    state()
], AppContainer.prototype, "isStartView", null);
__decorate([
    state()
], AppContainer.prototype, "isFullScreenGameView", null);
__decorate([
    state()
], AppContainer.prototype, "onboardingVisible", void 0);
AppContainer = __decorate([
    customElement("app-container")
], AppContainer);
export { AppContainer };
