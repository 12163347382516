import { css } from "lit";
const locationCSS = css `
  :host {
    width: 100vw;
    height: var(--real100vh, 100vh);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/game-background.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
  }

  :host > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3em;
    width: 21.5em;
    padding: 1.5em;
    max-width: 100%;
  }

  logo-uf {
    width: 5em;
  }

  logo-bengals {
    width: 10em;
  }

  logo-chargers {
    width: 11em;
  }

  :host > div > div {
    text-align: center;
  }

  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 1em;
    margin-bottom: 1.5em;
    line-height: 1.3;
  }
  
  h1 span {
    font-size: 1.5em;
  }

  p {
    margin: 0;
  }

  @media (min-width: 1000px) {
    :host {
      background-size: 80em;
    }
  }

  @media (max-width: 800px) {
    :host {
      --fontsize: 12px;
      font-size: var(--fontsize);
    }
  }

  @media (max-width: 500px) {
    :host {
      --fontsize: 4vw;
    }
  }

`;
export default locationCSS;
