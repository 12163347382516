import locationApi from "@/services/api/location/location.api";
import config, { Configurations } from "@/services/config/config";
import { checkGeoLocationIsInUKAndIreland, checkGeoLocationIsInUSAndCanda, checkGeoLocationIsInUS } from "./location.helpers";
class LocationService {
    /**
     * Checks whether an email address is whitelisted.
     * @param emailAddress The user's email address.
     * @returns Whether the user is whitelisted.
     */
    checkWhiteList(emailAddress) {
        return LocationService.whitelistedEmailAddresses.some(address => emailAddress.includes(address));
    }
    /**
     * Identifies the user's location and performs checks on whether the user is able to access to app.
     * @returns Whether the user's current location is allowed access to the app.
     */
    async checklocation() {
        let locationAcceptable = false;
        const locationResponse = await locationApi.getLocation();
        const geoLocationResponse = await locationApi.reverseGeocode(locationResponse);
        switch (config.current) {
            case Configurations.UF:
                // UK and Ireland only
                locationAcceptable = checkGeoLocationIsInUKAndIreland(geoLocationResponse);
                break;
            case Configurations.Bengals:
                // USA and Canada only
                locationAcceptable = checkGeoLocationIsInUSAndCanda(geoLocationResponse);
                break;
            case Configurations.Chargers:
                // USA only
                locationAcceptable = checkGeoLocationIsInUS(geoLocationResponse);
                break;
        }
        return locationAcceptable;
    }
}
/**
 * Email addresses that include a string listed here will be whitelisted and therefore not subject to a location check.
 */
LocationService.whitelistedEmailAddresses = [
    "compsoft.com",
    "compsoft.co.uk",
    "low6.com",
    "low6.co.uk"
];
export default new LocationService();
