var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconWithdraw = class IconWithdraw extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 1V5H3.83L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7H19V1H17Z" />
      </svg>
    `;
    }
};
IconWithdraw.styles = [iconCSS, css `
    path {
      fill: #111111;
    }
  `];
IconWithdraw = __decorate([
    customElement("icon-withdraw")
], IconWithdraw);
export { IconWithdraw };
