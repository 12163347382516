var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import "@/views/game/views/squad/parts/card-view-modal/card-view.modal";
let SquadView = class SquadView extends UnscopedElement {
    render() {
        return html `
      <router-outlet></router-outlet>
      <card-view-modal></card-view-modal>
    `;
    }
};
SquadView = __decorate([
    customElement("squad-view")
], SquadView);
export { SquadView };
