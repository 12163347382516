import { css } from "lit";
const navbarCSS = css `

  nav {
    height: var(--game-navbar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 0 3em;
    position: relative;
    z-index: 1;
  }
  nav > * {
    flex-shrink: 0;
  }

  #links {
    display: flex;
    align-items: center;
    column-gap: 4em;
    height: 100%;
  }
  #links > * {
    flex-shrink: 0;
  }

  logo-uf {
    width: 3em;
  }

  logo-bengals {
    width: 5em;
  }

  logo-chargers {
    width: 5.5em;
  }

  #menu {
    position: relative;
    z-index: 1;
  }

  #menu-icon {
    display: none;
    cursor: pointer;
    height: 100%;
    align-items: center;
  }
  uf-icon[icon=menu] {
    width: 1.5em;
  }

  ul {
    height: 100%;
    display: flex;
  }
  ul > li {
    height: 100%;
    flex-shrink: 0;
  }
  router-link {
    height: 100%;
  }
  router-link > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.4em;
    padding: 0 1.5em;
  }
  ul uf-icon {
    width: 1.5em;
  }

  uf-icon[icon="avatar"] {
    width: 2em;
  }

  #mobile-overlay {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-out, opacity 0.2s ease-out;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: var(--real100vh, 100vh);
    background-color: rgba(15, 15, 15, 0.5);
  }

  @media (max-width: 1000px) {
    ul span {
      display: none;
    }
  }

  @media (max-width: 500px) {
    nav {
      padding: 0 1.25em 0 0;
    }
    logo-uf, logo-chargers, logo-bengals {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1.5em;
    }
    logo-uf {
      width: 1.5em;
    }
    logo-chargers {
      width: 4em;
    }
    logo-bengals {
      width: 3.5em;
    }
    #menu-icon {
      display: flex;
      padding: 0 1.25em;
    }
    ul {
      position: fixed;
      top: 0;
      left: 0;
      flex-direction: column;
      background-color: #000000;
      width: 4em;
      padding-top: 0.5em;
      align-items: center;
      top: var(--game-navbar-height);
      height: calc(var(--real100vh) - var(--game-navbar-height));
      border-top: 1px solid rgba(245, 247, 244, 0.5);
      box-shadow: 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.25);
      transform: translateX(-100%);
      transition: transform 0.2s ease-out;
    }
    #links.open ul {
      transform: translateX(0);
    }
    ul > li {
      height: auto;
      width: 100%;
    }
    router-link > div {
      padding: 1em 0;
    }
    #links.open #mobile-overlay {
      visibility: visible;
      opacity: 1;
    }
  }

`;
export default navbarCSS;
