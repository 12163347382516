import { css } from "lit";
const gameScoringStyles = css `

  :host {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 1em;
    justify-content: center;
  }

  .heading {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 1em auto 0 auto;
    color: var(--color-soft-text);
    font-size: 1.125em;
    font-weight: 700;
  }

  .heading p {
    margin: 0;
    color: var(--color);
    font-size: 1.125em;
    font-weight: 700;
  }

  .heading p:first-child {
    text-align: left;
  }

  .heading p:last-child {
    text-align: right;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .row p {
    margin: 0;
    font-size: 1.125em;
    font-weight: 500;
  }

  .row p:first-child {
    color: var(--color);
    text-align: left;
  }

  .row p:last-child {
    color: var(--color-active);
    text-align: right;
  }

  .row p.negative:last-child {
    color: var(--color-error);
    text-align: right;
  }

`;
export default gameScoringStyles;
