import { ApiError, getErrorType } from "../error/error";
export class BaseAPI {
    constructor(config) {
        this.defaultHeaders = new Headers();
        this.tempUrl = "";
        if (config === null || config === void 0 ? void 0 : config.urls)
            this.api = config.urls;
        if (config === null || config === void 0 ? void 0 : config.defaultHeaders) {
            this.defaultHeaders = config.defaultHeaders;
        }
    }
    async get(path = "", headers) {
        headers = headers || this.getHeaders || this.defaultHeaders;
        try {
            const response = await fetch(`${this.api[process.env.NODE_ENV]}${path}`, {
                method: "GET", headers
            });
            if (!response.ok) {
                if (this.tempUrl)
                    this.tempUrl = "";
                throw new Error(getErrorType(response.status));
            }
            const data = await response.json();
            if (this.tempUrl)
                this.tempUrl = "";
            return data;
        }
        catch (e) {
            if (this.tempUrl)
                this.tempUrl = "";
            throw new Error(e);
        }
    }
    async post(path = "", body, headers) {
        var _a;
        headers = headers || this.postHeaders || this.defaultHeaders;
        if (headers.get("Content-Type") === "application/json" && (body !== undefined || body !== null)) {
            body = JSON.stringify(body);
        }
        try {
            const response = await fetch(`${this.tempUrl || this.api[process.env.NODE_ENV]}${path}`, {
                method: "POST", headers, body
            });
            const isJson = (_a = response.headers.get('content-type')) === null || _a === void 0 ? void 0 : _a.includes('application/json');
            const data = isJson ? await response.json() : null;
            if (!response.ok) {
                if (this.tempUrl)
                    this.tempUrl = "";
                if (isJson)
                    throw new ApiError(getErrorType(response.status), data);
                throw new Error(getErrorType(response.status));
            }
            if (this.tempUrl)
                this.tempUrl = "";
            return data;
        }
        catch (e) {
            if (this.tempUrl)
                this.tempUrl = "";
            throw new Error(e);
        }
    }
    async put(path = "", body, headers) {
        var _a;
        headers = headers || this.putHeaders || this.defaultHeaders;
        if (headers.get("Content-Type") === "application/json" && (body !== undefined || body !== null)) {
            body = JSON.stringify(body);
        }
        try {
            const response = await fetch(`${this.tempUrl || this.api[process.env.NODE_ENV]}${path}`, {
                method: "PUT", headers, body
            });
            const isJson = (_a = response.headers.get('content-type')) === null || _a === void 0 ? void 0 : _a.includes('application/json');
            const data = isJson ? await response.json() : null;
            if (!response.ok) {
                if (this.tempUrl)
                    this.tempUrl = "";
                if (isJson)
                    throw new ApiError(getErrorType(response.status), data);
                throw new Error(getErrorType(response.status));
            }
            if (this.tempUrl)
                this.tempUrl = "";
            return data;
        }
        catch (e) {
            if (this.tempUrl)
                this.tempUrl = "";
            throw new Error(e);
        }
    }
}
