var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconClear = class IconClear extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.31372" cy="8.31372" r="8" fill="#F6F3EE" />
        <rect width="9.14286" height="1.52382" rx="0.761908"
          transform="matrix(-0.707107 -0.707106 0.707107 -0.707106 11.0074 12.085)" fill="#36023A" />
        <rect width="9.14286" height="1.52381" rx="0.761903"
          transform="matrix(-0.707107 0.707106 -0.707107 -0.707106 12.085 5.62)" fill="#36023A" />
      </svg>
    `;
    }
};
IconClear.styles = [iconCSS];
IconClear = __decorate([
    customElement("icon-clear")
], IconClear);
export { IconClear };
