var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
let LoadingSpinner = class LoadingSpinner extends LitElement {
    render() {
        return html `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="18 18 80 80">
        <path d="M36,0A36.114,36.114,0,0,1,69.267,22.217,35.518,35.518,0,0,1,72,36,36,36,0,0,1,0,36" transform="translate(22 22)" fill="none" stroke-linecap="round" stroke-width="8" />
      </svg>
    `;
    }
};
LoadingSpinner.styles = [css `
    :host {
      display: block;
    }

    svg {
      animation: spin 1s linear infinite;
      display: block;
    }

    path {
      stroke: var(--color);
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `];
LoadingSpinner = __decorate([
    customElement("loading-spinner")
], LoadingSpinner);
export { LoadingSpinner };
