var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
const componentStyles = [css `
  .wrapper {
    display: flex;
    align-items: center;
    gap: 0.5em;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }

  .select {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 3rem;
    color: #f9f9f9;
    background: #151515;
    border: 0;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
  }

  .option {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 3rem;
    color: #f9f9f9;
    background: #151515;
    border: 0;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
  }

  .wrapper.form-error {
    border-color: var(--color-primary);
  }

  .select:focus:not(.form-error) {
    border-color: #f9f9f9;
  }

  .error-message {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
`];
let FormSelect = class FormSelect extends LitElement {
    constructor() {
        super(...arguments);
        this.value = "";
        this.options = [];
        this.name = "";
        this.id = "";
        this.required = false;
        this.error = "";
        this.handleChange = () => null;
        this.onBlur = () => null;
        this.isTouched = false;
        this.errorMessage = "";
        this.onChange = (event) => {
            this.handleChange(event);
        };
        this.getWrapperClass = (originalClass) => {
            return `
      ${originalClass}
      ${this.errorMessage && " form-error"}
    `;
        };
    }
    render() {
        return html `
    <div class="wrapper" class=${this.getWrapperClass("wrapper")}>
      <select 
        name=${this.name} 
        id=${this.id} 
        class="select"
        value=${this.options[0] ? this.options[0].value : this.value}
        @change=${this.onChange} 
        @input=${this.onBlur} 
        .required=${this.required}
      >
        <option class="option" value="">Please Choose...</option>

        ${this.options.map((option) => html `<option class="option" value=${option.value}>${option.text}</option>`)}
    </select>
    </div>
    ${this.errorMessage ? html `<div class="error-message">${this.errorMessage}</div>` : null}
    `;
    }
};
FormSelect.styles = [componentStyles];
__decorate([
    state()
], FormSelect.prototype, "value", void 0);
__decorate([
    property()
], FormSelect.prototype, "options", void 0);
__decorate([
    property()
], FormSelect.prototype, "name", void 0);
__decorate([
    property()
], FormSelect.prototype, "id", void 0);
__decorate([
    state()
], FormSelect.prototype, "required", void 0);
__decorate([
    property()
], FormSelect.prototype, "error", void 0);
__decorate([
    state()
], FormSelect.prototype, "handleChange", void 0);
__decorate([
    state()
], FormSelect.prototype, "onBlur", void 0);
__decorate([
    state()
], FormSelect.prototype, "isTouched", void 0);
__decorate([
    state()
], FormSelect.prototype, "errorMessage", void 0);
FormSelect = __decorate([
    customElement("form-select")
], FormSelect);
export { FormSelect };
