var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconSave = class IconSave extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = "white";
    }
    render() {
        return html `
      ${choose(this.theme, [
            ["white", this.renderWhiteSave],
            ["grey", this.renderGreySave]
        ])}
    `;
    }
    renderWhiteSave() {
        return html `
      <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5555 0.81897H2.22227C0.988672 0.81897 0 1.81936 0 3.04124V18.5967C0 19.8186 0.988672 20.819 2.22227 20.819H17.7777C18.9996 20.819 20 19.8186 20 18.5967V5.2635L15.5555 0.81897ZM17.7777 18.5967H2.22227V3.04124H14.6332L17.7777 6.18577V18.5967ZM10 10.819C8.15547 10.819 6.6668 12.3076 6.6668 14.1522C6.6668 15.9967 8.15547 17.4854 10 17.4854C11.8445 17.4854 13.3332 15.9967 13.3332 14.1522C13.3332 12.3076 11.8445 10.819 10 10.819ZM3.3332 4.15217H13.3332V8.5967H3.3332V4.15217Z" fill="#F6F3EE"/>
      </svg>
    `;
    }
    renderGreySave() {
        return html `
      <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5555 0.81897H2.22227C0.988672 0.81897 0 1.81936 0 3.04124V18.5967C0 19.8186 0.988672 20.819 2.22227 20.819H17.7777C18.9996 20.819 20 19.8186 20 18.5967V5.2635L15.5555 0.81897ZM17.7777 18.5967H2.22227V3.04124H14.6332L17.7777 6.18577V18.5967ZM10 10.819C8.15547 10.819 6.6668 12.3076 6.6668 14.1522C6.6668 15.9967 8.15547 17.4854 10 17.4854C11.8445 17.4854 13.3332 15.9967 13.3332 14.1522C13.3332 12.3076 11.8445 10.819 10 10.819ZM3.3332 4.15217H13.3332V8.5967H3.3332V4.15217Z" fill="#858585"/>
      </svg>
    `;
    }
};
IconSave.styles = iconCSS;
__decorate([
    property()
], IconSave.prototype, "theme", void 0);
IconSave = __decorate([
    customElement("icon-save")
], IconSave);
export { IconSave };
