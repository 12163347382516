var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
let LogoUF = class LogoUF extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg viewBox="0 0 93 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="${this.theme}" d="M40.2098 57.1946C39.266 62.6108 35.9048 67.387 28.2361 69.2921C20.4614 71.2242 18.5688 67.7572 19.5151 62.3411L27.7844 15.2481L8.97722 19.9238L0.392493 68.8557C-2.22936 84.2264 8.48767 92.2366 26.452 87.7693C42.6297 83.7458 55.5522 71.9303 58.593 54.3848L59.7386 47.4411L85.2909 41.0883L88.0389 24.7491L43.7653 35.758L40.2098 57.1946Z" fill-opacity="0.95"/>
        <path class="${this.theme}"d="M47.7952 11.2393L45.1153 27.0784L90.3353 15.8341L93 0L47.7952 11.2393Z" fill-opacity="0.95"/>
      </svg>
    `;
    }
};
LogoUF.styles = [css `
    :host {
      display: flex;
    }
    svg {
      width: 100%;
      height: 100%;
    }

    path {
      fill: #FF284F
    }
    path.grey {
      fill: #858585
    }

  `];
__decorate([
    property()
], LogoUF.prototype, "theme", void 0);
LogoUF = __decorate([
    customElement("logo-uf")
], LogoUF);
export { LogoUF };
