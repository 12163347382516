var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./create-league.css";
import "@/components/loading-spinner/loading-spinner";
import ufApi from "@/services/api/uf/uf.api";
import router from "@/router/router";
let CreateLeagueView = class CreateLeagueView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.name = '';
        this.nameError = false;
        this.loading = false;
        this.createModalVisible = false;
        this.copyPressed = false;
    }
    render() {
        return html `

      ${this.renderHeaderBar()}

      <form @submit=${this.checkCode} novalidate>
        <main>
          <div class="input">
            <input
              type="text"
              title="name"
              placeholder="League Name"
              @input=${this.onInput}
              @blur=${this.onBlur}
              class=${this.nameError ? "error" : ""}
            >
            <span>Invalid Name. Please check and try again.</span>
          </div>
          <p>League names must be between 2 and 24 characters. <br />
          Please avoid anything that may cause offense.</p>

          <button
            .disabled=${!(this.name.length > 1 && this.name.length < 25)}
          >Create League</button>

          ${when(this.loading, () => html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            `)}

        </main>

      </form>

      ${when(this.createModalVisible && this.leagueDetails, () => html `
          <uf-modal>
            <section>
              <h2>'${this.leagueDetails.name}'</h2>
              <h3>has been created</h3>
              <h4>${this.leagueDetails.code}</h4>
              <p>Share this unique code with your friends and family so they can join</p>
              <div class="modal-footer">
                <button
                class="${this.copyPressed ? "pressed" : ""} white"
                @click=${this.copyCode}>Copy Code</button>
                <button @click=${this.finishCreate}>OK. Got It.</button>
              </div>
            </section>
          </uf-modal>
        `)}
    `;
    }
    renderBack() {
        return html `
      <router-link href="/leaderboard/join">
        <uf-icon icon='chevron'></uf-icon>
      </router-link>
    `;
    }
    renderHeaderBar() {
        return html `
      <header-bar class="clickable">
        <div slot="center"><h2>Name your league</h2></div>
        <div slot="left">${this.renderBack()}</div>
      </header-bar>
    `;
    }
    onInput(e) {
        const input = e.composedPath()[0];
        switch (input.title) {
            case "name":
                this.name = input.value;
                this.nameError = false;
                break;
        }
    }
    onBlur(e) {
        const input = e.composedPath()[0];
        this.loading = false;
        switch (input.title) {
            case "name":
                this.name = input.value;
                break;
        }
    }
    hideCreateModal() {
        this.createModalVisible = false;
    }
    copyCode() {
        if (this.leagueDetails) {
            navigator.clipboard.writeText(`${this.leagueDetails.code}`).then(() => {
                this.copyPressed = true;
                setTimeout(() => {
                    this.copyPressed = false;
                }, 1000);
            });
        }
    }
    async checkCode(e) {
        e.preventDefault();
        this.loading = true;
        try {
            const response = await ufApi.createUserLeague(this.name);
            if (response.leaderboards.length > 0) {
                this.createModalVisible = true;
                this.leagueDetails = response.leaderboards[0];
            }
            else {
                this.nameError = true;
            }
        }
        catch (error) {
            this.loading = false;
            console.log("Error", error);
        }
    }
    async finishCreate() {
        this.hideCreateModal();
        ufApi.currentStage.subscribe(stage => {
            var _a;
            const currentStage = stage.stage;
            router.navigate(`leaderboard/positions/${currentStage.id}/userLeague/${(_a = this.leagueDetails) === null || _a === void 0 ? void 0 : _a.userLeagueDetailId}`);
        }).unsubscribe();
    }
};
__decorate([
    state()
], CreateLeagueView.prototype, "name", void 0);
__decorate([
    state()
], CreateLeagueView.prototype, "nameError", void 0);
__decorate([
    state()
], CreateLeagueView.prototype, "loading", void 0);
__decorate([
    state()
], CreateLeagueView.prototype, "createModalVisible", void 0);
__decorate([
    state()
], CreateLeagueView.prototype, "copyPressed", void 0);
__decorate([
    state()
], CreateLeagueView.prototype, "leagueDetails", void 0);
CreateLeagueView = __decorate([
    customElement("create-league-view")
], CreateLeagueView);
export { CreateLeagueView };
