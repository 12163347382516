var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconBag = class IconBag extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      ${this.theme === "filled" ? html `
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3125 6.25H16.75V5.1C16.75 3.88 16.2496 2.70998 15.3588 1.84731C14.468 0.984642 13.2598 0.5 12 0.5C10.7402 0.5 9.53204 0.984642 8.64124 1.84731C7.75044 2.70998 7.25 3.88 7.25 5.1V6.25H3.6875C3.37256 6.25 3.07051 6.37116 2.84781 6.58683C2.62511 6.80249 2.5 7.095 2.5 7.4V20.05C2.5 20.965 2.87533 21.8425 3.54343 22.4895C4.21153 23.1365 5.11767 23.5 6.0625 23.5H17.9375C18.8823 23.5 19.7885 23.1365 20.4566 22.4895C21.1247 21.8425 21.5 20.965 21.5 20.05V7.4C21.5 7.095 21.3749 6.80249 21.1522 6.58683C20.9295 6.37116 20.6274 6.25 20.3125 6.25ZM9.625 5.1C9.625 4.49 9.87522 3.90499 10.3206 3.47365C10.766 3.04232 11.3701 2.8 12 2.8C12.6299 2.8 13.234 3.04232 13.6794 3.47365C14.1248 3.90499 14.375 4.49 14.375 5.1V6.25H9.625V5.1Z" />
          </svg>
        ` : html `
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3125 6.25H16.75V5.1C16.75 3.88 16.2496 2.70998 15.3588 1.84731C14.468 0.984642 13.2598 0.5 12 0.5C10.7402 0.5 9.53204 0.984642 8.64124 1.84731C7.75044 2.70998 7.25 3.88 7.25 5.1V6.25H3.6875C3.37256 6.25 3.07051 6.37116 2.84781 6.58683C2.62511 6.80249 2.5 7.095 2.5 7.4V20.05C2.5 20.965 2.87533 21.8425 3.54343 22.4895C4.21153 23.1365 5.11767 23.5 6.0625 23.5H17.9375C18.8823 23.5 19.7885 23.1365 20.4566 22.4895C21.1247 21.8425 21.5 20.965 21.5 20.05V7.4C21.5 7.095 21.3749 6.80249 21.1522 6.58683C20.9295 6.37116 20.6274 6.25 20.3125 6.25ZM9.625 5.1C9.625 4.49 9.87522 3.90499 10.3206 3.47365C10.766 3.04232 11.3701 2.8 12 2.8C12.6299 2.8 13.234 3.04232 13.6794 3.47365C14.1248 3.90499 14.375 4.49 14.375 5.1V6.25H9.625V5.1ZM19.125 20.05C19.125 20.355 18.9999 20.6475 18.7772 20.8632C18.5545 21.0788 18.2524 21.2 17.9375 21.2H6.0625C5.74756 21.2 5.44551 21.0788 5.22281 20.8632C5.00011 20.6475 4.875 20.355 4.875 20.05V8.55H7.25V9.7C7.25 10.005 7.37511 10.2975 7.59781 10.5132C7.82051 10.7288 8.12256 10.85 8.4375 10.85C8.75244 10.85 9.05449 10.7288 9.27719 10.5132C9.49989 10.2975 9.625 10.005 9.625 9.7V8.55H14.375V9.7C14.375 10.005 14.5001 10.2975 14.7228 10.5132C14.9455 10.7288 15.2476 10.85 15.5625 10.85C15.8774 10.85 16.1795 10.7288 16.4022 10.5132C16.6249 10.2975 16.75 10.005 16.75 9.7V8.55H19.125V20.05Z" />
          </svg>
        `}
    `;
    }
};
IconBag.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }
  `];
__decorate([
    property()
], IconBag.prototype, "theme", void 0);
IconBag = __decorate([
    customElement("icon-bag")
], IconBag);
export { IconBag };
