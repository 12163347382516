var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import router from "@/router/router";
import globalCSS from "~/styles/global.css";
import resetPasswordCSS from "./reset-password.css";
import "@/components/start-container/start-container";
import "@/components/asset/uf-stamp/uf-stamp";
import "@/components/asset/logo-uf/logo-uf";
import "@/components/loading-spinner/loading-spinner";
import "@/components/uf-modal/uf-modal";
import low6API from "@/services/api/low6/low6.api";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
let ResetPasswordView = class ResetPasswordView extends LitElement {
    constructor() {
        super(...arguments);
        this.emailAddress = "";
        this.emailInputTouched = false;
        this.loading = false;
        this.errorModalVisible = false;
        this.errorModalText = "";
        this.successModalVisible = false;
    }
    get resetDisabled() {
        return !this.emailAddress;
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.emailInputTouched = false;
    }
    render() {
        return html `
      <start-container backRoute="/login">
        <form @submit=${this.resetPassword} class=${this.loading ? "loading" : ""} novalidate>
          
          <uf-stamp></uf-stamp>
          <div id="logo">
            <logo-uf></logo-uf>
          </div>

          <p>Please enter your email address. You'll receive an email to reset your password.</p>
        
          <div class="input">
            <input
              type="email"
              placeholder="email"
              .value=${this.emailAddress}
              @input=${this.onInput}
              @blur=${this.onBlur}
              class=${this.emailInputTouched && !this.emailAddress ? "error" : ""}
            >
            <span>Field is required</span>
          </div>
          
          <div id="button">
            <button ?disabled=${this.resetDisabled}>Send Link</button>
          </div>

          ${this.loading ? html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            ` : null}

        </form>
      </start-container>

      ${this.errorModalVisible ? html `
          <uf-modal>
            <h3>Error</h3>
            <p>${this.errorModalText}</p>
            <div>
              <button @click=${this.hideErrorModal}>OK</button>
            </div>
          </uf-modal>
        ` : null}

      ${this.successModalVisible ? html `
          <uf-modal>
            <h3>Password reset sent</h3>
            <p>An email has been sent to your inbox.</p>
            <div>
              <button @click=${this.hideSuccessModal}>OK</button>
            </div>
          </uf-modal>
        ` : null}
    `;
    }
    onInput(e) {
        const input = e.composedPath()[0];
        this.emailAddress = input.value;
    }
    onBlur() {
        if (!this.emailInputTouched) {
            this.emailInputTouched = true;
        }
    }
    /**
     * Attempts to submit a password reset request.
     * @param e Form SubmitEvent
     */
    async resetPassword(e) {
        analytics.recordTag(AnalyticsKeys.FORGOT_PASSWORD_SEND);
        e.preventDefault();
        this.loading = true;
        try {
            await low6API.forgotPassword(this.emailAddress);
            this.loading = false;
            this.successModalVisible = true;
        }
        catch (error) {
            this.loading = false;
            this.showErrorModal(error.message);
        }
    }
    showErrorModal(errorType) {
        switch (errorType) {
            case "client":
                this.errorModalText = "The user credentials were incorrect, please try again.";
                break;
            case "server":
                this.errorModalText = "There was something wrong on our end, please try again.";
                break;
            case "other":
            default:
                this.errorModalText = "There was an issue, please try again.";
                break;
        }
        this.errorModalVisible = true;
    }
    hideErrorModal() {
        this.errorModalVisible = false;
        this.errorModalText = "";
    }
    hideSuccessModal() {
        this.successModalVisible = false;
        this.emailAddress = "";
        this.emailInputTouched = false;
        router.navigate("login");
    }
};
ResetPasswordView.styles = [globalCSS, resetPasswordCSS];
__decorate([
    state()
], ResetPasswordView.prototype, "emailAddress", void 0);
__decorate([
    state()
], ResetPasswordView.prototype, "emailInputTouched", void 0);
__decorate([
    state()
], ResetPasswordView.prototype, "resetDisabled", null);
__decorate([
    state()
], ResetPasswordView.prototype, "loading", void 0);
__decorate([
    state()
], ResetPasswordView.prototype, "errorModalVisible", void 0);
__decorate([
    state()
], ResetPasswordView.prototype, "errorModalText", void 0);
__decorate([
    state()
], ResetPasswordView.prototype, "successModalVisible", void 0);
ResetPasswordView = __decorate([
    customElement("reset-password-view")
], ResetPasswordView);
export { ResetPasswordView };
