import { formatDate } from "@/services/utils/utils";
import { validateEmail } from "@/services/utils/validation";
export class FormController {
    constructor(host, initialValues, initialErrors) {
        this.serverErrors = {};
        this.isSubmitting = false;
        this.isDirty = false;
        this.isValid = false;
        this.handleChange = (e, valueThatHasToMatch) => {
            var _a, _b, _c;
            const { name, value, checked, type } = e.target;
            const valuesCopy = Object.assign({}, this.values);
            const valuesKey = name;
            //throw away invalid input
            if (e.target instanceof HTMLInputElement) {
                //if there is a pattern used to filter input
                //and the input is invalid
                //throw it away by resetting the input value back to what the last valid value was
                if (((_a = e.target) === null || _a === void 0 ? void 0 : _a.pattern) != '' && ((_c = (_b = e.target) === null || _b === void 0 ? void 0 : _b.validity) === null || _c === void 0 ? void 0 : _c.patternMismatch)) {
                    e.target.value = valuesCopy[valuesKey];
                    return;
                }
            }
            // update values
            valuesCopy[valuesKey] = type === "checkbox" ? checked : value;
            this.values = valuesCopy;
            // check if form is dirty
            this.isDirty =
                JSON.stringify(this.values) !== JSON.stringify(this.savedValues);
            // update errors
            const errorsCopy = Object.assign({}, this.errors);
            const errorsKey = name;
            errorsCopy[errorsKey] = this.getErrorMessage(e.target, valueThatHasToMatch);
            this.errors = errorsCopy;
            // check if form is valid
            this.isValid = Object.values(this.errors).every((obj) => obj === "");
            this.host.requestUpdate();
        };
        this.setValues = (values) => {
            this.values = values;
        };
        this.getErrorMessage = (e, valueThatHasToMatch) => {
            var _a, _b, _c, _d;
            const { name, placeholder, value, required, minLength, maxLength } = e;
            let error = "";
            if (required && value === "") {
                error = `${name} is required`;
            }
            if (value.length < minLength) {
                error = `${placeholder} must be between ${minLength} and ${maxLength} characters`;
            }
            if (name === "email" && !validateEmail(value)) {
                error = "email is invalid";
            }
            if (!!valueThatHasToMatch && value !== valueThatHasToMatch) {
                if (name == 'password' || name == 'passwordConfirm') {
                    return 'Passwords do not match';
                }
                if (name == 'pin' || name == 'pinConfirm') {
                    return 'Pin do not match';
                }
                return "Values entered do not match";
            }
            if (name === "birthDate") {
                const minAge = parseInt((_b = (_a = e === null || e === void 0 ? void 0 : e.dataset) === null || _a === void 0 ? void 0 : _a.minage) !== null && _b !== void 0 ? _b : "0");
                const format = (_d = (_c = e === null || e === void 0 ? void 0 : e.dataset) === null || _c === void 0 ? void 0 : _c.format) !== null && _d !== void 0 ? _d : "";
                if (!this.validateAge(value, minAge, format)) {
                    return `Age must be at least ${minAge}`;
                }
            }
            if (name === 'password') {
                return this.validatePassword(value);
            }
            return error;
        };
        this.host = host;
        host.addController(this);
        this.values = initialValues;
        // if (!this.savedValues) {
        //   this.savedValues = initialValues;
        // }
        this.savedValues = initialValues;
        this.errors = initialErrors;
        this.initialValues = initialValues;
        this.initialErrors = initialErrors;
    }
    validateAge(date, minAge, format) {
        if (minAge < 1)
            return true; //nothing to do
        const NUMBER_OF_MILI_SECONDS_IN_YEAR = 31536000000; //3.15576e+10
        const now = Date.now();
        const birthDate = formatDate(date, format);
        const age = ~~((now - new Date(birthDate).getTime()) / NUMBER_OF_MILI_SECONDS_IN_YEAR);
        return age >= minAge;
    }
    validatePassword(input) {
        if (!input.match(/^.*(?=.{8,}).*$/)) {
            return 'Password must be 8 or more characters';
        }
        if (!input.match(/^.*(?=.*[a-z])(?=.*[A-Z]).*$/)) {
            return 'Password must contain lower and upper case characters';
        }
        if (!input.match(/^.*(?=.*[0-9]).*$/)) {
            return 'Password must contain a number';
        }
        if (!input.match(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
            return 'Password is invalid';
        }
        return '';
    }
    hostConnected() { }
    hostDisconnected() { }
}
