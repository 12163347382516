var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import "./info-scoring.css";
import "@/components/game-scoring/game-scoring";
let InfoScoringView = class InfoScoringView extends UnscopedElement {
    render() {
        return html `<game-scoring></game-scoring>`;
    }
};
InfoScoringView = __decorate([
    customElement("info-scoring-view")
], InfoScoringView);
