var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconPackReward = class IconPackReward extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="${this.theme}" fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H9C10.1046 0 11 0.895431 11 2V9C11 10.1046 10.1046 11 9 11H2C0.895431 11 0 10.1046 0 9V2ZM0.6875 1.6875C0.6875 1.13522 1.13522 0.6875 1.6875 0.6875H9.3125C9.86479 0.6875 10.3125 1.13522 10.3125 1.6875V7.25C10.3125 7.80228 9.86479 8.25 9.3125 8.25H1.6875C1.13522 8.25 0.6875 7.80228 0.6875 7.25V1.6875ZM8.25 8.9375H2.75V10.3125H8.25V8.9375Z"/>
        <circle class="${this.theme}" cx="2.0625" cy="2.0625" r="0.6875"/>
        <path class="${this.theme}"  d="M5.5 4.8125C6.25969 4.8125 6.875 4.19719 6.875 3.4375C6.875 2.67781 6.25969 2.0625 5.5 2.0625C4.74031 2.0625 4.125 2.67781 4.125 3.4375C4.125 4.19719 4.74031 4.8125 5.5 4.8125ZM5.5 5.5C4.58219 5.5 2.75 5.96062 2.75 6.875V7.21875C2.75 7.40781 2.90469 7.5625 3.09375 7.5625H7.90625C8.09531 7.5625 8.25 7.40781 8.25 7.21875V6.875C8.25 5.96062 6.41781 5.5 5.5 5.5Z"/>
        <defs>
        <linearGradient id="paint0_linear_1909_61537" x1="5.5" y1="0" x2="5.5" y2="11" gradientUnits="userSpaceOnUse">
        <stop stop-color="#151515"/>
        <stop offset="0.6504" stop-color="#0F0F0F"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1909_61537" x1="2.0625" y1="1.375" x2="2.0625" y2="2.75" gradientUnits="userSpaceOnUse">
        <stop stop-color="#151515"/>
        <stop offset="0.6504" stop-color="#0F0F0F"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1909_61537" x1="5.5" y1="2.0625" x2="5.5" y2="7.5625" gradientUnits="userSpaceOnUse">
        <stop stop-color="#151515"/>
        <stop offset="0.6504" stop-color="#0F0F0F"/>
        </linearGradient>
        </defs>
      </svg>
    `;
    }
};
IconPackReward.styles = [iconCSS, css `
    path {
      fill: var(--color);
    }
    path.white {
      fill: var(--color)
    }
    path.dark-grey {
      fill: #292929
    }
    path.black {
      fill: #0F0F0F
    }
  `];
__decorate([
    property()
], IconPackReward.prototype, "theme", void 0);
IconPackReward = __decorate([
    customElement("icon-pack-reward")
], IconPackReward);
export { IconPackReward };
