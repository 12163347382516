var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "~/app/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./join-league.css";
import "@/components/loading-spinner/loading-spinner";
import router from "@/router/router";
import ufApi from "@/services/api/uf/uf.api";
let JoinLeagueView = class JoinLeagueView extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.code = '';
        this.codeError = false;
        this.codeTouched = false;
        this.loading = false;
        this.joinModalVisible = false;
    }
    renderBack() {
        return html `
      <router-link href="/leaderboard">
        <uf-icon icon='chevron'></uf-icon>
      </router-link>
    `;
    }
    renderHeaderBar() {
        return html `
      <header-bar class="clickable">
        <div slot="center"><h2>Join A League</h2></div>
        <div slot="left">${this.renderBack()}</div>
      </header-bar>
    `;
    }
    render() {
        return html `

      ${this.renderHeaderBar()}

      <form @submit=${this.checkCode} novalidate>
        <main>
          <p>Enter a league code below to join</p>
          <div class="input">
            <input
              type="text"
              title="code"
              placeholder="Enter League Code"
              @input=${this.onInput}
              @blur=${this.onBlur}
              class=${this.codeError ? "error" : ""}
            >
            <span>Invalid Code. Please check and try again.</span>
          </div>

          <button
            .disabled=${!this.code}
          >Join League</button>

          ${when(this.loading, () => html `
              <div id="loading">
                <loading-spinner></loading-spinner>
              </div>
            `)}

        </main>

        <footer>
          <router-link href="/leaderboard/create">Or, Create New League</router-link>
        </footer>

      </form>

      ${when(this.joinModalVisible && this.leagueDetails, () => {
            var _a;
            return html `
          <uf-modal>
            <section>
              <h3>Join '${(_a = this.leagueDetails) === null || _a === void 0 ? void 0 : _a.name}'</h3>
              <p>Are you sure you want to join this league?</p>
              <div class="modal-footer">
                <button class="dark" @click=${this.hideJoinModal}>No Thanks</button>
                <button @click=${this.confirmJoin}>Join</button>
              </div>
            </section>
          </uf-modal>
        `;
        })}
    `;
    }
    onInput(e) {
        const input = e.composedPath()[0];
        switch (input.title) {
            case "code":
                this.code = input.value;
                this.codeError = false;
                break;
        }
    }
    onBlur(e) {
        const input = e.composedPath()[0];
        this.loading = false;
        switch (input.title) {
            case "code":
                if (!this.codeTouched) {
                    this.codeTouched = true;
                }
                this.code = input.value;
                break;
        }
    }
    hideJoinModal() {
        this.joinModalVisible = false;
    }
    async checkCode(e) {
        e.preventDefault();
        this.loading = true;
        try {
            const response = await ufApi.getMiniLeagueDetails(this.code);
            this.loading = false;
            if (response.code === this.code) {
                this.leagueDetails = response;
                this.joinModalVisible = true;
            }
            else {
                this.codeError = true;
            }
        }
        catch (error) {
            this.loading = false;
            console.log("Error", error);
        }
    }
    async confirmJoin() {
        this.loading = true;
        try {
            const response = await ufApi.joinUserLeague(this.code);
            this.loading = false;
            if (response) {
                this.leagueDetails = response.leaderboards.find(leaderboard => leaderboard.code === this.code);
                if (this.leagueDetails) {
                    ufApi.currentStage.subscribe(stage => {
                        var _a;
                        const currentStage = stage.stage;
                        router.navigate(`leaderboard/positions/${currentStage.id}/userLeague/${(_a = this.leagueDetails) === null || _a === void 0 ? void 0 : _a.userLeagueDetailId}`);
                    }).unsubscribe();
                }
                else {
                    this.codeError = true;
                }
            }
            else {
                this.codeError = true;
            }
        }
        catch (error) {
            this.loading = false;
            console.log("Error", error);
        }
    }
};
__decorate([
    state()
], JoinLeagueView.prototype, "code", void 0);
__decorate([
    state()
], JoinLeagueView.prototype, "codeError", void 0);
__decorate([
    state()
], JoinLeagueView.prototype, "codeTouched", void 0);
__decorate([
    state()
], JoinLeagueView.prototype, "loading", void 0);
__decorate([
    state()
], JoinLeagueView.prototype, "joinModalVisible", void 0);
__decorate([
    state()
], JoinLeagueView.prototype, "leagueDetails", void 0);
JoinLeagueView = __decorate([
    customElement("join-league-view")
], JoinLeagueView);
export { JoinLeagueView };
