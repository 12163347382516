import { css } from "lit";
const iconCSS = css `
  :host {
    display: contents;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;
export default iconCSS;
