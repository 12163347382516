var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import ScopedElement from "@/layout/scoped-element";
import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import { when } from "lit/directives/when.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import gameCardStyles from "../game-card.css";
import gameCardFrontStyles from "./game-card-front.css";
import configService from "@/services/config/config";
import placeholders from "../placeholders";
import modifierGoldenBoot from "@/assets/icon_mod_golden_boot.svg";
import modifierCaptain from "@/assets/icon_modifier_captain.svg";
import modifierGoldenFootball from "@/assets/icon_mod_golden_football.svg";
import modifierGoldenHelmet from "@/assets/icon_mod_golden_helmet.svg";
import "@/components/asset/uf-icon/uf-icon";
import { SquadItemModifier, SquadItemRating, SquadItemType } from "@/services/api/uf/types/uf.api.types.enums";
import { squadPositionLabel } from "@/mappings/squad.mappings";
let GameCardFront = class GameCardFront extends ScopedElement {
    constructor() {
        super(...arguments);
        this.type = "player";
        this.modifier = SquadItemModifier.None;
    }
    render() {
        let type = "";
        let rating = "";
        let img = "";
        let roundelImg = "";
        if (this.squadItem) {
            switch (this.squadItem.scoreableType) {
                case SquadItemType.Team:
                    if (this.squadItem.externalRef) {
                        img = `${configService.storageBaseUrl()}/headshots/${this.squadItem.externalRef}.png`;
                    }
                    break;
                case SquadItemType.Player:
                    if (this.squadItem.teamExternalRef) {
                        roundelImg = `${configService.storageBaseUrl()}/headshots/${this.squadItem.teamExternalRef}.png`;
                        if (this.squadItem.externalRef) {
                            if (configService.isUF) {
                                // Currently PL player headshot filesnames are prefixed with the team id whereas the NFL ones are not
                                img = `${configService.storageBaseUrl()}/headshots/${this.squadItem.externalRef}_${this.squadItem.teamExternalRef}.png`;
                            }
                            else {
                                img = `${configService.storageBaseUrl()}/headshots/${this.squadItem.externalRef}.png`;
                            }
                        }
                    }
                    break;
            }
            if (this.squadItem.scoreableType !== null || this.squadItem.scoreableType !== undefined)
                type = SquadItemType[this.squadItem.scoreableType];
            if (this.squadItem.cardRating !== null || this.squadItem.cardRating !== undefined)
                rating = SquadItemRating[this.squadItem.cardRating];
        }
        return html `
      ${this.squadItem ? html `

          <div class=${classMap({
            [type.toLowerCase()]: true,
            [rating.toLowerCase()]: true
        })}>

            ${img ? html `
              
                <div class="img" style="background-image: url('${img}')"></div>
                
              ` : html `
                <div class="img img-placeholder">
                  ${choose(this.squadItem.scoreableType, [
            [SquadItemType.Team, () => choose(this.squadItem.cardRating, [
                    [SquadItemRating.Standard, () => unsafeSVG(placeholders.team.standard)
                    ],
                    [SquadItemRating.Silver, () => unsafeSVG(placeholders.team.silver)
                    ],
                    [SquadItemRating.Gold, () => unsafeSVG(placeholders.team.gold)
                    ]
                ])],
            [SquadItemType.Player, () => choose(this.squadItem.cardRating, [
                    [SquadItemRating.Standard, () => unsafeSVG(placeholders.player.standard)
                    ],
                    [SquadItemRating.Silver, () => unsafeSVG(placeholders.player.silver)
                    ],
                    [SquadItemRating.Gold, () => unsafeSVG(placeholders.player.gold)
                    ]
                ])]
        ])}
                </div>
              `}

            ${roundelImg ? html `
                <div class="roundel" style="background-image: url('${roundelImg}')"></div>
              ` : html `
                <div class="roundel roundel-placeholder">
                  ${choose(this.squadItem.cardRating, [
            [SquadItemRating.Standard, () => unsafeSVG(placeholders.roundel.standard)
            ],
            [SquadItemRating.Silver, () => unsafeSVG(placeholders.roundel.silver)
            ],
            [SquadItemRating.Gold, () => unsafeSVG(placeholders.roundel.gold)
            ]
        ])}
                </div>
              `}

            <div class=${classMap({
            label: true,
            [rating.toLowerCase()]: true
        })}>
              <span>
                ${squadPositionLabel(this.squadItem)}
              </span>
            </div>

            <footer>
              <div class=${this.squadItem.isInjured ? "warn" : ""}>
                <div>
                  ${this.squadItem.knownname ? html `
                      <p><span>${this.squadItem.knownname}</span></p>
                    ` : html `
                      ${when(this.squadItem.firstname && this.squadItem.lastname, () => html `
                          <p>${this.squadItem.firstname[0]}. <span>${this.squadItem.lastname}</span></p>
                        `)}
                    `}
                  ${when(SquadItemType[this.squadItem.scoreableType].toLowerCase() === "player", () => html `
                      <p class="team">${this.squadItem.team}</p>
                    `)}
                </div>
              </div>
              ${when(this.squadItem.isInjured, () => html `
                  <div class="warning">
                    <uf-icon icon="warning"></uf-icon>
                  </div>
                `)}
            </footer>
          </div>

        ` : html `
        
          <div class=${classMap({
            [this.type]: true
        })}>
            ${choose(this.type, [
            ["player", () => html `
                  <div class="img img-placeholder">
                    ${unsafeSVG(placeholders.player.standard)}
                  </div>
                `],
            ["team", () => html `
                  <div class="img img-placeholder">
                    ${unsafeSVG(placeholders.team.standard)}
                  </div>
                `],
            ["modifier", () => html `
                  <div>
                    ${choose(this.modifier, [
                    [SquadItemModifier.Captain, () => unsafeSVG(configService.isUF ? modifierCaptain : modifierGoldenHelmet)
                    ],
                    [SquadItemModifier.GoldenBoot, () => unsafeSVG(configService.isUF ? modifierGoldenBoot : modifierGoldenFootball)
                    ]
                ])}
                  </div>
                `]
        ])}
            <footer>
              <div>
                <div>
                  <p>${this.name}</p>
                </div>
              </div>
            </footer>
          </div>

        `}
    `;
    }
};
GameCardFront.styles = [ScopedElement.styles, gameCardStyles, gameCardFrontStyles];
__decorate([
    property({ type: Object, reflect: true })
], GameCardFront.prototype, "squadItem", void 0);
__decorate([
    property({ reflect: true })
], GameCardFront.prototype, "type", void 0);
__decorate([
    property({ reflect: true })
], GameCardFront.prototype, "name", void 0);
__decorate([
    property({ type: Number, reflect: true })
], GameCardFront.prototype, "modifier", void 0);
GameCardFront = __decorate([
    customElement("game-card-front")
], GameCardFront);
export { GameCardFront };
