var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import signupCSS from "../signup.view.css";
import "@/components/forms/form-checkbox";
import "@/components/forms/form-h2";
import "@/components/forms/form-p";
import "@/components/forms/form-grid";
import "@/components/forms/form-row";
import "@/components/forms/form-summary";
import { initialValues, initialErrors } from "../signup.config";
import ConfigurationService from "@/services/config/config";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
const pageStyles = [css `
  
  header {
    margin-bottom: 2.5em;
  }
  form-row button {
    width: 50%;
  }
  form-grid {
    gap: 0.6em;
  }
  form-h2 {
    transform: translate(0%, 10%);
  }
  .inline-href {
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s;
  }

  .inline-href:link,
  .inline-href:visited,
  .inline-href:active {
    color: rgba(255, 255, 255, 0.57);
  }

  .inline-href:hover {
    color: #f9f9f9;
  }
`];
let SignupStep4 = class SignupStep4 extends LitElement {
    constructor() {
        super(...arguments);
        this.confirmEmailAddress = "";
        this.backRoute = "/";
        this.handleChange = () => null;
        this.handleSelectAll = () => null;
        this.handleSubmit = () => { console.log('handleSubmit'); };
        this.setErrors = () => null;
        this.setStepNumber = () => null;
        this.isStepValid = false;
        this.isSubmitting = false;
        this.values = initialValues;
        this.errors = initialErrors;
        this.serverErrors = null;
        this.onChange = (e) => {
            this.handleChange(e);
        };
    }
    connectedCallback() {
        super.connectedCallback();
        analytics.recordTag(AnalyticsKeys.REGISTRATION_CONTACT_PERMISSIONS_PAGE);
    }
    handleBackClicked() {
        this.setStepNumber(3);
    }
    termsTitle() {
        if (ConfigurationService.isChargers)
            return "EULA";
        if (ConfigurationService.isBengals)
            return "EULA";
        return "Terms & Conditions";
    }
    acceptActionButtonTitle() {
        if (ConfigurationService.isChargers)
            return "Play UltimateChargers";
        if (ConfigurationService.isBengals)
            return "Play UltimateBengals";
        return "Play UltimateFan";
    }
    render() {
        const hasServerErrors = this.serverErrors !== null;
        return html `
      <header>
        <h1>Step 4</h1>
        <span>Your Consent</span>
      </header>
      <form-grid>
        
        <form-h2>${this.termsTitle()}</form-h2>
        <form-checkbox
          name="agreedTerms"
          id="agreed-terms"
          .handleChange=${this.onChange}
          .value=${this.values.agreedTerms}
          .errorMessage=${this.errors.agreedTerms}
          text="Please confirm that you have read and agreed to our "
          href="${ConfigurationService.termsAndConditionsLink()}"
          hrefText=${this.termsTitle()}>
        </form-checkbox>

        <form-h2>Privacy Policy</form-h2>
        <form-checkbox
          name="agreedPrivacy"
          id="agreedPrivacy"
          .handleChange=${this.onChange}
          .value=${this.values.agreedPrivacy}
          .errorMessage=${this.errors.agreedPrivacy}
          text="Please confirm that you have read and agreed to our "
          href="${ConfigurationService.low6PrivacyPolicyLink()}"
          hrefText="Privacy Policy"
        ></form-checkbox>
        <form-h2>Contact Permissions</form-h2>
        <form-p .isLighterText=${true}>
          We would like to contact you about exclusive offers, promotions,
          contests and bonuses from Low6 and promotions and offers
          from our commercial partners. If you agree, we will use your personal
          information to identify the products and services which we think will
          be of interest to you.
        </form-p>
        <form-p .isLighterText=${true}>
          If you are happy to receive these messages, please opt-in to your
          preferred method of contact below:
        </form-p>
        <form-checkbox
          name="agreedSMS"
          id="agreedSMS"
          .handleChange=${this.onChange}
          .value=${this.values.agreedSMS}
          .errorMessage=${this.errors.agreedSMS}
          text="SMS"
        ></form-checkbox>
        <form-checkbox
          name="agreedEmail"
          id="agreedEmail"
          .handleChange=${this.onChange}
          .value=${this.values.agreedEmail}
          .errorMessage=${this.errors.agreedEmail}
          text="Email"
        ></form-checkbox>
        <form-p .isLighterText=${true}>
          You can opt out of promotional messages at any time from your account
          settings or by contacting us at
          
          <a class="inline-href" 
            href="mailto:privacy@low6.co.uk">
            privacy@low6.co.uk.
          </a>

        </form-p>
        <form-p .isLighterText=${true}>
          To learn more about how we use your personal information and your
          rights, see our 

          <a class="inline-href"
            href="${ConfigurationService.low6PrivacyPolicyLink()}"
            target="_blank">
            privacy notice here. 
          </a>
          
        </form-p>
        <form-h2>Select All</form-h2>
        <form-checkbox
          id="select-all"
          .handleChange=${this.handleSelectAll}
          text="By selecting all you agree to the above terms & conditions, privacy notice and confirm consent for the above marketing mediums."
        ></form-checkbox>

        <div>
          <uf-icon icon=${ConfigurationService.ageRatingIcon()}></uf-icon>
        </div>

        <form-p .isDanger=${true} ?hidden=${this.isStepValid}>
          You must agree that you have read terms & conditions and privacy
          policy to continue
        </form-p>
      </form-grid>

      ${hasServerErrors
            ? html `
            <form-summary>
              ${this.serverErrors.message
                ? html `<div>${this.serverErrors.message}</div>`
                : ""}
              ${this.serverErrors.email
                ? html `<div>Email already exists</div>`
                : ""}
              ${this.serverErrors.username
                ? html `<div>Username already exists</div>`
                : ""}
              ${this.serverErrors.mobileNumber
                ? html `<div>Mobile number already in use</div>`
                : ""}
            </form-summary>
          `
            : ""}

      <form-row>
        <button 
          class="grey no-border" 
          @click=${this.handleBackClicked}>
          Previous Step
        </button>
        <button
          @click=${this.handleSubmit}
          .disabled=${(!this.isStepValid) || this.isSubmitting}
          .isPrimary=${true}
          .isLoading=${this.isSubmitting}>
          ${this.acceptActionButtonTitle()}
        </button>
      </form-row>
    `;
    }
};
SignupStep4.styles = [globalCSS, signupCSS, pageStyles];
__decorate([
    property()
], SignupStep4.prototype, "confirmEmailAddress", void 0);
__decorate([
    property()
], SignupStep4.prototype, "backRoute", void 0);
__decorate([
    state()
], SignupStep4.prototype, "handleChange", void 0);
__decorate([
    state()
], SignupStep4.prototype, "handleSelectAll", void 0);
__decorate([
    state()
], SignupStep4.prototype, "handleSubmit", void 0);
__decorate([
    state()
], SignupStep4.prototype, "setErrors", void 0);
__decorate([
    state()
], SignupStep4.prototype, "setStepNumber", void 0);
__decorate([
    state()
], SignupStep4.prototype, "isStepValid", void 0);
__decorate([
    state()
], SignupStep4.prototype, "isSubmitting", void 0);
__decorate([
    state()
], SignupStep4.prototype, "values", void 0);
__decorate([
    state()
], SignupStep4.prototype, "errors", void 0);
__decorate([
    state()
], SignupStep4.prototype, "serverErrors", void 0);
SignupStep4 = __decorate([
    customElement("signup-step4")
], SignupStep4);
export { SignupStep4 };
