var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c;
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement } from "lit/decorators.js";
import config from "@/services/config/config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.leaderboard) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
const skinStyles = [
    { name: "color-leaderboard-card", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardCard) || "#36023A" },
    { name: "color-leaderboard-card-title", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardCardTitle) || "#F6F3EE" },
    { name: "color-leaderboard-card-prize", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardCardPrize) || "#35E06A" },
    { name: "color-leaderboard-position-entry", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardPositionEntry) || "#47284A" },
    { name: "color-leaderboard-position-entry-selected", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardPositionEntrySelected) || "#FF284F" },
    { name: "color-leaderboard-position-entry-text", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardPositionEntryText) || "#F6F3EE" },
    { name: "color-leaderboard-position-entry-text-selected", value: (colour === null || colour === void 0 ? void 0 : colour.leaderboardPositionEntryTextSelected) || "#F6F3EE" },
    { name: "color-user-league-card-top", value: (colour === null || colour === void 0 ? void 0 : colour.userLeageCardTop) || "#47284A" },
    { name: "color-user-league-card-bottom", value: (colour === null || colour === void 0 ? void 0 : colour.userLeageCardBottom) || "#2F1631" },
    { name: "color-stage-card", value: (colour === null || colour === void 0 ? void 0 : colour.stageCard) || "#47284A" },
    { name: "color-stage-card-subheading", value: (colour === null || colour === void 0 ? void 0 : colour.stageCardSubheading) || "#F6F3EE" }
];
let LeaderboardView = class LeaderboardView extends UnscopedElement {
    constructor() {
        super(skinStyles);
    }
    render() {
        return html `
      <router-outlet></router-outlet>
    `;
    }
};
LeaderboardView = __decorate([
    customElement("leaderboard-view")
], LeaderboardView);
export { LeaderboardView };
