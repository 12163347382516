var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconHelpFilled = class IconHelpFilled extends LitElement {
    constructor() {
        super(...arguments);
        this.theme = null;
    }
    render() {
        return html `
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="${this.theme}" fill-rule="evenodd" clip-rule="evenodd" d="M0 12.375C0 5.544 5.544 0 12.375 0C19.206 0 24.75 5.544 24.75 12.375C24.75 19.206 19.206 24.75 12.375 24.75C5.544 24.75 0 19.206 0 12.375ZM13.4062 17V19.0625H11.3438V17H13.4062ZM8.25 10.8125C8.25 8.53344 10.0959 6.6875 12.375 6.6875C14.6541 6.6875 16.5 8.53344 16.5 10.8125C16.5 12.1355 15.6853 12.8475 14.892 13.5407C14.1395 14.1984 13.4062 14.8391 13.4062 15.9688H11.3438C11.3438 14.0906 12.3153 13.3459 13.1695 12.6911C13.8396 12.1775 14.4375 11.7192 14.4375 10.8125C14.4375 9.67813 13.5094 8.75 12.375 8.75C11.2406 8.75 10.3125 9.67813 10.3125 10.8125H8.25Z"/>
      </svg>
    `;
    }
};
IconHelpFilled.styles = [iconCSS, css `
    
    path.white {
      fill: var(--color);
    }

    path.black {
      fill: #292929;
    }
  `];
__decorate([
    property()
], IconHelpFilled.prototype, "theme", void 0);
IconHelpFilled = __decorate([
    customElement("icon-help-filled")
], IconHelpFilled);
export { IconHelpFilled };
