var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./game.css";
import "./parts/navbar/navbar";
import { toast } from "@/services/ui/toast/toast";
import "@/views/game/parts/uf-toast/uf-toast";
let GameView = class GameView extends UnscopedElement {
    connectedCallback() {
        super.connectedCallback();
        this.toastSubscription = toast.subscribe(toast => this.toast = toast);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.toastSubscription) {
            this.toastSubscription.unsubscribe();
        }
    }
    render() {
        return html `
      <header id="nav">
        <nav-bar></nav-bar>
      </header>
      <main id="game">
        <router-outlet></router-outlet>
      </main>
      ${when(this.toast, () => html `
          <uf-toast .visible=${this.toast.open}>
            ${this.toast.text}
          </uf-toast>
        `)}
    `;
    }
};
__decorate([
    state()
], GameView.prototype, "toast", void 0);
GameView = __decorate([
    customElement("game-view")
], GameView);
export { GameView };
