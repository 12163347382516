import { css } from "lit";
const gameCardFlippableStyles = css `

  :host {
    display: block;
    perspective: 1000px;
    width: calc(var(--game-card-width) * var(--game-card-scale));
    height: calc(var(--game-card-height) * var(--game-card-scale));
    transition: transform 0.4s linear;
  }

  :host > div {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.4s linear;
    transform-style: preserve-3d;
  }

  :host(.flipping) {
    transform: scale(0.6);
  }

  :host(.flipped) > div {
    transform: rotateY(180deg);
  }

  :host(.flipped) game-card-front {
    z-index: -1;
  }

  game-card-front, game-card-back {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    backface-visibility: hidden;
  }

  game-card-back {
    transform: rotateY(180deg);
  }

`;
export default gameCardFlippableStyles;
