var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import router from "@/router/router";
import formCSS from '@/components/forms/form.css';
import globalCSS from '~/styles/global.css';
let ProfileActions = class ProfileActions extends LitElement {
    constructor() {
        super(...arguments);
        this.gotoChangePassword = (e) => {
            e.stopPropagation();
            router.navigate("change-password");
        };
    }
    render() {
        return html `
      <form>
        <button @click=${this.gotoChangePassword}>
          Change Password
        </button>
      </form>
    `;
    }
};
ProfileActions.styles = [globalCSS, formCSS];
ProfileActions = __decorate([
    customElement("profile-actions")
], ProfileActions);
export { ProfileActions };
