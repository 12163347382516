var _a, _b, _c;
import { css, unsafeCSS } from "lit";
import config from "@/services/config/config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.raf) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
const loginStreakCardCSS = css `

    :host {
        --background-raf: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRaf) || "#FF284F")};
        --color-raf: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorRafFont) || "#F6F3EE")};
        --background-reward-card: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRewardCard) || "#7F1E31")}; 
        --background-reward-card-disabled: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRewardCardDisabled) || "#7F1E31")}; 
        --color-reward-card-font: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorRewardCardFont) || "#F6F3EE")};
        --color-reward-card-font-disabled: #F6F3EE; 
        --color-login-streak-selected-font: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorLoginStreakSelectedFont) || "#242424")};
    }

    div.card-header {
        display: flex;
        position: relative;
        flex-direction: column;
        row-gap: 0.6em;
        background-repeat: no-repeat;
        background-size: cover;
        background: var(--background-raf);
        border-radius: 1em;
        padding: 1em;
        margin: 0 auto;
    }

    div.top {
        display: flex;
        justify-content: center;
        color: var(--color-raf);
    }

    /* uf-icon[icon=share] {
        width: 1.31em;
        text-align: center;
    } */

    uf-icon[icon=helpFilled] {
        position: absolute;
        top: 1em;
        right: 1em;
        width: 1.55em;
        text-align: center;
    }

    div.top > div{
        display: flex;
        justify-content: center;
        column-gap: 0.6875em;
    }

    h1 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.25em;
        text-align: center;
        margin: auto;
    }

    div.body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 3em;
    }

    div.ladder-container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    uf-icon[icon=loginStreakLadder] {
        position: absolute;
        width: 100%;
        transform: translate(0%, -22%);
    }

    div.ladder {
        display: flex;
        justify-content: space-evenly;
        column-gap: 1em;
        z-index:1;
        width: 100%;
    }

    /* div.ladder {
        display: flex;
        justify-content: center;
        z-index:1;
        column-gap: 2.8em;
        position: relative;
    } */

    div.ladder div {
        margin: auto;
        position: relative;
    }

    div.ladder p {
        font-weight: bold;
        width: 1em;
        text-align: center;
        z-index:2;
        margin: auto;
        position: relative;
    }

    div.ladder p.day-selected{
        color: var(--color-login-streak-selected-font);
    }

    .pack-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.25em;
        padding: 0.3125em 0.4375em;
        /* border-radius: 0.3125em; */
        /* background-color: var(--background-reward-card-disabled); */
    }

    .small-text {
        z-index:1;
        padding: 0.2em 0 0;
    }

    uf-icon[icon=rewardLozenge] {
        position: absolute
    }

    uf-icon[icon=packReward] {
        z-index:1;
        vertical-align: middle;
        align-items: center;
        height: 1em;
        width: 1em;
        margin: 0.2em 0 0;
    }

    .reward-card {
        display: flex;
        position: absolute;
        z-index:1;
        top: 2.7em;
    }

    #reward1 {
        left: 0em;
    }

    #reward2 {
        left: 30%;
    }

    #reward3 {
        right: 30%;
    }

    #reward4 {
        right: 0em;
    }

    @media (max-width: 800px) {
        div.ladder {
            transform: translate(0%, 10%);
        }
    }

    @media (max-width: 600px) {
        #reward2 {
            left: 31%;
        }

        #reward3 {
            right: 31%;
        }
    }

    @media (max-width: 500px) {
        div.ladder {
            column-gap: 0.5em;
        }

        div.ladder p {
            font-size: 0.8em;
        }

        div.ladder {
            transform: translate(0%, -10%);
        }

        #reward2 {
            left: 27%;
        }

        #reward3 {
            right: 27%;
        }
    }


`;
export default loginStreakCardCSS;
