var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var _a, _b, _c;
import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import config from "@/services/config/config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.raf) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
let ReferralCard = class ReferralCard extends LitElement {
    constructor() {
        super(...arguments);
        this.username = '';
        this.date = '';
    }
    render() {
        return html `
            <div class="card">
                <div class="profile-username">
                    <!-- TODO - load avatar -->
                    <uf-icon icon="avatar"></uf-icon>
                    <p>${this.username}</p>
                </div>
                <p class="referral-date">${this.date}</p>
            </div>
        `;
    }
};
ReferralCard.styles = [globalCSS, css `
    
    :host {
        --header-bar-background: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundReferralItem) || "#47284A")};
    }

    .card {
        display: flex;
        row-gap: 0.75em;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var(--header-bar-background);
        border-radius: 1em;
        padding: 0.6em 1em ;
        justify-content: space-between;
    }

    .profile-username {
        display: flex;
        column-gap: 0.9525em;
    }
        
    .card uf-icon {
        width: 2.3438em;
        padding: 0em;
    }

    p {
        margin: auto 0;
        font-size: 0.875em;
    }

    p.referral-date {
        right: 0em;
        font-size: 1em;
    }

    `];
__decorate([
    property()
], ReferralCard.prototype, "username", void 0);
__decorate([
    property()
], ReferralCard.prototype, "date", void 0);
ReferralCard = __decorate([
    customElement("referral-card")
], ReferralCard);
export { ReferralCard };
