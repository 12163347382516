var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import "@/views/game/views/leaderboards/components/leaderboard-position-entry/leaderboard-position-entry.view";
import globalCSS from "~/styles/global.css";
let LeaderboardMiniLeague = class LeaderboardMiniLeague extends LitElement {
    render() {
        return html `

        <section>

          <form>

            <button type="submit" ?disabled=${!this.selectedId}><uf-icon icon="shirt"></uf-icon>View Lineup</button>

            <header>
              <p>Rank</p>
              <p></p>
              <p></p>
              <p></p>
              <p>Points</p>
            </header>

              ${map(this.leagueDetails.leaderboard, (entry) => html `

                <leaderboard-position-entry
                  selectedId=${ifDefined(this.selectedId)}
                  .entry=${entry}
                  @clickedElement=${this.handleSelected}
                ></leaderboard-position-entry>

              `)}
          </form>

        </section>
    `;
    }
    handleSelected(event) {
        this.selectedId = event.detail;
    }
};
LeaderboardMiniLeague.styles = [globalCSS, css `

    :host {
      display: flex;
      justify-content: center;
      margin-top: 1.5em;
    }

    section {
      width: 25.125em;
    }

    header {
      display: grid;
      grid-template-columns: 1fr 1fr 4fr 1fr 1fr;
      margin-bottom: 0.875em;
    }

    p {
      margin: 0;
    }

    header p:last-child {
      justify-self: end;
    }

    main {
      display: flex;
      flex-direction: column;
      row-gap: 0.5em;
    }
    
    button {
      width: 100%;
      margin-bottom: 4em;
      column-gap: 0.5em;
      height: 3.5em;
      border-radius: 0.93em;
    }

    uf-icon[icon=shirt] {
      height: 1em;
      width: 1em;
    }

  `];
__decorate([
    state()
], LeaderboardMiniLeague.prototype, "leagueDetails", void 0);
__decorate([
    state()
], LeaderboardMiniLeague.prototype, "selectedId", void 0);
LeaderboardMiniLeague = __decorate([
    customElement("leaderboard-mini-league")
], LeaderboardMiniLeague);
export { LeaderboardMiniLeague };
