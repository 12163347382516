var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
let ReferAFriendView = class ReferAFriendView extends LitElement {
    render() {
        return html `
      <router-outlet></router-outlet>
    `;
    }
};
ReferAFriendView.styles = [globalCSS, css `
    :host {
      display: block;
      width: 38.75em;
      max-width: 100%;
      margin: 0 auto;
    }
  `];
ReferAFriendView = __decorate([
    customElement("refer-a-friend-view")
], ReferAFriendView);
export { ReferAFriendView };
