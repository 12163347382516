import { css } from "lit";
const ufTooltipStyles = css `

  :host {
    max-width: 16em;
  }

  :host > div {
    --tooltip-chevron-width: 1.125em;
    --tooltip-chevron-height: 0.8125em;
    padding: var(--tooltip-padding, 1.25em 1.5em);
    text-align: center;
    background-color: var(--color-background);
    border-radius: 1.25em;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  :host > div:after {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0; 
  }

  .bottom::after, .top::after {
    border-left: calc(var(--tooltip-chevron-width) / 2) solid transparent;
    border-right: calc(var(--tooltip-chevron-width) / 2) solid transparent;
  }

  .bottom::after {
    bottom: 100%;
    border-bottom: var(--tooltip-chevron-height) solid var(--color-background);
  }

  .top::after {
    top: 100%;
    border-top: var(--tooltip-chevron-height) solid var(--color-background);
  }

  .left::after, .right::after {
    border-top: calc(var(--tooltip-chevron-width) / 2) solid transparent;
    border-bottom: calc(var(--tooltip-chevron-width) / 2) solid transparent;
  }

  .left::after {
    left: 100%;
    border-left: var(--tooltip-chevron-height) solid var(--color-background);
  }

  .right::after {
    right: 100%;
    border-right: var(--tooltip-chevron-height) solid var(--color-background);
  }

  span {
    font-size: var(--smallfontsize);
  }

`;
export default ufTooltipStyles;
