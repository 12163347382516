var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconTick = class IconTick extends LitElement {
    render() {
        return html `
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.85714L7.1 14L19 2"stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `;
    }
};
IconTick.styles = [iconCSS, css `
    path {
      stroke: var(--tick-color, #262626);
    }
  `];
IconTick = __decorate([
    customElement("icon-tick")
], IconTick);
export { IconTick };
