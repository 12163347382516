var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import globalCSS from "~/styles/global.css";
import "@/components/forms/form-textbox";
import "@/components/forms/form-select";
import "@/components/forms/form-grid";
import "@/components/forms/form-row";
import { isStep2Valid } from "../signup.helper";
import { initialValues, initialErrors } from "../signup.config";
import ConfigurationService from "@/services/config/config";
import { validateCountry, validateState } from "@/services/utils/validation";
import analytics from "@/services/utils/analytics";
import { AnalyticsKeys } from "@/services/utils/analytics.constants";
import { canadaStates, countryList, usaStates } from "~/app/services/utils/consts";
const pageStyles = [css `
  header {
    margin-bottom: 2.5em;
  }
  form-row button {
    width: 50%;
  }
  .inline-select {
    appearance: none;
    background: transparent;
    color: #f9f9f9;
  }
`];
let SignupStep2 = class SignupStep2 extends LitElement {
    constructor() {
        super(...arguments);
        this.confirmEmailAddress = "";
        this.backRoute = "/";
        this.setErrors = () => null;
        this.setStepNumber = () => null;
        this.isStepValid = false;
        this.handleChange = () => null;
        this.values = initialValues;
        this.errors = initialErrors;
        this.countryList = this.generateOptionsList(countryList);
        this.stateList = [];
        this.onChange = (e) => {
            this.handleChange(e);
        };
        this.didBlurNFLCountry = (e) => {
            const { value } = e.target;
            this.validateNFLCountry(value);
        };
        this.didBlurNFLState = (e) => {
            const { value } = e.target;
            this.validateNFLState(value);
        };
    }
    connectedCallback() {
        super.connectedCallback();
        analytics.recordTag(AnalyticsKeys.REGISTRATION_SECURITY_PAGE);
    }
    handleBackClicked() {
        this.setStepNumber(1);
    }
    handleNextClicked() {
        if (!ConfigurationService.isUF) {
            this.validateNFLCountry(this.values.country);
            this.validateNFLState(this.values.county);
        }
        this.isStepValid = isStep2Valid(this.values, this.errors, ConfigurationService.useMinRequirementsAPI());
        if (!this.isStepValid)
            return;
        this.setStepNumber(3);
    }
    validateNFLCountry(value) {
        if (validateCountry(value)) {
            this.values.country = value;
            this.errors.country = "";
        }
        else {
            this.errors.country = 'Select United States, USA or Canada';
        }
        this.createOptionsList(value);
        this.validateNFLState(this.values.county);
        this.isStepValid = isStep2Valid(this.values, this.errors, ConfigurationService.useMinRequirementsAPI());
        this.requestUpdate();
    }
    validateNFLState(value) {
        if (validateState(this.values.country, value)) {
            this.values.county = value;
            this.errors.county = "";
        }
        else {
            this.errors.county = `${value} is not a valid state`;
        }
        this.isStepValid = isStep2Valid(this.values, this.errors, ConfigurationService.useMinRequirementsAPI());
        this.requestUpdate();
    }
    createOptionsList(value) {
        switch (value) {
            case 'canada':
                this.stateList = this.generateOptionsList(canadaStates);
                break;
            case 'usa':
                this.stateList = this.generateOptionsList(usaStates);
                break;
            default: this.stateList = [];
        }
    }
    generateOptionsList(list) {
        return list.map(name => {
            return {
                text: name.toUpperCase(),
                value: name
            };
        });
    }
    countyStateTextOrSelect(values, errors) {
        if (ConfigurationService.isUF) {
            return html `
        <form-textbox
          name="county"
          .minlength=${2}
          .maxlength=${50}
          .handleChange=${this.onChange}
          .value=${values.county}
          .errorMessage=${errors.county}
          placeholder="county">
        </form-textbox>
        <form-textbox
          name="country"
          type="text"
          inputmode="text"
          .handleChange=${this.onChange}
          .value=${values.country}
          .errorMessage=${errors.country}
          placeholder="country">
        </form-textbox> 
      `;
        }
        else {
            return html `
        <form-select
          name="country"
          .handleChange=${this.didBlurNFLCountry}
          .value=${values.country}
          .options=${this.countryList}
          .errorMessage=${errors.country}>
        </form-select>

        <form-select
          name="county"
          .handleChange=${this.didBlurNFLState}
          .value=${values.county}
          .options=${this.stateList}
          .errorMessage=${errors.county}>
        </form-select>
      `;
        }
    }
    render() {
        const { values, errors } = this;
        if (ConfigurationService.useMinRequirementsAPI())
            this.createOptionsList(this.values.country);
        return html `
      <header>
        <h1>Step 2</h1>
        <span>Contact Details</span>
      </header>

      <form-grid>


      ${ConfigurationService.useMinRequirementsAPI() ? html `
        <form-textbox
          name="mobileNumber"
          type="tel"
          inputmode="numeric"
          pattern="[0-9\\s+-]{0,13}"
          customPrefix="+1"
          .minlength=${7}
          .maxlength=${13}
          .handleChange=${this.onChange}
          .value=${values.mobileNumber}
          .errorMessage=${errors.mobileNumber}
          placeholder="telephone number">
        </form-textbox>

        ${this.countyStateTextOrSelect(values, errors)}

      ` : null}

      ${!ConfigurationService.useMinRequirementsAPI() ? html `
        <form-textbox
          name="mobileNumber"
          type="tel"
          inputmode="numeric"
          pattern="[0-9\\s+-]{0,13}"
          .minlength=${7}
          .maxlength=${13}
          .handleChange=${this.onChange}
          .value=${values.mobileNumber}
          .errorMessage=${errors.mobileNumber}
          placeholder="mobile number">
        </form-textbox>
        <form-textbox
          name="line_1"
          .minlength=${2}
          .maxlength=${20}
          .handleChange=${this.onChange}
          .value=${values.line_1}
          .errorMessage=${errors.line_1}
          placeholder="address line 1"
        >
        </form-textbox>
        <form-textbox
          name="line_2"
          .minlength=${2}
          .maxlength=${20}
          .handleChange=${this.onChange}
          .value=${values.line_2}
          .errorMessage=${errors.line_2}
          placeholder="address line 2"
        >
        </form-textbox>
        <form-textbox
          name="city"
          .minlength=${2}
          .maxlength=${20}
          .handleChange=${this.onChange}
          .value=${values.city}
          .errorMessage=${errors.city}
          placeholder="city"
        >
        </form-textbox>
        <form-textbox
          name="postcode"
          .minlength=${2}
          .maxlength=${20}
          .handleChange=${this.onChange}
          .value=${values.postcode}
          .errorMessage=${errors.postcode}
          placeholder="postcode"
        >
        </form-textbox>
        ` : null}


      </form-grid>

      <form-row>
        <button 
          class="grey no-border" 
          @click=${this.handleBackClicked}>
          Previous Step
        </button>
        <button
          @click=${this.handleNextClicked}
          .disabled=${!this.isStepValid}
          .isPrimary=${true}
          id="nxt-btn">
          Next Step
        </button>
      </form-row>
    `;
    }
};
SignupStep2.styles = [globalCSS, pageStyles];
__decorate([
    property()
], SignupStep2.prototype, "confirmEmailAddress", void 0);
__decorate([
    property()
], SignupStep2.prototype, "backRoute", void 0);
__decorate([
    state()
], SignupStep2.prototype, "setErrors", void 0);
__decorate([
    state()
], SignupStep2.prototype, "setStepNumber", void 0);
__decorate([
    state()
], SignupStep2.prototype, "isStepValid", void 0);
__decorate([
    state()
], SignupStep2.prototype, "handleChange", void 0);
__decorate([
    state()
], SignupStep2.prototype, "values", void 0);
__decorate([
    state()
], SignupStep2.prototype, "errors", void 0);
__decorate([
    state()
], SignupStep2.prototype, "countryList", void 0);
__decorate([
    state()
], SignupStep2.prototype, "stateList", void 0);
SignupStep2 = __decorate([
    customElement("signup-step2")
], SignupStep2);
export { SignupStep2 };
