var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import iconCSS from "@/components/asset/uf-icon/icon.css";
let IconCurrency = class IconCurrency extends LitElement {
    render() {
        return html `
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="8.125" stroke-width="1.75" />
        <path d="M8.42967 11.404V9.712H10.8657V8.26H8.42967V7.12C8.42967 6.388 8.89767 6.04 9.46167 6.04C10.0737 6.04 10.3977 6.376 10.6017 7L12.0297 6.184C11.5857 5.092 10.6497 4.432 9.42567 4.432C8.14167 4.432 6.78567 5.236 6.78567 7.06V8.26H6.05367V9.712H6.78567V11.404H5.90967V13H12.3537V11.404H8.42967Z" />
      </svg>
    `;
    }
};
IconCurrency.styles = [iconCSS, css `
    circle {
      stroke: var(--color);
    }
    path {
      fill: var(--color);
    }
  `];
IconCurrency = __decorate([
    customElement("icon-currency")
], IconCurrency);
export { IconCurrency };
