var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UnscopedElement from "@/layout/unscoped-element";
import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import "./transfer-summary.css";
import "@/components/header-bar/header-bar";
import "@/components/asset/uf-icon/uf-icon";
import "@/components/game-card/game-card-front/game-card-front";
import "@/views/game/views/squad/views/transfer/components/transfer-tray/transfer-tray";
import "@/components/uf-modal/uf-modal";
import "@/components/loading-spinner/loading-spinner";
import router from "~/app/router/router";
import { pluralise } from "~/app/directives/app.directives";
import ufApi from "~/app/services/api/uf/uf.api";
let TransferSummary = class TransferSummary extends UnscopedElement {
    constructor() {
        super(...arguments);
        this.index = 0;
        this.transfer = "out";
        this.activeSquadSelections = [];
        this.collectionSelections = [];
        this.confirmModalOpen = false;
        this.confirmationLoading = false;
        this.cancelModalOpen = false;
    }
    get activeSquadSelected() {
        return this.activeSquadSelections.filter(selection => selection.selected);
    }
    get collectionSelected() {
        return this.collectionSelections.filter(selection => selection.selected);
    }
    get selected() {
        return this.transfer === "in" ? this.collectionSelected : this.activeSquadSelected;
    }
    render() {
        return html `

      <header-bar class="tall">
        <uf-icon icon="cancel" class="clickable" slot="left" @click=${() => this.cancelModalOpen = true}></uf-icon>
        <div slot="center">
          <h1>Summary</h1>
        </div>
      </header-bar>

      <section>
        <h2>Transfer ${this.transfer}</h2>
        ${when(this.selected.length, () => html `
            <span class="small-text">${this.selected.length} ${pluralise("Card", this.selected.length)}</span>
          `)}

        ${this.selected.length ? html `
            <div id="selected">
              ${this.selected.map(card => html `
                  <game-card-front .squadItem=${card.item}></game-card-front>
                `)}
            </div>
          ` : html `
            <p><span>You have not made any selections</span></p>
          `}
        
      </section>

      <transfer-tray>
        <div slot="body">
          <uf-icon icon="warning" theme="circle"></uf-icon>
          <p><span>Warning! Cards that you transfer out cannot be transferred back in later.</span></p>
        </div>
        <div slot="buttons">
          <button class="dark" @click=${this.back}>
            <span>Go Back</span>
          </button>
          ${this.index === 2 ? html `
              <button @click=${this.next}>
                <span>Next</span>
              </button>
              ` : html `
              <button class="active" @click=${() => this.confirmModalOpen = true} ?disabled=${this.confirmModalOpen}>
                <span>Confirm Transfer</span>
              </button>
            `}
        </div>
      </transfer-tray>

      ${when(this.cancelModalOpen, () => html `
          <uf-modal>
            <div>
              <div>
                <h2>Cancel Changes</h2>
                <p>Are you sure you want to cancel all your changes?</p>
              </div>
              <footer>
                <button class="plain" @click=${() => this.cancelModalOpen = false}>
                  <span>No, Go Back</span>
                </button>
                <button class="cancel" @click=${this.cancelChanges}>
                  <span>Cancel Changes</span>
                </button>
              </footer>
            </div>
          </uf-modal>
        `)}

      ${when(this.confirmModalOpen, () => html `
          <uf-modal>
            <div>
              <div>
                <h2>WARNING!!!</h2>
                <p>Warning! Cards that you transfer out cannot be transferred back in later.</p>
              </div>
              <footer>
                ${!this.confirmationLoading ? html `
                    <button class="plain" @click=${() => this.confirmModalOpen = false}>
                      <span>No, Go Back</span>
                    </button>
                    <button class="active" @click=${this.confirmTransfer}>
                      <span>Confirm Transfer</span>
                    </button>
                  ` : html `
                    <loading-spinner></loading-spinner>
                  `}
              </footer>
            </div>
          </uf-modal>
        `)}

    `;
    }
    next() {
        this.dispatchEvent(new Event("next"));
    }
    back() {
        this.dispatchEvent(new Event("back"));
    }
    async confirmTransfer() {
        if (this.activeSquadSelected.length || this.collectionSelected.length) {
            this.confirmationLoading = true;
            const transfers = {
                in: this.collectionSelected.map(card => card.item.cardId),
                out: this.activeSquadSelected.map(card => card.item.cardId)
            };
            try {
                await ufApi.transfer(transfers);
                router.navigate("squad");
                this.confirmationLoading = false;
            }
            catch (e) {
                this.handleConfirmError(e);
            }
        }
    }
    handleConfirmError(e) {
        console.log(e);
        this.confirmationLoading = false;
    }
    cancelChanges() {
        this.dispatchEvent(new Event("cancel"));
    }
};
__decorate([
    property({ type: Number })
], TransferSummary.prototype, "index", void 0);
__decorate([
    property()
], TransferSummary.prototype, "transfer", void 0);
__decorate([
    property({ type: Object })
], TransferSummary.prototype, "activeSquadSelections", void 0);
__decorate([
    property({ type: Object })
], TransferSummary.prototype, "collectionSelections", void 0);
__decorate([
    state()
], TransferSummary.prototype, "confirmModalOpen", void 0);
__decorate([
    state()
], TransferSummary.prototype, "confirmationLoading", void 0);
__decorate([
    state()
], TransferSummary.prototype, "cancelModalOpen", void 0);
TransferSummary = __decorate([
    customElement("transfer-summary")
], TransferSummary);
export { TransferSummary };
