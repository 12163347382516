import { SquadItemPosition, SquadItemSet, SquadItemType } from "@/services/api/uf/types/uf.api.types.enums";
import config from "@/services/config/config";
export function squadPositions() {
    switch (config.skin.league) {
        case "pl": return ["ALL", "DEF", "MID", "FWD", "TEAM"];
        case "nfl": return ["ALL", "RB", "TE", "WR", "QB", "TEAM"];
    }
}
/**
 * Filters and sorts an array of squad items to form the bench.
 * @param squad The squad items to sort.
 * @param filter Keyword to filter items.
 * @returns Array of bench squad items.
 */
export function filterSquadByPosition(squad, filter) {
    return squad.filter(item => {
        if (item.set === SquadItemSet.Squad || item.set === SquadItemSet.Collection)
            return filterSquadItem(item, filter);
        return;
    });
}
export function filterSquadItem(item, filter) {
    switch (filter) {
        case "ALL":
            return !!item;
        case "DEF":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Defender);
        case "MID":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Midfielder);
        case "FWD":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Forward);
        case "TEAM":
            return item.scoreableType === SquadItemType.Team;
        case "RB":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Defender);
        case "TE":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Midfielder);
        case "WR":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Forward);
        case "QB":
            return item.scoreableType === SquadItemType.Player && item.position === (SquadItemPosition.Any || SquadItemPosition.Goalkeeper);
        case "MOD":
            return false;
    }
}
/**
 * Filters an array of squad items by the items name/team from some filter text.
 * @param squad The squad items to sort.
 * @param filter Text to filter items.
 * @returns Array of bench squad items.
 */
export function filterSquadBySearch(squad, filter) {
    if (filter.length == 0)
        return squad;
    return squad.filter(item => {
        const searchString = `${item.firstname} ${item.lastname} ${item.knownname} ${item.team}`.toLowerCase();
        return searchString.includes(filter.toLowerCase());
    });
}
