import { css } from "lit";
const changePasswordCSS = css `

  :host {
    padding-bottom: 3em;
  }
  
  form {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 5em;
  }

  form button {
    width: 100%;
    margin-top: 4.5em;
  }

  #loading {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  loading-spinner {
    width: 3em;
  }

  uf-modal {
    text-align: center;
  }
  uf-modal p {
    margin-top: 0.5em;
  }
  uf-modal > div {
    display: flex;
    justify-content: center;
  }
  uf-modal button {
    border: none;
  }

  @media (max-height: 600px) and (min-width: 450px) {
    main {
      height: 75%;
    }
    main > div {
      margin: 1.5em 0;
    }
  }

  @media (max-height: 500px) and (min-width: 450px) {
    form {
      height: auto;
    }
  }

`;
export default changePasswordCSS;
