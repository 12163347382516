var _a, _b, _c;
import { css, unsafeCSS } from "lit";
import config from "@/services/config/config";
const colour = (_c = (_b = (_a = config.skin.features) === null || _a === void 0 ? void 0 : _a.raf) === null || _b === void 0 ? void 0 : _b.theme) === null || _c === void 0 ? void 0 : _c.colour;
const loginStreakOnboardingCSS = css `

    :host {
        --color-reward-card-font: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.colorRewardCardFont) || "#F6F3EE")};
        --background-reward-card: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundRewardCard) || "#7F1E31")}; 
        --row-item-background: ${unsafeCSS((colour === null || colour === void 0 ? void 0 : colour.backgroundReferralItem) || "#47284A")};
        --grid-width: 38.75em;
        --container-width: 48.25em;
    }

    uf-overlay > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2.5em auto 0;
        width: 100%;
        max-width: var(--container-width);
    }

    header-bar {
        --header-bar-background: transparent;
    }
    
    header-bar uf-icon {
        width: 2em;
    }

    section {
        display: flex;
        width: 100%;
        max-width: var(--grid-width);
        flex-direction: column;
        row-gap: 2em;
        justify-content: center;
        margin: 0 auto 3em;
    }

    section .button, section p{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    section .info-bubble {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 2em;
    }

    section .info-bubble p {
        width: 23.2em;
        border-radius: 1.25em;
        padding: 1.125em;
        margin: 0 auto;
        background-color: var(--background-reward-card);
        color: var(--color-reward-card-font);
    }

    uf-icon[icon=upTriangle] {
        width: 1.7725;
        margin: 0em auto;
        transform: translate(0%, 18%);
    }

    div.header {
        display: flex;
        text-align: center;
        flex-direction: column;
        row-gap: 2em;
        width: 23.875em;
        max-width: 100%;
        margin: 0 auto;
    }

    div.header p {
        margin: 0;
        font-size: 1em;
    }

    uf-icon[icon=lsOnboarding] {
        width: 3.625em;
        margin: 0em auto;
    }

    uf-icon[icon=packReward] {
        width: 1em;
    }

    h1 {
        font-size: 2.25em;
        text-align: center;
    }

    hr {
        margin: 0 0 1em;
        width: 100%;
    }

    #table {
        display: flex;
        flex-direction: column;
        row-gap: 0.5em;
    }

    #table #header{
        display: flex;
        column-gap: 0.5em;
        justify-content: space-evenly;
    }

    #table .row{
        display: flex;
        column-gap: 0.5em;
        justify-content: space-evenly;
        border-radius: 0.9375em;
        color: var(--color);
        background-color: var(--row-item-background);
    }

    .row > * {
        width: 7.75em;
        text-align: center;
        font-size: 1.25em;
    }

    .row p.dash {
        color: #111111
    }

    #header > *{
        width: 7.75em;
        margin: 0;
        text-align: center;
        font-size: 1.25em;
    }

    .reward-card {
        display: flex;
        /* position: absolute; */
        
        top: 2.7em;
    }

    .pack-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.25em;
        padding: 0.3125em 0.4375em;
        border-radius: 0.3125em;
        color: var(--color-reward-card-font);
        height: 1.976em;
        margin: auto;
        background-color: var(--background-reward-card);
    }

    .pack-wrapper.small-text {
        padding: 0;
    }

    @media (max-width: 600px) {
        :host {
            --grid-width: calc(100vw - 2em);
        }
    }

`;
export default loginStreakOnboardingCSS;
