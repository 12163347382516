export const isStep1Valid = (values, errors) => !errors.email &&
    !!values.email &&
    !errors.firstName &&
    !!values.firstName &&
    !errors.lastName &&
    !!values.lastName &&
    !errors.username &&
    !!values.username &&
    !errors.birthDate &&
    !!values.birthDate.match(/\d{2}\/\d{2}\/\d{4}/g);
export const isStep2Valid = (values, errors, minRequirements) => {
    if (minRequirements) {
        return !errors.mobileNumber &&
            !!values.mobileNumber &&
            !errors.county &&
            !!values.county &&
            !errors.country &&
            !!values.country;
    }
    return !errors.mobileNumber &&
        !!values.mobileNumber &&
        !errors.postcode &&
        !!values.postcode &&
        !errors.line_1 &&
        !!values.line_1 &&
        !errors.city &&
        !!values.city;
};
export const isStep3Valid = (values, errors, minRequirements) => {
    if (minRequirements) {
        return !errors.password &&
            !!values.password &&
            !errors.passwordConfirm &&
            !!values.passwordConfirm;
    }
    return !errors.password &&
        !!values.password &&
        !errors.passwordConfirm &&
        !!values.passwordConfirm &&
        !errors.pin &&
        !!values.pin &&
        !errors.pinConfirm &&
        !!values.pinConfirm;
};
export const isStep4Valid = (values, errors) => {
    var _a, _b;
    return values.agreedTerms &&
        values.agreedPrivacy &&
        !((_a = errors.email) === null || _a === void 0 ? void 0 : _a.length) &&
        !((_b = errors.username) === null || _b === void 0 ? void 0 : _b.length);
};
